// 确保当前 html 的属性是 fixed
function guardFixed(element) {
  try {
    return getComputedStyle(element).position === 'fixed'
  } catch (error) {
    return true
  }
}

// 确保当前路由是 routeName
function guardRouteName(vnode, routeName) {
  try {
    return vnode.context.$route.name === routeName
  } catch (error) {
    return false
  }
}

export default {
  inserted(el, { value, arg }, vnode) {
    if (el && (value || value === undefined) && guardFixed(el) && guardRouteName(vnode, arg)) {
      el.setAttribute('data-transfer-dom', '')
      document.body.appendChild(el)
    }
  },
  unbind(el) {
    if (el && el.parentNode && el.hasAttribute('data-transfer-dom')) {
      el.parentNode.removeChild(el)
    }
  }
}
