/**
 * needLogin middleware
 * 当页面需要登录的时候，需要加载此middleware到page component
 * @Component({
    async fetch({ store, query }) {
      const { mock, lng, lat } = query
      await store.dispatch('traveller/home-page/getHomePageData', { mock, lng, lat })
    },
    middleware: ['needLogin']
  })
 */
import { Middleware } from '@nuxt/types'
import api from '~/share/data/apis'

const middleware: Middleware = async function (ctx, next) {
  if (process.server) {
    const sdk = ctx.req.whiteLabelSdk

    if (sdk) {
      const redirectUrl = ctx.route.fullPath

      try {
        const resp = await ctx.app.$axios.$get(api.verify)
        if (!resp.success) { // 登录态失效
          await sdk.login({
            redirectUrl
          })
        }
      } catch (_error) { // 接口异常
        await sdk.login({
          redirectUrl
        })
      }
    }
  }

  next()
}

export default middleware
