import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import { RootState } from '~/store'
import { Activity } from '~/types/traveller/activity'
// import { ActivityTemplateIds } from '~/share/data/activity'
import { formatNumberInThousands, group } from '~/share/utils'

import bookInfo = Activity.bookInfo

const getters = {
  getPkgPreSettlementTips2getters(state: any) {
    return (pid: number) => {
      const obj = state.pkgsPrePriceApiData[pid]
      return obj?.result?.tips
    }
  },
  preSettlementTips2getters(state: any) {
    const obj = state.preSettlementApiData?.result?.tips
    return obj
  },
  breadcrumbDetail(state: any) {
    return [
      'countryName',
      'templateName',
      'mainTagName',
      'indexName',
      'cityName',
      'langPath',
      'countryId',
      'cityId',
      'countrySeoUrl',
      'templateId',
      'mainTagId',
      'citySeo',
      'isCityPublish',
      'isCountryPublish',
      'frontendTree'
    ].reduce((acc, v) => {
      acc[v] = state[v]
      return acc
    }, {} as any)
  },
  // mobile只有一张banner图
  headBannerImage(state: Activity.State) {
    const { actBannerV2List } = state
    return !isEmpty(actBannerV2List) ? actBannerV2List[0] : {}
  },
  merchatImageAmount(state: Activity.State) {
    const { actBannerV2List } = state
    return !isEmpty(actBannerV2List) ? actBannerV2List.length : 0
  },
  headInfo(state: Activity.State) {
    const { score, reviewCount, participantsFormat } = state

    return {
      score: score > 0 ? parseFloat(String(score)).toFixed(1) : '',
      reviewCount: Number(reviewCount) > 0 ? formatNumberInThousands(reviewCount) : '',
      participantsFormat
    }
  },
  editorChoice(state: Activity.State) {
    return state.cardTags.editorChoice
  },
  packageSelectedDate(state: Activity.State) {
    return state.packageSelectedDate
  },

  // 选择了所有的销售属性
  hasSelectedAllAttrs(state: Activity.State) {
    const { specList, chosenIds } = state
    return chosenIds?.length && chosenIds?.length === specList?.length
  },

  // 活动是否发布
  isPublished(state: Activity.State) {
    /**
     * published 活动发布
     * isCurrentLangPublish 活动在当前语言下发布
     * 两个开关是独立但，只要一个为false那么活动就是下架状态
     */
    return (state.isCurrentLangPublish) && (state.published || state.preview)
  },
  // 所有套餐售罄
  isSoldOut(state: Activity.State) {
    const { packages } = state
    return isEmpty(packages) || packages.every(v => !v.hasStocks)
  },
  isCrosellBannerVisible(state: Activity.State, getter: any) {
    const { isPublished, isSoldOut } = getter
    const { crosellBanner } = state

    return isPublished && !isSoldOut && !!crosellBanner
  },
  // 是否显示腰带(预热中和促销中都要显示)
  isShowPromotionDetail(state: Activity.State) {
    const promotionEvent = state.promotionDetail.promotion_event || {}
    return promotionEvent.status === 4 || promotionEvent.status === 5
  },
  // 是否处于促销中，需要处于当前平台
  isInPromotion(state: Activity.State, _getters: any, rootState: RootState) {
    const promotionEvent = state.promotionDetail.promotion_event || {}
    const platform = rootState.klook.platform === 'mobile' ? 'mweb' : 'web'
    const platformList = promotionEvent.platforms || []

    return promotionEvent.status === 5 && platformList.includes(platform)
  },
  // 价格属性是套餐（单个价格属性），并且正在促销
  isSingleSpecWithDiscount(state: Activity.State) {
    const { havePackageDiscount, specList } = state
    return havePackageDiscount && specList.length === 1
  },
  // 多个价格属性，并且正在促销
  isMultiSpecWithDiscount(state: Activity.State) {
    const { havePackageDiscount, specList } = state
    return havePackageDiscount && specList.length > 1
  },
  // isGlobalShowMarketPrice(state: Activity.State) {
  // todo 2020-01-29 qiliang
  // 因为需要暂时将所有类目活动的划线价全部展示出来，所以将这个判断强制为 true
  // 不过暂时保留原有逻辑，便于后期恢复
  //   const { templateId } = state
  //   return ActivityTemplateIds.includes(templateId)
  // },
  // 固定template_id要显示划线价
  isGlobalShowMarketPrice() {
    return true
  },
  // 未售罄套餐
  validPackages(state: Activity.State) {
    return state.packages.filter(v => v.hasStocks)
  },
  // 单个attr的套餐
  singleAttrPackages(state: Activity.State) {
    return state.packages.filter(v => v.specAttrIds.length === 1)
  },
  // 有套餐的销售属性组合
  validPackageAttrIds(state: Activity.State, getters: any) {
    return getters.validPackages.reduce((acc: any, v: Activity.activityPackage) => {
      const { specAttrIds = [] } = v

      return group(specAttrIds).reduce((idAcc, ids) => {
        const id = [...ids].sort().join(',')
        const isPackage = ids.length === state.specList.length

        // 如果销售属性id的组合对应一个套餐的完整attrId，将套餐暴露出去便于获取选中套餐
        return { ...idAcc, [id]: isPackage ? v : true }
      }, acc)
    }, {} as any)
  },

  // 当前选中的套餐
  currentSelectedPackage(state: Activity.State) {
    const { packageId, packages } = state
    if (packageId != null) {
      return packages.find(v => v.packageId === packageId) || {}
    }

    return {}
  },

  // 有库存的日期
  validSchedules(state: Activity.State) {
    const { schedules } = state

    // 按日期排序
    const sortedSchedulesKeys = [...Object.keys(schedules)].sort((a, b) => dayjs(a).diff(dayjs(b)))

    const validSortedSchedulesKeys = sortedSchedulesKeys.filter((date) => {
      const currentSchdule = schedules[date]

      if (!currentSchdule) {
        return false
      }

      // 当前日期下所有套餐都没有库存
      return Object.values(currentSchdule).reduce((acc, v) => acc + v, 0)
    })

    return validSortedSchedulesKeys.reduce((acc, v) => ({ ...acc, [v]: schedules[v] }), {})
  },

  // 所有已选的预定unit的数量
  totalUnitCount(state: Activity.State) {
    const { selectedTime: { arrangementId }, unitsMap } = state

    if (!arrangementId) {
      return 0
    }

    const units = unitsMap[arrangementId]
    if (units) {
      // 所有已选的预定unit的数量
      return units.unitList.reduce((acc: number, v: any) => acc + v.count, 0)
    }
    return 0
  },

  units(state: Activity.State, getters: any) {
    const { selectedTime: { arrangementId, maxPurchased }, unitsMap } = state
    const { totalUnitCount } = getters
    if (arrangementId) {
      const units = unitsMap[arrangementId]
      if (units) {
        return units.unitList.map((v: any) => ({
          ...v,
          minCount: v.required ? v.priceMinPax : 0,

          // 最大可预定的unit数量由unit的最大可预定数量和当前套餐的库存确定
          maxCount: Math.min(v.priceMaxPax, maxPurchased - (totalUnitCount - v.count))
        }))
      }
    } else if (unitsMap.default) {
      return unitsMap.default.unitList || [] // web没选中日期取默认数据
    } else {
      return []
    }
  },

  inventories(state: Activity.State) {
    const { selectedTime: { arrangementId }, unitsMap } = state

    if (arrangementId) {
      const units = unitsMap[arrangementId]

      if (units) {
        return units.inventories
      }
    }

    return {}
  },

  packageSchedules(state: Activity.State) {
    const { packageId, packageSchedulesMap } = state
    if (!packageId) {
      return []
    }

    const schedules = packageSchedulesMap[packageId]
    return schedules || []
  },

  priceSchedulesObj(state: Activity.State) {
    const { priceSchedules } = state
    return priceSchedules.reduce((acc, v) => ({ ...acc, [v.date]: v }), {})
  },

  selectedPackagePriceSchedule(state: Activity.State, getters: any) {
    const { packageSelectedDate } = state
    const { packagePriceSchedulesObj } = getters
    return packagePriceSchedulesObj[packageSelectedDate]
  },

  packagePriceSchedulesObj(_state: Activity.State, getters: any) {
    const { packagePriceSchedules } = getters
    return packagePriceSchedules.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
  },

  packagePriceSchedules(state: Activity.State) {
    const { packageId, packagePriceSchedulesMap } = state
    if (!packageId) {
      return []
    }

    return packagePriceSchedulesMap[packageId] ? packagePriceSchedulesMap[packageId].schedules : []
  },

  packageSchedulesFormatInDay(state: Activity.State) {
    const { packageId, packageSchedulesFormatInDayMap } = state
    if (!packageId) {
      return {}
    }

    const schedules = packageSchedulesFormatInDayMap[packageId]
    return schedules || {}
  },

  soldoutSchedules(state: Activity.State, getters: any) {
    const { packageId, schedules } = state
    const { packageSchedulesFormatInDay, currentSelectedPackage } = getters
    // 已经选中套餐，根据schedules_and_units获取的套餐日历判断库存和子主套餐是否售罄
    return Object.keys(schedules).reduce((acc: any, d: string) => {
      if (packageId) {
        let currentPackageSchedules: any = []
        if (packageSchedulesFormatInDay[d]) {
          currentPackageSchedules = currentPackageSchedules.concat(packageSchedulesFormatInDay[d])
        }

        if (isEmpty(currentPackageSchedules)) {
          return { ...acc, [d]: false }
        }

        const stock = currentPackageSchedules.reduce((acc: any, v: any) => acc + v.stock, 0)
        return { ...acc, [d]: stock === 0 || stock < (currentSelectedPackage || {}).minPax }
      }

      const schedule = schedules[d]
      if (schedule !== undefined) {
        for (const key in schedule) {
          const item = schedule[key]
          if (item !== 0) {
            return { ...acc, [d]: false }
          }
        }
        return { ...acc, [d]: false }
      }
      return { ...acc, [d]: false }
    }, {})
  },

  activitySoldOutSchedules(state: Activity.State) {
    const { schedules } = state
    // 已经选中套餐，根据schedules_and_units获取的套餐日历判断库存和子主套餐是否售罄
    const obj: any = {}
    Object.keys(schedules).forEach((item: any) => {
      let n = 0
      for (const k in schedules[item]) {
        n += schedules[item][k]
      }
      obj[item] = n <= 0
    })
    return obj
  },

  activitySpecList(state: Activity.State, getters: any) {
    const { specList, chosenIds, packageSelectedDate } = state

    const { validPackageAttrIds, packagesInDate, singleAttrPackages, isSingleSpecWithDiscount, isNewOpenTicket } = getters

    return specList.map((v) => {
      return {
        ...v,
        attrs: v.attrs.map((attr) => {
          const { id } = attr
          // 去除同级的已选销售属性
          const otherChooseIds = chosenIds.filter(id => !v.attrs.find(attr => attr.id === id))

          const attrKey = Array.from(new Set(otherChooseIds).add(id)).sort().join(',')

          // 新openticket套餐因为没有日历，所以这个字段强制为true
          const isScheduleMatch = (!isNewOpenTicket && packageSelectedDate)
            ? packagesInDate.find((pkg: Activity.activityPackage) => pkg.specAttrIds.includes(id))
            : true

          // 折扣信息：单个spec的话，并且有促销的话，需要显示折扣信息
          let promotionDiscount = 0
          if (isSingleSpecWithDiscount) {
            const singleAttrPackage = singleAttrPackages.find((pkg: Activity.activityPackage) => pkg.specAttrIds.includes(id))
            promotionDiscount = singleAttrPackage ? singleAttrPackage.promotionDiscount : 0
          }

          /**
           * 如某个日期下
           * A B C 构成套餐，但无库存
           * D B E 构成套餐，有库存
           * A D属于同一销售性，C E属于同一销售属性，B属于单独的销售属性
           * 选择AC后，B虽然可以组合成套餐，但不应该可选，同时因为不展示日期不可用的样式，所以也是算为不构成套餐的
           */
          const isPackageSkuInSchedule = packagesInDate
            .some((pkg: Activity.activityPackage) => [...otherChooseIds, id].every(id => pkg.specAttrIds.includes(id)))

          return {
            ...attr,
            promotionDiscount,
            isPackageMatch: validPackageAttrIds[attrKey] && (isNewOpenTicket || isPackageSkuInSchedule),
            isScheduleMatch,
            isActive: chosenIds.includes(attr.id)
          }
        })
      }
    })
  },

  isAttrsSelectedAll(state: Activity.State) {
    const { specList, chosenIds } = state
    return specList.length === chosenIds.length
  },

  packagesInDate(state: Activity.State, getters: any): Activity.activityPackage[] {
    const { packageSelectedDate, routeName } = state
    const { priceSchedulesObj } = getters
    const { validPackages } = getters

    if (!packageSelectedDate) {
      return validPackages
    }

    const currentSchedules = priceSchedulesObj[packageSelectedDate]

    if (!currentSchedules) {
      return []
    }

    /**
     * booking option页面认为所以套餐在当天可用
     * booking options页面因为都是通过packageId拿到的套餐日历
     * 所以在选择日历之后即使日期不匹配不反选套餐，如某一日期主套餐可以购买，但当天的schedule里只有子套餐的库存，packageId不一致导致判断当天没有主套餐
     * 只在跟新packageId之后判断下日期不匹配清除日历重选
     */
    if (routeName === 'ActivityBookOptions') {
      return validPackages
    }

    return validPackages.filter((v: Activity.activityPackage) => {
      const stocks = currentSchedules.stocks
      if (!stocks) {
        return false
      }
      /**
       * 判断当前套餐是否有捆绑套餐
       * @Note 方法不完善，因为拿不到自主套餐关系，所以只能这么判断当前套餐是否有子套餐，先留着
       */
      // const currentPackageSchedule = packagePriceSchedulesObj[packageSelectedDate]
      // const hasAdditionPackage = currentPackageSchedule && currentPackageSchedule.time_slots && currentPackageSchedule.time_slots.length
      // if (hasAdditionPackage && v.packageId === packageId) {
      //   return true
      // }

      const packageStock = stocks.find((item: any) => {
        return item.package_id === v.packageId
      })

      return packageStock ? packageStock.stock > 0 : false
    })
  },

  validBookInfo(state: Activity.State) {
    const { activityBookInfo } = state

    let result: Activity.bookInfo[] = []

    for (const info of activityBookInfo) {
      const { additional } = <{ additional: Activity.normalBookInfo[] }>info
      if (additional) {
        const validAdditional = additional.filter(v => v.data)

        if (!isEmpty(validAdditional)) {
          result = [
            ...result,
            { additional: validAdditional }
          ]
        }
      } else {
        result = result.concat((<Activity.normalBookInfo>info).data ? info : [])
      }
    }
    return result
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bookInfoVisible(state: Activity.State, getters: any) {
    const { validBookInfo } = getters
    return !isEmpty(validBookInfo)
  },

  // 部分活动的banner和video需要去水印
  isNoWatermarkActivity(state: Activity.State) {
    return state.isNoWatermarkActivity
  },

  // 最早可预定时间
  bookingStartDate(state: Activity.State) {
    const { packages } = state
    const sorted = [...packages].sort((a, b) => dayjs(a.availableDate).isBefore(b.availableDate) ? -1 : 1)

    return sorted[0] && sorted[0].availableDate
  },

  // 是否预售
  isPresale(state: Activity.State) {
    const { templateId, packageSelectedDate, packageId, presalePackages } = state

    const validPresalePackages = (presalePackages || []).filter(v => v.isSupportPresale)
    const isRailTransport = templateId === 5

    if (isRailTransport && packageSelectedDate && packageId && !isEmpty(validPresalePackages)) {
      const presalePackage = validPresalePackages.find(v => v.packageId === packageId)

      if (presalePackage) {
        return dayjs(presalePackage.presaleDate).isBefore(dayjs(packageSelectedDate))
      }
    }

    return false
  },

  // 判断是不是新openticket的逻辑：
  // 在预定页，因为有套餐信息，所以通过判断套餐是不是openticket来决定
  // 在其他页(包括web和mweb的活动详情)，因为没有套餐信息，只有活动信息，所以通过判断活动是不是openticket来决定
  // 如果是新openticket，则需要：1.隐藏日历（底层自动选日期）2.隐藏timeslot（底层自动选timeslot）3.其它一些展示修改
  isNewOpenTicket(state: Activity.State, getters: any) {
    const { activityTicketType, routeName } = state
    const { currentSelectedPackage } = getters

    if (['ActivityBookOptions', 'ExperienceActivityBookOptions'].includes(routeName)) {
      return currentSelectedPackage.packageTicketType === 3
    }

    return activityTicketType === 3
  },

  isYiFunVoucher(state: Activity.State) {
    return !!state.tagsInfo?.find((tag) => {
      return tag.tag_key === 'yifun_voucher'
    })
  },

  currPackageOptionTab(state: Activity.State): 'standalone' | 'combo' | '' {
    return state.currPackageOptionTab
  }
}

export default getters
