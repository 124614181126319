import { MutationTree, ActionTree } from 'vuex'
import { HomePage } from '~/types/traveller/home-page'
import { RootState } from '~/store'
import apis from '~/share/data/apis'
import mockData from '~/pages/traveller/home-page/home/desktop/mock'
import { customVerticalMenu, customBanners } from '~/affiliate/home/hackStore.ts'

const SET_HOME_PAGE_DATA = 'SET_HOME_PAGE_DATA'
const SET_HOME_SEARCH_POP = 'SET_HOME_SEARCH_POP'

export interface HomePageState {
  homePageData: HomePage.Page
  searchPop: boolean
}

export const state = () => ({
  homePageData: null,
  searchPop: false
})

export const mutations: MutationTree<HomePageState> = {
  [SET_HOME_PAGE_DATA](state, data) {
    state.homePageData = data.page
  },
  [SET_HOME_SEARCH_POP](state, payload) {
    state.searchPop = payload
  }
}

export const actions: ActionTree<HomePageState, RootState> = {
  getHomePageData({ commit, rootState }, { mock, lng, lat, admin_preview_mode }) {
    if (mock) {
      commit(SET_HOME_PAGE_DATA, mockData)
      return
    }

    const params = {}
    if (lng && lat) {
      Object.assign(params, {
        lng,
        lat
      })
    }

    if (admin_preview_mode) {
      Object.assign(params, {
        admin_preview_mode
      })
    }
    return this.$axios.$get(apis.homePage, { params }).then((res: Data.Res) => {
      if (res.success && res.result) {
        // affiliate过滤部分vertical
        customVerticalMenu(rootState, res.result)
        // affiliate定制centralBanner
        customBanners(rootState, res.result)
        commit(SET_HOME_PAGE_DATA, res.result)
      }
    })
  }
}
