const getRangeTemplateIds = (startId: number, endId: number, excludeIds: any[]) => {
  const arr = []
  for (let id = startId; id < endId + 1; id++) {
    if (!excludeIds.includes(id)) {
      arr.push(id)
    }
  }
  return arr
}
// 该函数从new-web迁移过来待优化具体字段
/*
 *  Attractions & Shows  活动 template_id: 1
 *
 *  Tours & Sightseeing 活动 template_id: 2
 *
 *  Activities & Experiences 活动 template_id: 3
 *
 *  Beats Food & Must Eats 活动 template_id: 4
 *
 *  Transport & Travel Service 活动 template_id: 5
 *
 *  普通 活动template_id:  [0, 1, 2, 3, 4, 5, 6]
 *
 *  wifi 活动template_id:  7
 *
 *  sim 活动template_id:   8
 *
 *  ysim 活动template_id:  9
 *
 *  gift 活动template_id:  10
 *
 *  europe rail 活动template_id:  11
 *
 *  China rail 活动template_id:  12
 *
 *  airport transfer 活动template_id:  13

 *  hotel voucher 活动 template_id: 14
 *
 *  car rental 活动template_id: 15
 */

const SUPPORT_ACT_TPL_ID: any = {
  attractionsAndShows: 1,

  tourAndSightseeing: 2,

  activitiesAndExperiences: 3,

  bestFoodAndMustEats: 4,

  transportAndTravelService: 5,

  ttd: [1, 2, 3, 5, 6],

  fnb: 4,

  wifi: 7,

  sim: 8,

  ysim: 9,

  gift: 10,

  europerail: 11,

  chinarail: 12,

  transfer: 13,

  hotelVoucher: 14,

  carRental: 15,
  hotel: 16,
  jrptp: 17,
  twbus: 18,
  ptp: [11, 12, 17, 18].concat(getRangeTemplateIds(21, 44, [33]))
}

// 五大类
function isTTD(tplId: number) {
  return SUPPORT_ACT_TPL_ID.ttd.includes(tplId)
}

// fnb活动template_id: 4
function isFNB(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.fnb
}

// wifi活动template_id: 7
function isWifiAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.wifi
}

// sim活动template_id: 8
function isSimAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.sim
}

// ysim活动template_id: 9
function isYsimAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.ysim
}

// 赠品活动template_id: 10
function isGiftAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.gift
}

// 欧铁活动template_id: 11
function isEuroperailAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.europerail
}

// transfer活动template_id: 13
function isTransferAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.transfer
}

// car rental 活动template_id: 15
function isCarRentalAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.carRental
}

// 中铁活动template_id: 12
function isChinarailAct(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.chinarail
}

// wifi&sim 活动
function isWifiSim(templateId: number) {
  return isWifiAct(templateId) || isSimAct(templateId)
}

// 套餐外置活动 1， 2， 3, 5
function isExternalOptions(templateId: number) {
  return isAttractionsAndShows(templateId) || isTourAndSightseeing(templateId) || isActivitiesAndExperiences(templateId) || isTransportAndTravelService(templateId)
}

// ysim buy 活动
function isYsimBuy(type: string) {
  return type === 'ysim_buy'
}

// ysim 充值活动
function isYsimTopUp(type: string) {
  return type === 'ysim_top_up'
}

function isHotelVoucher(templateId: number | string) {
  return +templateId === SUPPORT_ACT_TPL_ID.hotelVoucher
}

function isHotel(templateId: number | string) {
  return +templateId === SUPPORT_ACT_TPL_ID.hotel
}

// Attractions & Shows  活动 template_id: 1
function isAttractionsAndShows(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.attractionsAndShows
}

//  Tours & Sightseeing 活动 template_id: 2
function isTourAndSightseeing(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.tourAndSightseeing
}

//  Activities & Experiences 活动 template_id: 3
function isActivitiesAndExperiences(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.activitiesAndExperiences
}

//  Beats Food & Must Eats 活动 template_id: 4
function isBestFoodAndMustEats(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.bestFoodAndMustEats
}

// Transport & Travel Service 活动 template_id: 5
function isTransportAndTravelService(templateId: number) {
  return templateId === SUPPORT_ACT_TPL_ID.transportAndTravelService
}

function isTicketType(type: string, ticket: any) {
  let isThisType = false
  switch (type) {
    case 'wifi':
      // 7
      isThisType = isWifiAct(ticket.activity_template_id)
      break
    case 'sim':
      // 8
      isThisType = isSimAct(ticket.activity_template_id)
      break
    case 'europerail':
      // 11
      isThisType = isEuroperailAct(ticket.activity_template_id)
      break
    case 'chinarail':
      // 12
      isThisType = isChinarailAct(ticket.activity_template_id)
      break
    case 'transfer':
      // 13
      isThisType = isTransferAct(ticket.activity_template_id)
      break
    case 'ysim_top_up':
      // 9
      isThisType = isYsimAct(ticket.activity_template_id) && ticket.activity_type === 'ysim_top_up'
      break
    case 'ysim_buy':
      // 9
      isThisType = isYsimAct(ticket.activity_template_id) && ticket.activity_type === 'ysim_buy'
      break
    case 'hotel_voucher':
      isThisType = isHotelVoucher(ticket.activity_template_id)
      break
    case 'hotel':
      isThisType = isHotel(ticket.activity_template_id)
      break
    // 订单列表的特殊展示ui
    case 'special_ui':
      isThisType = isEuroperailAct(ticket.activity_template_id) ||
        isChinarailAct(ticket.activity_template_id) || isHotel(ticket.activity_template_id)
      break
    case 'fnb':
      isThisType = isFNB(ticket.activity_template_id)
      break
    case 'car_rental':
      // 15
      isThisType = isCarRentalAct(ticket.activity_template_id)
      break
    default:
      isThisType = false
      break
  }
  return isThisType
}

// 日铁 template_id: 17
function isJrptpAct(tplId: number) {
  return tplId === SUPPORT_ACT_TPL_ID.jrptp
}
// 日铁 template_id: 18
function isTwBusAct(tplId: number) {
  return tplId === SUPPORT_ACT_TPL_ID.twbus
}
function isPtpAct(tplId: number) {
  return SUPPORT_ACT_TPL_ID.ptp.includes(tplId)
}

const getVerticalType = function (templateId: number, activityType: string) {
  if (isAttractionsAndShows(templateId)) {
    return 'Attractions & Shows'
  }

  if (isTourAndSightseeing(templateId)) {
    return 'Tours & Sightseeing'
  }

  if (isActivitiesAndExperiences(templateId)) {
    return 'Activities & Experiences'
  }

  if (isBestFoodAndMustEats(templateId)) {
    return 'Beats Food & Must Eats'
  }

  if (isTransportAndTravelService(templateId)) {
    return 'Transport & Travel Service'
  }

  if (isWifiSim(templateId)) {
    return 'WiFi & SIM'
  }

  if (isEuroperailAct(templateId)) {
    return 'Europe Rail Vertical Page'
  }

  if (isChinarailAct(templateId)) {
    return 'China Rail Vertical Page'
  }

  if (isFNB(templateId)) {
    return 'F&B'
  }

  if (isYsimBuy(activityType)) {
    return 'YSIM'
  }

  if (isYsimTopUp(activityType)) {
    return 'YSIM Top Up'
  }

  if (isHotelVoucher(templateId)) {
    return 'Hotel Voucher'
  }

  if (isTransferAct(templateId)) {
    return 'Airport Transfers Vertical Page'
  }

  if (isCarRentalAct(templateId)) {
    return 'Car Rental'
  }

  if (isJrptpAct(templateId)) {
    return 'JR PTP'
  }

  if (isTwBusAct(templateId)) {
    return 'TW Bus'
  }

  if (isPtpAct(templateId)) {
    return 'ptp'
  }

  return ''
}

export {
  getVerticalType,
  isFNB,
  isWifiAct,
  isSimAct,
  isYsimAct,
  isGiftAct,
  isEuroperailAct,
  isChinarailAct,
  isTransferAct,
  isCarRentalAct,
  isWifiSim,
  isYsimBuy,
  isYsimTopUp,
  isHotelVoucher,
  isHotel,
  isTicketType,
  isTTD,
  isTransportAndTravelService,
  isExternalOptions,
  isBestFoodAndMustEats
}
