import Vue, { VNode } from 'vue'
import { Plugin } from '@nuxt/types'

// 初始化 datalayer
function initDataLayer() {
  window.dataLayer = window.dataLayer || []
}

function fireGtm() {
  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': Date.now()
  })
}

/**
 * 设置或者更新初始数据
 * @param {object} fields 初始字段
 */
function updateInitialFields(fields = {}) {
  const klook = window.__KLOOK__.state.klook
  const user = window.__KLOOK__.state.auth.user
  const oldFields = window.dataLayer.find(item => item.Language && item.Currency)

  const userField = user ? {
    BackendUserCountry: user.countryCode,
    Gender: user.title,
    UserEmailMD5: user.emailHash,
    UserCreateTime: user.createTime,
    userId: user.globalId
  } : {}

  if (oldFields) {
    Object.assign(oldFields, userField, fields)
  } else {
    const newFields = {
      Currency: klook.currency,
      IPLocationCity: undefined,
      IPLocationCountry: klook.ipCountry,
      Language: klook.backendLanguage,
      Page: undefined,
      ...userField,
      ...fields
    }

    window.dataLayer.push(newFields)
  }
}

// 发送 GTM 原始事件
function sendGTM(data: any) {
  if (window.dataLayer && data) {
    if (data.event === 'virtualPageView') {
      updateInitialFields({ Page: data.pageName })
    } else {
      updateInitialFields()
    }

    window.dataLayer.push(data)
  }
}

/**
 * 发送 GTM 自定义事件
 * @param {string} tags 参数, 格式: category | action | label | value
 */
function sendGTMCustomEvent(tags: string, nonInteractive = false) {
  if (!tags || typeof tags !== 'string') {
    return
  }

  const [category, action, label, value] = tags.split('|')
  const data = {
    event: 'trackEventCustom',
    eventNonInteractive: nonInteractive,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value
  }

  sendGTM(data)
}

// 可通过 this.$sendGTM(), this.$sendGTMCustomEvent() 手动发事件
Vue.prototype.$sendGTM = sendGTM
Vue.prototype.$sendGTMCustomEvent = sendGTMCustomEvent

const events = ['click', 'mousedown', 'mouseup', 'mouseenter', 'mouseleave']

/**
 * 添加一个 gtm 埋点指令，方便去埋点
 * 示例：
 * <button v-gtm:click="'Activity Page|View Original Btn Click'">Click Me</button>
 * <button v-gtm:click.prevent="'Activity Page|View Original Btn Click'">Click Me</button>
 * <button v-gtm:click.stop="'Activity Page|View Original Btn Click'">Click Me</button>
 * <button v-gtm:click.prevent.stop="'Activity Page|View Original Btn Click'">Click Me</button>
 */
Vue.directive('gtm', {
  bind(el: HTMLElement, binding: any, vnode: VNode) {
    const { arg: eventName, modifiers, value } = binding
    const vm = vnode.context as any

    if (!events.includes(eventName)) {
      // eslint-disable-next-line no-console
      console.warn('[gtm directive] Unsupport event:', eventName)
      return
    }

    if (!value) {
      // eslint-disable-next-line no-console
      console.warn('You must binding a value to the directive')
      return
    }

    const keyName = '_callback'

    binding[keyName] = function (event: Event) {
      if (modifiers.prevent) {
        event.preventDefault()
      }

      if (modifiers.stop) {
        event.stopPropagation()
      }

      vm.$sendGTMCustomEvent(value)
    }

    el.addEventListener(eventName, binding[keyName], { once: !!modifiers.once })
  },

  unbind(el: HTMLElement, binding: any) {
    const { arg: eventName, _callback } = binding
    if (typeof _callback === 'function') {
      el.removeEventListener(eventName, _callback)
    }
  }
})

const gtm: Plugin = function (_ctx, inject) {
  initDataLayer()
  inject('fireGtm', fireGtm)
  inject('sendGTM', sendGTM)
}

export default gtm
