/* eslint-disable */
"use strict";

/**
 * copy from: https://bitbucket.org/klook/klook-new-web/src/master/web/libs/markdown/markdown_render.js
 */

function Renderer() {}

/**
 *  Walks the AST and calls member methods for each Node type.
 *
 *  @param ast {Node} The root of the abstract syntax tree.
 */
function render(ast) {
  var walker = ast.walker()
    , event
    , type;

  this.buffer = '';
  this.lastOut = '\n';

  while((event = walker.next())) {
    type = event.node.type;
    if (this[type]) {
      this[type](event.node, event.entering);
    }
  }
  console.log(this.allItems)
  return this.buffer;
}


/**
 *  Walks the AST and calls member methods for each Node type.
 *
 *  @param ast {Node} The root of the abstract syntax tree.
 */
function renderAst(ast) {
  var walker = ast.walker()
    , event
    , type;

  this.buffer = '';
  this.lastOut = '\n';

  this.allItems = []

  while((event = walker.next())) {
    type = event.node.type;
    if (this[type]) {
      this[type](event.node, event.entering);
    }
  }
  return processFirstItemMargin(this.allItems);
}

function processFirstItemMargin(items){
    if(items && items.length> 0 && (items[0].type != "title" && items[0].type != "section_title")){
        items[0].props.margin_top = (items[0].props.margin_top || 0) + 16
    }
    return items
}

/**
 *  Concatenate a literal string to the buffer.
 *
 *  @param str {String} The string to concatenate.
 */
function lit(str) {
  this.buffer += str;
  this.lastOut = str;
}

function cr() {
    if (this.lastOut !== '\n') {
        this.lit('\n');
    }
}

/**
 *  Concatenate a string to the buffer possibly escaping the content.
 *
 *  Concrete renderer implementations should override this method.
 *
 *  @param str {String} The string to concatenate.
 */
function _out(str) {
  this.lit(str);
}

Renderer.prototype.render = render;
Renderer.prototype.renderAst = renderAst;
Renderer.prototype.out = _out;
Renderer.prototype.lit = lit;
Renderer.prototype.cr  = cr;

var replaceUnsafeChar = function(s) {
    switch (s) {
    case '&':
        return '&amp;';
    case '<':
        return '&lt;';
    case '>':
        return '&gt;';
    case '"':
        return '&quot;';
    default:
        return s;
    }
};

function escapeXml(s, preserve_entities){
    var XMLSPECIAL = '[&<>"]';
    var reXmlSpecial = new RegExp(XMLSPECIAL, 'g');

    var ENTITY = "&(?:#x[a-f0-9]{1,8}|#[0-9]{1,8}|[a-z][a-z0-9]{1,31});";
    var reXmlSpecialOrEntity = new RegExp(ENTITY + '|' + XMLSPECIAL, 'gi');

    if (reXmlSpecial.test(s)) {
        if (preserve_entities) {
            return s.replace(reXmlSpecialOrEntity, replaceUnsafeChar);
        } else {
            return s.replace(reXmlSpecial, replaceUnsafeChar);
        }
    } else {
        return s;
    }
}

var esc = escapeXml;


//------------------------- markdown render -----------

var reUnsafeProtocol = /^javascript:|vbscript:|file:|data:/i;
var reSafeDataProtocol = /^data:image\/(?:png|gif|jpeg|webp)/i;

var potentiallyUnsafe = function(url) {
    return reUnsafeProtocol.test(url) &&
        !reSafeDataProtocol.test(url);
};

// Helper function to produce an HTML tag.
function tag(name, attrs, selfclosing) {
    if (this.disableTags > 0) {
        return;
    }
    this.buffer += ('<' + name);
    if (attrs && attrs.length > 0) {
        var i = 0;
        var attrib;
        while ((attrib = attrs[i]) !== undefined) {
            this.buffer += (' ' + attrib[0] + '="' + attrib[1] + '"');
            i++;
        }
    }
    if (selfclosing) {
        this.buffer += ' /';
    }
    this.buffer += '>';
    this.lastOut = '>';
}

function getTagContent(name, attrs, selfclosing){
    if (this.disableTags > 0) {
        return '';
    }
    var buffer = ''
    buffer += ('<' + name);
    if (attrs && attrs.length > 0) {
        var i = 0;
        var attrib;
        while ((attrib = attrs[i]) !== undefined) {
            buffer += (' ' + attrib[0] + '="' + attrib[1] + '"');
            i++;
        }
    }
    if (selfclosing) {
        buffer += ' /';
    }
    buffer += '>';
    return buffer;
}


function MarkdownRenderer(options) {
  options = options || {};
  // by default, soft breaks are rendered as newlines in HTML
  options.softbreak = options.softbreak || '\n';
  // set to "<br />" to make them hard breaks
  // set to " " if you want to ignore line wrapping in source

  this.disableTags = 0;
  this.lastOut = "\n";
  this.options = options;

  //所有的item
  this.allItems = [];
  //当前的tag
  this._tag = undefined;
  this._props = {}
  this._content = ''
  //是否在处理图片中, 如果在处理图片中, 直接设置props
  this._inImage = false;
  //如果是order_item的时候, 它的顺序是什么
  this._order = 0
}

/* Node methods */

function text(node) {
  this.out(node.literal);

  // new
  if(this._inImage){
      this._props["alt"] = node.literal
  }
  else{
    this._content +=  esc(node.literal, false)
  }
}

/**
 *
 */
function softbreak() {
  this.lit(this.options.softbreak);

  //new
  if(this._tag == 'paragraph' || this._tag == 'item' || this._tag == 'order_item'){
    this._content += ' '
  }
  else{
    this._content += ' '
  }
}

function linebreak() {
  this.tag('br', [], true);
  this.cr();

  if(this._tag == 'paragraph' || this._tag == 'item' || this._tag == 'order_item'){
    this._content += '\n'
  }
}

function link(node, entering) {
  var attrs = this.attrs(node);
  if (entering) {
      if (!(this.options.safe && potentiallyUnsafe(node.destination))) {
          attrs.push(['href', esc(node.destination, true)]);
      }
      if (node.title) {
          attrs.push(['title', esc(node.title, true)]);
      }
      this.tag('a', attrs);
  } else {
      this.tag('/a');
  }

  //new
  var newattrs = this.attrs(node);
  if(entering){
      if (!(this.options.safe && potentiallyUnsafe(node.destination))) {
          newattrs.push(['href', esc(node.destination, true)]);
      }

      if (node.title) {
          newattrs.push(['title', esc(node.title, true)]);
      }

      this._content += this.getTagContent('a', newattrs)
  }
  else{

      this._content += this.getTagContent('/a')
  }
}

function image(node, entering) {

  if (entering) {
      if (this.disableTags === 0) {
          if (this.options.safe &&
               potentiallyUnsafe(node.destination)) {
              this.lit('<img src="" alt="');
          } else {
              this.lit('<img src="' + esc(node.destination, true) +
                  '" alt="');
          }
      }


      //new
      if (this.disableTags === 0) {
          //前面有一个P
          if(this._tag == "paragraph"){
              this._inParagraph = true;
              this.closeLastItem();
          }
          this._tag = "image"
          this._props["src"] = node.destination
          this._inImage = true;
      }
      //new

      this.disableTags += 1;
  } else {
      this.disableTags -= 1;
      if (this.disableTags === 0) {
          if (node.title) {
              this.lit('" title="' + esc(node.title, true));
          }
          this.lit('" />');
      }

      //new

      if (this.disableTags === 0) {
          if(node.title){
              this._props["title"] = node.title
          }
          this.closeLastItem()
          this._inImage = false;
          this._tag = 'paragraph'
      }

      //new
  }
}

function emph(node, entering) {

  //new
  if(entering){
      this.tag(entering ? 'em' : '/em');
      if (this.disableTags === 0){
          this._content += this.getTagContent('em')
      }
      //this.disableTags += 1
  }
  else{
      //this.disableTags -= 1
      this.tag(entering ? 'em' : '/em');
      if (this.disableTags === 0){
          this._content += this.getTagContent('/em')
      }
  }
}

function strong(node, entering) {

  //new
  //this._content += this.getTagContent(entering ? 'em' : '/em')

  if(entering){
      this.tag(entering ? 'strong' : '/strong');
      if (this.disableTags === 0){
          this._content += this.getTagContent('strong')
      }
      //this.disableTags += 1
  }
  else{
      //this.disableTags -= 1
      this.tag(entering ? 'strong' : '/strong');
      if (this.disableTags === 0){
          this._content += this.getTagContent('/strong')
      }
  }
}

function paragraph(node, entering) {

  var grandparent = node.parent.parent
    , attrs = this.attrs(node);
  if (grandparent !== null &&
      grandparent.type === 'list') {
      return;
      /*
      if (grandparent.listTight) {
          return;
      }
      */
  }

  if (entering) {
      this.cr();
      this.tag('p', attrs);
  } else {
      this.tag('/p');
      this.cr();
  }

  //new
  //
  if(entering){
      this._tag = "paragraph"
  }
  else{
      this.closeLastItem();
  }

}

function heading(node, entering) {
  var tagname = 'h' + node.level , attrs = this.attrs(node);
  if (entering) {
      this.cr();
      this.tag(tagname, attrs);
  } else {
      this.tag('/' + tagname);
      this.cr();
  }

  //new
  //heading 除了literal, 不应该出现其他内容
  if(entering){
      //H6是一个section标题
      if(node.level == 6){
        this._tag = "section_title"
      }
      else if(node.level == 1){
        this._tag = "title"
      }
      else{
        this._tag = "sub_title"
      }
  }
  else{
      this.closeLastItem();
  }
}

/**
 *  关闭上一个item
 */
function closeLastItem(){
    if(this._tag){
        if(this._tag != "paragraph"
                || this._content.replace(/^\s|\s$/,'') != ''){
            var result = {
                type: this._tag,
                content: this._content,
                props: this._props
            }
            if(this._tag == "order_item"){
                this._order += 1
                result.props.order = this._order;
            }
            else{
                this._order = 0
            }

            //设置 isFirst
            if(this._open_list && (this._tag == "item" || this._tag == "order_item")){
                this._props.is_first = true;
                this._open_list = false;
            }

            this.allItems.push(result);
        }

        this._tag = null;
        this._content = '';
        this._props = {}
    }
}

function code(node) {
  this.tag('code');
  this.out(node.literal);
  this.tag('/code');

  //new
}

function code_block(node) {
  var info_words = node.info ? node.info.split(/\s+/) : []
    , attrs = this.attrs(node);
  if (info_words.length > 0 && info_words[0].length > 0) {
      attrs.push(['class', 'language-' + esc(info_words[0], true)]);
  }
  this.cr();
  this.tag('pre');
  this.tag('code', attrs);
  this.out(node.literal);
  this.tag('/code');
  this.tag('/pre');
  this.cr();

  //new
  //
  //pass
}

function thematic_break(node) {
  var attrs = this.attrs(node);
  this.cr();
  this.tag('hr', attrs, true);
  this.cr();

  //new
  //
  //pass
}

function block_quote(node, entering) {
  var attrs = this.attrs(node);
  if (entering) {
      this.cr();
      this.tag('blockquote', attrs);
      this.cr();
  } else {
      this.cr();
      this.tag('/blockquote');
      this.cr();
  }

  //new
  //
  //pass
}

function list(node, entering) {

  var tagname = node.listType === 'bullet' ? 'ul' : 'ol'
    , attrs = this.attrs(node);

  if (entering) {
      var start = node.listStart;
      if (start !== null && start !== 1) {
          attrs.push(['start', start.toString()]);
      }
      this.cr();
      this.tag(tagname, attrs);
      this.cr();
  } else {
      this.cr();
      this.tag('/' + tagname);
      this.cr();
  }

  //new
  if(entering){
    this._list_type = tagname;
    this._open_list = true;
  }
  else{
    this._list_type = undefined
    if(this.allItems.length > 0){
        var lastItem = this.allItems[this.allItems.length -1];
        if(lastItem.type == "item" || lastItem.type == "order_item"){
            lastItem.props.is_last = true;
        }
    }
  }
}

function checkLastItem(){
}

function item(node, entering) {
  var attrs = this.attrs(node);
  if (entering) {
      this.tag('li', attrs);
  } else {
      this.tag('/li');
      this.cr();
  }

  //new
  if(entering){
      if(this._list_type == 'ul'){
          this._tag = 'item'
      }
      else {
          this._tag = 'order_item'
      }
  }
  else{
      this.closeLastItem()
  }
}

function html_inline(node) {
  if (this.options.safe) {
      this.lit('<!-- raw HTML omitted -->');
  } else {
      this.lit(node.literal);
  }

  //new
  //pass
}

function html_block(node) {
  this.cr();
  if (this.options.safe) {
      this.lit('<!-- raw HTML omitted -->');
  } else {
      this.lit(node.literal);
  }
  this.cr();

  //new
  //pass
}

function custom_inline(node, entering) {
  if (entering && node.onEnter) {
      this.lit(node.onEnter);
  } else if (!entering && node.onExit) {
      this.lit(node.onExit);
  }

  //new
  //pass
}

function custom_block(node, entering) {
  this.cr();
  if (entering && node.onEnter) {
      this.lit(node.onEnter);
  } else if (!entering && node.onExit) {
      this.lit(node.onExit);
  }
  this.cr();

  //new
  //pass
}

/* Helper methods */

function out(s) {
  this.lit(esc(s, false));

  //new
  //pass
}

function attrs (node) {
  var att = [];
  if (this.options.sourcepos) {
      var pos = node.sourcepos;
      if (pos) {
          att.push(['data-sourcepos', String(pos[0][0]) + ':' +
                      String(pos[0][1]) + '-' + String(pos[1][0]) + ':' +
                      String(pos[1][1])]);
      }
  }
  return att;
}

// quick browser-compatible inheritance
MarkdownRenderer.prototype = Object.create(Renderer.prototype);

MarkdownRenderer.prototype.text = text;
MarkdownRenderer.prototype.html_inline = html_inline;
MarkdownRenderer.prototype.html_block = html_block;
MarkdownRenderer.prototype.softbreak = softbreak;
MarkdownRenderer.prototype.linebreak = linebreak;
MarkdownRenderer.prototype.link = link;
MarkdownRenderer.prototype.image = image;
MarkdownRenderer.prototype.emph = emph;
MarkdownRenderer.prototype.strong = strong;
MarkdownRenderer.prototype.paragraph = paragraph;
MarkdownRenderer.prototype.heading = heading;
MarkdownRenderer.prototype.code = code;
MarkdownRenderer.prototype.code_block = code_block;
MarkdownRenderer.prototype.thematic_break = thematic_break;
MarkdownRenderer.prototype.block_quote = block_quote;
MarkdownRenderer.prototype.list = list;
MarkdownRenderer.prototype.item = item;
MarkdownRenderer.prototype.custom_inline = custom_inline;
MarkdownRenderer.prototype.custom_block = custom_block;

MarkdownRenderer.prototype.out = out;
MarkdownRenderer.prototype.tag = tag;
MarkdownRenderer.prototype.getTagContent = getTagContent;
MarkdownRenderer.prototype.closeLastItem = closeLastItem;
MarkdownRenderer.prototype.attrs = attrs;

export default MarkdownRenderer;
