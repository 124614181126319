











import axios from 'axios'
import { Vue, Component, Prop } from 'vue-property-decorator'

interface RootVue extends Vue {
  _svgSprites: Set<string>
}

@Component
export default class SvgIcon extends Vue {
  @Prop({ required: true }) readonly name!: string
  @Prop({ default: true }) readonly scoped!: boolean
  @Prop({ type: [String, Number] }) readonly width?: string | number
  @Prop({ type: [String, Number] }) readonly height?: string | number
  @Prop({ type: [String, Number] }) readonly size?: string | number
  @Prop({ type: String }) readonly color?: string

  get colorClass() {
    const color = this.color
    const style = this.attrStyle

    if (color && !style) {
      return `klook-symbol-${color}`
    }
  }

  get attrWidth() {
    return this.width ? this.width : this.size
  }

  get attrHeight() {
    return this.height ? this.height : this.size
  }

  get attrStyle() {
    const color = this.color
    if (color && /#|rgba?/.test(color)) {
      return { color }
    }
  }

  get spriteName() {
    if (this.name.includes('#')) {
      return this.name.split('#')[0]
    }
    return 'default'
  }

  get iconName() {
    if (this.name.includes('#')) {
      if (this.scoped) {
        return `#${this.name.replace('#', '-')}`
      }

      return `#${this.name.split('#')[1]}`
    }

    return `#${this.name}`
  }

  created() {
    if (process.server) {
      return
    }

    let url = ''

    try {
      url = require(`~/assets/sprites/output/${this.spriteName}.svg`)
    } catch (error) {
      // Purpose is to test
      return
    }

    const $root = this.$root as RootVue

    if (!$root._svgSprites) {
      $root._svgSprites = new Set()
    }

    if (!$root._svgSprites.has(url)) {
      $root._svgSprites.add(url)
      axios.get(url, { noCache: false }).then((res) => {
        const $div = document.createElement('div')

        $div.innerHTML = res.data
        document.body.insertBefore($div.firstChild as ChildNode, document.body.childNodes[0])
      })
    }
  }
}
