import Vue from 'vue'
import { Plugin, Context } from '@nuxt/types'
import inhouse, { Marketing } from '@klook/inhouse-tracker'
import isEqual from 'lodash/isEqual'
import { getUserPermission, onPermissionChange } from '@klook/cookie-manage'
import apemClient from '@klook/apem-client'
import ApemPluginWeb from '@klook/apem-plugin-web'
import loginSDK from '~/share/login'
import { getKlookReferer } from '~/share/utils'
import { getSupportLanguageList } from '~/share/data/language'
import { getSessionData, setSessionData, deleteSessionData } from '~/assets/scripts/sessionstorage'

type ObjType = {[key: string]: any}
/**
 * utm数组判断处理<string | (string | null)[]>
 * @param data data 参数
 */
function filterUtm(data: any) {
  return Array.isArray(data) ? data[0] : data
}

/**
 * 获取市场推广信息
 * @param query query 参数
 * @param affiliateConf aff 信息
 * @param cid -
 */
export function getMarketingInfo(query: any, affiliateConf: any, cid: string) {
  const aidExtraInfo = affiliateConf.aid_extra_info || {}
  const aid = affiliateConf.wid
  const pid = aidExtraInfo.aff_pid
  const sid = aidExtraInfo.aff_sid
  const adid = aidExtraInfo.aff_adid
  const marketing: Marketing = {
    utmSource: filterUtm(query.utm_source),
    utmMedium: filterUtm(query.utm_medium),
    utmCampaign: filterUtm(query.utm_campaign),
    utmTerm: filterUtm(query.utm_term),
    utmContent: filterUtm(query.utm_content),
    googleAdsId: filterUtm(query.gclid),
    googleDisplayadsId: filterUtm(query.dclid),
    utmId: filterUtm(query.utm_id)
  }

  if (aid) {
    marketing.affAid = aid ? String(aid) : ''
    marketing.affPid = pid ? String(pid) : ''
    marketing.affSid = sid ? String(sid) : ''
    marketing.affAdid = adid ? String(adid) : ''

    marketing.utmMedium = 'affiliate-alwayson'
    marketing.utmSource = String(affiliateConf.affiliate_type || '')
    marketing.utmCampaign = String(affiliateConf.wid || '')
    marketing.utmTerm =
      affiliateConf.aid_extra_info &&
      String(affiliateConf.aid_extra_info.aff_pid || '')
  }

  if (cid) {
    marketing.campaignId = String(cid)
  }

  return marketing
}

function toSelf<T>(v: T) {
  return v
}

function objMapFilter<T extends {[key: string]: any}>(obj: T, filter: (v: T[keyof T], key: keyof T, o: T) => boolean, format: (v: T[keyof T], key: string, o: T) => any = toSelf) {
  const res: {[key: string]: any} = {}
  Object.keys(obj).forEach((key) => {
    const val = obj[key]
    if (filter(val, key, obj)) {
      res[key] = format(val, key, obj)
    }
  })
  return res
}

function filterEmpty(val: any) {
  if (typeof val === 'boolean') { return true }
  if (typeof val === 'undefined') { return false }
  if (!val && val === null) { return false }
  if (val !== 0 && !val) { return false }
  if (!String(val)) { return false }
  return true
}

export function mergeMarketing(obj1: ObjType, obj2: ObjType) {
  const o1 = objMapFilter(obj1, filterEmpty)
  const o2 = objMapFilter(obj2, filterEmpty)
  return {
    ...o1,
    ...o2
  }
}

/**
 *
小程序project：mp-wechat 和 mp-xiaohongshu
小程序source
utmSource
utmMedium
utmCampaign
utmContent
 */
function getMpExtraInfo(_query: any, _klook: any) {
  if (_klook.platformMp) {
    let superProperty: any = { project: _klook.platformMp }
    try {
      superProperty = Object.assign(superProperty, JSON.parse(_query.mp_tracking).super)
    } catch (error) {
    }
    if (superProperty.distinctId) {
      setSessionData('MP_DISCINCT_ID', superProperty.distinctId)
    } else {
      superProperty.distinctId = getSessionData('MP_DISCINCT_ID')
    }
    return superProperty
  } else {
    deleteSessionData('MP_DISCINCT_ID')
    return {}
  }
}

function transformExpExtra(obj: Record<string, any>) {
  const userId = obj?.user_id
  const originKeplerId = obj?.origin_kepler_id
  return {
    ...(userId ? { userId } : {}),
    ...(originKeplerId ? { originKeplerId } : {})
  }
}

/**
 * 探测额外的 business log 参数
 */
function detectExtraBusinessParams(this: any) {
  const extra = {} as any
  if (this?.activityId) {
    extra.activityId = this.activityId
  }
  if (this?.packageId) {
    extra.packageId = this.packageId
  }
  if (this?.shoppingCartGuid) {
    extra.shoppingCartGuid = this.shoppingCartGuid
  }
  if (this?.subCategoryId) {
    extra.subCategoryId = this.subCategoryId
  }
  return extra
}

function createBusinessLogger(apemClient: any) {
  // 服务端不上报，防止内存泄漏
  if (process.server) {
    return () => null
  }

  // 闭包保存 apemMap
  const apemMap = new Map<string, any>()
  return function businessLogger(this: Vue, arg: Data.BusinessLogArg) {
    const { tag } = arg
    const logger = apemMap.get(tag) ?? apemClient.createLogger(tag)

    if (!apemMap.has(tag)) {
      apemMap.set(tag, logger)
    }

    // @ts-ignore
    delete arg.tag
    logger({
      ...arg,
      ...detectExtraBusinessParams.apply(this)
    }, { isMasked: true })
  }
}

const plugin: Plugin = function (ctx: Context) {
  const { app, store } = ctx
  const auth = store.state.auth
  const klook = store.state.klook as Data.Klook
  const kepler = store.state.common.kepler as Data.IKepler
  let cid = ''

  window.$axios = app.$axios

  function getCookieMarketingConsent() {
    return getUserPermission(store.getters.isEUArea).marketing ? 'GRANTED' : 'DENIED'
  }

  apemClient.init({
    service: 'experiencenodeweb',
    _debug: !!localStorage.getItem('__apem_debug'),
    platform: klook.platform,
    plugins: [
      new ApemPluginWeb()
    ],
    logger: {
      options: {
        url: process.env.LOGQUERY_URL_V3
      }
    },
    report: {
      ipCountry: klook.ipCountry,
      language: klook.language,
      currency: klook.currency
    }
  })

  Vue.prototype.$apem = apemClient
  Vue.prototype.$businessLog = createBusinessLogger(apemClient)
  Vue.prototype.$loginSDK = loginSDK

  if (getKlookReferer() && document.referrer.includes('/promo/')) {
    cid = app.$cookies.get('cid_mixpanel')
  }

  Vue.prototype.$inhouse = inhouse

  // 上报页面性能数据
  if (app.$clientReport) {
    Vue.directive('element-timing', app.$clientReport.elementTimingDirective)
    app.$clientReport.reportPerformance(app, {
      pageName: Object.assign({}, ...ctx.route.meta).trackedPageName || ctx.route.name
    })
  }
  const getGaData = (ctx.app as any).$getGaData
  // 配置in-house url, 让缓存数据可以提前发送
  inhouse.setConfig({
    url: process.env.LOGQUERY_URL,
    beacon: {
      url: process.env.LOGQUERY_URL_V3
    },
    eventCommon: transformExpExtra(window.BaseData?.expExtra || {}),
    data: async () => {
      return {
        clientId: await getGaData('client_id'),
        sessionId: await getGaData('session_id')
      }
    }
  })

  // custom support languages
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  app.router!.beforeEach(async (to, from, next) => {
    // 如果不是刷新进入该页面
    if (from.matched && from.matched.length) {
      const storeSupportLanguages = store.state.klook.supportLanguages

      if (!isEqual(getSupportLanguageList(store.state.klook.websiteConfig.website), storeSupportLanguages)) {
        await store.dispatch('syncSupportLanguages', getSupportLanguageList(store.state.klook.websiteConfig.website))
      }
    }
    next()
  })

  const traffic = app.$traffic
  app.router!.beforeEach((to, from, next) => {
    traffic.useAction({
      type: traffic.constant.TrafficCommon,
      action: 'routerChange'
    })
    traffic.useAction({
      type: traffic.constant.TrafficCommon,
      action: 'processTrafficQuery',
      payload: [{
        query: to.query,
        from
      }]
    })

    next()
  })

  // inhouse tracking
  app.router!.afterEach((to, from) => {
    const trafficState = traffic.useState({
      type: traffic.constant.TrafficAll
    })
    const marketingInfo = trafficState.trafficCommon?.marketing || {}
    if (cid) {
      marketingInfo.campaignId = String(cid)
    }

    setTimeout(() => {
      const tmpExperiments = []
      for (const item of Object.values(kepler.experimentsGroup)) {
        tmpExperiments.push({
          experimentId: item.id,
          groupId: item.group.id
        })
      }
      let distinctId = ''

      const mpExtraInfo = getMpExtraInfo(to.query, klook)
      if (mpExtraInfo.distinctId) {
        distinctId = mpExtraInfo.distinctId
        delete mpExtraInfo.distinctId
      }

      if (klook.utilConfig.whiteLabelUtilConfig) {
        mpExtraInfo.partner_id = klook.utilConfig.whiteLabelUtilConfig.partner_id
        mpExtraInfo.platform_id = 'WhitelabelPlatform'
      }

      const kepler_id = app.$cookies.get('kepler_id') || window.BaseData?.keplerId

      if (!distinctId && kepler_id) {
        distinctId = kepler_id
      }

      inhouse.init({
        url: process.env.LOGQUERY_URL,
        isDebugMode: process.env.APP_ENV !== 'production',
        autoPageView: false,
        eventCommon: transformExpExtra(kepler?.expExtra || {}),
        serverTime: window.BaseData?.serverTime,
        props: {
          userId: auth.user && auth.user.globalId,
          pageId: klook.pageId,
          distinctId,
          deviceId: kepler_id || '',
          keplerId: kepler_id || '',
          experiments: tmpExperiments,
          siteName: window.location.host,
          siteLanguage: klook.language,
          siteCurrency: klook.currency,
          platform: klook.platform,
          marketing: marketingInfo
        },
        sprops: {
          $assumed_residency: klook.residenceCode,
          $ad_user_data: getCookieMarketingConsent(),
          ...mpExtraInfo
        }
      })

      onPermissionChange(() => {
        inhouse.setSprops({
          $ad_user_data: getCookieMarketingConsent()
        })
      })
    }, 0)

    if (to.query?.pv !== 'none' && from.query?.pv !== 'none') {
      setTimeout(() => {
        inhouse.track('pageview', 'all')
      }, 500)
    }
  })

  // 这里将一些client端的参数重新commit到store里面
  store.commit('common/kepler/SET_KEPLER_ID', window.BaseData?.keplerId || '')
  store.commit('common/kepler/SET_EXPERIMENTS_HIT_LIST', window.BaseData?.experimentList || {})
  store.commit('common/kepler/SET_TINTED_LIST', window.BaseData?.tintedList || [])
  store.commit('common/kepler/SET_EXP_EXTRA', window.BaseData?.expExtra || {})
  store.commit('SET_CLIENT_INIT_DATA', {
    ip: window.BaseData?.ip || '',
    isIOS: window.BaseData?.isIOS || '',
    pageId: window.BaseData?.pageId || '',
    residenceCode: window.BaseData?.residenceCode,
    residenceId: window.BaseData?.residenceId
  })
}

export default plugin
