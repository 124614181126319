import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import apis from '~/share/data/apis'

const SET_THEME_DATA = 'SET_THEME_DATA'

export interface ThemePageState {
  theme: {
    banner_info: String
    description: String
    title: String
    items: []
  }
}

export const state = () => ({
  theme: {
    banner_info: '',
    description: '',
    title: '',
    items: []
  }
})

export const mutations: MutationTree<ThemePageState> = {
  [SET_THEME_DATA](state, data) {
    state.theme = data
  }
}

export const actions: ActionTree<ThemePageState, RootState> = {
  getThemePageData({ commit }, { theme_id, redirect }) {
    if (!theme_id) {
      redirect && redirect({ name: 'Error404' })
    }
    return this.$axios.$get(apis.themepage, { params: { theme_id } }).then((res: Data.Res) => {
      if (res.success && res.result) {
        commit(SET_THEME_DATA, res.result)
      } else {
        redirect && redirect({ name: 'Error404' })
      }
    })
  }
}
