const state = () => ({
  commonLoadingState: '',
  pkgsAddonSku: {
    // pid: preSettlementApiDataStruct
  },
  pkgsAddonSchedulesAndUnits: {
    // pid: preSettlementApiDataStruct
  },
  pkgsBasicInfo: {
    // pid: preSettlementApiDataStruct
  },
  tagFilter2state: {
    value: '', // filter value
    list: []
  },
  pkgsPrePriceApiData: {
    // pid: preSettlementApiDataStruct
  }, // 增减unit数量，试算接口数据
  preSettlementApiData: {
    error: null,
    success: false,
    result: null
  }, // 增减unit数量，试算接口数据
  //
  useAbTestMobilePage: 'ActivityPage',
  //
  customerImageAmount: 0,
  reviewGalleryShouldShow: false,
  reviewGalleryList: [],
  cachedShouldResetPackageSelectedDate: false,
  cachedPackageId: null, // 在bookingoption页面属性选项中用来临时保存选中的package id
  packageId: null,
  packages: null,
  packageIdList: [],
  fetchPackageDetailStatus: '',
  packageDetail: {
    multiLanguageIcons: [],
    useSection: false,
    sectionContent: {},
    confirmationDetail: null,
    cancelationPolicy: null,
    packageOption: null,
    howToUse: null,
    instant: false,
    images: [],
    enableTranslationButton: false
  },
  schedules: {},
  priceSchedules: [],
  packagePriceSchedulesMap: {},
  fetchPriceScheduleStatus: 'fetching',
  packageSelectedDate: '',
  activityReviews: [],
  selectedTime: {},
  unitsMap: {},
  packageSchedulesMap: {},
  chosenIds: [],
  fetchScheduleStatus: 'success',
  presalePackages: [],
  reviewIdList: [],
  reviewMap: {},
  reviewListVisible: false,
  activityFaqVisible: false,
  activityReopenModalVisible: false,
  activitySeoLink: [],
  favorite: false,
  dateShow: false,
  sourcePackageDetail: null,
  translatePackageResult: {},
  translateActivityResult: null,
  activityContentTranslated: false,
  packageSchedulesFormatInDayMap: {},
  packagePriceSchedulesFormatInDayMap: {},
  isClearAllClick: false,
  enableTranslationButton: false,
  modificationShoppingCart: false,
  routeName: 'ActivityHome',
  isUsingPackagePriceSchedule: false,
  fromPrice: 0,
  toPrice: 0,
  promotionInfoList: [],
  havePackageDiscount: false,
  promotionDetail: {},
  fetchPromotionDetailStatus: 'success',
  promotionDetailMap: {},
  otherFaq: [],
  fetchPackagePriceScheduleStatus: 'success',
  relatedActivities: [],
  recommendActivities: [],
  relatedAndRecommendActivities: [],
  activityTicketType: 0,
  tagsInfo: [],
  userAgent: '',
  previewExpired: false,
  currPackageOptionTab: ''
})

export default state
