import { getAffiliateItemConf, whiteListVertical } from '~/share/utils'
import { RootState } from '~/store'

export function customVerticalMenu(rootState: RootState, result: any) {
  const vertical_menu_types = getAffiliateItemConf(
    rootState,
    'partner_web_config.vertical_menu_types',
    false
  )
  if (vertical_menu_types) {
    const businessEntry = result.page.body.sections.find((v: any) => {
      return (v.meta || {}).name === 'BusinessEntry'
    })
    businessEntry &&
      (businessEntry.body.content.data.menus = whiteListVertical(
        rootState,
        businessEntry.body.content.data.menus
      ))
  }
}

export function customBanners(rootState: RootState, result: any) {
  const affiliateAds = getAffiliateItemConf(
    rootState,
    'partner_web_config.affiliate_ads',
    false
  )
  if (affiliateAds && affiliateAds.length) {
    const centralBanner = result.page.body.sections.find((v: any) => {
      return (v.meta || {}).name === 'CentralBanner'
    })
    centralBanner && (centralBanner.body.content.data.banners = affiliateAds)
  }
}
