import { Middleware } from '@nuxt/types'

/**
 * 注意，这里只是把 nuxt server 的 X-Frame-Options 请求头去掉，
 * 但是 nginx 服务器也会给 experience 活动页加 X-Frame-Options 请求头
 */

const middleware: Middleware = function (ctx, next) {
  next()
  ctx.res.removeHeader('X-Frame-Options')
}

export default middleware
