import { languageConfig } from '@klook/site-config'

const {
  renderLanguages,
  supportLanguageList,
  countryLanguageMap,
  f2bLanguageMap,
  languageIsoMap,
  languageDayjsLocaleMap,

  getSupportLanguageList,
  isSupportLanguage,
  getRenderLanguages,
  getLanguageByCountry,
  convertToBackendCode,
  convertToFrontendCode,
  isErLang,
  getPreferCountryListByLangCode,
  getPreferCountryListByCountryCode
} = languageConfig

export {
  // 语言配置列表，主要用于切换语言面板的 UI 展示
  renderLanguages,
  // 默认支持的所有语言列表
  supportLanguageList,

  // 国家和语言映射表
  countryLanguageMap,

  // 语言与 ISO 码 Map 表
  languageIsoMap,

  // 根据站点获取语言配置
  getSupportLanguageList,

  // 前后端语言映射
  f2bLanguageMap,

  // 语言 与 dayjs 的语言 Map 表
  languageDayjsLocaleMap,

  // 是否支持该语言
  isSupportLanguage,
  isSupportLanguage as supportLanguage,

  // header、footer 等切换语言选择列表
  getRenderLanguages,

  // 获取国家映射语言
  getLanguageByCountry,

  // 前端 与 后端 的语言转换
  convertToBackendCode,
  convertToFrontendCode,

  // 是否欧洲语言
  isErLang,

  // 获取语言偏好的地区code, 用来选择区号的推荐
  getPreferCountryListByLangCode,

  // 获取国家偏好的地区code, 用来选择区号的推荐
  getPreferCountryListByCountryCode,

  // 兼容现有代码
  getPreferCountryListByLangCode as getLangPreferCountryCode,
  getPreferCountryListByLangCode as langPreferCountryCode,
  getPreferCountryListByCountryCode as getIpPreferCountryCode
}

/**
* 语言 与 seo title/description中的国家 Map表
* https://orion.klook.io/1000021/68/orion/features/1028454/detail
*/
type languageTitleDescCountryMap = Partial<Record<Data.Language, string>>
export const languageTitleDescCountryMap: languageTitleDescCountryMap = {
  'en-AU': ' Australia',
  'en-CA': ' Canada',
  'en-HK': ' Hong Kong',
  'en-IN': ' India',
  'en-MY': ' Malaysia',
  'en-NZ': ' New Zealand',
  'en-PH': ' Philippines',
  'en-SG': ' Singapore',
  'en-US': ' US',
  'en-GB': ' UK',
  'zh-CN': ' 中国',
  'zh-TW': ' 台灣',
  'zh-HK': ' 香港'
}

export const erLangCode = ['de', 'it', 'fr', 'ru', 'es']

export function hideFooterPartner(lang: string) {
  return erLangCode.includes(lang)
}

/**
 * 基础的语言前缀
 * ^/(de/|en/|en-AU/|en-CA/|en-GB/|en-HK/|en-IN/|en-MY/|en-NZ/|en-PH/|en-SG/|en-US/|es/|fr/|id/|it/|ja/|ko/|ru/|th/|vi/|zh-CN/|zh-HK/|zh-TW/)?
 */
export const langReg = (() => {
  const langPath = supportLanguageList.map((lang: string) => `${lang}/`)
  return `^/(${langPath.join('|')})?`
})()

export function getRegLangStr() {
  // new RegExp('^/(|zh-CN/|zh-TW/|ko/|th/|vi/|zh-HK/|id/|ja/|en-NZ/|en-AU/|en-GB/|en-IN/|en-SG/|en-CA/|en-PH/|en-MY/|ms-MY/|en-HK/|en-US/|de/|es/|ru/|fr/|it/)$')
  const langPathArr = supportLanguageList.reduce((pre: string[], lang: string) => {
    lang === 'en' ? pre.unshift('') : pre.push(`${lang}/`)
    return pre
  }, [])
  return `^/(${langPathArr.join('|')})`
}
