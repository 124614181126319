import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Middleware } from '@nuxt/types'
import { aesDecryptHex, aesDecryptBase64 } from '~/share/aes'

dayjs.extend(utc)

/**
 * https://klook.larksuite.com/docx/YwSgdp2aHoS4FEx6mujuyleDs7f
 */
const middleware: Middleware = function ({ req, route, store, redirect }, next) {
  if (route?.params?.preview) {
    try {
      const { deadline, deadline_version } = route?.query || {}
      if (!deadline) {
        throw new Error('Deadline is required!')
      }

      // 解密出 token
      const decryptFunc = deadline_version ? aesDecryptHex : aesDecryptBase64
      const [time, id] = decryptFunc(decodeURIComponent(String(deadline))).split('::')

      if (parseInt(route?.params?.activityPath) !== parseInt(id)) {
        throw new Error(`ActivityId do not match: ${parseInt(id)}`)
      }

      // const serverTime = deadline_server_time ? dayjs.utc(String(deadline_server_time)) : dayjs.utc()
      store.commit('traveller/activity/SET_PREVIEW_EXPIRED', !!dayjs.utc(time).isBefore(dayjs.utc()))
    } catch (error) {
      const logquery = req?.logquery
      if (logquery) {
        logquery.service({
          timestamp: Date.now(),
          level: 'W',
          funcName: 'redirect.activity.preview',
          isMasked: true,
          message: {
            url: route?.fullPath,
            error: JSON.stringify(error)
          }
        })
      }
      redirect(route?.fullPath?.replace('/preview', ''))
    }
  }

  next()
}

export default middleware
