import { _scroll2, _scrollHeight, _windowScrollTop, _nodeScrollTop, _clientHeight } from '~/share/scroll2/_scroll2.js'

interface ScrollArguments {
  scrollY?: number
  duration?: number
  lockType?: string
  lockLocation?: number
  callback?: Function
}

export const scrollHeight: () => number = _scrollHeight
export const clientHeight: () => number = _clientHeight
export const windowScrollTop: (el?: any) => number = _windowScrollTop
export const nodeScrollTop: (node: any, config?: any) => number = _nodeScrollTop
export const scroll2: (scrollArguments: ScrollArguments, config?: any) => void = _scroll2

// 检测滚动是否停止，需要在滚动执行之后立即执行此函数
export function checkScrollStop(elem: Element) {
  return new Promise((resolve) => {
    if (!(elem instanceof Element)) {
      throw new TypeError('Argument must be an Element')
    }

    let count = 0 // a counter
    let lastPos: number | null = null // last known Y position

    requestAnimationFrame(check)

    function check() {
      const newPos = elem.getBoundingClientRect().top
      if (newPos === lastPos) {
        count += 1

        if (count > 2) {
          return resolve(1)
        }
      } else {
        count = 0 // reset our counter
        lastPos = newPos // remember our current position
      }
      requestAnimationFrame(check)
    }
  })
}

// 滚动到package_option
export function scrollToPackageOption(config?: any) {
  const possibleClassList = [
    '.package_options_fnb',
    '.wifi_package_option',
    '.package_option',
    '.package_option_spec',
    '.package_option_card',
    '.package_option_card_sku',
    '.package_option_card_sku_with_detail',
    '.package_options',
    '.package_options_spec',
    '.package_options_spec_type',
    '.package_options_spec_type_left',
    '.package_options_card',
    '.Package_options_group',
    '.package_options_group'
  ]
  if (config?.firstEl) {
    possibleClassList.unshift(config.firstEl)
  }
  for (const cls of possibleClassList) {
    const packageOptionDom = document.querySelector(cls)
    if (packageOptionDom) {
      scroll2({ scrollY: nodeScrollTop(packageOptionDom, config), ...(config || {}) }, config)
      if (config?.cb && typeof config?.cb === 'function') {
        config.cb(packageOptionDom)
      }
      break
    }
  }
}

/**
 * 滚动元素到中间的方法（注意：有时候要在外层包上$nextTick）
 */
export function scrollToCenter(targetDom: HTMLElement, scrollDom: HTMLElement, behavior: ScrollBehavior = 'smooth') {
  if (targetDom && scrollDom) {
    const { offsetLeft, offsetWidth } = targetDom
    const { offsetLeft: wrapperOffsetLeft, offsetWidth: wrapperOffsetWidth } = scrollDom
    scrollDom.scrollTo({
      behavior,
      left: (offsetLeft - wrapperOffsetLeft) - (wrapperOffsetWidth - offsetWidth) / 2
    })
  }
}
