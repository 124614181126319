import { MutationTree, ActionTree } from 'vuex'
import apis from '~/share/data/apis'
import { RootState } from '~/store'
import { getDefaultLanguage, getOfflineDepartment } from '~/components/traveller/chat/util/chat-util'
import { Chat } from '~/types/traveller/chat'

export interface ChatState {
  isOffline: boolean
  isChatting: boolean
  isTyping: boolean
  isNeverRating: boolean
  isEndChat: boolean
  firstConnection: boolean
  department: string
  chatPool: Chat.Message[],
  chatHistory: Chat.Message[],
  form: Chat.Form | null,
  unReadList: Array<string|number>
  globalWarningTxt: string
}

export const state = () => ({
  isOffline: false, // 离线状态
  isChatting: false, // 是否处于聊天状态
  isEndChat: false, // 判断是否结束聊天
  isTyping: false, // 是否正在输入
  isNeverRating: true, // 没有评价过
  firstConnection: true, // 首次登陆
  department: '', // 这里的部门指的是chinaCEG还是globalCEG
  chatPool: [], // 存储消息的池
  chatHistory: [],
  form: null,
  unReadList: [],
  globalWarningTxt: ''
})

export const mutations: MutationTree<ChatState> = {
  SET_IS_OFFLINE(state, payload) {
    state.isOffline = payload.isOffline
  },

  SET_IS_END_CHAT(state, payload) {
    state.isEndChat = payload
  },

  SET_IS_CHATTING(state, payload) {
    state.isChatting = payload
  },

  CLEAR_CHAT_POOL(state) {
    state.chatPool = []
  },

  SET_FIRST_CONNECTION(state, payload) {
    state.firstConnection = payload
  },

  ADD_CHAT_POLL(state, payload) {
    state.chatPool.push(payload)
  },

  ADD_CHAT_HISTORY(state, payload) {
    state.chatHistory.push(payload)
  },

  ADD_UNREAD_LIST(state, payload) {
    state.unReadList.push(payload)
  },

  DELETE_UNREAD_LIST(state, payload) {
    state.unReadList.splice(payload, 1)
  },

  CLEAR_UNREAD_LIST(state) {
    state.unReadList = []
  },

  SET_VISIBLE(state, payload) {
    const idx = state.chatPool.findIndex(item => item.id === payload.id)
    state.chatPool[idx].visible = payload.visible
  },

  SET_CHAT_ITEM_STATUS(state, payload) {
    const idx = state.chatPool.findIndex(item => item.timestamp === payload.timestamp)
    state.chatPool[idx].status = payload.status
  },

  SET_CHAT_ITEM_RATED(state, payload) {
    const idx = state.chatPool.findIndex(item => item.timestamp === payload.timestamp)
    state.chatPool[idx].rated = payload.rated
  },

  SET_IS_TYPING(state, payload) {
    state.isTyping = payload
  },

  SET_DEPARTMENT(state, payload) {
    state.department = payload
  },

  SET_FORM(state, payload) {
    state.form = payload
  },

  SET_IS_NEVER_RATE(state, payload) {
    state.isNeverRating = payload
  },

  SET_GLOBAL_WARNING_TXT(state, payload) {
    state.globalWarningTxt = payload
  }
}

export const actions: ActionTree<ChatState, RootState> = {

  async getDepartmentAndOffline({ commit, rootState }, { lang }) {
    const language = lang || getDefaultLanguage(rootState.klook.language) || 'english'
    let isChinaMainland = false
    try {
      const { success, result } = await this.$axios.$get(apis.isChinaMainland)
      if (success && result) {
        isChinaMainland = result.IsChinaMainland
      }
    } catch (e) {}
    const offlineDepartment = getOfflineDepartment(isChinaMainland, language)
    const { department = '', isOffline = false } = offlineDepartment || {}
    commit('SET_IS_OFFLINE', { isOffline })
    commit('SET_DEPARTMENT', department)
  }
}
