import showdown from 'showdown'
import { MutationTree, ActionTree } from 'vuex'
import { HelpCenter } from '~/types/traveller/faq'
import apis from '~/share/data/apis'
import { RootState } from '~/store'

const SET_FAQ = 'SET_FAQ'
const SET_POPULAR_FAQS = 'SET_POPULAR_FAQS'
const SET_HELPFUL_FAQS = 'SET_HELPFUL_FAQS'
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
const SET_CATEGORY = 'SET_CATEGORY'
const SET_CATEGORY_FAQS = 'SET_CATEGORY_FAQS'
const SET_SECOND_CATE_LIST = 'SET_SECOND_CATE_LIST'

const converter = new showdown.Converter({
  tables: true
})
const getCategoryId = (category: any) => {
  return category.category_id
}

export interface FaqState {
  popularFaqs: HelpCenter.FaqList
  helpfulFaqs: HelpCenter.FaqList
  categories: HelpCenter.Category
  faq: HelpCenter.Faq | null
  recommendFaqs: HelpCenter.FaqList
  relatedFaqs: HelpCenter.FaqList
  searchResults: HelpCenter.SearchResultList,
  searchResultCount: number,
  categoryFaqs: HelpCenter.FaqList,
  categoryFaqTotal: number,
  secondCateList: HelpCenter.SecondCateList
}

export const state = () => ({
  popularFaqs: [],
  helpfulFaqs: [],
  categories: [],
  faq: null,
  recommendFaqs: [],
  relatedFaqs: [],
  searchResults: [],
  searchResultCount: 0,
  categoryFaqs: [],
  categoryFaqTotal: 0,
  secondCateList: []
})

export const mutations: MutationTree<FaqState> = {
  [SET_POPULAR_FAQS](state, data) {
    state.popularFaqs = data.popularFaqs
  },

  [SET_HELPFUL_FAQS](state, data) {
    state.helpfulFaqs = data.helpfulFaqs
  },

  [SET_FAQ](state, data) {
    state.faq = data.faq
    state.recommendFaqs = data.recommendFaqs
    state.relatedFaqs = data.relatedFaqs
  },

  [SET_SEARCH_RESULTS](state, data) {
    state.searchResultCount = data.count
    state.searchResults = data.searchResults
  },

  [SET_CATEGORY](state, data) {
    state.categories = data.categories
  },

  [SET_CATEGORY_FAQS](state, data) {
    state.categoryFaqs = data.categoryFaqs
    state.categoryFaqTotal = data.categoryFaqTotal
  },

  [SET_SECOND_CATE_LIST](state, data) {
    state.secondCateList = data.secondCateList
  }
}

export const actions: ActionTree<FaqState, RootState> = {
  getPopularFaqs({ commit }) {
    return this.$axios
      .$get(apis.categoryFaqs, { params: { category_id: 14, limit: 8, page: 1 } })
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.faq_info_list.length > 0) {
          const data = res.result
          commit(SET_POPULAR_FAQS, {
            popularFaqs: data.faq_info_list.map((faq: any) => ({
              faqId: faq.faq_id,
              question: faq.question,
              categoryId: faq.sub_category_id || faq.category_id
            }))
          })
        }
      })
  },

  // help center 首页重构AB测试使用的新url
  getHelpfulFaqs({ commit }) {
    return this.$axios
      .$get(apis.mostHelpfulFaqs, { params: { limit: 5 } })
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.helpful_faqs.length > 0) {
          const data = res.result
          commit(SET_HELPFUL_FAQS, {
            helpfulFaqs: data.helpful_faqs
          })
        }
      })
  },

  getFaqById({ commit }, { faqId }) {
    const emptyResult = {
      faq: null,
      recommendFaqs: [],
      relatedFaqs: []
    }

    return this.$axios
      .$get(apis.faqDetail, { params: { faq_id: faqId } })
      .then((res: Data.Res) => {
        if (res.success && res.result) {
          const data = res.result
          const recommendFaqs = data.recommend_faq_list || []
          const relatedFaqs = data.same_category_faq_list || []
          const result = {
            faq: {
              faqId: data.faq_id,
              question: data.question,
              answer: converter.makeHtml(data.answer),
              voteStatus: data.helpful_status,
              categoryId: data.sub_category_id || data.category_id,
              categoryName: data.sub_category_name || data.category_name,
              firstClassName: data.category_name,
              firstCategoryId: data.category_id
            },
            recommendFaqs: recommendFaqs.map((faq: any) => ({
              faqId: faq.faq_id,
              question: faq.question,
              categoryId: faq.sub_category_id || faq.category_id
            })),
            relatedFaqs: relatedFaqs.map((faq: any) => ({
              faqId: faq.faq_id,
              question: faq.question,
              categoryId: data.sub_category_id || data.category_id
            }))
          }

          commit(SET_FAQ, result)
          return result
        } else {
          commit(SET_FAQ, emptyResult)
          return emptyResult
        }
      })
      .catch(() => {
        // Ignore
        commit(SET_FAQ, emptyResult)
        return emptyResult
      })
  },

  search({ commit }, { keyword, limit = 10 }) {
    const emptyResult = { count: 0, searchResults: [] }

    if (!keyword) {
      commit(SET_SEARCH_RESULTS, emptyResult)
      return Promise.resolve(emptyResult)
    }

    return this.$axios
      .$get(apis.faqSearchSuggest, { params: { keyword, limit } })
      .then((res: Data.Res) => {
        if (res.success && res.result) {
          const data = res.result.faq_category_list || []
          const count = res.result.count || 0
          const searchResults = data.map((item: any) => ({
            categoryId: item.category_id,
            categoryName: item.category_name,
            subCategoryId: item.sub_category_id,
            subCategoryName: item.sub_category_name,
            faqs: (item.faq_list || []).map((faq: any) => ({
              faqId: faq.faq_id,
              question: faq.question,
              categoryId: item.sub_category_id || item.category_id
            }))
          }))
          const result = { count, searchResults }

          commit(SET_SEARCH_RESULTS, result)
          return result
        } else {
          commit(SET_SEARCH_RESULTS, emptyResult)
          return emptyResult
        }
      })
      .catch(() => {
        commit(SET_SEARCH_RESULTS, emptyResult)
        return emptyResult
      })
  },

  getCategories({ commit, rootState }) {
    const emptyResult = { categories: [] }

    return this.$axios
      .$get(apis.faqCategory)
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.length > 0) {
          const categories = res.result

          commit(SET_CATEGORY, {
            categories: categories.map((category: any) => ({
              categoryId: getCategoryId(category),
              level: category.category_level,
              type: category.category_type,
              name: category.name,
              desc: category.desc,
              iconUrl: category.icon_url,
              opened: rootState.klook.platform === 'desktop',
              children: (category.children_category_list || []).map((categoryChild: any) => ({
                categoryId: categoryChild.category_id,
                level: categoryChild.category_level,
                type: categoryChild.category_type,
                name: categoryChild.name,
                desc: categoryChild.desc,
                iconUrl: categoryChild.icon_url
              }))
            }))
          })
        } else {
          commit(SET_CATEGORY, emptyResult)
        }
      }).catch(() => {
        commit(SET_CATEGORY, emptyResult)
      })
  },

  getCategoryFaqs({ commit }, { categoryId, limit, page }) {
    const emptyResult = { categoryFaqTotal: 0, categoryFaqs: [] }
    return this.$axios
      .$get(apis.categoryFaqs, { params: { category_id: categoryId, limit, page } })
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.faq_info_list.length > 0) {
          const data = res.result
          commit(SET_CATEGORY_FAQS, {
            categoryFaqTotal: data.count,
            categoryFaqs: data.faq_info_list.map((faq: any) => ({
              faqId: faq.faq_id,
              question: faq.question,
              categoryId: faq.sub_category_id || faq.category_id
            }))
          })
        } else {
          commit(SET_CATEGORY_FAQS, emptyResult)
        }
      }).catch(() => {
        commit(SET_CATEGORY_FAQS, emptyResult)
      })
  },

  getSecondCateList({ commit }, { categoryId }) {
    const emptyResult = { secondCateList: [] }
    return this.$axios
      .$get(apis.secondCateList, { params: { category_id: categoryId } })
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.children_category_with_faq_list.length > 0) {
          const data = res.result
          const list = res.result.children_category_with_faq_list
          commit(SET_SECOND_CATE_LIST, {
            categoryName: data.name,
            categoryId: data.category_id,
            secondCateList: list.map((faq: any) => ({
              subName: faq.name,
              categoryId: faq.category_id,
              isViewAll: faq.faq_count > 4,
              faqList: faq.faq_info_list.map((second: any) => ({
                faqId: second.faq_id,
                question: second.question,
                categoryId: second.sub_category_id
              }))
            }))
          })
        } else {
          commit(SET_SECOND_CATE_LIST, emptyResult)
        }
      }).catch(() => {
        commit(SET_SECOND_CATE_LIST, emptyResult)
      })
  }
}
