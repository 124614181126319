export interface ErrorParams {
  status: number,
  message: string
}
/**
 * 通用异常基类
 *
 * @class HttpException
 * @extends {Error}
 */
class HttpException extends Error {
  public status: number
  public message: string
  public ctx: Vue
  /**
   * Creates an instance of HttpException.
   * @param {number} status 状态码
   * @param {string} message 异常文案
   * @param {*} app 组件上下文
   * @memberof HttpException
   */
  constructor(status: number, message: string, app: Vue) {
    super(message)
    this.status = status
    this.message = message
    this.ctx = app
  }

  /**
   * 触发异常处理
   * 默认弹窗
   * @param {Function} [cb] 异常定制函数
   * @memberof HttpException
   */
  send(cb?: Function): void {
    const { platform } = this.ctx.$store.state.klook
    const isClient = process.client
    if (cb && cb instanceof Function) {
      cb.call(this, this.message)
    } else if (isClient) {
      if (platform === 'desktop') {
        this.ctx.$alert(this.message, undefined, {
          okLabel: this.ctx.$t('global.tips.okTxt')
        })
      } else {
        this.ctx.$message.errorTip(this.message)
      }
    } else {
    }
  }
}

/**
 * 系统异常
 *
 * @class SystemError
 * @extends {HttpException}
 */
class SystemError extends HttpException {
  public app: Vue
  constructor(error: ErrorParams, app: Vue) {
    super(error.status || 500, error.message, app)
    this.app = app
  }
}

export {
  HttpException,
  SystemError
}
