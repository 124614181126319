/**
 * orderDetailDeeplinkRedirect middleware
 * 订单详情页deeplink中转页请求（仅CN会访问）
 * @Component({
    middleware: ['needLogin', 'orderDetailDeeplinkRedirect']
  })
 */
import { Middleware } from '@nuxt/types'
import apis from '~/share/data/apis'

const middleware: Middleware = async function (ctx, next) {
  if (process.client) {
    return next()
  }
  const { route: { query }, req, store: { state: { klook } } } = ctx
  const isCn = klook.market === 'cn'
  let origin_url = klook.host + req?.url // 注意：req在客户端为空，可以考虑使用ctx.route.fullPath
  if (process.env.APP_ENV !== 'development' || !klook.host.includes('localhost')) {
    origin_url = 'https://' + origin_url
  }
  // console.log(778899, isCn, origin_url, ctx.store.state.klook.market)
  if (isCn) {
    try {
      const params = {
        origin_url,
        order_no: query.guid,
        booking_no: query.bookingNo
      }
      const resp = await ctx.app.$axios.$get(apis.getOrderDetailDeeplinkRedirect, { params })
      if (resp?.success && resp?.result?.redirect) {
        ctx.redirect(resp.result.redirect)
      }
    } catch (_error) { // 接口异常，按正常流程处理
      console.log(`Fetch Error: ${apis.getOrderDetailDeeplinkRedirect}`, _error)
    }
  }
  next()
}

export default middleware
