import { Context } from '@nuxt/types'
import { RouterOptions, RouteConfig } from 'vue-router'

/**
 * Generate routes by language and platform
 */
const genSuperRoutes = function (
  routes: RouteConfig[],
  language: Data.Language,
  platform: Data.Platform,
  i18nPath = true
) {
  const normalizeRoutes = routes.map((route: RouteConfig) => {
    // Support i18n path
    if (i18nPath && language !== 'en') {
      route.path = `/${language}${route.path}`
      if (route.redirect) {
        route.redirect = `/${language}${route.redirect}`
      }
    }

    // Set component by platform and delete others
    if (route.components) {
      route.component = route.components[platform]
      delete route.components
    }

    if (route.children) {
      route.children = genSuperRoutes(route.children, language, platform, false)
    }

    return route
  })

  return normalizeRoutes
}

/**
 * Create router before rendering (server/client)
 * So we can change router options here
 */
export const createRouter = function (ssrContext: Context, routerOptions: RouterOptions) {
  let language: Data.Language = 'en'
  let platform: Data.Platform = 'mobile'

  if (process.server) {
    language = ssrContext.req.language
    platform = ssrContext.req.platform
  } else if (process.client) {
    language = window.__KLOOK__.state.klook.language
    platform = window.__KLOOK__.state.klook.platform
  }

  if (routerOptions.routes) {
    routerOptions.routes = genSuperRoutes(routerOptions.routes, language, platform)
  }

  return routerOptions
}
