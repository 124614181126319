import { Prop, Component, Vue } from 'nuxt-property-decorator'
import Placeholder from './placeholder/placeholder.vue'

const createOffScreen = (Comp: Vue.VueConstructor) => (isSSR: boolean) => {
  @Component
  class OffScreenWrap extends Vue {
    @Prop() isOffScreen!: boolean

    get offScreen() {
      /**
       * 如果是bot或者限流，isSSR = true，则不再判断组件传入的prop
       * 否则以组件传入的isOffScreen做判断，默认true，走CSR
       */
      return isSSR ? false : this.isOffScreen
    }

    render() {
      return <Comp isOffScreen={this.offScreen} {...{ props: this.$attrs }} >
        { this.$slots.default }
        <template slot={'placeholder'}>
          { this.$slots.placeholder || <Placeholder /> }
        </template>
      </Comp>
    }
  }

  return OffScreenWrap
}

export default createOffScreen
