














import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class OffScreen extends Vue {
  @Prop({ default: () => true }) isOffScreen!: boolean
}
