import MD5 from 'md5.js'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import apis from '~/share/data/apis'

export const state = () => ({
  lastVerify: Date.now(),
  isLoggedIn: false,
  user: null
})

export interface AuthState {
  lastVerify: number
  isLoggedIn: boolean
  user: Data.User | null
}

export const mutations: MutationTree<AuthState> = {
  SET_VERIFY_STATUS(state) {
    state.lastVerify = Date.now()
  },

  SET_USER(state, user: any) {
    if (user) {
      const isLimitedAccess = (user?.restrict_policies || []).includes('deprecated')
      state.isLoggedIn = true
      state.user = {
        id: user.user_id,
        globalId: user.global_id,
        username: user.user_name,
        title: user.title,
        email: user.email,
        emailHash: user.email && new MD5().update(user.email).digest('hex'),
        avatar: user.avatar,
        countryCode: user.country_code,
        createTime: user.create_time && new Date(user.create_time.replace(/-/g, '/')).toLocaleDateString('en-US'),
        unreview: user.unreview,
        giftCard: user.gift_card,
        registerSite: user.register_site,
        preferSite: user.prefer_site,
        confirmed_residence: user.confirmed_residence || '',
        membership_level: user.membership_level || '',
        user_residence: user.user_residence || '',
        membership_style: user.membership_style || {},
        title_list: user.title_list || [],
        display_membership_entry: user.display_membership_entry || false,
        isLimitedAccess
      }
    } else {
      state.isLoggedIn = false
      state.user = null
    }
  }
}

export const actions: ActionTree<AuthState, RootState> = {
  /**
   * @NOTE: 每次切换路由都会验证用户状态，比较浪费性能，这里做一个锁，每 5 分钟校验一次
   */
  canVerify({ commit, state }) {
    if (process.client) {
      if (Date.now() - state.lastVerify > 180000) { // 3min
        commit('SET_VERIFY_STATUS')
        return true
      }
      return false
    } else {
      return true
    }
  },

  /**
   * @NOTE: 用一个更轻量的校验接口来验证 token 的有效性，不存储验证结果
   */
  async verify() {
    try {
      const res = await this.$axios.$get(apis.verify, { timeout: process.client ? 8000 : 300 })
      if (res.success && res.result) {
        const user = res.result
        const isLimitedAccess = (user?.restrict_policies || []).includes('deprecated')
        return {
          id: user.user_id,
          globalId: user.global_id,
          username: user.user_name,
          title: user.title,
          email: user.email,
          avatar: user.avatar,
          countryCode: user.country_code,
          preferSite: user.prefer_site,
          isLimitedAccess
        }
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },

  updateUid(_, ctx = {}) {
    const { cookies, global_id, type } = ctx || {}
    if (cookies && type === 'remove') {
      cookies.remove('user_id')
      return
    }

    if (cookies && global_id && cookies.get('user_id') !== global_id) {
      cookies.set('user_id', global_id, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7
      })
    }
  },

  async getProfile({ commit, dispatch }, { ctx } = {}) {
    try {
      const res = await this.$axios.$get(apis.simpleProfile)
      const unreviewNum = 0

      const cookies = ctx?.app?.$cookies

      if (res.success && res.result) {
        /** 双11去掉unreview的接口
         * const resUnreview = await this.$axios.$get(apis.unreview)

            if (resUnreview.success && resUnreview.result) {
              unreviewNum = resUnreview.result.unreview_num
            }
            res.result.unreview = unreviewNum
         */

        res.result.unreview = unreviewNum
        commit('SET_USER', res.result)

        await dispatch('updateUid', { cookies, global_id: res.result?.global_id })

        return res.result
      }

      commit('SET_USER', null)
      await dispatch('updateUid', { cookies, type: 'remove' })
      return null
    } catch (error) {
      commit('SET_USER', null)
      return null
    }
  }
}
