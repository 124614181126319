import { VueConstructor, CreateElement } from 'vue'

export default {
  install(Vue: VueConstructor) {
    function dialog(
      content: string | Function,
      title?: string,
      options?: {[key: string]: any}
    ) {
      if (typeof title === 'object') {
        options = title
        title = undefined
      }
      options = options || {}
      return new Promise((resolve) => {
        const hide = (result: boolean = false) => {
          resolve(result)
          document.body.removeChild(instance.$el)
          instance.$destroy()
          instance = null
        }
        let instance: any = new Vue({
          render: (h: CreateElement) => h('klk-modal', {
            props: {
              ...options,
              scrollable: true, // 是否可以内部滚动   处理超长的内容
              open: true,
              overlayClosable: true,
              title
            },
            on: {
              'on-confirm': () => hide(true),
              'on-cancel': hide,
              close: hide,
              'on-close': hide
            }
          },
          [
            typeof content === 'function'
              ? content(h)
              : content
          ])
        }).$mount()
        document.body.appendChild(instance.$el)
      })
    }

    ;['alert', 'confirm'].forEach((method: string, index: number) => {
      // @ts-ignore
      dialog[method] = function (
        content: string | Function,
        title?: string,
        options: {[key: string]: any} = {}
      ) {
        if (typeof title === 'object') {
          options = title
          title = undefined
        }
        options = options || {}
        return this(content, title, {
          titleAlign: 'center',
          buttonAlign: 'center',
          showCancelButton: Boolean(index),
          ...options
        })
      }
    })
    Vue.prototype.$dialog = dialog
  }
}
