import { Plugin } from '@nuxt/types'
import { getQueryKey } from '~/share/utils'
import whiteLabelSdkInit from '~/share/white-label/white-label-sdk-init'

export const filterLanguageCurrency = (wl: any, filterOptions: any) => {
  const { languages, currencies } = wl?.config || {}
  const { filterLanguages = [], filterCurrencies = [] } = filterOptions
  if (Array.isArray(languages?.value)) {
    languages.value = languages.value.filter((v: string) => !filterLanguages.includes(v))
  }
  if (Array.isArray(currencies?.value)) {
    currencies.value = currencies.value.filter((v: string) => !filterCurrencies.includes(v))
  }
  return wl
}

const plugin: Plugin = async function (ctx) {
  const { store, query } = ctx
  if (store.state.klook.utilConfig?.whiteLabelUtilConfig) {
    const dev_domain: string = getQueryKey('dev_domain', query)
    const host = process.env.APP_ENV === 'development' ? dev_domain : store.state.klook.host
    const loggerQuery = ctx?.store?.$logquery

    const wl = await whiteLabelSdkInit({
      loggerQuery,
      nodeEnv: process.env.APP_ENV || 'production',
      host: host || '',
      platform: store.state.klook.platform,
      language: store.state.klook.language,
      currency: store.state.klook.currency,
      ctx
    })

    // filterLanguageCurrency(wl, {
    //   filterLanguages: ['zh_CN', 'ko_KR'],
    //   filterCurrencies: ['CNY', 'KRW']
    // })

    store.state.klook.utilConfig.whiteLabelUtilConfig.sdk = wl
  }
}

export default plugin
