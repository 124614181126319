import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import apis from '~/share/data/apis'

const SET_HEADER_SEARCH_DATA = 'SET_HEADER_SEARCH_DATA'
const SET_HEADER_SEARCH_HISTORY = 'SET_HEADER_SEARCH_HISTORY'
const SET_SLIDE_WORD_DATA = 'SET_SLIDE_WORD_DATA'

export const state = () => ({
  placeholder: '',
  suggestParams: null,
  searchQuery: '',
  searchHistoryArr: [],
  slideWordList: []
})

export const mutations: MutationTree<Data.HeaderSearch> = {
  [SET_HEADER_SEARCH_DATA](state, data) {
    state.placeholder = data.placeholder
    state.searchQuery = data.searchQuery
    state.suggestParams = data.suggestParams
  },
  [SET_HEADER_SEARCH_HISTORY](state, data) {
    state.searchHistoryArr = data
  },
  [SET_SLIDE_WORD_DATA](state, data) {
    state.slideWordList = data
  }
}

export const actions: ActionTree<Data.HeaderSearch, RootState> = {
  setHeaderSearch({ commit }, data) {
    commit(SET_HEADER_SEARCH_DATA, data)
  },
  getSlideWord({ commit }) {
    return this.$axios.$get(apis.slideWord).then((res: Data.Res) => {
      if (res.success && res.result) {
        commit(SET_SLIDE_WORD_DATA, res.result.data || [])
      }
    })
  }
}
