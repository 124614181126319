import { businessNames, handler } from '~/share/data/experience/constant'
import { Experience } from '~/types/experience'
import apis from '~/share/data/apis'

export function getSection({
  name,
  data,
  dataType,
  sync = true,
  title,
  sub_title,
  src,
  type = 'any',
  more_title,
  more_deep_link,
  bottom_button_title,
  bottom_button_deep_link,
  customMeta = {}
}: {
  name: string,
  dataType: string,
  data?: any,
  sync?: boolean,
  title?: string,
  sub_title?: string,
  src?: string,
  type?: string,
  more_title?: string
  more_deep_link?: string
  bottom_button_title?: string
  bottom_button_deep_link?: string
  customMeta?: Record<string, string>
}) {
  return {
    meta: {
      type,
      track: null,
      name,
      ...customMeta
    },
    data: null,
    body: {
      content:
        {
          handler,
          data_type: dataType,
          load_type: sync ? 'sync' : 'async',
          src,
          track: null,
          data: {
            title,
            sub_title,
            more_title,
            more_deep_link,
            bottom_button_title,
            bottom_button_deep_link,
            ...data
          }
        }
    }
  }
}

const world = [
  businessNames.ExperienceHeader,
  businessNames.subVerticalMenu,
  businessNames.subVerticalBanner,
  businessNames.ExperiencePromoSystem,
  businessNames.ExperienceHotCountry
]

const country = [
  businessNames.ExperienceHeader,
  businessNames.subVerticalMenu,
  businessNames.subVerticalBanner,
  businessNames.ExperiencePromoSystem,
  businessNames.ExperienceDestinationSection,
  businessNames.ActivityHighlight,
  businessNames.ExperienceBlogList,
  businessNames.ReviewList,
  businessNames.CountrySeoLink
]

const city = [
  businessNames.ExperienceHeader,
  businessNames.subVerticalMenu,
  businessNames.subVerticalBanner,
  businessNames.ExperiencePromoSystem,
  businessNames.ActivityHighlight,
  businessNames.ExperienceBlogList,
  businessNames.NearbyActivities,
  businessNames.NearbyCities,
  businessNames.ReviewList,
  businessNames.CitySeoLink
]

export const sectionListMap: {
  [prop: string]: string[]
} = {
  'world-l0': world,
  'world-l1': world,
  'country-l0': country,
  'country-l1': country,
  'city-l0': city,
  'city-l1': city
}

/**
 * 楼层配置
 * action: 获取楼层数据的action
 * params: 接口参数
 */
interface SectionsConfig {
  sync: boolean,
  type: string,
  required?: boolean,
  action?: string,
  src?: string,
  title?: string | Function,
  params?: any,
  bottom_button_title?: string,
  validate?: Function,
  [prop: string]: any

}
const baseConfig = {
  sync: true,
  type: 'common',
  required: false
}

const seoConfig = {
  ...baseConfig,
  dataType: businessNames.SeoLink,
  sync: true,
  type: 'common',
  title: '',
  validate: (result: any) => result.seo_static_link && result.seo_static_link.length
}

export const sectionsConfig: { [prop: string]: SectionsConfig } = {
  [businessNames.ExperiencePromoSystem]: {
    ...baseConfig,
    src: apis.getPromoCouponList, // 平台广告数据，对接的是平台 SDK, 目前不做处理
    sync: false,
    type: 'any',
    customMeta: {
      placeholder: false
    },
    params: ({ cityId, countryId, breadcrumb }: Experience.State) => {
      return {
        city_id: cityId || breadcrumb?.city?.id,
        country_id: countryId || breadcrumb?.country?.id,
        vertical_type: 'exp',
        sub_category_id_list: '1,415,523,527,529'
      }
    },
    validate: (result: any) => result.items && result.items.length
  },
  [businessNames.ExperienceHeader]: {
    ...baseConfig,
    action: 'experience/attractions_getSectionHeader',
    sync: true,
    type: 'any'
  },
  [businessNames.subVerticalMenu]: {
    ...baseConfig,
    src: apis.getAttractionsActivityNav,
    sync: true,
    type: 'any',
    validate: (result: any) => result.categories && result.categories.length
  },
  [businessNames.subVerticalBanner]: {
    ...baseConfig,
    src: apis.getAttractionsActivityBanner,
    sync: true,
    type: 'any',
    validate: (result: any) => result.banner_image_items && result.banner_image_items.length
  },
  [businessNames.ExperienceBlogList]: {
    ...baseConfig,
    src: apis.getAttractionsBlog,
    sync: true,
    title: '16785',
    validate: (result: any) => result.blogs && result.blogs.length
  },
  [businessNames.ReviewList]: {
    ...baseConfig,
    src: apis.getAttractionsReviewList,
    sync: true,
    type: 'common',
    title: ({ destinationTitle = '', categoryTitle = '' }: { destinationTitle: string, categoryTitle: string }) => ['23035', { destination: destinationTitle, keyword: categoryTitle }],
    params: ({ cityId, countryId, frontendId }: Experience.State) => {
      return {
        city_id: cityId,
        country_id: countryId,
        frontend_id: frontendId
      }
    },
    validate: (result: any) => result.review_list && result.review_list.length
  },
  [businessNames.ExperienceDestinationSection]: {
    ...baseConfig,
    src: apis.getAttractionsHotCity,
    sync: true,
    title: ({ destinationTitle = '' }: { destinationTitle: string }) => ['16792', { destination: destinationTitle }],
    validate: (result: any) => result.hot_cities && result.hot_cities.length
  },
  [businessNames.NearbyCities]: {
    ...baseConfig,
    sync: false,
    type: 'common',
    src: apis.getAttractionsNearbyCity,
    title: '16790',
    validate: (result: any) => result.nearby_city && result.nearby_city.length
  },
  [businessNames.ExperienceHotCountry]: {
    ...baseConfig,
    sync: true,
    type: 'common',
    src: apis.getAttractionsHotCountry,
    validate: (result: any) => result.hot_countries && result.hot_countries.length
  },
  [businessNames.ActivityHighlight]: {
    ...baseConfig,
    src: apis.getAttractionsActivityHighlight,
    sync: true,
    required: true,
    title: '16775',
    params: ({ cityId, countryId, pageType, frontendId }: Experience.State) => {
      let type: string = 'all'
      if (pageType.includes('city')) {
        type = 'city'
      } else if (pageType.includes('country')) {
        type = 'country'
      }

      return {
        city_id: cityId,
        country_id: countryId,
        frontend_id: frontendId,
        type
      }
    },
    validate: (result: any) => result.highlight_activities && result.highlight_activities.length
  },
  [businessNames.NearbyActivities]: {
    ...baseConfig,
    sync: false,
    type: 'common',
    src: apis.getAttractionsNearbyActivity,
    title: ({ destinationTitle = '' }: { destinationTitle: string }) => ['16788', { destination: destinationTitle }],
    bottom_button_title: '16776',
    bottom_button_deep_link: ({ cityId, frontendId }: Experience.State) => `/experiences/nearby/?cityId=${cityId}&frontendId=${frontendId}`,
    validate: (result: any) => !!(result?.activities_v2?.items?.length ?? result?.activities?.length)
  },
  [businessNames.CountrySeoLink]: {
    ...seoConfig,
    src: apis.getAttractionsCountrySeo,
    title: 'more_recommended'
  },
  [businessNames.CitySeoLink]: {
    ...seoConfig,
    src: apis.getAttractionsCitySeo,
    title: 'more_recommended'
  }
}

export function getFormatData(key: Function | string, state: Experience.State) {
  if (typeof key === 'function') {
    return key(state)
  }

  return key
}
