import Vue from 'vue'
import { cloneDeep, get } from 'lodash'
import apis from '~/share/data/apis'

const onCache = {
  // 简单版只会注册一次事件
}
const state = Vue.observable({
  globalData: {
    apiWaitCount: 0
  },
  [apis.experienceGetAddonSku]: {
    // pid: { start_time: apiData }
  },
  pkgsCache: {},
  preResult: null
})
export const stateObj = state
export const action = {
  // setter
  set(pid, key, val, rootKey = 'pkgsCache') {
    const obj = cloneDeep(state[rootKey][pid] || {})
    obj[key] = val
    Vue.set(state[rootKey], pid, obj)
    // console.log(789789, Array.from(arguments), 'set', state.pkgsCache)
  },
  // getter
  get(pid, key, rootKey = 'pkgsCache') {
    const obj = state[rootKey][pid] || {}
    // console.log(789789, Array.from(arguments), 'get', obj[key])
    return key ? obj[key] : obj
  },
  getList2key(key, rootKey = 'pkgsCache') {
    const pids = Object.keys(state[rootKey]) || []
    const arr = pids.reduce((list, pid) => {
      const obj = get(state, `${rootKey}.${pid}.${key}`)
      obj?.package_id && list.push(obj)
      return list
    }, [])
    return arr
  },
  setPreData(result) {
    state.preResult = result
  },
  getPreData() {
    return state.preResult || {}
  },
  setGlobalData(key, val) {
    Vue.set(state.globalData, key, val)
  },
  getGlobalData(key) {
    return state.globalData[key]
  }
}
export const on = (eventName, callback) => {
  onCache[eventName] = callback
}
export const emit = (...args) => {
  const eventName = args.shift()
  if (typeof onCache[eventName] === 'function') {
    onCache[eventName](...args)
  }
}
