import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { cloneDeep } from 'lodash'
import * as types from '~/store/traveller/activity/types'
import { ActionTree } from '~/node_modules/vuex'
import { Activity } from '~/types/traveller/activity'
import { RootState } from '~/store'
import { f2bLanguageMap } from '~/share/data/language'
import { urlTemplate } from '~/share/utils'
import apis from '~/share/data/apis'
import { markdown2RenderObj } from '~/assets/scripts/markdown'

const cms = require('~/share/data/cms/index')

let promotionDetailCancelToken: CancelTokenSource | null = null
let activityReviewsCancelToken: CancelTokenSource | null = null
let preSettlementCancel: any = null

const CancelToken = axios.CancelToken

const actions: ActionTree<Activity.State, RootState> = {
  async getBasicInfoAndAddonSchedules2action({ commit }, params: any) {
    const pids = ('' + params?.package_id)?.split(',')?.map((str: string) => Number(str)) || []
    const loadingConfig = {
      showOverlay: false
    }
    const apiConfig = { loading: params?.hideLoading ? undefined : loadingConfig, ...(params?.apiConfig) }
    let res: any
    try {
      res = await this.$axios.$get(apis.experienceGetBasicInfoAndAddonSchedules, {
        params,
        ...apiConfig
      })
      pids.forEach((package_id: any) => {
        const pid = package_id
        const { result } = res || {}
        const obj = (result || []).find((o: any) => o.package_id === pid)
        commit(types.SET_PKGS_BASIC_INFO, { params: { pid }, res: { ...res, result: obj && obj.basic_info } })
        commit(types.SET_PKGS_ADDON_SCHEDULES_AND_UNITS, { params: { pid }, res: { ...res, result: obj && obj.schedule } })
      })
    } catch (error) {
      pids.forEach((package_id: any) => {
        const pid = Number(package_id)
        commit(types.SET_PKGS_BASIC_INFO, { params: { pid }, res: { ...res, result: null } })
        commit(types.SET_PKGS_ADDON_SCHEDULES_AND_UNITS, { params: { pid }, res: { ...res, result: null } })
      })
      return {
        error: { message: '' + error }
      }
    }
    return res
  },
  async getPreSettlementApiData({ commit }, params: any) {
    const loadingConfig = {
      showOverlay: false
    }
    const apiConfig = { loading: params?.hideLoading ? undefined : loadingConfig, ...(params?.apiConfig) }
    commit(types.SET_COMMON_LOADING_STATE, 'fetching')
    if (preSettlementCancel) {
      preSettlementCancel()
    }
    let res: any
    const cloneParams = { ...params }
    delete cloneParams.pid
    try {
      res = await this.$axios.$post(apis.experiencePostPreSettlement, cloneParams, {
        timeout: 3000,
        ...apiConfig,
        cancelToken: new CancelToken(function executor(c: any) {
          preSettlementCancel = c
        })
      })
      commit(types.SET_PRE_SETTLEMENT_API_DATA, { params, res })
      if (res?.success) {
        commit(types.SET_COMMON_LOADING_STATE, 'success')
      } else {
        commit(types.SET_COMMON_LOADING_STATE, 'failure')
      }
    } catch (error) {
      commit(types.SET_COMMON_LOADING_STATE, 'failure')
      commit(types.SET_PRE_SETTLEMENT_API_DATA, { params, res: null })
    }
    return res
  },

  async initialData2act({ commit, state }, { actDetail, isMergePackages }) {
    // 注意此处需要还原活动级别参数
    const activityDetail = {
      id: state.activityId,
      is_nsw_activity: state.isNswActivity,
      date_description: state.pickupDateDescription,
      is_jump_old_settlement: state.isJumpOldSettlement,
      ...(actDetail || {})
    }
    await commit(types.GET_ACTIVITY_DETAIL, { ...activityDetail, isMergePackages })
  },

  async initialData2pkg({ dispatch }, { pkgDetail }) {
    pkgDetail && (pkgDetail.has_stocks = true)
    // 注意此处需要还原活动级别参数
    const activityDetail = {
      id: pkgDetail?.activity_id,
      activity_name: pkgDetail?.activity_name,
      is_nsw_activity: pkgDetail?.is_nsw_activity,
      date_description: pkgDetail?.date_desc,
      is_jump_old_settlement: pkgDetail?.is_jump_old_settlement,
      packages: pkgDetail ? [pkgDetail] : [],
      support_language: pkgDetail?.support_language
    }
    await dispatch('initialData', { activityDetail })
  },

  initialData({ commit, rootState }, { activityDetail, CMSGenericItem, preview }) {
    const { klook } = rootState
    const isGuestCheckout = klook.utilConfig.is_guest_checkout

    // guest checkout对app凭证的活动需要屏蔽套餐
    if (isGuestCheckout) {
      const { packages = [], spec = [], best_package_id: bestPackageId } = activityDetail

      let appSpecs: any[] = []
      let appPackageIds: any[] = []
      activityDetail.packages = packages && packages.filter(function (item: any) {
        const isAppActivity = [2, 3].includes(item.db_voucher_usage)
        if (isAppActivity) {
          appSpecs = appSpecs.concat(item.spec_attr_id || [])
          appPackageIds = appPackageIds.concat(item.package_id)
        }
        return !isAppActivity
      })

      if (spec && spec.length === 1) {
        activityDetail.spec[0].attr = (spec[0].attr || []).filter(function (item: any) {
          return !appSpecs.includes(item.id)
        })
      }

      if (appPackageIds.includes(bestPackageId)) {
        activityDetail.best_package_id = ''
      }
    }
    commit(types.GET_ACTIVITY_DETAIL, { ...activityDetail })
    commit(types.CMS_AND_PREVIEW, { CMSGenericItem, preview })

    /**
     * seo support language
     */
    const { language } = rootState.klook
    const activitySupportLanguages: string[] = activityDetail.support_language || []
    const b2fLanguageMap: { [key: string]: Data.Language } = reduce(f2bLanguageMap, (acc, v, k) => ({
      ...acc,
      [v]: k
    }), {})
    const supportLanguages: Data.Language[] = reduce(activitySupportLanguages, (acc, v) => {
      // seo-1005740 越南语去除hreflang=x-default
      if (language === 'vi' && v === 'en') {
        return acc
      }
      if (language === 'en' && v === 'vi') {
        return acc
      }
      const b2fLanguage = b2fLanguageMap[v]
      return b2fLanguage ? [...acc, b2fLanguage] : acc
    }, [] as Data.Language[])

    // preview 状态下展示所有语言，不区分已发布和未发布
    if (!preview) {
      commit(types.SET_INITIAL_DATA, {
        supportLanguages
      }, { root: true })
    }
  },

  async getActivityDetail({ state }) {
    const { activityId, preview, source } = state

    const activityDetailApi = urlTemplate(apis.activityDetail, { activityId })

    const activityDetailRes = await this.$axios
      .$get(activityDetailApi, {
        regularUrl: '/v1/usrcsrv/activity/{*}/detail',
        params: {
          preview: preview ? 1 : 0,
          campaign_id: source,
          // 默认入口版
          testing_4958: 'Variant1',
          only_static: true
        }
      })

    return activityDetailRes
  },

  // 获取促销详情信息(变更套餐或者日期时调用，need_promotion_price为true的套餐才需要调用)
  // api文档：https://klook.slab.com/drafts/%E4%BF%83%E9%94%80%E9%A2%84%E8%AE%A2%E6%B5%81%E7%A8%8B%E8%AF%A6%E7%BB%86%E8%AE%BE%E8%AE%A1-vjnnp23a
  async getPromotionDetail({ state, commit, rootState }) {
    const { klook } = rootState
    const noPromotion = klook.utilConfig?.whiteLabelUtilConfig?.hide_merchandising_promotion
    if (process.server || noPromotion) {
      return
    }

    const { packageId, packages, packageSelectedDate } = state
    const targetPackage = packages.find((pkg: any) => pkg.packageId === packageId)

    if (targetPackage && targetPackage.needPromotionPrice) {
      const params = {
        package_id: packageId
      } as any

      if (packageSelectedDate) {
        params.date = packageSelectedDate
      }

      try {
        if (promotionDetailCancelToken) {
          promotionDetailCancelToken.cancel('cancel')
        }

        // 从缓存里面取
        // const cacheKey = Object.keys(params).map((k: any) => params[k]).join(';')
        // const memory = promotionDetailMap[cacheKey]
        // if (memory) {
        //   commit(types.GET_PROMOTION_DETAIL, { status: 'success', detail: memory })
        //   return
        // }

        promotionDetailCancelToken = this.$axios.CancelToken.source()
        commit(types.GET_PROMOTION_DETAIL, { status: 'fetching' })
        const { result, success } = await this.$axios.$get(apis.activityPackagePromotion, {
          params,
          cancelToken: promotionDetailCancelToken.token
        })

        if (success && !isEmpty(result)) {
          commit(types.GET_PROMOTION_DETAIL, { status: 'success', detail: result })
        } else {
          // 清空促销详情(服务器错误)
          commit(types.CLEAR_PROMOTION_DETAIL)
          commit(types.GET_PROMOTION_DETAIL, { status: 'failure' })
        }
      } catch (err) {
        // 清空促销详情(运行错误)
        commit(types.CLEAR_PROMOTION_DETAIL)
      }
    } else {
      // 清空促销详情(没有促销)
      commit(types.CLEAR_PROMOTION_DETAIL)
    }
  },

  // 获取评论中用户上传的图片信息
  async getReviewsImageInfo({ commit, state }) {
    const { preview, activityId } = state
    const res = await this.$axios.$get(urlTemplate(apis.activityReviewGallery, { activityId }), { params: { preview }, regularUrl: '/v1/usrcsrv/activities/{*}/images/show' })

    const { result } = res
    if (res.success && !isEmpty(result)) {
      const { count } = result
      const reviewGalleryShouldShow = result.should_show
      const imageInfo = result.image_info
      commit(types.GET_REVIEW_IMAGES_INFO, { count, reviewGalleryShouldShow, imageInfo })
    }
  },

  // 获取活动卡片，相关活动，推荐活动
  async getCards({ commit, state }) {
    const { activityId } = state
    try {
      const res = await this.$axios.$get(apis.getActivityCards, { params: { activity_id: activityId }, timeout: 1000 })

      const { result } = res
      if (res.success && !isEmpty(result)) {
        commit(types.GET_ACTIVITY_CARDS, result)
      }
    } catch (e) {

    }
  },

  // 获取活动评论中的图片列表
  async getReviewsImages({ state }, { page, pageSize = 10 }) {
    const { activityId } = state
    const res = await this.$axios.$get(urlTemplate(apis.activityReviewGalleryImages, { activityId }), {
      regularUrl: '/v1/usrcsrv/activities/{*}/images/get',
      params: {
        page,
        limit: pageSize
      }
    })

    const { result } = res
    if (res.success && !isEmpty(result)) {
      const { image_total_count: imageTotalCount, review_images_info: reviewImageInfo } = result
      if (imageTotalCount === 0 || isEmpty(reviewImageInfo) || isEmpty(reviewImageInfo)) {
        return {}
      }

      /**
       * 评论reviews和图片image是一对多的关系
       * 通过图片image_id检索review_id，最后通过imageReviewMap得到review的信息
       * reviews的数据保存一份，因为有对数据对操作，这样可以保持所有数据的同步
       * @reviewImagesInfo Array 后端请求数据
       * @imageList Array 图片数组
       * @reviewsMap Object reviews和reviewId的map
       */

      const formatReviews = reduce(reviewImageInfo, (acc, v) => {
        acc.imageList = [...acc.imageList, ...(v?.images ?? []).map((img: any) => ({ ...img, reviewId: v.review_id }))]
        acc.reviewsMap = { ...acc.reviewsMap, [v.review_id]: v }

        return acc
      }, {
        imageList: [] as any[],
        reviewsMap: {}
      })

      return {
        imageTotalCount: result.image_total_count,
        reviewsCount: result.reviews_count,
        reviewImagesInfo: result.review_images_info,
        imageList: formatReviews.imageList,
        reviewsMap: formatReviews.reviewsMap
      }
    }
  },

  // 获取套餐详情
  async getPackageDetail({ state, commit }, translation) {
    const { preview, packageId: tempPackageId, cachedPackageId, activityDetail } = state
    const packageId = cachedPackageId || tempPackageId
    if (packageId == null) {
      return
    }
    commit(types.GET_PACKAGE_DETAIL, { status: 'fetching' })

    let res = null
    const basic_data = activityDetail?.basic_data ?? {}
    const is_line_page = basic_data.is_line_page || false
    if (preview) {
      // preview 状态下，使用新接口
      res = await this.$axios.$get(apis.activityPackageDetail, { params: { preview, translation: !!translation, package_id_list: packageId, publish_status: 'all', is_line_page: false } })
    } else {
      res = await this.$axios.$get(apis.activityPackageDetail, { params: { preview, translation: !!translation, publish_status: 'viewable', package_id_list: packageId, is_line_page } })
    }

    const { result } = res
    if (res.success && !isEmpty(result)) {
      const packageDetail = result[0]
      commit(types.GET_PACKAGE_DETAIL, { status: 'success', detail: packageDetail })
      commit(types.KEEP_SOURCE_PACKAGE_DETAIL, packageDetail)
    } else {
      commit(types.GET_PACKAGE_DETAIL, { status: 'failure' })
    }
  },

  // 选中套餐或者匹配成功完整套餐
  async selectPackage({ commit, dispatch, state }, { packageId }) {
    commit(types.RESET_CACHED_PACKAGE_ID)
    commit(types.SET_PACKAGE_ID, { packageId })

    if (packageId) {
      // 设置销售属性
      const { packages } = state
      const activityPackage = (packages || []).find(v => v.packageId === packageId)

      if (activityPackage) {
        commit(types.SET_ATTRIBUTES, activityPackage.specAttrIds)

        // 这里需要放在 getPackageDetail 前面，否则价格会闪一下
        await dispatch('getPromotionDetail')

        // 获取packageDetail
        await dispatch('getPackageDetail')
      }
    } else {
      dispatch('clearPackage')
    }
  },

  // 清除已选中套餐详情（主要是指 markdown 部分）
  clearPackage({ commit }) {
    commit(types.CLEAR_PACKAGE)
    commit(types.CLEAR_PROMOTION_DETAIL)
  },

  // 清除已选中套餐所有信息
  clearPackageOptions({ commit }) {
    commit(types.CLEAR_PACKAGE)
    commit(types.CLEAR_PACKAGE_OPTIONS)
    commit(types.CLEAR_PROMOTION_DETAIL)
    commit(types.SET_PACKAGE_SELECTED_DATE, '')
    commit(types.CLEAR_ALL_CLICK, true)
  },

  // 获取活动评论, 实际是增加评论
  async getActivityReviews({ commit, dispatch }, payload) {
    const res = await dispatch('fetchActivityReview', payload)

    if (res && res.result) {
      const { result, activityReviewsCancelToken } = res

      commit(types.SET_ACTIVITY_REVIEWS, result)

      return { ...result, activityReviewsCancelToken }
    }
  },

  // 单次请求review, limit 100以内（review接口最大limit是100）
  async fetchActivityReview({ state },
    {
      page = 1,
      pageSize = 15,
      star_num = '',
      lang = '',
      sort_type = 0,
      only_image = false,
      timeout,
      activityId,
      config
    }) {
    const axiosConfig: AxiosRequestConfig = {
      params: {
        page,
        limit: pageSize,
        star_num,
        lang,
        sort_type,
        only_image,
        activity_id: activityId || state.activityId
      },
      ...config
    }

    activityReviewsCancelToken = this.$axios.CancelToken.source()

    if (timeout) {
      axiosConfig.timeout = timeout
    }
    axiosConfig.cancelToken = activityReviewsCancelToken?.token

    try {
      const res = await this.$axios.$get(apis.getActivityReviewsList, axiosConfig)

      const { result } = res
      if (res.success && !isEmpty(result)) {
        return { result, activityReviewsCancelToken }
      }
    } catch (e) {
    }
  },

  // 并发获取活动评论, limit无限制
  async fetchActivityReviewsList({ dispatch }, payload) {
    // review接口最大limit
    const MAX_LIMIT = 100

    const { pageSize, ...rest } = payload

    const size = Math.floor(pageSize / MAX_LIMIT)
    const lastSize = pageSize % MAX_LIMIT

    const paging = new Array(size).fill(MAX_LIMIT).concat(lastSize).filter(v => v)

    const request = paging.map((v, index) => dispatch('fetchActivityReview', ({ page: index + 1, pageSize: v, ...rest })))

    const resList = await Promise.all(request)

    if (!resList || !resList.length) { return }

    let total = -1
    const reviews = resList.reduce((acc, v) => {
      if (!v) { return acc }

      const { result } = v

      if (total < 0) { total = result?.total }

      if (result && result.item) {
        return acc.concat(result.item)
      }

      return acc
    }, [])

    // 超过了limit后其他结果就不正确了，只保留total
    if (total > -1) {
      return { total, item: reviews || [] }
    }
  },

  // 替换评论列表
  async replaceActivityReviews({ dispatch, commit }, payload) {
    const res = await dispatch('fetchActivityReviewsList', payload)

    if (res) {
      commit(types.CLEAR_REVIEW_LIST)
      commit(types.SET_ACTIVITY_REVIEWS, res)

      return res
    }
  },

  // 获取活动日期、数量配置
  async getSchedules({ commit, state }) {
    const { preview, activityId } = state

    commit(types.GET_PACKAGE_SCHEDULE, { status: 'fetching' })

    const res = await this.$axios.$get(urlTemplate(apis.activitySchedules, { activityId }), { params: { preview }, regularUrl: '/v1/usrcsrv/activity/{*}/schedules' })
    const { result } = res
    if (res.success && !isEmpty(result)) {
      const { schedules } = result
      commit(types.GET_PACKAGE_SCHEDULE, { schedules, status: 'success' })
    } else {
      commit(types.GET_PACKAGE_SCHEDULE, { status: 'failure' })
    }

    return res
  },

  // 获取价格日历
  async getPriceSchedules({ commit, state }) {
    const { preview = 0, activityId, salesChannels = 0, activityDetail } = state
    // 排队状态不需要调接口
    const is_line_page = activityDetail?.basic_data?.is_line_page ?? false
    if (is_line_page) {
      return
    }

    commit(types.GET_PRICE_PACKAGE_SCHEDULE, { status: 'fetching' })
    let res: any
    try {
      res = await this.$axios.$get(apis.activityPriceSchedules, {
        params: {
          preview,
          activity_id: activityId,
          sales_channel: salesChannels
        }
      })
    } catch (error) {
      commit(types.GET_PRICE_PACKAGE_SCHEDULE, { status: 'failure' })
    }
    if (res?.success && !isEmpty(res?.result)) {
      const { schedules } = res.result
      commit(types.GET_PRICE_PACKAGE_SCHEDULE, { schedules, status: 'success' })
    } else {
      commit(types.GET_PRICE_PACKAGE_SCHEDULE, { status: 'failure' })
    }

    return res
  },

  // 获取套餐价格日历的可选时间和unit
  async getPackagePriceSchedule({ commit, dispatch, state, getters, rootState }) {
    const { packageId, preview, selectedTime: { arrangementId } } = state
    const { isNewOpenTicket } = getters

    if (!packageId) {
      return
    }

    commit(types.SET_PRICE_PACKAGE_SCHEDULE_STATE, 'fetching')

    const memory = state.packagePriceSchedulesMap[packageId]
    if (memory) {
      if (!arrangementId && rootState.klook.platform === 'desktop' && !isEmpty(memory.units)) {
        commit(types.SET_PACKAGE_UNITMAP, { prices: memory.units })
      }

      // 新openticket会自动选日期
      if (isNewOpenTicket) {
        dispatch('autoSetFeasibleDate')
        // commit(types.SET_PACKAGE_SELECTED_DATE, memory.schedules[0].date)
      }

      commit(types.SET_PRICE_PACKAGE_SCHEDULE_STATE, 'success')
      return true
    }

    const res = await this.$axios.$get(apis.getPackagePriceSchedulesAndUnits, {
      params: {
        package_id: packageId,
        preview
      }
    })

    const { result } = res
    if (res.success && !isEmpty(result) && !isEmpty(result.schedules)) {
      const { schedules, units } = result
      commit(types.SET_PRICE_PACKAGE_SCHEDULE, {
        packageId,
        schedules,
        units
      })
      if (rootState.klook.platform === 'desktop' && !isEmpty(units)) {
        commit(types.SET_PACKAGE_UNITMAP, { prices: units })
      }

      // 新openticket会自动选日期
      if (isNewOpenTicket) {
        dispatch('autoSetFeasibleDate')
        // commit(types.SET_PACKAGE_SELECTED_DATE, schedules[0].date)
      }

      commit(types.SET_PRICE_PACKAGE_SCHEDULE_STATE, 'success')
      return true
    }

    commit(types.SET_PRICE_PACKAGE_SCHEDULE_STATE, 'failure')

    return false
  },

  // 自动选日期，自动选最远的可选的日期
  // 可行的定义：有库存并且库存大于套餐最小限制数量
  autoSetFeasibleDate({ commit, getters, state }) {
    const { packageSelectedDate, routeName } = state
    const { currentSelectedPackage } = getters
    let { packagePriceSchedules } = getters
    const isFirstDate = currentSelectedPackage.auto_select_first_date
    const packageSchedulesCopy = cloneDeep(packagePriceSchedules || [])
    packagePriceSchedules = isFirstDate ? packageSchedulesCopy : packageSchedulesCopy.reverse() // 倒序：自动选最远的可选的日期
    const validateFeasible = (timelost: any) => !!timelost.stock && timelost.stock >= currentSelectedPackage.minPax

    // 在bookingoption因为要接收来自活动详情页的日期，所以要校验日期是否可行
    // 其它地方不需要，直接重选
    if (routeName === 'ActivityBookOptions' && !!packageSelectedDate) {
      const selectedSchedule = packagePriceSchedules.find((item: any) => item.date === packageSelectedDate)
      const isFeasible = (selectedSchedule?.time_slots || []).some(validateFeasible)

      if (isFeasible) {
        return
      }
    }

    // 如果已选了日期，并且是valid，则继续使用这个日期
    if (packageSelectedDate) {
      const target = packageSchedulesCopy.find((item: any) => item.date === packageSelectedDate)
      if (target && (target.time_slots || []).some(validateFeasible)) {
        return
      }
    }

    const schedule = packagePriceSchedules.find((item: any) => (item.time_slots || []).some(validateFeasible))
    commit(types.SET_PACKAGE_SELECTED_DATE, schedule ? schedule.date : '')
  },

  // 设置套餐日期，booking options页面刷新只需要单纯设置套餐日期
  setSelectedDate({ commit }, date) {
    commit(types.SET_PACKAGE_SELECTED_DATE, date)
  },

  setDefaultPackageSelectedDate({ dispatch, getters }) {
    const { packagePriceSchedules } = getters

    if (packagePriceSchedules && packagePriceSchedules.length) {
      const schedule = packagePriceSchedules[0]

      dispatch('setPackageSelectedDate', schedule.date)
    }
  },

  // 默认选中当前套餐有库存的日期
  setValuablePackageSelectedDate({ dispatch, getters }) {
    const { packagePriceSchedules } = getters
    if (packagePriceSchedules && packagePriceSchedules.length) {
      const schedule = packagePriceSchedules.find((schedule: any) => {
        return (schedule.time_slots || []).find((slot: any) => slot.stock)
      })

      schedule && dispatch('setPackageSelectedDate', schedule.date)
    }
  },

  async setPackageSelectedDate({ dispatch }, date) {
    dispatch('setSelectedDate', date)
    dispatch('packageSelectedDateChange')
    await dispatch('getPromotionDetail')
  },

  // 设置选中套餐日期
  packageSelectedDateChange({ dispatch, state, getters }) {
    // 如果当前选中的套餐不匹配日期，清空套餐
    const { packageId, packageSelectedDate } = state

    if (!packageSelectedDate) {
      return
    }

    const { packagesInDate } = getters
    const currentSchedules = packagesInDate.find((v: any) => v.packageId === packageId)

    if (!currentSchedules) {
      dispatch('setChosenAttributeIds', {
        isSyncPackageId: true,
        attrs: []
      })
    }
  },

  // 设置套餐时间
  setPackageTime({ commit, getters }, data) {
    const { currentSelectedPackage: { maxPax } } = getters
    commit(types.SET_PACKAGE_TIME, { ...data, maxPax })
  },

  // 获取套餐可购买数量配置
  async fetchPackageUnits({ commit, state }) {
    const { selectedTime: { arrangementId } } = state
    if (!arrangementId) {
      return {
        success: false
      }
    }

    // promotion活动需要去掉这个缓存
    // const units = state.unitsMap[arrangementId]

    // if (units) {
    //   return {
    //     success: true,
    //     ...units
    //   }
    // }

    const res = await this.$axios.$get(urlTemplate(apis.getPackageUnits, { arrangementId }), {
      regularUrl: '/v1/usrcsrv/arrangements/{*}/units'
    })

    const { result } = res
    if (res.success && !isEmpty(result) && !isEmpty(result.prices) && !isEmpty(result.inventories)) {
      // 缓存结果
      commit(types.SET_PACKAGE_UNITMAP, { ...result, arrangementId })

      return {
        ...res,
        success: true
      }
    }

    return {
      success: false
    }
  },

  // 套餐可购买数量--增加
  addUnit({ commit }, priceId) {
    commit(types.CHANGE_UNIT_COUNT, { priceId, type: 'add' })
  },

  // 套餐可购买数量--减少
  minusUnit({ commit }, priceId) {
    commit(types.CHANGE_UNIT_COUNT, { priceId, type: 'minus' })
  },

  // 获取套餐的可选时间和unit
  // async getPackageSchedules({ commit, state, getters, rootState }) {
  //   const { packageId } = state
  //   if (!packageId) {
  //     return
  //   }
  //
  //   const memory = state.packageSchedulesMap[packageId]
  //   if (memory) {
  //     return { success: true }
  //   }
  //
  //   const res = await this.$axios.$get(urlTemplate(apis.getPackageSchedulesAndUnits, { packageId }))
  //
  //   const { result } = res
  //   if (res.success && !isEmpty(result) && !isEmpty(result.schedules)) {
  //     const { schedules } = result
  //     commit(types.SET_PACKAGE_SCHEDULE, {
  //       packageId,
  //       schedules,
  //       minPax: getters.currentSelectedPackage && getters.currentSelectedPackage.minPax
  //     })
  //     if (rootState.klook.platform === 'desktop' && !isEmpty(result.unit_info)) {
  //       result.unit_info.prices = result.unit_info.units
  //       commit(types.SET_PACKAGE_UNITMAP, { ...result.unit_info })
  //     }
  //     return { success: true }
  //   }
  //
  //   return { success: false }
  // },

  // 套餐属性选中后是否组成套餐的判断、当前属性对其他属性的影响处理
  setChosenAttributeIds({ commit, state, getters, dispatch }, { attrs, isSyncPackageId = true }) {
    const { specList, packages, packageId } = state

    // 只有单个销售属性默认选中
    const singleAttrs = specList.reduce((acc, spec) => {
      const { attrs } = spec
      if (attrs && attrs.length === 1) {
        return [...acc, attrs[0].id]
      }

      return acc
    }, [] as number[])

    const currentChosenIds = Array.from(new Set([...attrs, ...singleAttrs]))
    commit(types.SET_ATTRIBUTES, currentChosenIds)

    // 选中attr后设置packageId
    let selectedPackageId = null
    if (getters.isAttrsSelectedAll) {
      // 选择了全部销售属性，此时可以获得packageId了
      if (currentChosenIds.length === specList.length) {
        const selectedPackage = packages.find((v) => {
          const { specAttrIds } = v

          return [...specAttrIds].sort().join(',') === [...currentChosenIds].sort().join(',')
        })
        if (selectedPackage && selectedPackage.packageId !== packageId) {
          selectedPackageId = selectedPackage.packageId
        }
      }
    }
    if (isSyncPackageId) {
      // 其他情况会清空选中的package
      dispatch('selectPackage', { packageId: selectedPackageId })
    } else {
      // 找个地方把 packageId 存起来后面再 flush
      commit(types.SET_CACHED_PACKAGE_ID, { packageId: selectedPackageId })

      // 但是套餐详情需要请求回来
      // 这里取了巧，把 cachedPackageId 的 packageDetail 储存在了 packageDetail 里面
      if (selectedPackageId) {
        dispatch('getPackageDetail')
      }
    }
  },

  // （确认操作）把储存的 cached package_id 刷给真正的 package_id
  async flushCachedPackageId({ commit, state, dispatch }) {
    const { packageId, cachedPackageId, cachedShouldResetPackageSelectedDate } = state

    if (cachedPackageId && cachedPackageId !== packageId) {
      if (cachedShouldResetPackageSelectedDate) {
        commit(types.SET_PACKAGE_SELECTED_DATE, '')
      }

      await dispatch('selectPackage', { packageId: cachedPackageId })

      // 及时回收，避免对其它地方产生影响
      commit(types.RESET_CACHED_PACKAGE)
    }
  },

  // （取消操作）清空cached package_id，此时需要看时机拉取 packageDetail
  async resetCachedPackageId({ commit, state, dispatch }) {
    const { packageId, cachedPackageId } = state

    commit(types.RESET_CACHED_PACKAGE)

    if (cachedPackageId !== packageId) {
      await dispatch('selectPackage', { packageId })
    }
  },

  // 套餐属性选中状态改变
  attributesChange({ commit, state, dispatch }, { attr, spec, isSyncPackageId = true }) {
    const { id, isScheduleMatch, isPackageMatch } = attr
    const { chosenIds } = state

    let currentChosenIds: number[] = []
    let shouldResetPackageSelectedDate = false
    // 和当前日历不匹配，清空日历
    // 和已选销售属性构成的套餐不匹配，清空已选销售属性
    if (!isScheduleMatch || !isPackageMatch) {
      currentChosenIds = [id]

      if (!isScheduleMatch) {
        if (isSyncPackageId) {
          commit(types.SET_PACKAGE_SELECTED_DATE, '')
        } else {
          shouldResetPackageSelectedDate = true
        }
      }
    } else {
      // 同一层级只判断是否和其他层级构成套餐，但是同一层级依然是互斥的，只能选一个
      const otherChooseIds = chosenIds.filter(
        id => !(spec.attrs as { id: number }[]).find(attr => attr.id === id)
      )
      currentChosenIds = [...otherChooseIds, id]
    }

    if (!isSyncPackageId) {
      commit('SET_CACHED_SHOULD_RESET_DATE', shouldResetPackageSelectedDate)
    }

    dispatch('setChosenAttributeIds', {
      isSyncPackageId,
      attrs: currentChosenIds
    })
  },

  // 获取活动相关 faq
  async getActivityFaq({ dispatch, commit, state }) {
    const resp = await dispatch('getCMSGenericItemList', {
      type: cms.types.TYPE_ACTIVITY_HELP_CONTENT,
      options: {
        type_id: state.activityId
      }
    }, { root: true })

    if (isEmpty(resp) || isEmpty(resp.qa_list)) {
      return []
    }

    const t = resp.qa_list.map((item: any) => {
      if (!item.question || !item.answer) {
        return ''
      }

      return '## ' + item.question + ' \n\n ' + item.answer
    }).join('\n\n')

    const markdownT = markdown2RenderObj(t)
    commit(types.GET_ACTIVITY_FAQ, markdownT)
  },

  // 获取活动相关 preInfo
  async getActivityPreInfo({ state }, { activityId }) {
    try {
      const resp = await this.$axios.$get(apis.activityRedirectInfo, { params: { activity_id: activityId || state.activityId }, timeout: 2000 })

      if (resp.success && resp.result) {
        return resp.result
      }
    } catch (e) {
      // ignore
    }
  },

  // 重置booking options页面
  async resetBookingOptions({ dispatch }, data) {
    const { packageId, packageSelectedDate } = data
    await dispatch('selectPackage', { packageId })
    dispatch('setPackageSelectedDate', packageSelectedDate)
  },

  // 请求预售活动套餐
  async getPresalePackages({ commit }, activityId) {
    const res = await this.$axios
      .$get(apis.getPresalePackages, { params: { activity_id: activityId } })

    if (res.success && !isEmpty(res.result)) {
      const { packages } = res.result

      commit(types.SET_PRESALE_PACKAGES, (packages || []).map((v: any) => ({
        isSupportPresale: v.is_support_presale,
        packageId: v.package_id,
        presaleDate: v.presale_date,
        presaleDay: v.presale_day
      })))
    }
  },

  async getReopenActivity({ commit }, data) {
    const res = await this.$axios.$post(apis.updateReopen, data)
    if (res.success) {
      commit(types.SET_REOPEN_MODAL_VISIBLE, false)
      commit(types.SET_REOPEN_REMIND, res.result.reopen_notice)
      return res
    }
  },

  // 预售活动根据日期的变化，会改变当前活动的instant状态
  setActivityInstant({ commit }, instant) {
    commit(types.SET_ACTIVITY_INSTANT, instant)
  },

  // 点餐评论
  setReviewLiked({ commit }, id) {
    commit(types.SET_REVIEWS_LIKED, id)
  },

  // 显示评论列表
  triggerReviewListVisible({ commit }, visible = true) {
    commit(types.TRIGGER_REVIEW_LIST_VISIBLE, visible)
  },

  // 显示faq
  triggerActivityFaqVisible({ commit }, visible = true) {
    commit(types.ACTIVITY_FAQ_VISIBLE, visible)
  },

  updateActivityDetail({ commit }, data: any) {
    commit(types.SAVE_ACTIVITY_TRANSLATE, data)
    commit(types.GET_ACTIVITY_DETAIL, { ...data })
  },

  updatePackageDetail({ commit }, data: any) {
    commit(types.SAVE_PACKAGE_TRANSLATE, data)
    commit(types.GET_PACKAGE_DETAIL, { status: 'success', detail: data })
  },

  changeTranslateStatus({ commit }, data: boolean) {
    commit(types.CHANGE_TRANSLATE_STATUS, data)
  },

  saveActivityTranslate({ commit }, data: any) {
    commit(types.SAVE_ACTIVITY_TRANSLATE, data)
  },

  savePackageTranslate({ commit }, data: any) {
    commit(types.SAVE_PACKAGE_TRANSLATE, data)
  },

  async getShoppingCartList({ commit, state }, data: any) {
    const res = await this.$axios.$get(apis.shoppingCartList)
    if (res && res.success && res.result) {
      const group = res.result.group
      commit(types.SET_MODIFICATION_SHOPPING_CART)
      const { shopId } = data

      if (shopId && group) {
        let shoppingInfoSnapshot: any = null
        group.find((x: any) => {
          shoppingInfoSnapshot = x.items.find((y: any) => {
            return y.shoppingcart_id === Number(shopId)
          })
          return !!shoppingInfoSnapshot
        })
        if (!shoppingInfoSnapshot) {
          return 0
        }
        const shoppingCartPackageId = shoppingInfoSnapshot.package_id
        const packageDetail = state.packages.find((item: any) => {
          return item.packageId === shoppingCartPackageId
        })
        if (!packageDetail) {
          return 0
        }
        for (let i = 0; i < shoppingInfoSnapshot.main_package_specs.length; i++) {
          const item = shoppingInfoSnapshot.main_package_specs[i]
          if (!packageDetail.specAttrIds.includes(item.attr_id)) {
            return 0
          }
        }
        return shoppingCartPackageId
      }
    }
  },

  setRouteName({ commit }, name: string) {
    commit(types.SET_ROUTE_NAME, name)
  },

  changeScheduleMode({ commit }, data: boolean) {
    commit(types.CHANGE_SCHEDULE_MODE, data)
  },

  setCurrPackageOptionTab({ commit }, tabName: 'standalone' |'combo') {
    commit(types.SET_CURR_PACKAGE_OPTION_TAB, tabName)
  }
}

export default actions
