import Vue from 'vue'
import Logquery from '@klook/logquery'
import { Plugin, NuxtAppOptions } from '@nuxt/types'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { SystemError, HttpException, ErrorParams } from './httpException'
import logger, { Logger } from '~/share/logger'
import { href, formatPriceThousands, percent, imageFormat, parseUrl, getOneLinkPrefix, getExt } from '~/share/utils'
import { currencySymbolMap } from '~/share/data/currency'
import apis from '~/share/data/apis'
import localStorageEx from '~/assets/scripts/localstorage.js'

const localStorage = localStorageEx()

type Href = (pathname: string, language?: Data.Language, baseLink?: string) => string
interface Exception {
  send?: (cb: Function) => void
}

dayjs.extend(isBetween)

declare module 'vue/types/vue' {
  interface Vue {
    $logquery: Logquery
    $logger: Logger
    $href: Href
    $expection: {
      systemError: any
    }
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $logquery: Logquery
    $logger: Logger
    $href: Href
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $logquery: Logquery
    $logger: Logger
    $href: Href
  }
}

Vue.filter('currencyToSymbol', (value: Data.Currency) => {
  return currencySymbolMap[value]
})

Vue.filter('formatThousands', (value: string | number) => {
  return formatPriceThousands(value)
})

Vue.filter('percent', (numerator: number, denominator: number) => {
  return percent(numerator, denominator)
})

Vue.filter('imageFormat', (src: string, type: string, retina: boolean) => {
  return imageFormat(src, type, retina)
})

const plugin: Plugin = ({ app, req, store, route }, inject) => {
  if (process.server && req) {
    (req as any).route = route
  }

  if (!process.server) {
    const queue = process.client
      ? { interval: 5000, size: 15 }
      : { interval: 15000, size: 15 }
    const logquery = new Logquery({
      url: process.env.LOGQUERY_URL,
      headers: { 'X-Platform': store.state.klook.platform },
      queue
    })
    inject('logquery', logquery)
  }
  inject('getExt', getExt)
  inject('logger', logger)
  inject('href', function (
    this: NuxtAppOptions | Vue,
    pathname: string,
    language?: Data.Language,
    baseLink?: string
  ) {
    const { host: currentHost } = store.state.klook

    const urlParsed = parseUrl(pathname)
    const { protocol = '', host = '' } = urlParsed || {}
    if (protocol && host) {
      return host !== currentHost ? pathname.replace(host, currentHost) : pathname
    }

    if (!language) {
      if ((this as any).$i18n) {
        language = this.$i18n.locale as Data.Language
      }
    }
    return href(pathname, language as Data.Language, baseLink)
  })

  // 定制异常，详细可看： ./httpException.ts
  inject('expection', {
    __default: (error: ErrorParams, ctx: Vue) => new HttpException(error.status, error.message, ctx),
    systemError: (ctx: Vue) => {
      return new SystemError({
        message: ctx.$t('12298')!!,
        status: 500
      }, ctx)
    }
  })

  const getAllCountryCode = async () => {
    if (process.server) {
      return
    }
    const cacheKey = `${store.state.klook.language}_AllCountries`
    const cache = localStorage.getItem(cacheKey)
    if (cache) {
      return cache
    }
    try {
      const { result } = await app.$axios.$get(apis.getAllCountries)
      result.forEach((item: any) => {
        item.value = item.country_number
        item.countryCode = item.name
        item.country = item.country_code
      })
      localStorage.setItem(cacheKey, result, 24 * 3600)
      return result
    } catch {
      return []
    }
  }

  inject('getAllCountryCode', getAllCountryCode)
  inject('getOneLinkPrefix', getOneLinkPrefix)
}

export default plugin
