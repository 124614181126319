import { MutationTree, ActionTree } from 'vuex'
import { GiftCard } from '~/types/traveller/gift-card'
import apis from '~/share/data/apis'
import { RootState } from '~/store'
import { urlTemplate } from '~/share/utils'

const SET_GIFT_CARD = 'SET_GIFT_CARD'
const SET_PACKAGE_SCHDULES = 'SET_PACKAGE_SCHDULES'
const SET_ACCOUNT_GIFT_CARD = 'SET_ACCOUNT_GIFT_CARD'
const SET_WALLETS = 'SET_WALLETS'

export interface GiftCardState {
  giftCardInformaton: GiftCard.giftCardInformaton
  packageSchedules: GiftCard.packageSchedules
  accountGiftCard: GiftCard.accountGiftCard
}

export const state = () => ({
  giftCardInformaton: {},
  packageSchedules: {},
  accountGiftCard: {}
})

export const mutations: MutationTree<GiftCardState> = {
  [SET_GIFT_CARD](state, data) {
    state.giftCardInformaton = data
  },
  [SET_PACKAGE_SCHDULES](state, data) {
    state.packageSchedules = data
  },
  [SET_ACCOUNT_GIFT_CARD](state, data) {
    state.accountGiftCard = data
  },
  [SET_WALLETS](state, data) {
    state.accountGiftCard.wallets = data
  }
}

export const actions: ActionTree<GiftCardState, RootState> = {
  async getInformaton({ commit }) {
    const resp = await this.$axios.$get(apis.giftCardInformaton)
    commit(SET_GIFT_CARD, resp.result)
    return resp
  },

  async getPackageSchedules({ commit }, packageId) {
    if (!packageId) {
      return
    }

    const resp = await this.$axios.$get(
      urlTemplate(apis.getPackageSchedulesAndUnits, { packageId }),
      {
        regularUrl: '/v1/usrcsrv/packages/{*}/schedules_and_units'
      }
    )
    commit(SET_PACKAGE_SCHDULES, resp.result)
    return resp
  },

  async getAccountGiftCardInfo({ commit }) {
    const resp = await this.$axios.$get(apis.accountGiftCard)
    commit(SET_ACCOUNT_GIFT_CARD, resp.result)
    return resp
  },

  async getWalletsData({ commit }) {
    const resp = await this.$axios.$get(apis.getWalletsData, { params: { pending_total: 1 } })
    if (resp.success) {
      commit(SET_WALLETS, resp.result)
    }
    return resp
  }
}
