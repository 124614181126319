import showdown from 'showdown'
import commonmark from 'commonmark'
import MarkdownRender from './render.js'
import { replaceUrlHost } from '~/share/utils'

// Reference: https://bitbucket.org/klook/klook-new-web/src/a11d23f5613f9f105630a733949304aed5e7405e/web/web_comm.js#lines-2293

const converter = new showdown.Converter({
  tables: true,
  noHeaderId: true
})

function parseParagraph(item: any) {
  if (item.type === 'paragraph') {
    const strongMatch = /^<strong>(.+)<\/strong>$/.exec(item.content)
    const emMatch = /^<em>(.+)<\/em>$/.exec(item.content)

    if (strongMatch) {
      return { type: 'title', content: strongMatch[1].replace(/<\/?strong>/g, '') }
    } else if (emMatch) {
      return { type: 'sub_title', content: emMatch[1].replace(/<\/?em>/g, '') }
    }
  }

  return item
}

function htmlTag(tag: string, content: string, className?: string) {
  if (className) {
    return `<${tag} class="${className}">${content}</${tag}>`
  } else {
    return `<${tag}>${content}</${tag}>`
  }
}

/**
 * 将 markdown 文件转换为 markdown render object
 * @param {string} markdownText markdown 文本
 */
export function markdown2RenderObj(markdownText: string) {
  const reader = new commonmark.Parser()
  const writer = new (MarkdownRender as any)() as any
  const parsed = reader.parse(markdownText)
  const result = writer.renderAst(parsed) as any[]

  if (result && result.length > 0) {
    for (let i = 0, len = result.length; i < len; i++) {
      result[i] = parseParagraph(result[i])
    }
  }

  return result
}

/**
 * 将 markdown render object 转换为 html
 * @param {array} renderObj markdown render object
 */
export function renderObj2Html(renderObj: any[]) {
  const htmls = []

  for (let i = 0, len = renderObj.length; i < len; i++) {
    const x = { ...renderObj[i] }
    // 在非主站情况下，a标签链接替换成当前域名
    x.content = replaceUrlHost(x.content)

    /**
     * h1 => h3 title
     * h2 => h4 sub_title
     * h3|h4|h5|h6 => p paragraph
    **/

    if (x.type === 'title') {
      if (x.props && x.props.has_style) {
        htmls.push(htmlTag('h3', x.content, 'has_style'))
      } else {
        htmls.push(htmlTag('h3', x.content))
      }
    } else if (x.type === 'sub_title') {
      htmls.push(htmlTag('h4', x.content))
    } else if (x.type === 'section_title') {
      htmls.push(htmlTag('h4', x.content))
    } else if (x.type === 'item_with_icon') {
      if (x.props.is_first) {
        htmls.push('<ul class="item-with-icon">')
      }
      const imgStr = x.icon ? `<img class="item-with-icon__img" src="${x.icon}" />` : ''
      const contentStr = htmlTag('span', x.content)
      htmls.push(htmlTag('li', imgStr + contentStr, 'item-with-icon__li'))
      if (x.props.is_last) {
        htmls.push('</ul>')
      }
    } else if (x.type === 'item') {
      if (x.props.is_first) {
        htmls.push('<ul>')
      }
      htmls.push(htmlTag('li', x.content))
      if (x.props.is_last) {
        htmls.push('</ul>')
      }
    } else if (x.type === 'order_item') {
      if (x.props.is_first) {
        htmls.push('<ol>')
      }
      htmls.push(htmlTag('li', x.content))
      if (x.props.is_last) {
        htmls.push('</ol>')
      }
    } else if (x.type === 'paragraph') {
      if (renderObj[i + 1] && parseParagraph(renderObj[i + 1]).type === 'paragraph') {
        htmls.push(htmlTag('p', x.content, 'p_followed'))
      } else {
        htmls.push(htmlTag('p', x.content))
      }
    } else if (x.type === 'image') {
      htmls.push(`<img src="${x.props.src}"`)
      if (x.props.alt) {
        htmls.push(` alt="${x.props.alt}" `)
      }
      if (x.props.title) {
        htmls.push(` title="${x.props.title}" `)
      }
      htmls.push('>')
    }
  }

  return htmls.join('')
}

/**
 * 渲染 markdown render object 为 html
 * @param renderObj markdown render object
 * @param header header text
 */
export function renderMarkdownObj(renderObj: any[], header?: string) {
  if (!Array.isArray(renderObj) || renderObj.length === 0) {
    return ''
  }

  const firstItem = renderObj[0]

  // 如果有 header， 然后header是第一个标题，我们就把render_obj第一个标题去掉
  if (
    header &&
    ((firstItem.type === 'paragraph' &&
        firstItem.content === '<strong>' + header + '</strong>') ||
        (firstItem.type === 'title' && firstItem.content === header))
  ) {
    renderObj = renderObj.slice(1)
  }

  return renderObj2Html(renderObj)
}

/** -
 * 渲染 markdown 文本为 html
 * @param markdownText markdown 文本
 */
export function renderMarkdownStr(markdownText: string) {
  if (!markdownText) {
    return markdownText
  }
  return converter.makeHtml(markdownText)
}
