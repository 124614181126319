import Vue from 'vue'
import { generateCanonicalAndHreflangTag } from '@klook/header-tags'
import { MetaProps, MetaItem, LinkItem, LdJson } from './types'
import { RootState } from '~/store'

declare module 'vue/types/vue' {
  interface canonicalArgument {
    indexable: boolean,
    path?: string,
    whitelist?: string[],
  }
  interface Vue {
    $setMeta: (porps: MetaProps, isI18n?: boolean) => MetaItem
    $title: (value?: string, isI18n?: boolean) => string
    $keywords: (value: string, isI18n?: boolean) => MetaItem
    $description: (value: string, isI18n?: boolean) => MetaItem
    $robots: (value: string, isI18n?: boolean) => MetaItem
    $canonical: (options: canonicalArgument) => LinkItem[]
    $ldjson: (json: any) => LdJson
    $seoHeadTags: (value?: string, whitelist?: string[]) => any
  }
}

/**
 * Setting meta of document
 */
function setMeta(this: Vue, props: MetaProps, isI18n: boolean = false) {
  const meta = {} as MetaItem

  if (props.name) {
    meta.hid = props.name
    meta.name = props.name
  }

  if (props.property) {
    meta.hid = props.property
    meta.property = props.property
  }

  if (props.content) {
    meta.content = isI18n ? this.$t(props.content) as string : props.content
  }

  return meta
}

/**
 * Setting title of document
 */
function title(this: Vue, value: string, isI18n: boolean = false) {
  const _value = isI18n ? this.$t(value) : value

  if (value) {
    if (this.$i18n.locale === 'ja') {
      return _value
    } else {
      return `${_value}${this.$t('global.seo.titlesite')}`
    }
  } else {
    return this.$t('global.seo.title')
  }
}

/**
 * Setting meta.keywords of document
 */
function keywords(this: Vue, value: string, isI18n: boolean = false) {
  return this.$setMeta({ name: 'keywords', content: value }, isI18n)
}

/**
 * Setting meta.description of document
 */
function description(this: Vue, value: string, isI18n: boolean = false) {
  return this.$setMeta({ name: 'description', content: value }, isI18n)
}

/**
 * Setting meta.robots of document
 */
function robots(this: Vue, value: string, isI18n: boolean = false) {
  return this.$setMeta({ name: 'robots', content: value }, isI18n)
}

/**
 * Setting link.alternate of document
 * whitelist: 需要保留的参数
 *
 */
function canonical(this: Vue, { indexable, path, whitelist }: any) {
  const { link } = seoHeadTags.call(this, indexable, path || this.$route.fullPath, whitelist || [])
  return link
}
/**
 * Setting script.ld+json of document
 */
function ldjson(this: Vue, json: any) {
  return {
    type: 'application/ld+json',
    json
  }
}

function seoHeadTags(this: Vue, indexable: boolean, path?: string, whitelist?: string[]) {
  const language = this.$i18n.locale
  const originalPath = (path as string).replace(`/${language}`, '')
  const { supportLanguages } = (this.$store.state as RootState).klook
  const prefix =
    `https://${this.$store.state.klook.host}` ||
    'https://www.klook.com'
  const link = generateCanonicalAndHreflangTag({
    domain: prefix,
    path: originalPath,
    include_lang: supportLanguages,
    current_lang: language,
    params: whitelist,
    indexable
  })
  return {
    link
  }
}

Vue.prototype.$setMeta = setMeta
Vue.prototype.$title = title
Vue.prototype.$keywords = keywords
Vue.prototype.$description = description
Vue.prototype.$robots = robots
Vue.prototype.$canonical = canonical
Vue.prototype.$ldjson = ldjson
Vue.prototype.$seoHeadTags = seoHeadTags
