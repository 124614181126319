export default {
  page: {
    page_id: 'en_US:MobileWeb:home_page_v2:11',
    meta: {
      type: 'home_page_v2',
      track: null
    },
    body: {
      sections: [{
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'MainBanner'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'MainBanner',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              search_place_holder: 'Search by destination or activity',
              cart_number: 0,
              title: 'Hi TEST',
              subtitle: 'Discover and book amazing things-to-do at exclusive prices',
              banners: [{
                type: 'image',
                deep_link: '',
                video: null,
                image: {
                  src: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_640,,/v1608522899/banner/q6eeaeyrwr9cl5wuyhky.jpg'
                }
              }, {
                type: 'image',
                deep_link: '',
                video: null,
                image: {
                  src: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,w_640,,/v1608522857/banner/iac6nwhzwquwduhv2kwh.jpg'
                }
              }],
              local_entrance: null
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'Local'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'LocalEntrance',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              title: 'Explore more of Tokyo',
              background_src: 'https://res.klook.com/image/upload/v1597395662/homepage2020/see_what_s_in_loction_bg.png',
              button_title: "Let's Go",
              deep_link: 'https://localhost:3000/en-US/city/28-tokyo-things-to-do/'
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'BusinessEntry'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'VerticalEntrance',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              menus: [{
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351703/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_attractions_xm_colorful.png',
                corner_marker_url: '',
                title: 'Attractions',
                type: 'deep_link',
                business_name: '2_Attractions',
                deep_link: 'https://t146.test.klook.io/en-US/experiences/city-mcate/2-2-hong-kong-Attractions/',
                extra_info: null,
                partner_extra_info: {
                  category: 'Attractions'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351707/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_tours_xm_colorful.png',
                corner_marker_url: '',
                title: 'Tours',
                type: 'deep_link',
                business_name: '9_Tours',
                deep_link: 'https://t146.test.klook.io/en-US/experiences/city-mcate/2-9-hong-kong-Tours/',
                extra_info: null,
                partner_extra_info: {
                  category: 'Tours'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351707/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_staycation_xm_colorful.png',
                corner_marker_url: '',
                title: 'Staycations',
                type: 'deep_link',
                business_name: '55_Staycations',
                deep_link: 'https://t146.test.klook.io/en-US/search/?city_id=2&frontend_id_list=55&start=1&tag_id=174',
                extra_info: null,
                partner_extra_info: {
                  category: 'Staycations'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351705/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_fnd_xm_colorful.png',
                corner_marker_url: '',
                title: 'Food & dining',
                type: 'deep_link',
                business_name: '56_Food & dining',
                deep_link: 'https://t146.test.klook.io/en-US/food-and-dining/entrance?city_id=2',
                extra_info: null,
                partner_extra_info: {
                  category: 'Food \u0026 dining'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351704/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_car_rentals_xm_colorful.png',
                corner_marker_url: '',
                title: 'Car rentals',
                type: 'deep_link',
                business_name: '38_Car rentals',
                deep_link: 'https://t146.test.klook.io/en-US/car-rentals/?city_id=2',
                extra_info: null,
                partner_extra_info: {
                  category: 'Car rentals'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351703/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_airports_transfers_xm_colorful.png',
                corner_marker_url: '',
                title: 'Private airport transfers',
                type: 'deep_link',
                business_name: '46_Private airport transfers',
                deep_link: 'https://t146.test.klook.io/en-US/airport-transfers/?city_id=2',
                extra_info: null,
                partner_extra_info: {
                  category: 'Private airport transfers'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600351707/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_stays_xm_colorful.png',
                corner_marker_url: '',
                title: 'Hotels & more',
                type: 'deep_link',
                business_name: '54_Hotels & more',
                deep_link: 'https://t146.test.klook.io/en-US/hotels/?city_id=2',
                extra_info: null,
                partner_extra_info: {
                  category: 'Hotels \u0026 more'
                },
                sub_menu: null
              }, {
                icon_src: 'https://klook-res.cloudinary.com/image/upload/v1600352796/UED%20Team%EF%BC%88for%20DE%20only%EF%BC%89/category%20icon/L1%20and%20L2/icon_category_wifi_sim_cards_xm_colorful.png',
                corner_marker_url: '',
                title: 'WiFi & SIM cards',
                type: 'deep_link',
                business_name: '58_WiFi & SIM cards',
                deep_link: 'https://t146.test.klook.io/en-US/wifi-sim-card/?city_id=2',
                extra_info: null,
                partner_extra_info: {
                  category: 'WiFi \u0026 SIM cards'
                },
                sub_menu: null
              }],
              important_menus: []
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'SrvEntrance'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'SrvEntrance',
            load_type: 'sync',
            src: '',
            track: null,
            data: null
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'Notification'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'LocalNotification',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              open_local_permission: true
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'CentralBanner'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'Campaign',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              paging_scroll: true,
              banners: [{
                image_url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,,w_800,h_342/v1595142473/banner/pkf1umh5js5ogh4xptpz.jpg',
                deep_link: '/en-US/promo/tainan2020'
              }, {
                image_url: '',
                deep_link: ''
              }, {
                image_url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,,w_800,h_342/v1609232147/banner/rz2qmnt8kexpa1ibxhb4.jpg',
                deep_link: 'http://t264.test.klook.io/en-US/promo/masterdealspage?'
              }],
              bottom_banner: null
            }
          }
        }
      }, {
        meta: {
          type: 'common',
          placeholder: '',
          track: null,
          name: 'RecentlyViewed'
        },
        data: {
          title: 'Recently viewed',
          sub_title: '',
          more_title: '',
          more_deep_link: '',
          bottom_button_title: '',
          bottom_button_deep_link: '',
          is_navigation: false
        },
        body: {
          content: {
            handler: 'platform',
            data_type: 'RecentViewed',
            load_type: 'async',
            src: 'https://t145.test.klook.io/v2/usrcsrv/home/recently/viewed?admin_preview_mode=0&city_id=2&country_id=2&current_location_city_id=28&current_location_city_name=Tokyo&current_location_country_id=12&current_location_country_name=Japan&ip=153.121.38.61&ip_location_city_id=28&ip_location_city_name=Tokyo&ip_location_country_id=12&ip_location_country_name=Japan&is_proxy=false&lat=&lbs_location_city_id=0&lbs_location_city_name=&lbs_location_country_id=0&lbs_location_country_name=&lng=&not_in_city_id_list=&not_in_country_id_list=&not_template_id_list=&user_id=100086154&utm_campaign=&x_country_code=JP',
            track: null,
            data: null
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'Nearby'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'NearbyCardList',
            load_type: 'async',
            src: 'https://t145.test.klook.io/v2/usrcsrv/nearby/cards?admin_preview_mode=0&city_id=2&country_id=2&current_location_city_id=28&current_location_city_name=Tokyo&current_location_country_id=12&current_location_country_name=Japan&ip=153.121.38.61&ip_location_city_id=28&ip_location_city_name=Tokyo&ip_location_country_id=12&ip_location_country_name=Japan&is_proxy=false&lat=&lbs_location_city_id=0&lbs_location_city_name=&lbs_location_country_id=0&lbs_location_country_name=&lng=&not_in_city_id_list=&not_in_country_id_list=&not_template_id_list=&user_id=100086154&utm_campaign=&x_country_code=JP',
            track: null,
            data: null
          }
        }
      }, {
        meta: {
          type: 'common',
          placeholder: '',
          track: null,
          name: 'PoiCardList'
        },
        data: {
          title: 'Hot deals to book now',
          sub_title: '',
          more_title: '',
          more_deep_link: '',
          bottom_button_title: 'View all',
          bottom_button_deep_link: 'https://t146.test.klook.io/en-US/popular/all',
          is_navigation: false
        },
        body: {
          content: {
            handler: 'platform',
            data_type: 'PoiCardList',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              items: [
                {
                  ref_data: {
                    object_id: '20835571',
                    city_id: 0,
                    country_id: 0,
                    template_id: 0
                  },
                  card_template: 'home_page_poi_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    city_name: {
                      text: 'Hong Kong',
                      text_color: '#FFFFFF',
                      icon: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      bold: false
                    },
                    distance: {
                      text: '24Km'
                    },
                    image: {
                      url: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      is_gray: false
                    },
                    poi_description: {
                      text: 'Gateway to Lantau Island & Citygate Outlets!',
                      text_color: '#FF5722',
                      icon: '',
                      bold: false
                    },
                    poi_type: {
                      text: 'Sights & Landmarks',
                      text_color: '#212121',
                      icon: '',
                      bold: false
                    },
                    title: {
                      text: 'Ngong Ping & Tung Chung',
                      text_color: '#212121'
                    },
                    visitors: {
                      avatar1: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar2: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar3: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      text: '1,230 reviews'
                    }
                  },
                  track_info: {
                    object_id: '20835571',
                    extra_info: {}
                  },
                  card_action: {
                    type: '',
                    deep_link: ''
                  }
                },
                {
                  ref_data: {
                    object_id: '20835571',
                    city_id: 0,
                    country_id: 0,
                    template_id: 0
                  },
                  card_template: 'home_page_poi_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    city_name: {
                      text: 'Hong Kong',
                      text_color: '#FFFFFF',
                      icon: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      bold: false
                    },
                    distance: {
                      text: '24Km'
                    },
                    image: {
                      url: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      is_gray: false
                    },
                    poi_description: {
                      text: 'Gateway to Lantau Island & Citygate Outlets!',
                      text_color: '#FF5722',
                      icon: '',
                      bold: false
                    },
                    poi_type: {
                      text: 'Sights & Landmarks',
                      text_color: '#212121',
                      icon: '',
                      bold: false
                    },
                    title: {
                      text: 'Ngong Ping & Tung Chung',
                      text_color: '#212121'
                    },
                    visitors: {
                      avatar1: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar2: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar3: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      text: '1,230 reviews'
                    }
                  },
                  track_info: {
                    object_id: '20835571',
                    extra_info: {}
                  },
                  card_action: {
                    type: '',
                    deep_link: ''
                  }
                },
                {
                  ref_data: {
                    object_id: '20835571',
                    city_id: 0,
                    country_id: 0,
                    template_id: 0
                  },
                  card_template: 'home_page_poi_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    city_name: {
                      text: 'Hong Kong',
                      text_color: '#FFFFFF',
                      icon: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      bold: false
                    },
                    distance: {
                      text: '24Km'
                    },
                    image: {
                      url: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      is_gray: false
                    },
                    poi_description: {
                      text: 'Gateway to Lantau Island & Citygate Outlets!',
                      text_color: '#FF5722',
                      icon: '',
                      bold: false
                    },
                    poi_type: {
                      text: 'Sights & Landmarks',
                      text_color: '#212121',
                      icon: '',
                      bold: false
                    },
                    title: {
                      text: 'Ngong Ping & Tung Chung',
                      text_color: '#212121'
                    },
                    visitors: {
                      avatar1: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar2: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar3: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      text: '1,230 reviews'
                    }
                  },
                  track_info: {
                    object_id: '20835571',
                    extra_info: {}
                  },
                  card_action: {
                    type: '',
                    deep_link: ''
                  }
                },
                {
                  ref_data: {
                    object_id: '20835571',
                    city_id: 0,
                    country_id: 0,
                    template_id: 0
                  },
                  card_template: 'home_page_poi_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    city_name: {
                      text: 'Hong Kong',
                      text_color: '#FFFFFF',
                      icon: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      bold: false
                    },
                    distance: {
                      text: '24Km'
                    },
                    image: {
                      url: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      is_gray: false
                    },
                    poi_description: {
                      text: 'Gateway to Lantau Island & Citygate Outlets!',
                      text_color: '#FF5722',
                      icon: '',
                      bold: false
                    },
                    poi_type: {
                      text: 'Sights & Landmarks',
                      text_color: '#212121',
                      icon: '',
                      bold: false
                    },
                    title: {
                      text: 'Ngong Ping & Tung Chung',
                      text_color: '#212121'
                    },
                    visitors: {
                      avatar1: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar2: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar3: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      text: '1,230 reviews'
                    }
                  },
                  track_info: {
                    object_id: '20835571',
                    extra_info: {}
                  },
                  card_action: {
                    type: '',
                    deep_link: ''
                  }
                },
                {
                  ref_data: {
                    object_id: '20835571',
                    city_id: 0,
                    country_id: 0,
                    template_id: 0
                  },
                  card_template: 'home_page_poi_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    city_name: {
                      text: 'Hong Kong',
                      text_color: '#FFFFFF',
                      icon: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      bold: false
                    },
                    distance: {
                      text: '24Km'
                    },
                    image: {
                      url: 'https://res.klook.com/image/upload/2_20835571_Ngong-Ping_HK_Ngong_Ping_360-8_1125x630_pvlt9v.jpg',
                      is_gray: false
                    },
                    poi_description: {
                      text: 'Gateway to Lantau Island & Citygate Outlets!',
                      text_color: '#FF5722',
                      icon: '',
                      bold: false
                    },
                    poi_type: {
                      text: 'Sights & Landmarks',
                      text_color: '#212121',
                      icon: '',
                      bold: false
                    },
                    title: {
                      text: 'Ngong Ping & Tung Chung',
                      text_color: '#212121'
                    },
                    visitors: {
                      avatar1: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar2: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      avatar3: 'https://res.klook.com/image/upload/activities/v8jlqheda850tjckjeuu.webp',
                      text: '1,230 reviews'
                    }
                  },
                  track_info: {
                    object_id: '20835571',
                    extra_info: {}
                  },
                  card_action: {
                    type: '',
                    deep_link: ''
                  }
                }]
            }
          }
        }
      }, {
        meta: {
          type: 'common',
          placeholder: '',
          track: null,
          name: 'TopDestination'
        },
        data: {
          title: 'Incredible destinations ',
          sub_title: '',
          more_title: '',
          more_deep_link: '',
          bottom_button_title: 'Explore all destinations',
          bottom_button_deep_link: '',
          is_navigation: false
        },
        body: {
          content: {
            handler: 'platform',
            data_type: 'TopDestinationList',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              items: [{
                title: 'Hong Kong',
                city_id: 2,
                background_src: 'https://res.klook.com/image/upload/cities/x9lbkqf1dj77nunksglt.jpg',
                deep_link: 'https://localhost:3000/en-US/city/2-hong-kong-things-to-do/',
                sub_title: ''
              }, {
                title: 'Macau',
                city_id: 3,
                background_src: 'https://res.klook.com/image/upload/cities/c1cklkyp6ms02tougufx.jpg',
                deep_link: 'https://localhost:3000/en-US/city/3-macau-things-to-do/',
                sub_title: ''
              }, {
                title: 'Taipei',
                city_id: 19,
                background_src: 'https://res.klook.com/image/upload/cities/i5itbqsg2alwruhqkgvx.jpg',
                deep_link: 'https://localhost:3000/en-US/city/19-taipei-things-to-do/',
                sub_title: ''
              }, {
                title: 'Shanghai',
                city_id: 59,
                background_src: 'https://res.klook.com/image/upload/cities/oq4xk64zpodfl3uxymxr.jpg',
                deep_link: 'https://localhost:3000/en-US/city/59-shanghai-things-to-do/',
                sub_title: ''
              }, {
                title: 'London',
                city_id: 106,
                background_src: 'https://res.klook.com/image/upload/cities/xkeic9zojhtxffeovjtl.jpg',
                deep_link: 'https://localhost:3000/en-US/city/106-london-things-to-do/',
                sub_title: ''
              }, {
                title: 'Kenting',
                city_id: 23,
                background_src: 'https://res.klook.com/image/upload/cities/ekag7x5vkmhxond0vhgg.jpg',
                deep_link: 'https://localhost:3000/en-US/city/23-kenting-things-to-do/',
                sub_title: ''
              }]
            }
          }
        }
      }, {
        meta: {
          type: 'common',
          placeholder: '',
          track: null,
          name: 'Popular'
        },
        data: {
          title: 'Top things to do',
          sub_title: '',
          more_title: '',
          more_deep_link: '',
          bottom_button_title: 'View all',
          bottom_button_deep_link: 'https://t146.test.klook.io/en-US/popular/all',
          is_navigation: false
        },
        body: {
          content: {
            handler: 'platform',
            data_type: 'PopularActivity',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              card_list: [{
                ref_data: {
                  object_id: '524',
                  city_id: 6,
                  country_id: 15,
                  template_id: 1
                },
                card_template: 'home_page_popular_vertical_card',
                card_render_type: '',
                element_list: null,
                card_data: {
                  branding_tag: null,
                  general_info: {
                    text: 'Singapore',
                    text_color: '',
                    icon: '',
                    bold: false
                  },
                  general_tag: [{
                    bg_color: '#E9F8F1',
                    text_color: '#16AA77',
                    text: 'Bestseller'
                  }, {
                    bg_color: '#F5F5F5',
                    text_color: '#757575',
                    text: 'Instant Confirmation'
                  }],
                  image: {
                    url: 'https://res.klook.com/image/upload/activities/dg0la99n5j3wumqcycxb.jpg',
                    is_gray: false
                  },
                  price_desc: {
                    selling_price_format: 'From {price}',
                    selling_price: '¥ 6',
                    market_price: '¥ 81',
                    market_price_strike: true,
                    discount_tag: {
                      background_color_left: '#FF9557',
                      background_color_right: '#FF6B3D',
                      text_color: '#FFFFFF',
                      text: 'Up to 60% off'
                    }
                  },
                  promo_tag_list: null,
                  review: {
                    star: '4.5',
                    text: '(6,832) • 6K+ Booked'
                  },
                  seo_data: {
                    seo_title: 'Meal coupons for Hong Kong Disneyland1',
                    seo_link: 'https://t146.test.klook.io/en-US/activity/524-disneyland-gourmet-meal-voucher-hong-kong-%E3%80%90%E7%BE%8E%E9%A3%9F%E9%A4%90%E5%88%B8%E3%80%91%E7%9B%A1%E4%BA%AB%E8%BF%AA%E5%A3%AB%E5%B0%BC%E6%A8%82%E5%9C%92%E7%BE%8E%E9%A3%9F%EF%BC%81%E9%A6%99%E6%B8%AF%E8%BF%AA%E5%A3%AB%E5%B0%BC%E6%A8%82%E5%9C%92%E9%A4%90%E5%88%B8/'
                  },
                  title: {
                    text: 'Meal coupons for Hong Kong Disneyland1',
                    text_color: ''
                  }
                },
                track_info: {
                  object_id: '524',
                  extra_info: {
                    branding_tag: '',
                    discount_tag: 'Up to 60% off',
                    general_tag_list: 'Bestseller,Instant Confirmation',
                    promo_tag_list: ''
                  }
                },
                card_action: {
                  type: 'deep_link',
                  deep_link: 'https://t146.test.klook.io/en-US/activity/524-disneyland-gourmet-meal-voucher-hong-kong-%E3%80%90%E7%BE%8E%E9%A3%9F%E9%A4%90%E5%88%B8%E3%80%91%E7%9B%A1%E4%BA%AB%E8%BF%AA%E5%A3%AB%E5%B0%BC%E6%A8%82%E5%9C%92%E7%BE%8E%E9%A3%9F%EF%BC%81%E9%A6%99%E6%B8%AF%E8%BF%AA%E5%A3%AB%E5%B0%BC%E6%A8%82%E5%9C%92%E9%A4%90%E5%88%B8/'
                }
              }, {
                ref_data: {
                  object_id: '18464',
                  city_id: 6,
                  country_id: 15,
                  template_id: 1
                },
                card_template: 'home_page_popular_vertical_card',
                card_render_type: '',
                element_list: null,
                card_data: {
                  branding_tag: null,
                  general_info: {
                    text: 'Singapore',
                    text_color: '',
                    icon: '',
                    bold: false
                  },
                  general_tag: [{
                    bg_color: '#E9F8F1',
                    text_color: '#16AA77',
                    text: 'Bestseller'
                  }, {
                    bg_color: '#F5F5F5',
                    text_color: '#757575',
                    text: 'Instant Confirmation'
                  }],
                  image: {
                    url: 'https://res.klook.com/image/upload/activities/zi3pln6zjkvpqpfruvkj.jpg',
                    is_gray: false
                  },
                  price_desc: {
                    selling_price_format: '{selling_price}',
                    selling_price: '¥ 400,000,000',
                    market_price: '¥ 600,000,000',
                    market_price_strike: true,
                    discount_tag: null
                  },
                  promo_tag_list: null,
                  review: {
                    star: '4.2',
                    text: '(4,073) • 4K+ Booked'
                  },
                  seo_data: {
                    seo_title: '保险一期测试活动01 en',
                    seo_link: 'https://t146.test.klook.io/en-US/activity/18464-url/'
                  },
                  title: {
                    text: '保险一期测试活动01 en',
                    text_color: ''
                  }
                },
                track_info: {
                  object_id: '18464',
                  extra_info: {
                    branding_tag: '',
                    discount_tag: '',
                    general_tag_list: 'Bestseller,Instant Confirmation',
                    promo_tag_list: ''
                  }
                },
                card_action: {
                  type: 'deep_link',
                  deep_link: 'https://t146.test.klook.io/en-US/activity/18464-url/'
                }
              }, {
                ref_data: {
                  object_id: '523',
                  city_id: 2,
                  country_id: 2,
                  template_id: 2
                },
                card_template: 'home_page_popular_vertical_card',
                card_render_type: '',
                element_list: null,
                card_data: {
                  branding_tag: null,
                  general_info: {
                    text: 'Tours • Hong Kong',
                    text_color: '',
                    icon: '',
                    bold: false
                  },
                  general_tag: null,
                  image: {
                    url: 'https://res.klook.com/image/upload/activities/hhgakmmao13ypgay9yiz.jpg',
                    is_gray: false
                  },
                  price_desc: {
                    selling_price_format: 'From {price}',
                    selling_price: '¥ 90',
                    market_price: '¥ 117',
                    market_price_strike: true,
                    discount_tag: null
                  },
                  promo_tag_list: null,
                  review: {
                    star: '4.8',
                    text: '(5,707) • 5K+ Booked'
                  },
                  seo_data: {
                    seo_title: '活动标题',
                    seo_link: 'https://t146.test.klook.io/en-US/activity/523-peak-tram-3-in-1-combo-ticket-hong-kong/'
                  },
                  title: {
                    text: '活动标题',
                    text_color: ''
                  }
                },
                track_info: {
                  object_id: '523',
                  extra_info: {
                    branding_tag: '',
                    discount_tag: '',
                    general_tag_list: '',
                    promo_tag_list: ''
                  }
                },
                card_action: {
                  type: 'deep_link',
                  deep_link: 'https://t146.test.klook.io/en-US/activity/523-peak-tram-3-in-1-combo-ticket-hong-kong/'
                }
              }]
            }
          }
        }
      }, {
        meta: {
          type: 'common',
          placeholder: '',
          track: null,
          name: 'TravelInspiration'
        },
        data: {
          title: 'Get inspired',
          sub_title: '',
          more_title: '',
          more_deep_link: '',
          bottom_button_title: '',
          bottom_button_deep_link: '',
          is_navigation: false
        },
        body: {
          content: {
            handler: 'platform',
            data_type: 'ThemeList',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              showing_theme_count: 3,
              more_title: 'See More',
              themes_v_2: [{
                title: 'cata testing',
                subtitle: 'short description',
                deep_link: 'https://localhost:3000/en-US/theme/7105-123123/',
                background_src: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,,w_1838,/v1596537181/banner/rh415d8jetidxbyc1qdp.jpg',
                card_list: [{
                  ref_data: {
                    object_id: '3300',
                    city_id: 2,
                    country_id: 2,
                    template_id: 2
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Hong Kong',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#E9F8F1',
                      text_color: '#16AA77',
                      text: 'Bestseller'
                    }, {
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/ngffc2htpfegspi7uns4.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: 'From {price}',
                      selling_price: '¥ 399',
                      market_price: '',
                      market_price_strike: false,
                      discount_tag: {
                        background_color_left: '#FF9557',
                        background_color_right: '#FF6B3D',
                        text_color: '#FFFFFF',
                        text: 'Up to 40% off'
                      }
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.6',
                      text: '(101,903)'
                    },
                    seo_data: {
                      seo_title: 'Shared Shopping Express Coach Transfers from Paris to La Vallée Village ',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/3300-roundtrip-transfers-to-la-vall%C3%A9e-village-paris/'
                    },
                    title: {
                      text: 'Shared Shopping Express Coach Transfers from Paris to La Vallée Village ',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '3300',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: 'Up to 40% off',
                      general_tag_list: 'Bestseller,Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/3300-roundtrip-transfers-to-la-vall%C3%A9e-village-paris/'
                  }
                }, {
                  ref_data: {
                    object_id: '87',
                    city_id: 18,
                    country_id: 10,
                    template_id: 1
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: {
                      icon_src: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      hover_text: 'This is a Klook Preferred activity,  provided by one of our trusted partners and recommended by fellow travelers'
                    },
                    general_info: {
                      text: 'Jeju',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/xuo1pyc4a6ru4jb9h3xp.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: '{selling_price}',
                      selling_price: 'Free Voucher',
                      market_price: '',
                      market_price_strike: false,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.8',
                      text: '(106,528)'
                    },
                    seo_data: {
                      seo_title: 'Sky100 Ticket in Hong Kong',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/87-sky100-hong-kong/'
                    },
                    title: {
                      text: 'Sky100 Ticket in Hong Kong',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '87',
                    extra_info: {
                      branding_tag: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      discount_tag: '',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/87-sky100-hong-kong/'
                  }
                }, {
                  ref_data: {
                    object_id: '34127',
                    city_id: 13,
                    country_id: 10,
                    template_id: 1
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: {
                      icon_src: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      hover_text: 'This is a Klook Preferred activity,  provided by one of our trusted partners and recommended by fellow travelers'
                    },
                    general_info: {
                      text: 'Seoul',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/jdosiswdfaxa6fjlnooa.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: 'From {price}',
                      selling_price: '¥ 20',
                      market_price: '',
                      market_price_strike: false,
                      discount_tag: {
                        background_color_left: '#FF9557',
                        background_color_right: '#FF6B3D',
                        text_color: '#FFFFFF',
                        text: 'Up to 10% off'
                      }
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.8',
                      text: '(46)'
                    },
                    seo_data: {
                      seo_title: 'test-无屏蔽语言-自动翻译&发布 US',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/34127-1/'
                    },
                    title: {
                      text: 'test-无屏蔽语言-自动翻译&发布 US',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '34127',
                    extra_info: {
                      branding_tag: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      discount_tag: 'Up to 10% off',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/34127-1/'
                  }
                }, {
                  ref_data: {
                    object_id: '18464',
                    city_id: 6,
                    country_id: 15,
                    template_id: 1
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Singapore',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#E9F8F1',
                      text_color: '#16AA77',
                      text: 'Bestseller'
                    }, {
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/zi3pln6zjkvpqpfruvkj.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: '{selling_price}',
                      selling_price: '¥ 400,000,000',
                      market_price: '¥ 600,000,000',
                      market_price_strike: true,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.2',
                      text: '(4,073)'
                    },
                    seo_data: {
                      seo_title: '保险一期测试活动01 en',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/18464-url/'
                    },
                    title: {
                      text: '保险一期测试活动01 en',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '18464',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: '',
                      general_tag_list: 'Bestseller,Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/18464-url/'
                  }
                }]
              }, {
                title: 'Autumn in the Air-en_US',
                subtitle: "en-Admire the season's spectrum of autumn colors and experience some Halloween fun as you try these activities, handpicked especially for you",
                deep_link: 'https://www.klook.com/zh-CN/theme/9970-2020-spring-activities',
                background_src: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,,w_1920,/v1596449816/banner/nyo1rjjcqanmmvvncjdp.jpg',
                card_list: [{
                  ref_data: {
                    object_id: '6252',
                    city_id: 2,
                    country_id: 2,
                    template_id: 4
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: {
                      icon_src: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      hover_text: 'This is a Klook Preferred activity,  provided by one of our trusted partners and recommended by fellow travelers'
                    },
                    general_info: {
                      text: 'Hong Kong',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/zbv0a8ardvq5qfkm8dwj.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: 'From {price}',
                      selling_price: '¥ 1',
                      market_price: '',
                      market_price_strike: false,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.4',
                      text: '(113,789)'
                    },
                    seo_data: {
                      seo_title: 'Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/6252-mammy-pancake-hong-kongHAHHAHAH/'
                    },
                    title: {
                      text: 'Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST Mammy Pancake in TST',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '6252',
                    extra_info: {
                      branding_tag: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      discount_tag: '',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/6252-mammy-pancake-hong-kongHAHHAHAH/'
                  }
                }, {
                  ref_data: {
                    object_id: '4881',
                    city_id: 2,
                    country_id: 2,
                    template_id: 5
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Hong Kong',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#E9F8F1',
                      text_color: '#16AA77',
                      text: 'Bestseller'
                    }, {
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/gxhzonxp4szbrypi1rfp.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: '{selling_price}',
                      selling_price: '¥ 38',
                      market_price: '',
                      market_price_strike: false,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.0',
                      text: '(2,464)'
                    },
                    seo_data: {
                      seo_title: 'Shared One Way Shuttle Bus Transfer from NP360 (Tung Chung) to Jordan or Central MTR',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/4881-shuttle-bus-np360-jordan-central-hong-kong/'
                    },
                    title: {
                      text: 'Shared One Way Shuttle Bus Transfer from NP360 (Tung Chung) to Jordan or Central MTR',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '4881',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: '',
                      general_tag_list: 'Bestseller,Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/4881-shuttle-bus-np360-jordan-central-hong-kong/'
                  }
                }, {
                  ref_data: {
                    object_id: '549',
                    city_id: 2,
                    country_id: 2,
                    template_id: 4
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: {
                      icon_src: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      hover_text: 'This is a Klook Preferred activity,  provided by one of our trusted partners and recommended by fellow travelers'
                    },
                    general_info: {
                      text: 'Hong Kong',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/rcz3wqkubzqv4hle4rpw.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: '{selling_price}',
                      selling_price: 'Free Reservation',
                      market_price: '',
                      market_price_strike: false,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: null,
                    seo_data: {
                      seo_title: 'Macau Tower - Tea Delights & Sky High Views',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/549-macau-tower-afternoon-tea-macau/'
                    },
                    title: {
                      text: 'Macau Tower - Tea Delights & Sky High Views',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '549',
                    extra_info: {
                      branding_tag: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      discount_tag: '',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/549-macau-tower-afternoon-tea-macau/'
                  }
                }, {
                  ref_data: {
                    object_id: '2889',
                    city_id: 2,
                    country_id: 2,
                    template_id: 5
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Hong Kong',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: null,
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/xzozladi9quw3mdi3e9b.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: '{selling_price}',
                      selling_price: '¥ 2,090',
                      market_price: '¥ 2,400',
                      market_price_strike: true,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.0',
                      text: '(892)'
                    },
                    seo_data: {
                      seo_title: 'Private MPV Transfers between Hong Kong and Guangzhou',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/2889-guangzhou-mpv-transfer-hong-kong/'
                    },
                    title: {
                      text: 'Private MPV Transfers between Hong Kong and Guangzhou',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '2889',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: '',
                      general_tag_list: '',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/2889-guangzhou-mpv-transfer-hong-kong/'
                  }
                }]
              }, {
                title: 'blueblue000',
                subtitle: 'blueblue000blueblue000',
                deep_link: 'www.qq.com',
                background_src: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/c_fill,,w_640,/v1595233963/banner/jrabvhzsshzdcmx93bvl.jpg',
                card_list: [{
                  ref_data: {
                    object_id: '32990',
                    city_id: 6,
                    country_id: 15,
                    template_id: 3
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Singapore',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/t67rizrv9uhtrofqkjim.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: 'From {price}',
                      selling_price: '¥ 15',
                      market_price: '¥ 151',
                      market_price_strike: true,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.0',
                      text: '(162)'
                    },
                    seo_data: {
                      seo_title: 'Hyatt Centric Staycation – Set Dinner with 2-hour free flow at Cruise Restaurant & Bar or Lunch Buffet or Dinner Buffet with Breakfast for 2 at the Farmhouse',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/32990-hyatt-centric-victoria-harbour-staycation-set-dinner-breakfast-2/'
                    },
                    title: {
                      text: 'Hyatt Centric Staycation – Set Dinner with 2-hour free flow at Cruise Restaurant & Bar or Lunch Buffet or Dinner Buffet with Breakfast for 2 at the Farmhouse',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '32990',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: '',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/32990-hyatt-centric-victoria-harbour-staycation-set-dinner-breakfast-2/'
                  }
                }, {
                  ref_data: {
                    object_id: '523',
                    city_id: 2,
                    country_id: 2,
                    template_id: 2
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Hong Kong',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: null,
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/hhgakmmao13ypgay9yiz.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: 'From {price}',
                      selling_price: '¥ 90',
                      market_price: '¥ 117',
                      market_price_strike: true,
                      discount_tag: null
                    },
                    promo_tag_list: null,
                    review: {
                      star: '4.8',
                      text: '(5,707)'
                    },
                    seo_data: {
                      seo_title: '活动标题',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/523-peak-tram-3-in-1-combo-ticket-hong-kong/'
                    },
                    title: {
                      text: '活动标题',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '523',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: '',
                      general_tag_list: '',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/523-peak-tram-3-in-1-combo-ticket-hong-kong/'
                  }
                }, {
                  ref_data: {
                    object_id: '1579',
                    city_id: 28,
                    country_id: 12,
                    template_id: 1
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: {
                      icon_src: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      hover_text: 'This is a Klook Preferred activity,  provided by one of our trusted partners and recommended by fellow travelers'
                    },
                    general_info: {
                      text: 'Tokyo',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/0808f5c9-Universal-Studios-Japan-%28Physical-Ticket%29.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: '{selling_price}',
                      selling_price: '¥ 468',
                      market_price: '¥ 484',
                      market_price_strike: true,
                      discount_tag: {
                        background_color_left: '#FF9557',
                        background_color_right: '#FF6B3D',
                        text_color: '#FFFFFF',
                        text: 'Up to 55% off'
                      }
                    },
                    promo_tag_list: [{
                      background_color_left: '',
                      background_color_right: '',
                      text_color: '',
                      text: '压测促销3',
                      icon_src: ''
                    }],
                    review: {
                      star: '5.0',
                      text: '(172,608)'
                    },
                    seo_data: {
                      seo_title: 'Universal Studios Japan Ticket Osaka',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/1579-universal-studios-japan-ticket-osaka/'
                    },
                    title: {
                      text: 'Universal Studios Japan Ticket Osaka',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '1579',
                    extra_info: {
                      branding_tag: 'https://res.klook.com/image/upload/v1607395559/ued/product%20card/tag_klook_preferred.png',
                      discount_tag: 'Up to 55% off',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: '压测促销3'
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/1579-universal-studios-japan-ticket-osaka/'
                  }
                }, {
                  ref_data: {
                    object_id: '18219',
                    city_id: 28941,
                    country_id: 12,
                    template_id: 1
                  },
                  card_template: 'home_page_theme_vertical_card',
                  card_render_type: '',
                  element_list: null,
                  card_data: {
                    branding_tag: null,
                    general_info: {
                      text: 'Miyazaki',
                      text_color: '',
                      icon: '',
                      bold: false
                    },
                    general_tag: [{
                      bg_color: '#F5F5F5',
                      text_color: '#757575',
                      text: 'Instant Confirmation'
                    }],
                    image: {
                      url: 'https://res.klook.com/image/upload/activities/j8dlin9agtavmyoagqjm.jpg',
                      is_gray: false
                    },
                    price_desc: {
                      selling_price_format: 'From {price}',
                      selling_price: '¥ 9',
                      market_price: '¥ 399',
                      market_price_strike: true,
                      discount_tag: {
                        background_color_left: '#FF9557',
                        background_color_right: '#FF6B3D',
                        text_color: '#FFFFFF',
                        text: 'Up to 30% off'
                      }
                    },
                    promo_tag_list: null,
                    review: {
                      star: '5.0',
                      text: '(1,688)'
                    },
                    seo_data: {
                      seo_title: 'lenis - 测试活动勿改',
                      seo_link: 'https://t146.test.klook.io/en-US/activity/18219-%E6%99%AF%E7%82%B9%E9%97%A8%E7%A5%A8%20FR/'
                    },
                    title: {
                      text: 'lenis - 测试活动勿改',
                      text_color: ''
                    }
                  },
                  track_info: {
                    object_id: '18219',
                    extra_info: {
                      branding_tag: '',
                      discount_tag: 'Up to 30% off',
                      general_tag_list: 'Instant Confirmation',
                      promo_tag_list: ''
                    }
                  },
                  card_action: {
                    type: 'deep_link',
                    deep_link: 'https://t146.test.klook.io/en-US/activity/18219-%E6%99%AF%E7%82%B9%E9%97%A8%E7%A5%A8%20FR/'
                  }
                }]
              }]
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'MustEats'
        },
        data: null,
        body: {
          content: {
            handler: 'fnd',
            data_type: 'FnbEntrance',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              title: '',
              view_more_text: '',
              view_more_deep_link: '',
              items: null
            }
          }
        }
      }, {
        meta: {
          type: 'common',
          placeholder: '',
          track: null,
          name: 'KlookPreferred'
        },
        data: {
          title: 'Klook recommended',
          sub_title: 'Incredible experiences wherever you are - chosen by our travel curators',
          more_title: '',
          more_deep_link: '',
          bottom_button_title: '',
          bottom_button_deep_link: '',
          is_navigation: false
        },
        body: {
          content: {
            handler: 'platform',
            data_type: 'ActivityCardList',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              showing_count: 3,
              more_title: 'See More',
              card_list: []
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'TailPromoteCard'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'Promote',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              title: 'Check out the Klook blog',
              title_color: '',
              background_src: 'https://res.klook.com/image/upload/v1595421355/homepage2020/%E5%8D%9A%E5%AE%A2%E5%85%A5%E5%8F%A3.png',
              button_title: '',
              button_background_color: '',
              button_title_color: '',
              type: 'deep_link',
              deep_link: 'https://t146.test.klook.io/blog/',
              subtitle: "Follow the team's musings on trends in travel, itinerary ideas and travel tips"
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'TailPromoteCard'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'Promote',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              title: 'Use promo code',
              title_color: '',
              background_src: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_65,f_auto/c_fill,w_800,h_518/redeem.jpg',
              button_title: 'Redeem',
              button_background_color: '',
              button_title_color: '',
              type: 'deep_link',
              deep_link: 'https://localhost:3000/en-US/coupons/',
              subtitle: " 'Mobile10' to get ¥9 off your first booking"
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'TailPromoteCard'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'Promote#RightIcon',
            load_type: 'sync',
            src: '',
            track: null,
            data: {
              title: 'Get free credits when you complete an experience',
              title_color: '',
              background_src: 'https://res.klook.com/image/upload/v1596608665/homepage2020/credits.png',
              button_title: 'See More',
              button_background_color: '',
              button_title_color: '',
              type: 'deep_link',
              deep_link: 'https://localhost:3000/en-US/credits/landing/',
              subtitle: ''
            }
          }
        }
      }, {
        meta: {
          type: 'any',
          placeholder: '',
          track: null,
          name: 'SEO'
        },
        data: null,
        body: {
          content: {
            handler: 'platform',
            data_type: 'SeoStaticLink',
            load_type: 'sync',
            src: '',
            track: null,
            data: [{
              heading: 'Top Cities to Visit',
              linking: [{
                key_word: 'Things To Do in San Francisco',
                url: 'https://www.klook.com/en-US/city/129-san-francisco-things-to-do/'
              }, {
                key_word: 'Things To Do in Chicago',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/'
              }, {
                key_word: 'Things To Do in San Diego',
                url: 'https://www.klook.com/en-US/city/330-san-diego-things-to-do/'
              }, {
                key_word: 'Things To Do in Orlando',
                url: 'https://www.klook.com/en-US/city/123-orlando-things-to-do/'
              }, {
                key_word: 'Things To Do in Miami',
                url: 'https://www.klook.com/en-US/city/198-Miami-things-to-do/'
              }, {
                key_word: 'Things To Do in Las Vegas',
                url: 'https://www.klook.com/en-US/city/136-las-vegas-things-to-do/'
              }, {
                key_word: 'Things To Do in Boston',
                url: 'https://www.klook.com/en-US/city/167-Boston-things-to-do/'
              }, {
                key_word: 'Things To Do in New York',
                url: 'https://www.klook.com/en-US/city/93-new-york-things-to-do/'
              }, {
                key_word: 'Things To Do in Los Angeles',
                url: 'https://www.klook.com/en-US/city/124-los-angeles-things-to-do/'
              }, {
                key_word: 'Things To Do in Washington DC',
                url: 'https://www.klook.com/en-US/city/166-Washington-DC-things-to-do/'
              }]
            }, {
              heading: 'Top Things to Do',
              linking: [{
                key_word: 'Las Vegas Shows',
                url: 'https://www.klook.com/en-US/city/136-las-vegas-things-to-do/1-cate/'
              }, {
                key_word: 'Places To Visit in New York',
                url: 'https://www.klook.com/en-US/city/93-new-york-things-to-do/2-cate/'
              }, {
                key_word: 'Las Vegas Attractions',
                url: 'https://www.klook.com/en-US/city/136-las-vegas-things-to-do/1-cate/'
              }, {
                key_word: 'San Francisco Tours',
                url: 'https://www.klook.com/en-US/city/129-san-francisco-things-to-do/2-cate/'
              }, {
                key_word: 'Washington DC Tours',
                url: 'https://www.klook.com/en-US/city/166-Washington-DC-things-to-do/2-cate/'
              }, {
                key_word: 'Orlando Attractions',
                url: 'https://www.klook.com/en-US/city/123-orlando-things-to-do/1-cate/'
              }, {
                key_word: 'Chicago Tour',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/2-cate/'
              }, {
                key_word: 'Chicago Attractions',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/1-cate/'
              }, {
                key_word: 'San Diego Attractions',
                url: 'https://www.klook.com/en-US/city/330-san-diego-things-to-do/1-cate/'
              }, {
                key_word: 'Shows in Chicago',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/1-cate/'
              }, {
                key_word: 'San Diego Zoo',
                url: 'https://www.klook.com/en-US/city/330-san-diego-things-to-do/34-tag/'
              }, {
                key_word: 'Boston Aquarium',
                url: 'https://www.klook.com/en-US/city/167-Boston-things-to-do/34-tag/'
              }, {
                key_word: 'Miami Zoo',
                url: 'https://www.klook.com/en-US/city/198-Miami-things-to-do/34-tag/'
              }, {
                key_word: 'San Francisco Zoo',
                url: 'https://www.klook.com/en-US/city/129-san-francisco-things-to-do/34-tag/'
              }, {
                key_word: 'Concerts in Chicago',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/139-tag/'
              }, {
                key_word: 'New York Aquarium',
                url: 'https://www.klook.com/en-US/city/93-new-york-things-to-do/34-tag/'
              }, {
                key_word: 'Las Vegas Concerts',
                url: 'https://www.klook.com/en-US/city/136-las-vegas-things-to-do/139-tag/'
              }, {
                key_word: 'Chicago Museums',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/38-tag/'
              }, {
                key_word: 'Los Angeles Zoo',
                url: 'https://www.klook.com/en-US/city/124-los-angeles-things-to-do/34-tag/'
              }, {
                key_word: 'Chicago Aquarium',
                url: 'https://www.klook.com/en-US/city/134-chicago-things-to-do/34-tag/'
              }]
            }, {
              heading: 'Top Activities',
              linking: [{
                key_word: 'Universal Studios Orlando',
                url: 'https://www.klook.com/en-US/activity/4912-universal-orlando-admission-ticket-orlando/'
              }, {
                key_word: 'San Diego Zoo',
                url: 'https://www.klook.com/en-US/activity/6698-san-diego-zoo-admission-ticket-los-angeles/'
              }, {
                key_word: 'Empire State Building',
                url: 'https://www.klook.com/en-US/activity/2956-empire-state-building-new-york/'
              }, {
                key_word: 'Universal Studios Hollywood',
                url: 'https://www.klook.com/en-US/activity/9024-universal-studios-hollywood-ticket-los-angeles/'
              }, {
                key_word: 'Seaworld San Diego',
                url: 'https://www.klook.com/en-US/activity/4096-sea-world-san-diego-ticket-los-angeles/'
              }, {
                key_word: 'Six Flags Magic Mountain',
                url: 'https://www.klook.com/en-US/activity/4115-six-flags-magic-mountain-ticket-los-angeles/'
              }, {
                key_word: 'Top Of The Rock',
                url: 'https://www.klook.com/en-US/activity/2964-top-of-the-rock-new-york/'
              }, {
                key_word: 'Legoland California',
                url: 'https://www.klook.com/en-US/activity/4097-legoland-california-ticket-los-angeles/'
              }, {
                key_word: 'Neuschwanstein Castle',
                url: 'https://www.klook.com/en-US/activity/3588-neuschwanstein-linderhof-royal-castle-oberammergau-tour-munich/'
              }, {
                key_word: 'Great Barrier Reef',
                url: 'https://www.klook.com/en-US/activity/12225-great-barrier-reef-cruise-scenic-helicopter-ride-cairns/'
              }, {
                key_word: 'Angkor Wat',
                url: 'https://www.klook.com/en-US/activity/6455-angkor-temples-sunrise-tour-siem-reap/'
              }, {
                key_word: 'Met Museum',
                url: 'https://www.klook.com/en-US/activity/2958-metropolitan-museum-art-new-york/'
              }, {
                key_word: 'Disneyland Paris',
                url: 'https://www.klook.com/en-US/activity/3969-disneyland-resort-paris/'
              }, {
                key_word: 'San Francisco Zoo',
                url: 'https://www.klook.com/en-US/activity/13691-san-francisco-zoo-gardens-admission-ticket-san-francisco/'
              }, {
                key_word: 'Mont Saint Michel',
                url: 'https://www.klook.com/en-US/activity/3315-mont-saint-michel-full-day-tour-paris/'
              }, {
                key_word: 'High Roller Las Vegas',
                url: 'https://www.klook.com/en-US/activity/10320-las-vegas-high-roller-admission-ticket-las-vegas/'
              }, {
                key_word: 'Chicago City Pass',
                url: 'https://www.klook.com/en-US/activity/18333-citypass-chicago/'
              }, {
                key_word: 'Shanghai Tower',
                url: 'https://www.klook.com/en-US/activity/4333-shanghai-tower-observation-deck-shanghai/'
              }, {
                key_word: 'Uss Midway Museum',
                url: 'https://www.klook.com/en-US/activity/6525-san-diego-uss-midway-museum-los-angeles/'
              }, {
                key_word: 'Spyscape',
                url: 'https://www.klook.com/en-US/activity/13995-spyscape-spy-museum-admission-ticket-new-york/'
              }]
            }, {
              heading: 'Travel Categories',
              linking: [{
                key_word: 'China Train Tickets',
                url: 'https://www.klook.com/en-US/china-train-ticket/'
              }, {
                key_word: 'Airport Transfers',
                url: 'https://www.klook.com/en-US/airport-transfers/'
              }, {
                key_word: 'Japan Rail Pass',
                url: 'https://www.klook.com/en-US/japan-rail-jr-pass/'
              }, {
                key_word: 'Taiwan Rail',
                url: 'https://www.klook.com/en-US/city/393-taiwan-rail-things-to-do/'
              }, {
                key_word: 'Vietnam Rail',
                url: 'https://www.klook.com/en-US/city/467-vietnam-rail-things-to-do/'
              }, {
                key_word: 'Hotel',
                url: 'https://www.klook.com/en-US/hotels/'
              }, {
                key_word: 'WiFi & SIM Card',
                url: 'https://www.klook.com/en-US/wifi-sim-card/'
              }]
            }]
          }
        }
      }],
      second_floor: {
        is_show: false,
        title: 'Swipe down to join now!',
        deep_link: 'https://t146.test.klook.io/en-US/live_show/home',
        banner_url: 'https://res.klook.com/image/upload/v1594780649/Traveltainment_Homepage_wsrm6j.png',
        type: 'living'
      }
    }
  }
}
