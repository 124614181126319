import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import apis from '~/share/data/apis'

const SET_KEPLER_ID = 'SET_KEPLER_ID'
const SET_EXPERIMENTS_HIT_LIST = 'SET_EXPERIMENTS_HIT_LIST'
const SET_TINTED_LIST = 'SET_TINTED_LIST'
const SET_EXP_EXTRA = 'SET_EXP_EXTRA'

export const state = () => ({
  keplerId: '',
  experimentsGroup: {},
  tintedList: '',
  expExtra: {}
})

export const mutations: MutationTree<Data.IKepler> = {
  [SET_KEPLER_ID](state, id) {
    state.keplerId = id
  },
  [SET_EXPERIMENTS_HIT_LIST](state, experimentsHitGroup) {
    // 重新结构化Store挂载的全局实验列表
    state.experimentsGroup = experimentsHitGroup
  },
  [SET_TINTED_LIST](state, tintedList) {
    state.tintedList = tintedList
  },
  [SET_EXP_EXTRA](state, payload) {
    state.expExtra = payload
  }
}

export const actions: ActionTree<Data.IKepler, RootState> = {
  async getExperimentsHitList({ commit, rootState }) {
    try {
      const res: Data.Res = await this.$axios
        .$get(apis.getExperimentsHitList, {
          timeout: 500,
          headers:
            { 'X-Platform': rootState.klook.platform }
        })

      // 染色逻辑
      if (res && res.result) {
        const expsList: Data.IKeplerExpsList = res.result.exps || []
        const dyeList: string[] = []
        expsList.forEach((item) => {
          if (item.need_dye) {
            dyeList.push(`${item.experiment_id}:${item.group_id}`)
          }
        })
        if (dyeList.length) {
          // node端维系染色列表，以便后端染色请求头部使用
          commit(SET_TINTED_LIST, JSON.stringify({ kepler: dyeList }))
        }
        const result = res.result
        const expExtra = {
          ...(result.user_id ? { user_id: result.user_id } : {}),
          ...(result.origin_kepler_id
            ? { origin_kepler_id: result.origin_kepler_id }
            : {})
        }
        commit(SET_EXP_EXTRA, expExtra)
      }
      commit(SET_EXPERIMENTS_HIT_LIST, res.result.exps || [])
    } catch (e) {
      commit(SET_EXPERIMENTS_HIT_LIST, [])
    }
  }
}
