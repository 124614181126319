interface whiteLabelSdk {
  loggerQuery: any
  nodeEnv: string
  host: string
  platform?: 'mobile' | 'desktop'
  language?: string
  currency?: string
  ctx?: any
  aid?: string | number
  domain?: string
  environment?: string
}

async function whiteLabelSdkInit(data: whiteLabelSdk) {
  const wlSdk: any = await import('@klook/klk-wl-platform')
  const a = await wlSdk.default(data)
  return a
}

export default whiteLabelSdkInit
