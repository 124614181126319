let isLocalStorageSupport = false
export function localStorageSupport() {
  if (!isLocalStorageSupport) {
    try {
      window.localStorage.setItem('test', '4')
      window.localStorage.removeItem('test')
      isLocalStorageSupport = true
      return true
    } catch (err) {
      isLocalStorageSupport = false
      return false
    }
  } else {
    return isLocalStorageSupport
  }
}

// 带过期时间的localstorage版本
const localStorageEx = function () {
  function setItem(key, value, expires) {
    if (localStorageSupport()) {
      window.localStorage.setItem(key, JSON.stringify({
        localData: value,
        expires: new Date().getTime() + expires
      }))
    }
  }

  function getItem(key) {
    if (localStorageSupport()) {
      try {
        const data = JSON.parse(window.localStorage.getItem(key))
        const curTime = new Date().getTime()

        if (data) {
          if (curTime > data.expires) {
            window.localStorage.removeItem(key)
          } else {
            return data.localData
          }
        }
      } catch (e) {
        // console.error(e.name + ": " + e.message);
      }
    }
  }

  function removeItem(key) {
    if (localStorageSupport()) {
      window.localStorage.removeItem(key)
    }
  }

  return {
    setItem,
    getItem,
    removeItem
  }
}

/**
 * 设置 localstorage 的数据
 * @param key 希望设置的 key 值
 * @param value 希望设置的 value 值
 */
export function setLocal(key, value) {
  if (localStorageSupport()) {
    window.localStorage.setItem(key, value)
  }
}

/**
 * 获取 localstorage 或者 cookie 中的数据
 * @Note 老框架中localstorage中的数据不一定都有 localData, expires 字段，这样使用 localStorageEx 会不适用
 * @param key 希望获取的 key 值
 * @param cookies
 */
export function getLocalCache(key, cookies) {
  if (localStorageSupport()) {
    return window.localStorage.getItem(key)
  } else {
    return cookies && cookies.get(key)
  }
}

/**
 * 移除 localstorage 或者 cookie 中的数据
 * @param key 希望移除的 key 值
 * @param cookies
 */
export function removeLocalCache(key) {
  if (localStorageSupport()) {
    window.localStorage.removeItem(key)
  }
}

export default localStorageEx
