import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import apiNameMap from '~/share/data/apis'
import { Tour } from '~/types/tour'

/*
  路由匹配规则
    L0页面 无目的地 && 无类目
    /experiences/list
    城市 City X 類目 Category
    /experiences/list/{city}-{cate}/c{city_id}-cate{front_id}/
    國家 Country X 類目 Category
    /experiences/list/{country}-{cate}/g{country_id}-cate{front_id}/
    國家 Country Only
    /experiences/list/{country}/g{country_id}/
    城市 City Only
    /experiences/list/{city}/c{city_id}/
    類目 Category Only
    /experiences/list/{category}/cate{front_id}/

  需求： 主题类landing page MVP
    global x interest
    /experiences/list/{seo_url_slug}/t{tag_id}/
    country x interest
    /experiences/list/{seo_url_slug}/g{country_id}-t{tag_id}/
    city x interest
    /experiences/list/{seo_url_slug}/c{city_id}-t{tag_id}/
**/

export function parseParamsId(params: any) {
  const returnParams: any = {}
  const CATEGORY = 'cate'
  const COUNTRY = 'g'
  const CITY = 'c'
  const PID = 'p'
  const TAG = 't'
  const paramsId = params.id || ''
  const getId = (str: string, replacer: string) => str.replace(replacer, '')

  if (paramsId) {
    // g-cate | c-cate | p | tag | g-tag | c-tag
    if (paramsId.includes('-')) {
      const idList = paramsId.split('-')

      if (idList.length > 1) {
        const [pre, suf] = idList
        // cate | t
        if (suf.startsWith(TAG)) {
          returnParams.tagId = getId(suf, TAG)
        } else {
          returnParams.frontendId = getId(suf, CATEGORY)
        }

        if (pre.startsWith(CITY)) {
          returnParams.cityId = getId(pre, CITY)
        } else if (pre.startsWith(PID)) {
          returnParams.pid = getId(pre, PID)
        } else {
          returnParams.countryId = getId(pre, COUNTRY)
        }
      }
    } else if (paramsId.startsWith(TAG)) {
      returnParams.tagId = getId(paramsId, TAG)
    } else if (paramsId.startsWith(CATEGORY)) {
      returnParams.frontendId = getId(paramsId, CATEGORY)
    } else if (paramsId.startsWith(CITY)) {
      returnParams.cityId = getId(paramsId, CITY)
    } else if (paramsId.startsWith(PID)) {
      returnParams.pid = getId(paramsId, PID)
    } else {
      returnParams.countryId = getId(paramsId, COUNTRY)
    }
  }

  return returnParams
}

// 校验id是否合法
export function validateParamsId(ids: Tour.RouteParamsIds) {
  for (const value of Object.values(ids)) {
    if (!/^[0-9]+$/.test(value)) {
      return false
    }
  }

  return true
}

export function numberify(query: any) {
  if (isEmpty(query)) {
    return {}
  }

  const numberifyList = [
    'country_id',
    'city_id',
    'not_cal_destination',
    'poi_id',
    'start',
    'tag_id'
  ]

  const cloneQuery = cloneDeep(query)

  numberifyList.forEach((item: string) => {
    if (query[item]) {
      cloneQuery[item] = Number(query[item])
    }
  })

  return cloneQuery
}

export function setWindowHistoryV2(payload: any) {
  if (process.client) {
    const queryKeys: string[] = Object.keys(payload)
    const queryList: any = []
    const excludeList: string[] = [
      'size',
      'city_id',
      'country_id',
      'poi_id',
      'init_filter',
      'tag_id'
    ]

    if (window?.__KLOOK__?.state?.klook?.platform === 'mobile') {
      excludeList.push('start')
    }

    queryKeys.forEach((key: string) => {
      if (key !== 'history_destinations' && !excludeList.includes(key)) {
        queryList.push(`${key}=${payload[key]}`)
      }
    })

    if (queryList.length) {
      const url = '?' + queryList.join('&')
      window.history.replaceState(null, '', url)
    }
  }
}

export function formatHistoryDestination(historyString: string) {
  if (historyString === '') {
    return []
  }

  const list = []
  const history_destinations_list = historyString.split('__')

  for (const item of (history_destinations_list || [])) {
    const [id, timestamp] = item.split('_')
    const obj: any = {
      timestamp: Number(timestamp),
      destination_id: Number(id.slice(1))
    }

    obj.destination_type = id.startsWith('p') ? 2 : (id.startsWith('g') ? 1 : 0)

    list.push(obj)
  }

  return list
}

// 拼凑缓存的历史数据
export function composeHistoryDestination(
  history_destination: string,
  countryId: number,
  cityId: number,
  pid: number
) {
  let cache = ''

  if (countryId || cityId || pid) {
    let str = ''

    if (cityId) {
      str = `c${cityId}`
    }

    if (countryId) {
      str = `g${countryId}`
    }

    if (pid) {
      str = `p${pid}`
    }

    cache = `${str}_${new Date().getTime()}`

    if (history_destination) {
      const history_destination_list = history_destination.split('__') || []
      let selected = false
      // 要排除掉id相等的情况
      for (let i = 0; i < history_destination_list.length; i++) {
        const item = history_destination_list[i]
        const [id] = item.split('_')
        const [cacheId] = cache.split('_')

        if (id === cacheId) {
          // 之前有，更新时间即可
          const newTimeItem = `${id}_${new Date().getTime()}`
          history_destination_list.splice(i, 1, newTimeItem)
          selected = true
          break
        }
      }

      if (selected) {
        cache = history_destination_list.join('__')
      } else if (history_destination_list.length > 9) {
        history_destination_list.pop()
        cache += `__${history_destination_list.join('__')}`
      } else {
        cache += `__${history_destination_list.join('__')}`
      }
    }
  }

  return cache
}

export function setData(source: any, data: any, key: string) {
  const old = source[key]
  const newVal = data[key]

  return newVal || old
}

export async function getReviews(payload: any, suggestion: any, $axios: any) {
  const {
    frontendId,
    cityId,
    countryId,
    pid,
    tagId
  } = payload || {}
  const { country_id: suggestCountryId, city_id: suggestCityId } = suggestion || {}

  let paramsCityId = cityId
  let paramsCountryId = countryId
  let r: any = {}

  if (suggestCountryId > 0 || suggestCityId > 0) {
    paramsCityId = suggestCityId
    paramsCountryId = suggestCountryId
  }

  const params = {
    frontend_id: frontendId,
    city_id: paramsCityId,
    country_id: paramsCountryId,
    poi_id: pid,
    tag_id: tagId
  }

  try {
    const { success, result } = await $axios.$get(apiNameMap.getSeoReviewList, {
      params,
      timeout: process.client ? 8000 : 3000
    })

    if (success && result) {
      r = result
    }
  } catch (error) {

  }

  return r
}

export async function commonGetDataFun(url: string, params: any = {}, $axios: any) {
  let r: any = {}

  if (!url) {
    return r
  }

  try {
    const { success, result } = await $axios.$get(url, {
      params,
      timeout: process.client ? 8000 : 2000
    })

    if (success && result) {
      r = result
    }
  } catch (error) {

  }

  return r
}

export function formatQuery(frontendId: string, query: any) {
  if (!frontendId || query?.is_manual_flag) {
    return query
  }

  const r: any = {
    frontend_id_list: frontendId
  }

  if (query.not_cal_destination) {
    r.not_cal_destination = 1
  }

  return r
}

export function genPageName(params: any, payload: any = {}) {
  const { tagId, pid } = parseParamsId(params)

  if (pid) {
    return payload?.poi || 'TNA_Explore'
  }

  if (tagId) {
    return payload?.tag || 'TNA_Theme'
  }

  return payload?.default || 'TNA_'
}

export const ratioMap = {
  '1:1': {
    styles: {
      paddingBottom: '100%'
    }
  },
  '4:3': {
    styles: {
      paddingBottom: '75%'
    }
  },
  '16:9': {
    styles: {
      paddingBottom: '56.25%'
    }
  }
}
