import { MutationTree, ActionTree } from 'vuex'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import { RootState } from '~/store'
import { getFormatData, getSection, sectionListMap } from '~/share/data/experience/sections'
import {
  getFormatData as attractions_getFormatData,
  getSection as attractions_getSection,
  sectionListMap as attractions_sectionListMap
} from '~/share/data/attractions/sections'
import { Experience } from '~/types/experience'
import apiNameMap from '~/share/data/apis'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import { bannerUrl, businessNames } from '~/share/data/experience/constant'
import { numberify, setWindowHistoryV2, formatHistoryDestination, setData } from '~/components/tour/tour-base/utils'

import UrlQuery = Experience.UrlQuery
import TreeNode = Experience.TreeNode

const SET_EXP_INTERNAL_LINK_OBJ = 'SET_EXP_INTERNAL_LINK_OBJ'
const SET_SECTIONS = 'SET_SECTIONS'
const SET_PAGE_INFO = 'SET_PAGE_INFO'
const SET_DESTINATION_TITLE = 'SET_DESTINATION_TITLE'
const SET_SECTION_LIST = 'SET_SECTION_LIST'
const CATEGORY_VISIBLE = 'CATEGORY_VISIBLE'
const CHANGE_DESTINATION_VISIBLE = 'CHANGE_DESTINATION_VISIBLE'
const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'
const SET_SEARCH_RESULT_V2 = 'SET_SEARCH_RESULT_V2'
const SET_URL_QUERY = 'SET_URL_QUERY'
const SET_FILTER_PRICE = 'SET_FILTER_PRICE'
const SET_STATUS = 'SET_STATUS'
const SET_SELECTED_RANGES = 'SET_SELECTED_RANGES'
const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'
const SET_BREADCRUMB = 'SET_BREADCRUMB'
const SET_PRE_INFO = 'SET_PRE_INFO'
const SET_CAN_AUTO_SCROLL_TO_TOP = 'SET_CAN_AUTO_SCROLL_TO_TOP'
const SET_ACTIVITIES = 'SET_ACTIVITIES'
const SET_FILTER_CONDITIONS = 'SET_FILTER_CONDITIONS'
const SET_CACHE_FILTER_CONDITIONS = 'SET_CACHE_FILTER_CONDITIONS'
const SET_URL_QUERY_PAYLOAD = 'SET_URL_QUERY_PAYLOAD'
const REPLACE_FILTER_CONDITIONS = 'REPLACE_FILTER_CONDITIONS'
const CLEAR_FILTER_CONDITIONS = 'CLEAR_FILTER_CONDITIONS'
const RECORD_COMPONENT_EXPAND = 'RECORD_COMPONENT_EXPAND'
const SET_SEO_DATA = 'SET_SEO_DATA'
const SET_ORIGIN_ROUTE_QUEYR = 'SET_ORIGIN_ROUTE_QUEYR'
const SET_INIT_STATUS = 'SET_INIT_STATUS'
const SET_TOUR_HOME_BASIC = 'SET_TOUR_HOME_BASIC'
const SET_TOUR_HOME_SECTIONS = 'SET_TOUR_HOME_SECTIONS'
const SET_FILTER_KEYWORDS = 'SET_FILTER_KEYWORDS'
const SET_FILTER_SORT = 'SET_FILTER_SORT'
const SET_NOT_CAL_DESTINATION = 'SET_NOT_CAL_DESTINATION'
const SET_UPDATE_FILTER_FLAG = 'SET_UPDATE_FILTER_FLAG'
const SET_THEME_LIST = 'SET_THEME_LIST'

export const state = () => ({
  expInternalLinkObj2state: null,
  sectionActData: {
    templateId: -1,
    isWifi: false,
    isSimCard: false,
    isWifiSimCard: false
  },
  sections: {},
  sectionList: [],
  cityId: undefined,
  countryId: undefined,
  frontendId: undefined,
  categoryVisible: false,
  currentCategory: {
    id: 0,
    name: '',
    deeplink: ''
  },
  destinationVisible: false,
  categoryTreeData: [],
  rangeData: [],
  searchResultActivities: [],
  useNewCardTemplate: true,
  travelServices: [],
  searchResultActivitiesCount: 0,
  urlQuery: {
    keyword: '',
    sort: '',
    range_id: '',
    country_ids: '',
    city_ids: '',
    frontend_id_list: '',
    page: 1,
    size: 24,
    start_date: '',
    price_from: '',
    price_to: '',
    instant: 0
  },
  searchStartTime: [],
  priceFrom: 0,
  priceTo: 0,
  searchPageStatus: 'default',
  selectedRanges: null,
  selectedCategory: null,
  pageType: '',
  bannerUrl: '',
  destinationTitle: '',
  categoryTitle: '',
  description: '',
  breadcrumb: {
    city: null,
    country: null,
    frontend_tree: []
  },
  canAutoScrollToTop: true,
  preInfo: {
    destination_title: '',
    redirect_url: '',
    isFromDestination: false
  },
  activities: [],
  filterConditions: {},
  cacheFilterConditions: {},
  filter: {},
  componentExpand: {},
  isOverLimitCount: false,
  urlQueryPayload: {},
  originRouterQuery: {},
  is_first_request: true,
  seoData: {},
  tourHomePageBasic: {},
  tourHomePageSections: [],
  search_placeholder: '',
  current_destination: '',
  suggestion: {},
  filterKeywords: '',
  filterSort: '',
  notCalDestination: 0,
  updateFilter: true,
  isAttractions: 0, // 是否是 Attractions
  themeData: {},
  stateClearFlag: false,
  activity_filter_values: {}
})
const expandNode: any = {}

function initTreeItem(type: 'range' | 'country' | 'city' | 'category', id: number, title: string, checked: boolean, disable = false, show = true) {
  const expand = !!(expandNode[type] && expandNode[type][id])
  const item: TreeNode = {
    type,
    id,
    show,
    title, // 节点标题
    expand, // 有子节点时，是否展开子节点
    disabled: disable, // 禁用节点
    checked: disable ? false : checked, // 节点是否选中，单选模式下只能有一个节点被选中
    children: null
  }
  return item
}

function getFilterCount(obj: any) {
  const limitedSelected = 20
  let count = 0

  for (const item of Object.values(obj)) {
    count += (item as any).count || 0
  }

  return count >= limitedSelected
}

export function formatRangeData(range: any, checkedRanges: any) {
  const rangeIds = (checkedRanges.rangeIds || '').split(',')
  const countryIds = (checkedRanges.countryIds || '').split(',')
  const cityIds = (checkedRanges.cityIds || '').split(',')
  const a = range.map((val: any) => {
    let rangeDisable = true
    let rangeChecked = rangeIds.includes(String(val.range_id))
    const rangeItem: any = initTreeItem('range', val.range_id, val.range_name, rangeChecked)

    // 遍历国家
    rangeItem.children = (val.countries || []).map((country: any) => {
      let countryDisable = true
      let countryChecked = countryIds.includes(String(country.country_id))
      rangeChecked && (countryChecked = true)
      const countryItem: any = initTreeItem('country', country.country_id, country.country_name, countryChecked)

      // 遍历城市
      countryItem.children = (country.cities || []).map((city: any) => {
        let cityChecked = cityIds.includes(String(city.city_id))

        countryIds.includes(String(country.country_id)) && (cityChecked = true)

        countryChecked && (cityChecked = true)
        const cityItem: any = initTreeItem('city', city.city_id, city.city_name, cityChecked, city.disable)

        cityItem.disabled ? countryChecked = false : countryDisable = false

        return cityItem
      })
      countryItem.disabled = countryDisable
      if (countryItem.disabled) {
        rangeChecked = false
        countryItem.checked = false
      } else {
        rangeDisable = false
      }
      countryItem.checked = countryChecked

      return countryItem
    })
    rangeItem.disabled = rangeDisable
    rangeItem.disabled && (rangeItem.checked = false)
    rangeItem.checked = rangeChecked
    return rangeItem
  })
  return a
}

function formatCategoryTreeData(data: any, checkedCategoryIds: string) {
  const a = data.map((val: any) => {
    const checked = checkedCategoryIds.split(',').includes(String(val.id))
    const o: TreeNode = initTreeItem('category', val.id, val.name, checked, !val.enable)

    o.children = val.children ? val.children.map((child: any) => {
      let childChecked = checkedCategoryIds.split(',').includes(String(child.id))
      checked && (childChecked = true)
      return initTreeItem('category', child.id, child.name, childChecked, !child.enable)
    }) : null // 子节点
    return o
  })
  return a
}

export const mutations: MutationTree<Experience.State> = {
  [SET_EXP_INTERNAL_LINK_OBJ](state, data) {
    state.expInternalLinkObj2state = data
  },
  setClearFlag(state, flag) {
    state.stateClearFlag = flag
  },
  sectionActDataMutations(state, data) {
    const { sectionActData } = state
    for (const key in data) {
      sectionActData[key] = data[key]
    }
  },
  [SET_ACTIVITIES](state, data) {
    const { page } = state.urlQuery
    if (page === 1) {
      state.activities = data
    } else {
      state.activities = [...state.activities, ...data]
    }
  },
  [SET_PAGE_INFO](state, data) {
    data.isAttractions && (state.isAttractions = Number(data.isAttractions))
    data.cityId && (state.cityId = Number(data.cityId))
    data.countryId && (state.countryId = Number(data.countryId))
    data.frontendId && (state.frontendId = Number(data.frontendId))
    data.type && (state.pageType = data.type)
  },
  [SET_DESTINATION_TITLE](state, data) {
    state.destinationTitle = data.destinationTitle
    state.categoryTitle = data.categoryTitle
    state.description = data.description
    state.bannerUrl = data.bannerUrl || bannerUrl
  },
  [SET_SECTIONS](state, data) {
    const sections = [].concat(data)

    // 这里每次都只能运行一次，为啥要用数组循环？
    sections.forEach((v) => {
      const { meta: { name } } = v

      if (!name) {
        return
      }

      state.sections = {
        ...(state.sections || {}),
        [name]: v
      }
      // state.sections[name] = v
    })
  },
  [SET_SECTION_LIST](state, sectionList) {
    state.sectionList = sectionList
  },
  [CATEGORY_VISIBLE](state, visible) {
    state.categoryVisible = visible
  },
  [CHANGE_DESTINATION_VISIBLE](state, visible) {
    state.destinationVisible = visible
  },
  [SET_SEARCH_RESULT](state, data) {
    const categoryIds = state.urlQuery.frontend_id_list || ''
    const { range_id: rangeIds, country_ids: countryIds, city_ids: cityIds } = state.urlQuery
    state.categoryTreeData = formatCategoryTreeData(data.categoryTreeData, categoryIds)
    state.rangeData = formatRangeData(data.rangeData, { rangeIds, countryIds, cityIds })
    state.searchResultActivities = data.searchResultActivities
    state.travelServices = data.travelServices
    state.searchResultActivitiesCount = data.searchResultActivitiesCount
    state.searchStartTime = data.searchStartTime
    state.useNewCardTemplate = data.useNewCardTemplate
    state.resultActivitiesCardRatio = data.resultActivitiesCardRatio
    state.filter = data.filter || {}
  },
  [SET_SEARCH_RESULT_V2](state, data) {
    state.suggestion = setData(state, data, 'suggestion')
    state.search_placeholder = setData(state, data, 'search_placeholder')
    state.resultActivitiesCardRatio = setData(state, data, 'resultActivitiesCardRatio')
    state.current_destination = setData(state, data, 'current_destination')
    state.activity_filter_values = setData(state, data, 'activity_filter_values')

    state.searchResultActivities = data.searchResultActivities
    state.searchResultActivitiesCount = data.searchResultActivitiesCount
    // 这里比较特殊， filter可能会不返回，不返回就就不需要更新filter
    state.updateFilter && (state.filter = data.filter)
  },
  [SET_URL_QUERY](state, d) {
    const query = JSON.parse(JSON.stringify(state.urlQuery))
    const data = Object.assign(query, d)
    state.urlQuery.keyword = data.keyword
    state.urlQuery.keyword_id = data.keyword_id
    state.urlQuery.keywords = data.keywords
    state.urlQuery.city_id = data.city_id
    state.urlQuery.country_id = data.country_id
    state.urlQuery.sort = data.sort || ''
    state.urlQuery.range_id = data.range_id || ''
    state.urlQuery.country_ids = data.country_ids || ''
    state.urlQuery.city_ids = data.city_ids || ''
    state.urlQuery.frontend_id_list = data.frontend_id_list || ''
    state.urlQuery.page = Number(data.page) || 1
    state.urlQuery.size = Number(data.size) || 24
    state.urlQuery.instant = data.instant || 0
    state.urlQuery.start_date = data.start_date || ''
    state.urlQuery.price_from = data.price_from || ''
    state.urlQuery.price_to = data.price_to || ''
    state.urlQuery.l1_front_id = data.l1_front_id || ''
  },
  [SET_FILTER_PRICE](state, data) {
    state.priceFrom = Number(data.priceFrom)
    state.priceTo = Number(data.priceTo)
  },
  [SET_STATUS](state, status) {
    state.searchPageStatus = status
  },
  [SET_SELECTED_RANGES](state, data) {
    state.selectedRanges = data
  },
  [SET_SELECTED_CATEGORY](state, data) {
    state.selectedCategory = data
  },
  [SET_BREADCRUMB](state, data) {
    state.breadcrumb = data
  },
  [SET_PRE_INFO](state, data) {
    state.preInfo = data
  },
  [SET_CAN_AUTO_SCROLL_TO_TOP](state, data) {
    state.canAutoScrollToTop = data
  },
  [SET_FILTER_CONDITIONS](state, { key, value }) {
    const initState = state.filterConditions
    // 这里其实设置一个delete函数更加直观，没有理解成本(TODO)
    if (value) {
      initState[key] = value
    } else {
      key && (delete initState[key])
    }

    state.filterConditions = { ...initState }
    state.isOverLimitCount = getFilterCount(state.filterConditions)
  },
  [CLEAR_FILTER_CONDITIONS](state, data) {
    if (data?.clearData) {
      // 清空部分条件
      merge(state.filterConditions, data.clearData)
      return
    }
    state.filterConditions = {}
    state.isOverLimitCount = false
  },
  [RECORD_COMPONENT_EXPAND](state, { key, value }) {
    const source = { ...state.componentExpand }
    source[key] = value
    state.componentExpand = { ...source }
  },
  [SET_URL_QUERY_PAYLOAD](state, payload) {
    state.urlQueryPayload = payload
  },
  [SET_CACHE_FILTER_CONDITIONS](state, data) {
    state.cacheFilterConditions = data
  },
  [REPLACE_FILTER_CONDITIONS](state, data) {
    state.filterConditions = data
  },
  [SET_SEO_DATA](state, data) {
    state.seoData = data
  },
  [SET_ORIGIN_ROUTE_QUEYR](state, query) {
    state.originRouterQuery = query
  },
  [SET_INIT_STATUS](state, flag) {
    state.is_first_request = flag
  },
  [SET_TOUR_HOME_BASIC](state, basic) {
    state.tourHomePageBasic = basic
  },
  [SET_TOUR_HOME_SECTIONS](state, sections) {
    state.tourHomePageSections = sections
  },
  [SET_FILTER_KEYWORDS](state, keywords) {
    state.filterKeywords = keywords
  },
  [SET_UPDATE_FILTER_FLAG](state, flag) {
    state.updateFilter = flag
  },
  [SET_FILTER_SORT](state, sort) {
    state.filterSort = sort
  },
  [SET_NOT_CAL_DESTINATION](state, num) {
    state.notCalDestination = num
  },
  [SET_THEME_LIST](state, data = []) {
    state.themeData = data
  }
}

export const actions: ActionTree<Experience.State, RootState> = {
  setSectionList({ commit }, type) {
    commit(SET_SECTION_LIST, sectionListMap[type] || [])
  },

  changeCategoryVisible({ commit }, visible: boolean) {
    commit('CATEGORY_VISIBLE', visible)
  },

  changeDestinationVisible({ commit }, visible: boolean) {
    commit('CHANGE_DESTINATION_VISIBLE', visible)
  },

  async createSection({ commit, state }, config) {
    const { name, dataType, sync, src, required, validate } = config
    const { cityId, countryId, frontendId } = state

    if (!isEmpty(state.sections[name])) {
      return true
    }

    const formatData = {
      bottom_button_deep_link: getFormatData(config.bottom_button_deep_link, state),
      title: getFormatData(config.title, state)
    }

    // 异步楼层
    if (!sync) {
      commit(SET_SECTIONS, getSection({
        name,
        dataType: dataType || name,
        ...config,
        ...formatData
      }))

      return true
    }

    // 同步楼层
    const params = getFormatData(config.params, state) || {
      city_id: cityId,
      country_id: countryId,
      frontend_id: frontendId
    }

    try {
      const { success, result } = await this.$axios.$get(src, {
        params,
        timeout: required ? 0 : 3000
      })
      if (!success || !result) {
        return !required
      }

      if (validate && !validate(result)) {
        return !required
      }

      commit(SET_SECTIONS, getSection({
        name,
        dataType: dataType || name,
        ...config,
        data: result,
        ...formatData
      }))
      return true
    } catch (error) {
      return !required
    }
  },

  getSectionHeader({ commit, state }, config) {
    const {
      categoryTitle,
      description,
      destinationTitle,
      bannerUrl
    } = state

    const ExperienceHeader = getSection({
      name: config.name,
      dataType: businessNames.ExperienceHeader,
      data: {
        backgroundUrl: bannerUrl,
        vertical: {
          title: categoryTitle,
          deepLink: '/v1/experiencesrv/home/filter/category'
        },
        description,
        destination: {
          title: destinationTitle,
          deepLink: '/v1/experiencesrv/home/filter/destination'
        },
        search: {
          placeholder: ''
        }
      }
    })

    commit(SET_SECTIONS, ExperienceHeader)

    return true
  },
  async fetchHeaderInfo({ commit, state }, params) {
    try {
      const { success, result } = await this.$axios
        .$get(state.isAttractions ? apiNameMap.getAttractionsMainBanner : apiNameMap.getExperienceMainBanner, {
          params: {
            frontend_id: params.frontendId,
            city_id: params.cityId,
            country_id: params.countryId
          },
          throwError: true
        })

      if (success && result) {
        const {
          destination_title,
          description,
          category_title,
          banner_url
        } = result

        commit(SET_DESTINATION_TITLE, {
          destinationTitle: destination_title,
          categoryTitle: category_title,
          description,
          bannerUrl: banner_url
        })
      }
    } catch (e) {

    }
  },

  setPageInfo({ commit }, { frontendId, cityId, countryId, type, isAttractions }) {
    commit(SET_PAGE_INFO, {
      isAttractions,
      cityId,
      countryId,
      frontendId,
      type
    })
  },

  // 修改搜索参数
  async setUrlQuery({ commit, dispatch, state }, data) {
    const method = data.method || 'getSearchResult'
    commit('SET_URL_QUERY', data)
    await dispatch(method, { ...state.urlQuery, ...state.urlQueryPayload })
    // 去除吸顶的交互，先留着
    // dispatch('scrollToListTop')
    commit('SET_CAN_AUTO_SCROLL_TO_TOP', true)
  },

  // 拆解 URL 参数
  formatQuery({ commit }, query: any) {
    commit('SET_URL_QUERY', {
      ...query
    })

    const o: any = {}
    // 剔除无值的参数
    Object.keys(query).forEach((item: string) => {
      if (query[item]) {
        o[item] = query[item]
      }
    })

    return o
  },
  // 将搜索参数置于 URL 中
  setWindowHistory({ state }) {
    if (process.client) {
      const urlQuery: any = state.urlQuery
      const payloadQuery: any = state.urlQueryPayload
      const queryList: any = []
      const urlQueryKeys: string[] = Object.keys(urlQuery)
      const payloadQueryKeys: string[] = Object.keys(payloadQuery)

      urlQueryKeys.forEach((key: string) => {
        // 剔除无值的参数，并且排除掉 page = 1 的情况
        if (urlQuery[key] && !(key === 'page' && urlQuery[key] === 1)) {
          queryList.push(`${key}=${urlQuery[key]}`)
        }
      })

      payloadQueryKeys.forEach((key: string) => {
        if (!urlQueryKeys.includes(key)) {
          queryList.push(`${key}=${payloadQuery[key]}`)
        }
      })

      const url = '?' + queryList.join('&')
      window.history.replaceState(null, '', url)
    }
  },
  filterQuery({ state, commit }, query: any) {
    const payloadQuery = state.urlQueryPayload
    const mergeQuery = { ...payloadQuery, ...query }
    const o: any = {}

    // 剔除无值的参数
    Object.keys(mergeQuery).forEach((item: string) => {
      if (mergeQuery[item]) {
        if (item === 'history_destinations') {
          o[item] = formatHistoryDestination(mergeQuery[item])
        } else {
          o[item] = mergeQuery[item]
        }
      }
    })

    // 如果有city_id | country_id | poi_id 则要删除history_destinations
    if (o.country_id || o.city_id || o.poi_id) {
      delete o.history_destinations
    }

    // 如果有清除操作，就清空类目
    if (o.is_clear_flag || state.stateClearFlag) {
      delete o.frontend_id_list
      delete o.is_clear_flag
    }

    o.init_filter = 1
    o.size = 24

    delete o.is_manual_flag

    commit(SET_URL_QUERY_PAYLOAD, o)

    return o
  },
  /**
   *  列表页服务端请求接口（非常重要的接口）
   *  请求内容包含： seo、internal link、themeList、activities
   * **/
  async getVerticalPageInfo({ commit, dispatch }, query: any) {
    let requestSuccessFlag: boolean = false
    let requestErrorCode: string = ''
    const r: any = {} // 返回出去的数据
    const params = await dispatch('filterQuery', numberify(query))

    try {
      const { success, result, error } = await this.$axios.$post(
        apiNameMap.searchVerticalPageInfo,
        params,
        {
          timeout: 3000
        }
      )

      if (success && result) {
        requestSuccessFlag = true

        const {
          seo = {}, // seo信息
          activity_list, // 列表页
          set_page_param, // suggest
          header, // 列表页header信息
          search_placeholder,
          current_destination,
          internal_link,
          trending_sights,
          breadcrumb,
          activity_filter_values
        } = result

        r.seoInfo = seo
        r.suggestion = set_page_param
        r.headerInfo = header

        // 可能是异步，如果有，就赋值，如果没有，就客户端异步请求
        if (internal_link) {
          r.internal_link = internal_link
        }

        if (trending_sights) {
          r.trending_sights = trending_sights
        }

        commit('SET_SEARCH_RESULT_V2', {
          searchResultActivities: activity_list.activities.items || [],
          resultActivitiesCardRatio: activity_list.activities.ratio || '',
          searchResultActivitiesCount: activity_list.total || 0,
          activity_filter_values: activity_filter_values || {},
          filter: activity_list.filter || {},
          search_placeholder: search_placeholder || '',
          suggestion: set_page_param || {},
          current_destination: current_destination || ''
        })

        // 设置seo
        commit(SET_SEO_DATA, seo)

        // 设置面包屑
        if (breadcrumb) {
          commit(SET_BREADCRUMB, breadcrumb)
        }
      } else if (error) {
        requestErrorCode = (error as any)?.code
      }
    } catch (error) {
      requestErrorCode = (error as any)?.code
    }

    return {
      requestSuccessFlag,
      r,
      requestErrorCode
    }
  },
  // vertical重构模块，获取filter页面数据(客户端请求)
  async getSearchResultV2({ state, commit, dispatch }, query: any) {
    commit('SET_STATUS', 'fetching')

    const params = await dispatch('filterQuery', numberify(query))

    try {
      const { success, result } = await this.$axios.$post(
        apiNameMap.searchVerticalActivityResult,
        params
      )

      if (success && result) {
        commit('SET_SEARCH_RESULT_V2', {
          searchResultActivities: result.activities.items || [],
          searchResultActivitiesCount: result.total || 0,
          filter: result.filter
        })

        if (state.stateClearFlag) {
          params.is_clear_flag = 1
        }

        params.is_manual_flag = 1

        setWindowHistoryV2(params)
      }

      const a = setTimeout(() => {
        commit('SET_STATUS', 'success')
        clearTimeout(a)
      }, 200)
    } catch (error) {
      commit('SET_STATUS', 'fail')
    }
  },
  // 请求搜索结果
  async getSearchResult({ commit, dispatch, state }, query: UrlQuery) {
    commit('SET_STATUS', 'fetching')
    let showLoading = false
    if (process.client) {
      const b = setTimeout(() => {
        showLoading = true
        clearTimeout(b)
      }, 800)
    }

    const params = await dispatch('formatQuery', query)
    let api = state.isAttractions ? apiNameMap.attractionsSearch : apiNameMap.experiencesrvSearch
    if (state.pageType !== 'search') {
      api = state.isAttractions ? apiNameMap.getAttractionsCategoryActivityNew : apiNameMap.getExperienceCategoryActivityNew

      if (params.useOldUrl) {
        // 兼容新旧接口，不然旧的搜索结果页，需要修改大量参数，希望ab打过旧的
        api = apiNameMap.getExperienceCategoryActivity
        delete params.useOldUrl
      }

      if (params.page) {
        params.start = params.page
        delete params.page
      }
    }

    if (state.pageType === 'mfo') {
      api = apiNameMap.experienceMFOActivityList
    }

    !params.size && (params.size = 24)

    if (state.countryId) {
      if (params.country_ids && params.city_ids) {
        params.country_ids = ''
      } else if (!params.country_ids && !params.city_ids) {
        params.country_ids = String(state.countryId)
      }
    } else if (state.cityId) {
      if (
        state.originRouterQuery.city_ids &&
        !state.originRouterQuery.city_ids.includes(String(state.cityId)) &&
        params?.city_ids?.includes(String(state.cityId)) &&
        state.is_first_request
      ) {
        params.city_ids = state.originRouterQuery.city_ids
      }
      // 只检查一次
      commit('SET_INIT_STATUS', false)
    }

    try {
      const res = await this.$axios.$get(api, { params })

      if (res.success && res.result) {
        commit('SET_SEARCH_RESULT', {
          rangeData: res.result.ranges || [],
          categoryTreeData: res.result.frontend_tree_mapping || [],
          searchResultActivities: (res.result?.activities_v2?.items ?? res.result?.activities) || [],
          useNewCardTemplate: !!res.result?.activities_v2?.items,
          resultActivitiesCardRatio: res.result?.activities_v2?.ratio || '',
          travelServices: res.result.travel_services || [],
          searchResultActivitiesCount: res.result.total || 0,
          searchStartTime: res.result.start_time || [],
          filter: res.result?.filter || {}
        })
        commit('SET_ACTIVITIES', (res.result?.activities_v2?.items ?? res.result?.activities) || [])
        commit('SET_FILTER_PRICE', {
          priceFrom: (res.result.price || {}).from || 0,
          priceTo: (res.result.price || {}).to || 0
        })
        dispatch('setWindowHistory')

        // server端直接设置为success！
        if (showLoading || !process.client) {
          commit('SET_STATUS', 'success')
        } else {
          const a = setTimeout(() => {
            commit('SET_STATUS', 'success')
            clearTimeout(a)
          }, 500)
        }
      } else {
        commit('SET_STATUS', 'failure')
        return null
      }
    } catch (e) {
      commit('SET_STATUS', 'failure')
      return null
    }
  },

  scrollToListTop({ state }) {
    if (process.client && state.canAutoScrollToTop) {
      const layoutHeaderHeight = -120
      const listContainer = window.document.querySelector('.js-category-tree-and-activity-list') as HTMLElement
      const scrollTop = nodeScrollTop(listContainer) + layoutHeaderHeight
      scroll2({ scrollY: scrollTop })
    }
  },

  async getDestination({ state }) {
    const { cityId, countryId, frontendId } = state

    const res = await this.$axios.$get(state.isAttractions ? apiNameMap.getAttractionsDestination : apiNameMap.getExperienceDestination, {
      params: {
        city_id: cityId,
        country_id: countryId,
        frontend_id: frontendId
      }
    })

    return res
  },

  async getSearchSuggestions({ state }, options = {}) {
    const { cityId, countryId, frontendId } = state

    const { keyword, ...rest } = options

    const res = await this.$axios.$get(state.isAttractions ? apiNameMap.attractionsSuggest : apiNameMap.experienceSearch, {
      params: {
        city_id: cityId,
        country_id: countryId,
        frontend_id: frontendId,
        query: keyword,
        limit: 20,
        htl_gry: 1
      },
      ...rest
    })

    return res
  },

  setSelectedRanges({ commit }, data: any) {
    commit('SET_SELECTED_RANGES', data)
  },

  setSelectedCategory({ commit }, data: any) {
    commit('SET_SELECTED_CATEGORY', data)
  },
  setExpandNode(_ctx, data: any) {
    const type = data.type
    const id = data.id
    if (expandNode[type]) {
      if (expandNode[type][id]) {
        delete expandNode[type][id]
      } else {
        expandNode[type][id] = data
      }
    } else {
      expandNode[type] = {}
      expandNode[type][id] = data
    }
  },
  async getBreadcrumb({ commit, state }) {
    const { cityId, countryId, frontendId } = state

    try {
      const { success, result } = await this.$axios.$get(state.isAttractions ? apiNameMap.getAttractionsBreadcrumb : apiNameMap.getBreadcrumb, {
        params: {
          city_id: cityId,
          country_id: countryId,
          frontend_id: frontendId
        }
      })

      if (success && result) {
        commit(SET_BREADCRUMB, result)
      }
    } catch (e) {

    }
  },
  setCanAutoScrollToTop({ commit }, data) {
    commit('SET_CAN_AUTO_SCROLL_TO_TOP', data)
  },
  async getPreInfo({ commit, state }, data = {}) {
    const {
      frontendId,
      cityId,
      countryId
    } = data

    const params: any = {
      frontend_id: frontendId
    }

    if (cityId) {
      params.city_id = cityId
    }

    if (countryId) {
      params.country_id = cityId
    }

    const { success, result } = await this.$axios.$get(state.isAttractions ? apiNameMap.getAttractionsPreInfo : apiNameMap.getExperiencePreInfo, { params })

    if (success && result) {
      commit(SET_PRE_INFO, { ...result, isFromDestination: !!(cityId || countryId) })
    }
  },
  async getSeoData({ commit }, data = {}) {
    let seoResult: any = {}

    try {
      const {
        apiData,
        frontendId,
        cityId,
        countryId
      } = data

      if (apiData) {
        if (apiData?.success) {
          seoResult = apiData?.result || {}
        } else {
          seoResult = apiData
        }

        commit('SET_SEO_DATA', seoResult)
      } else {
        const params: any = {}
        if (cityId) {
          params.city_id = cityId
        }

        if (countryId) {
          params.country_id = countryId
        }

        if (frontendId) {
          params.frontend_id = frontendId
        }

        const { success, result } = await this.$axios.$get(apiNameMap.getSeoData, { params })

        if (success && result) {
          seoResult = result
          commit('SET_SEO_DATA', result)
        }
      }
    } catch (error) {

    }

    return seoResult
  },
  async getThemeList({ commit, state }, data = {}) {
    let themeData = {
      list: [],
      section_title: ''
    }

    try {
      const { country_id: suggestCountryId, city_id: suggestCityId } = state.suggestion || {}
      const { cityId: city_id = '', countryId: country_id = '', apiData = null } = data

      if (apiData) {
        if (apiData?.success) {
          themeData.list = apiData.result.list || []
          themeData.section_title = apiData.result.section_title || ''
        } else {
          themeData = apiData
        }
        commit('SET_THEME_LIST', themeData)
      } else {
        const { result, success } = await this.$axios.$get(apiNameMap.getThemeList, {
          params: {
            country_id: country_id || suggestCountryId,
            city_id: city_id || suggestCityId
          },
          timeout: 2000
        })

        if (success && result) {
          themeData = result
          commit('SET_THEME_LIST', themeData)
        }
      }
    } catch (error) {
    }

    return themeData
  },
  async getTourHomePageInfo({ commit }, payload: any) {
    const params: any = { history_destinations: [] }
    const { history_destinations = '' } = payload

    params.history_destinations = formatHistoryDestination(history_destinations)

    const { success, result } = await this.$axios.$post(
      apiNameMap.getTourHomePageInfo,
      params
    )

    if (success && result) {
      const { basic = {}, page = {} } = result || {}
      commit('SET_TOUR_HOME_BASIC', basic)
      commit('SET_TOUR_HOME_SECTIONS', page?.body?.sections || [])
    }
  },
  // Attractions 数据源拆分
  attractions_getSectionHeader({ commit, state }, config) {
    const {
      categoryTitle,
      description,
      destinationTitle,
      bannerUrl
    } = state

    const ExperienceHeader = attractions_getSection({
      name: config.name,
      dataType: businessNames.ExperienceHeader,
      data: {
        backgroundUrl: bannerUrl,
        vertical: {
          title: categoryTitle,
          deepLink: '/v1/experiencesrv/home/filter/category'
        },
        description,
        destination: {
          title: destinationTitle,
          deepLink: '/v1/experiencesrv/home/filter/destination'
        },
        search: {
          placeholder: ''
        }
      }
    })

    commit(SET_SECTIONS, ExperienceHeader)

    return true
  },

  attractions_setSectionList({ commit }, type) {
    commit(SET_SECTION_LIST, attractions_sectionListMap[type] || [])
  },

  async attractions_createSection({ commit, state }, config) {
    const { name, dataType, sync, src, required, validate } = config
    const { cityId, countryId, frontendId } = state

    if (!isEmpty(state.sections[name])) {
      return true
    }

    const formatData = {
      bottom_button_deep_link: attractions_getFormatData(config.bottom_button_deep_link, state),
      title: attractions_getFormatData(config.title, state)
    }

    // 异步楼层
    if (!sync) {
      commit(SET_SECTIONS, attractions_getSection({
        name,
        dataType: dataType || name,
        ...config,
        ...formatData
      }))

      return true
    }

    // 同步楼层
    const params = attractions_getFormatData(config.params, state) || {
      city_id: cityId,
      country_id: countryId,
      frontend_id: frontendId
    }

    try {
      const { success, result } = await this.$axios.$get(src, {
        params,
        timeout: required ? 0 : 3000
      })
      if (!success || !result) {
        return !required
      }

      if (validate && !validate(result)) {
        return !required
      }

      commit(SET_SECTIONS, attractions_getSection({
        name,
        dataType: dataType || name,
        ...config,
        data: result,
        ...formatData
      }))
      return true
    } catch (error) {
      return !required
    }
  }
}
