import { VueConstructor } from 'vue'
import Message from './message.vue'
import { errorTipFactory, ErrorTipOptions } from './error-tip'
import { messageTipFactory, MessageTipOptions } from './message-tips'

type ConfirmCallback = (confirm: boolean) => void

interface Options {
  visible?: boolean
  title?: string
  content?: string
  btnYes?: string
  btnNo?: string
  confirmCallback?: ConfirmCallback
}

const install = (Vue: VueConstructor) => {
  const messageInstance: any[] = []
  if ((Vue.prototype as any).$message) {
    return
  }

  const noop: ConfirmCallback = () => {}
  const defaultOptions: Options = {
    visible: false,
    title: '',
    content: '',
    btnYes: '',
    btnNo: '',
    confirmCallback: noop
  }
  const MessageConstructor = Vue.extend(Message)

  MessageConstructor.prototype.close = function close() {
    this.visible = false
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.$destroy()
  }

  const message = (userOptions: Options) => {
    if (Vue.prototype.$isServer) { return null }

    const options = { ...defaultOptions, ...userOptions }
    const instance = new MessageConstructor({
      el: document.createElement('div'),
      data: options
    })

    // Change context
    // instance.$root = $parent.$root
    // instance.$parent = $parent

    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
      instance.visible = true
      messageInstance.push(instance)
    })

    return instance
  }

  function alert(userOptions: Options, callback = noop) {
    const options = {
      confirmCallback: callback,
      ...userOptions
    }

    return message(options)
  }

  function confirm(userOptions: Options, callback = noop) {
    const options = {
      confirmCallback: callback,
      ...userOptions
    }

    return message(options)
  }

  // @TODO
  function prompt() {}

  function errorTip(userOptions: ErrorTipOptions) {
    return errorTipFactory(Vue, userOptions)
  }

  function messageTip(userOptions: MessageTipOptions) {
    return messageTipFactory(Vue, userOptions)
  }

  // 关闭所有现有弹窗
  function closeAll() {
    messageInstance.forEach(instance => instance.close())
  }

  Object.defineProperty(Vue.prototype, '$message', {
    get() {
      return {
        alert: alert.bind(this),
        confirm: confirm.bind(this),
        prompt: prompt.bind(this),
        errorTip: errorTip.bind(this),
        messageTip: messageTip.bind(this),
        closeAll: closeAll.bind(this)
      }
    }
  })
}

export default {
  install
}
