import { cms } from './types'

export const types: cms.types = {
  // 通用配置, 特殊处理类型
  TYPE_GENERAL: 999,

  // 当季优选主题
  TYPE_THEME_SEASONAL: 111,

  // 首页Banner
  TYPE_HOME_BANNER: 101,

  // 首页热门城市设置
  TYPE_HOME_DESTINATION: 102,

  // 首页的热卖活动
  TYPE_HOME_ACTIVITIES: 103,

  // 活动页帮助内容
  TYPE_ACTIVITY_HELP_CONTENT: 104,

  // 活动的MAP
  TYPE_ACTIVITY_HELP_CONTENT_MAP: 1041,

  // brand页面配置数据
  TYPE_BRAND: 105,

  // campaign页面配置的新数据
  TYPE_CAMPAIGN: 1051,

  // 城市页面的主题
  TYPE_CITY_THEME: 121,

  // destination page template theme
  TYPE_CITY_TEMPLATE_THEME: 122,

  // 帮助中心的faq
  TYPE_FAQ: 401,

  // About Us NewsRoom
  TYPE_IN_THE_NEWS: 402,
  TYPE_NEWS_ROOM: 414,

  // About Us Join Us
  TYPE_JOIN_US: 403,

  // city page articles
  TYPE_CITY_ARTICLES: 123,

  // city page single articles
  TYPE_CONTENT_ARTICLE: 1231,

  // 活动页 特殊id seo跳转
  TYPE_ACTIVITY_SEO_LINK: 501,

  // new-web的一些配置参数
  TYPE_WEB_CONFIG: 502,

  // klook notice
  TYPE_KLOOK_NOTICE: 405,

  // klook trems
  TYPE_KLOOK_TERMS: 406,

  // 首页banner ad
  TYPE_HOME_BANNER_AD: 106,

  // 目的地页banner ad
  TYPE_CITY_BANNER_AD: 126,
  // VN policy
  TYPE_KLOOK_VN_POLICY: 413,

  // klook platform notice
  TYPE_KLOOK_PLATFORM_NOTICE: 407,

  // 铁路landing页面
  TYPE__EUROPE_LAND: 437,

  // 中铁landing页面
  TYPE_CHINA_LAND: 447
}

export const platform: cms.platforms = {
  ALL: 15,
  ANDROID: 1,
  IOS: 2,
  APP: 3,
  MOBILE: 4,
  WEB: 8
}

export const CMSGenericItemCacheKey = 'CMS_GENERIC_ITEM_CACHE'
