import isEqual from 'lodash/isEqual'
import upperFirst from 'lodash/upperFirst'

const TYPE_PROVIDE = 'provide'
const TYPE_INJECT = 'inject'
// 不需要解析成埋点的属性
const excludesList = [TYPE_INJECT, 'custom']

function getParent(vnode) {
  return vnode.$parent || vnode.context.$parent
}

function getProvideData(vnode, name) {
  let parent = getParent(vnode)

  while (parent) {
    // 获取v-track:provide指令
    const directives = parent.$vnode?.data?.directives

    if (directives && directives.length) {
      const trackDirectiveName = `v-track:${TYPE_PROVIDE}:${name}`
      const trackDirective = directives.find(v => v.rawName.startsWith(trackDirectiveName))

      if (trackDirective) {
        return trackDirective.value
      }
    }

    parent = getParent(parent)
  }
}

function bindClientTrack(
  el,
  binding,
  vnode
) {
  const { type } = parseBindingArg(binding)
  const spmName = `spm${upperFirst(type)}`

  // 已经有埋点不处理
  if (el.dataset[spmName]) { return }

  const trackInfo = getSpmTrackInfo(binding, vnode)

  if (!trackInfo) { return }

  el.setAttribute(trackInfo.name, trackInfo.track)
}

function parseBindingArg(binding) {
  const { arg } = binding

  const argArray = arg?.split(':')
  if (!argArray || !argArray.length) { return }

  const [type, name = ''] = argArray

  return {
    type,
    name
  }
}

function getSpmTrackInfo(binding, vnode) {
  let { value } = binding
  const spmInfo = parseBindingArg(binding)

  if (!spmInfo) { return }

  const { type } = spmInfo

  // provide用来传递数据，不埋到dom上
  // if (type === TYPE_PROVIDE) { return }

  let name = spmInfo.name

  // 没有name可以通过inject去继承一个
  if (!value && !name) {
    return
  }

  const spmName = `data-spm-${type}`

  // 只有一个name的埋点
  if (!value) {
    return {
      name: spmName,
      track: name
    }
  }

  if (typeof value === 'string') {
    return {
      name: spmName,
      track: name + '?' + value
    }
  }

  // 继承埋点数据
  const { inject, custom } = value

  // inject可以继承provide的name
  if (!name && !inject) { return }

  if (inject) {
    const provideValue = getProvideData(vnode, inject)

    if (!provideValue?.name && !name) { return }

    if (provideValue) {
      const { name: provideName, ...rest } = provideValue

      /**
       * * 默认处理
       *    * 埋点名称使用 : 拼接（楼层的埋点方式）
       *    * 埋点数据合并
       * * 可以使用custom自定义埋点数据
       */
      value = custom ? custom(provideValue) : Object.assign({}, rest, value)
      name = `${provideName}:${name || ''}`
    }
  }

  const spmString = Object.keys(value).reduce((acc, v) => {
    let prop = value[v]
    if (prop == null) {
      return acc
    }

    if (excludesList.includes(v)) { return acc }

    if (v === 'ext') {
      try {
        Object.keys(prop, (k) => {
          const val = prop[k]

          if (val.startsWith('http')) {
            prop[k] = encodeURIComponent(val)
          }
        })

        prop = JSON.stringify(prop)
      } catch (e) {
        return acc
      }
    }

    const mark = acc.includes('?') ? '&' : '?'

    return `${acc}${mark}${v}=${prop}`
  }, name)

  return {
    name: spmName,
    track: spmString
  }
}

export const clientTrack = {
  bind(el, binding, vnode) {
    if (binding?.value?.inject) { return }

    bindClientTrack(el, binding, vnode)
  },
  inserted(el, binding, vnode) {
    // 继承埋点数据
    if (!binding?.value?.inject) { return }

    // 因为bind拿不到父级节点，所以在inserted时查找provide的埋点信息
    bindClientTrack(el, binding, vnode)
  },
  // todo: 可以在inserted里通过处理el.parent.children加上idx和len
  update(_el, { value, oldValue }, vnode) {
    if (isEqual(value, oldValue)) { return }

    vnode.context.$inhouse.updateBinding(_el, value)
  }
}

export function serverTrack(vnode, binding) {
  const trackInfo = getSpmTrackInfo(binding, vnode)

  if (!trackInfo) { return }

  vnode.data.attrs = {
    ...vnode.data.attrs || {},
    [trackInfo.name]: trackInfo.track
  }
}
