import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '~/store'

export type ExpOrderState = {
  countryCode: string
  countryNumber: string
  mobile: string
  submitButtonStatus: string
  mobileVerifyStatus: boolean
}

export const state = () => ({
  countryCode: '', // 格式: '+86'
  mobile: '', // 格式: '18259154440'
  countryNumber: '', // 格式: CN
  submitButtonStatus: 'default',
  mobileVerifyStatus: false
})

export const mutations: MutationTree<ExpOrderState> = {
  updateCountryCode(state, val: string) {
    state.countryCode = val
  },
  updateCountryNumber(state, val: string) {
    state.countryNumber = val
  },
  updateMobile(state, val: string) {
    state.mobile = val
  },
  updateSubmitButtonStatus(state, val: string) {
    state.submitButtonStatus = val
  },
  updateMobileVerifyStatus(state, val: boolean) {
    state.mobileVerifyStatus = val
  }
}

export const actions: ActionTree<ExpOrderState, RootState> = {

}

export const getters: GetterTree<ExpOrderState, RootState> = {
  countryCodeMobile(state) {
    if (!state.countryCode || !state.mobile) { return '' }
    return `${state.countryCode}-${state.mobile}`
  }
}
