import Vue from 'vue'
import xssFilters from 'xss-filters'

declare module 'vue/types/vue' {
  interface Vue {
    $insetDataLayer: (data: any) => ({ innerHTML: string })
  }
}

function genDatalayer(data: any) {
  // 防止xss攻击
  const safeData = Object.keys(data)
    .reduce((acc, v) => ({ ...acc, [v]: xssFilters.inHTMLData(data[v]) }), {})

  try {
    return JSON.stringify(safeData)
  } catch (e) {
    return '{}'
  }
}

function insetDatalayer(this: Vue, data?: any) {
  if (Object.prototype.toString.call(data) !== '[object Object]') { return {} }

  return {
    innerHTML: `
       var dataLayer = dataLayer || []
       dataLayer.push(${genDatalayer(data)})
    `
  }
}

Vue.prototype.$insetDataLayer = insetDatalayer
