import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/store'
import { Poi } from '~/types/traveller/poi'
import apis from '~/share/data/apis'

export const state = () => ({
  sectionList: [],
  reviewIdList: [],
  reviewListVisible: false,
  reviewMap: null,
  reviewTotal: 0,
  breadcrumb: null,
  shareInfo: null
})

export const mutations: MutationTree<Poi.State> = {
  SET_ACTIVITY_REVIEWS(state, data) {
    const reviewsList = (data.item || []).map((v: any) => {
      const { review_image: reviewImage } = v
      return {
        id: v.id,
        author: v.author,
        date: v.date,
        avatar: v.avatar,
        rating: v.rating,
        content: v.content,
        translateContent: v.translate_content,
        needTranslateButton: v.need_translate_button,
        packageName: v.package_name,
        reviewImage: reviewImage && reviewImage.map((img: any) => ({
          imgResizeUrl: img.img_resize_url,
          imageId: img.image_id,
          imgUrl: img.img_url
        })),
        hasReply: v.has_reply,
        reply: v.reply ? {
          content: v.reply.content,
          translateContent: v.reply.translate_content
        } : {},
        hasLiked: v.has_liked,
        likeCount: v.like_count
      }
    })

    const reviews = reviewsList.reduce((acc: any, v: any) => ({
      reviewMap: {
        ...acc.reviewMap,
        [v.id]: v
      },
      reviewIdList: [...acc.reviewIdList, v.id]
    }), {
      reviewMap: {},
      reviewIdList: []
    })

    state.reviewIdList = Array.from(new Set([...state.reviewIdList, ...reviews.reviewIdList]))
    state.reviewMap = { ...state.reviewMap, ...reviews.reviewMap }
    state.reviewTotal = data.total
  },

  SET_BREADCRUMB(state, data) {
    state.breadcrumb = data
  },

  SET_SHAREINFO(state, data) {
    state.shareInfo = data
  },

  SET_SECTION_List(state, data) {
    state.sectionList = [].concat(data)
  },

  SET_REVIEWS_LIKED(state, id) {
    const { reviewMap } = state

    const review: any = reviewMap[id]

    if (review) {
      review.likeCount += 1
      review.hasLiked += true
      state.reviewMap = {
        ...reviewMap,
        [review.id]: review
      }
    }
  },

  TRIGGER_REVIEW_LIST_VISIBLE(state, visible) {
    state.reviewListVisible = visible
  }
}

export const actions: ActionTree<Poi.State, RootState> = {
  // 获取poi详情信息
  async getPoiDetail({ commit }, { poiId, redirect }) {
    try {
      const { success, result } = await this.$axios
        .$get(apis.getPoiDetail, {
          params: {
            poi_id: poiId
          },
          throwError: true
        })

      if (success && result) {
        const {
          page
        } = result

        commit('SET_SECTION_List', page?.body?.sections)
        commit('SET_BREADCRUMB', result.poi_area_info)
        commit('SET_SHAREINFO', result.share_info)
      }
    } catch (e) {
      redirect && redirect({ name: 'Error404' })
    }
  },

  // 点赞评论
  setReviewLiked({ commit }, id) {
    commit('SET_REVIEWS_LIKED', id)
  },

  // 获取活动评论
  async getActivityReviews({ commit }, { poi_id, page }) {
    try {
      const { success, result } = await this.$axios
        .$get(apis.getPoiReviewList, {
          params: {
            poi_id,
            page,
            limit: 10
          },
          throwError: true
        })

      if (success && result) {
        commit('SET_ACTIVITY_REVIEWS', result)
        return result
      }
    } catch (e) {

    }
  },

  // 显示评论列表
  triggerReviewListVisible({ commit }, visible = true) {
    commit('TRIGGER_REVIEW_LIST_VISIBLE', visible)
  }
}
