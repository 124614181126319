import middleware from '../middleware'

// @NOTE: The route.meta field is an Array
// Ref: https://github.com/nuxt/nuxt.js/issues/5885#issuecomment-507670640
const detectAuthByRoute = function (key, value, route) {
  return route.meta.some(meta => meta[key] === value)
}

/**
 * An auth middleware
 *
 * @example
 * // In component
 * export default {
 *  meta: {
 *    auth: true // true, false, undefined
 *  }
 * }
 *
 * // Or in route config
 * const routes = [{
 *   name: 'Home',
 *   path: '/',
 *   component: Home,
 *   meta: {
 *     auth: true // true, false, undefined
 *   }
 * }]
 */
middleware.auth = async ({ app, route, store, redirect }) => {
  // Public
  if (detectAuthByRoute('auth', false, route)) {
    return
  }

  // Check with time lock
  const canVerify = await store.dispatch('auth/canVerify')
  if (!canVerify) {
    return
  }

  // Default: verify token
  const user = await store.dispatch('auth/verify')

  app.user = user

  // Private
  if (detectAuthByRoute('auth', true, route)) {
    if (!user) {
      const callbackPath = encodeURIComponent(route.fullPath)
      redirect(`${app.$href('/signin')}/?signin_jump=${callbackPath}`)
    }
  }
}
