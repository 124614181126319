// require('raf').polyfill()
/**
 * get scrollHeight
 * @return {Number} scrollHeight
 */
export function _scrollHeight(config) {
  const viewportHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.getElementsByTagName('body')[0].clientHeight

  const { scrollEl: el, lockLocation } = config || {}
  if (el) {
    const scrollHeight = Math.max(
      el?.scrollHeight || document.querySelector(el)?.scrollHeight,
      el?.offsetHeight || document.querySelector(el)?.offsetHeight
    )
    if (typeof scrollHeight === 'number') {
      const hackViewportHeight = viewportHeight - (lockLocation || 0)
      return scrollHeight > hackViewportHeight ? scrollHeight - hackViewportHeight : 0
    }
  }
  const body = document.body
  const html = document.documentElement
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
  return height > viewportHeight ? height - viewportHeight : 0
}

/**
 * get window scrollTop
 * @return {Number} scrollTop
 */
export function _windowScrollTop(el) {
  let scrollTop = 0
  if (el) {
    scrollTop = typeof el?.scrollTop === 'number' ? el.scrollTop : document.querySelector(el)?.scrollTop
    if (typeof scrollTop === 'number') {
      return scrollTop
    }
  }
  return document.documentElement.scrollTop || window.pageYOffset ||
    document.body.scrollTop
}

/**
 * get node scrollTop
 * @param {Object}  [node] dom节点
 * @return {Number} scrollTop
 */

export function _nodeScrollTop(node, config) {
  if (node) {
    const windowY = _windowScrollTop(config?.scrollEl)
    return parseInt(node.getBoundingClientRect().top + windowY)
  }
  return 0
}

/**
 * get client height
 * @return {Number} client height
 */

export function _clientHeight() {
  return document.documentElement.clientHeight
}

/**
 * scrollto scrollY position
 * @param  {Object}
 * param  {Number}   [scrollY=0]       scroll end position
 * param  {Number}   [duration=1000]   scroll time
 * param  {Function} callback          scroll end callback, callback(scrollY, id)
 * param  {STRING} lockType [top, header, bottom]           scroll end at top / top add hear height / bottom
 * param  {Number} lockLocation        scroll end at customize height distance of top
 */

export function _scroll2({ scrollY = 0, lockType = 'free', duration = 200, callback, lockLocation = 0 }, config) {
  let id
  const { scrollEl } = config || {}
  const scrollNode = document.querySelector(scrollEl)
  // scrollTop is a intValue
  const scrollTop = _windowScrollTop(scrollNode)
  const scrollHeight = _scrollHeight(config)
  const o = {
    top: 0,
    middle: (_clientHeight() / 2),
    bottom: _clientHeight(),
    free: lockLocation
  }
  const lockHeight = o[lockType]
  scrollY = scrollY < 0 ? 0 : scrollY - lockHeight // hack negative
  scrollY = scrollHeight <= scrollY ? scrollHeight : scrollY // hack max-than-scrollHeight
  const speed = (scrollY - scrollTop) * 1000 / 60 / duration

  function __scroll2() {
    let scrollTop = _windowScrollTop(scrollNode)

    if (
      (speed > 0 && scrollTop + speed > scrollY) ||
      (speed < 0 && scrollTop + speed < scrollY) ||
      speed === 0
    ) {
      scrollNode ? scrollNode.scrollTo(0, scrollY) : window.scrollTo(0, scrollY)
      cancelAnimationFrame(id)
      callback && typeof callback === 'function' && callback(scrollY, id)
    } else {
      scrollNode ? scrollNode.scrollTo(0, (scrollTop += speed)) : window.scrollTo(0, (scrollTop += speed))

      const currentScrollTop = _windowScrollTop(scrollNode)
      if (_scrollHeight(config) <= currentScrollTop || currentScrollTop === 0) {
        cancelAnimationFrame(id)
        callback && typeof callback === 'function' && callback(scrollY, id)
        return
      }

      id = requestAnimationFrame(__scroll2)
    }
  }
  id = requestAnimationFrame(__scroll2)
}
