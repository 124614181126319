/**
 *
 * jsessionid风控用来识别用户设备信息的标识，如果支付的时候没有这个标识，会被风控标识为fraud订单
 * 使用场景: 用户第一次进入网站
 * 使用方法: 页面@Component装饰器里添加属性 middleware: 'jsessionid'
 *
 */

import { Middleware } from '@nuxt/types'
import apis from '~/share/data/apis'
import { isSearchBot } from '~/share/utils'

const middleware: Middleware = async function (ctx) {
  if (ctx.app.$cookies.get('JSESSIONID') || (process.server && isSearchBot(ctx.req.headers['user-agent']))) {
    return
  }

  const resp = await ctx.app.$axios.$get(apis.jsessionId)
  let jsessionid = ''
  if (resp.success && resp.result) {
    jsessionid = resp.result.sid
    ctx.app.$cookies.set('JSESSIONID', jsessionid, {
      path: '/',
      httpOnly: true,
      expires: new Date(Date.now() + 0.5 * 24 * 3600 * 1000)
    })
  }
}

export default middleware
