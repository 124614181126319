import { MutationTree, ActionTree } from 'vuex'
import { BookingListType } from '~/types/traveller/booking-list'
import apis from '~/share/data/apis'
import { RootState } from '~/store'

export interface BookingListState {
  recentlyPurchased: BookingListType.RecentlyPurchasedList
  orderList: BookingListType.OrderList[]
  totalPage: number
  orderStatus: string
  pageIndex: number
  listType: string
  logisticsVisible: boolean
  logisticsInfo: any
  mergeOptionVisible: boolean
  mergeOptionInfo: BookingListType.MergeOptionInfo
  appDownloadVisible: boolean
  bookingManagementVisible: boolean
  bookingManagementInfo: BookingListType.BookingManagementInfo
  bookingPopularList: any
  coupon: any
}

export const state = () => ({
  recentlyPurchased: [],
  orderList: [],
  totalPage: 0,
  orderStatus: 'loading', // loading, success, error三种状态
  pageIndex: 1,
  listType: 'normal',
  logisticsVisible: false,
  logisticsInfo: null,
  mergeOptionVisible: false,
  mergeOptionInfo: null,
  appDownloadVisible: false,
  bookingManagementVisible: false,
  coupon: null,
  bookingManagementInfo: {
    bookingReferenceNumber: '',
    confirmationType: ''
  },
  bookingPopularList: []
})

export const mutations: MutationTree<BookingListState> = {
  SET_BOOKING_POPULAR_LIST(state, payload) {
    state.bookingPopularList = payload
  },

  SET_LIST_TYPE(state, data) {
    state.listType = data
  },

  SET_PAGE_INDEX(state, data) {
    state.pageIndex = data
  },

  SET_RECENTLY_PURCHASED(state, data) {
    state.recentlyPurchased = data.recentlyPurchased
  },

  PUSH_ORDER_LIST(state, payload) {
    state.orderList = state.orderList.concat(payload)
  },

  SET_ORDER_LIST(state, payload) {
    state.orderList = payload
  },

  SET_TOTAL_PAGE(state, payload) {
    state.totalPage = payload.totalPage
  },

  SET_ORDER_STATUS(state, payload) {
    state.orderStatus = payload
  },

  SET_DOWNLOAD_VISIBLE(state, payload) {
    state.appDownloadVisible = payload
  },

  SET_LOGISTICS_VISIBLE(state, payload) {
    state.logisticsVisible = payload
  },

  SET_LOGISTICS_INFO(state, payload) {
    state.logisticsInfo = payload
  },

  SET_MERGE_OPTION_VISIBLE(state, payload) {
    state.mergeOptionVisible = payload
  },

  SET_MERGE_OPTION_INFO(state, payload) {
    state.mergeOptionInfo = payload
  },

  SET_BOOKING_MANAGEMENT_INFO(state, payload) {
    state.bookingManagementInfo.bookingReferenceNumber = payload.bookingReferenceNumber
    state.bookingManagementInfo.confirmationType = payload.confirmationType
  },

  SET_BOOKING_VISIBLE(state, payload) {
    state.bookingManagementVisible = payload
  },

  SET_COUPON(state, payload) {
    state.coupon = payload
  }
}

export const actions: ActionTree<BookingListState, RootState> = {
  getCoupon({ commit }) {
    return this.$axios.$get(apis.commonCoupon).then((res) => {
      if (res.success && res.result) {
        commit('SET_COUPON', res.result)
      }
    })
  },
  getBookingPopularList({ commit }) {
    return this.$axios.$get(apis.bookingPopularList)
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.items) {
          commit('SET_BOOKING_POPULAR_LIST', res.result.items)
        }
      })
  },

  getRecentlyPurchased({ commit }) {
    return this.$axios
      .$get(apis.getRecentlyPurchase)
      .then((res: Data.Res) => {
        if (res.success && res.result && res.result.activity_list.length > 0) {
          const data = res.result
          commit('SET_RECENTLY_PURCHASED', {
            recentlyPurchased: data.activity_list.map((item: any) => ({
              title: item.title,
              market_price: item.market_price,
              sell_price: item.sell_price,
              sold_out: item.sold_out,
              image_url: item.image_url,
              id: item.id,
              url_seo: item.url_seo,
              currency: item.currency
            }))
          })
        }
      })
  },

  getOrderList({ commit, state, rootState }) { // mweb请求列表
    const parmas = {
      lang: rootState.klook.backendLanguage,
      page: state.pageIndex,
      page_size: 10,
      list_type: state.listType
    }
    return this.$axios.$post(apis.getBookingList, parmas)
      .then((res: Data.Res) => {
        if (res.success) {
          commit('SET_ORDER_STATUS', 'success')
          if (res.result && res.result.booking_list && res.result.booking_list.length > 0) {
            commit('SET_TOTAL_PAGE', { totalPage: res.result.num_page })
            commit('SET_PAGE_INDEX', state.pageIndex + 1)
            commit('PUSH_ORDER_LIST', res.result.booking_list)
          }
        } else {
          commit('SET_ORDER_STATUS', 'error')
        }
      })
      .catch(() => {
        commit('SET_ORDER_STATUS', 'error')
      })
  },

  getBookingList({ commit, state, rootState }, loading = true) { // web请求列表
    const emptyResult: BookingListType.OrderList[] = [] // 空结果
    const PAGE_SIZE = 10 // pageSize常量
    const params = {
      lang: rootState.klook.backendLanguage,
      page: state.pageIndex,
      page_size: PAGE_SIZE,
      list_type: state.listType
    }
    return this.$axios.$post(apis.getBookingList, params, { loading })
      .then((res) => {
        if (res.success) {
          commit('SET_ORDER_STATUS', 'success')
          if (res.result && res.result.booking_list && res.result.booking_list.length > 0) {
            commit('SET_TOTAL_PAGE', { totalPage: res.result.num_page })
            commit('SET_ORDER_LIST', res.result.booking_list)
          } else {
            commit('SET_TOTAL_PAGE', 0)
            commit('SET_ORDER_LIST', emptyResult)
          }
        } else {
          commit('SET_ORDER_STATUS', 'error')
          commit('SET_TOTAL_PAGE', 0)
          commit('SET_ORDER_LIST', emptyResult)
        }
      })
      .catch(() => {
        commit('SET_ORDER_STATUS', 'error')
        commit('SET_ORDER_LIST', emptyResult)
      })
  },

  showLogisticsModal({ commit }, bookingReferenceNo) {
    return this.$axios.$get(apis.getLogistics, {
      params: { booking_reference_no: bookingReferenceNo },
      loading: true
    }).then((res: Data.Res) => {
      if (res.success && res.result) {
        commit('SET_LOGISTICS_VISIBLE', true)
        commit('SET_LOGISTICS_INFO', res.result)
      }
    })
  },

  showBookingManagement({ commit }, { confirmationType, bookingReferenceNumber }) {
    commit('SET_BOOKING_MANAGEMENT_INFO', { confirmationType, bookingReferenceNumber })
    commit('SET_BOOKING_VISIBLE', true)
  },

  showMergeOption({ commit }, { orderNo, type }) {
    return this.$axios.$get(apis.comboQuery, {
      params: { order_no: orderNo, type },
      loading: true
    }).then((res: Data.Res) => {
      if (res.success && res.result) {
        res.result.type = type
        commit('SET_MERGE_OPTION_INFO', res.result)
        commit('SET_MERGE_OPTION_VISIBLE', true)
      }
    })
  }

}
