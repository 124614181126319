import { currencyConfig } from '@klook/site-config'

const {
  currencySymbolMap,
  currencyRateMap,
  getSupportCurrenciesList,
  getSuggestCurrency,
  isServerMiddlewareSupportCurrency,
  getCurrencySymbol,
  getCurrencyByCountry,
  getCurrencyCodeByLang
} = currencyConfig

export {
  currencySymbolMap,
  currencyRateMap,
  // 当前站点支持的currency list
  getSupportCurrenciesList,
  // 获取推荐货币
  getSuggestCurrency,
  // 兼容代码，可直接替换 getSuggestCurrency
  getSuggestCurrency as suggestCurrencyByLanguage,
  // 是否在 Server Middleware 中支持改币种，中间件设置币种请使用该方法检测
  // 新增币种需要优先对中间件放行，所以提供了单独方法做中间件中判断使用
  isServerMiddlewareSupportCurrency,
  // 获取货币标识
  getCurrencySymbol,
  // 通过国家获取货币
  getCurrencyByCountry,
  // 根据语言选择默认货币
  getCurrencyCodeByLang
}
