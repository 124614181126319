import Vue from 'vue'
import { AxiosRequestConfig } from 'axios'
import { LoadingOptions } from './types'

const globalLoading = new Vue()

function isObject(value: any) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

/**
 * show loading
 * @param config axios config
 */
export function showLoading(config: AxiosRequestConfig) {
  if (process.server || !config.loading) {
    return
  }
  const { loading } = config

  if (isObject(loading)) {
    const { el, ...options } = loading as LoadingOptions

    if (el) { // Scope loading
      const $el = typeof el === 'string'
        ? document.querySelector(el) as HTMLElement | null
        : el

      if ($el) {
        const Loading = Vue.component('klk-loading')
        const instance = new Loading({
          el: document.createElement('div'),
          propsData: {
            showOverlay: true,
            overlayColor: 'rgba(255, 255, 255, .7)',
            ...options
          }
        })
        const component = instance.$mount()

        $el.appendChild(component.$el)
        // @ts-ignore
        $el._$loading = component.$el
      }
    } else {
      globalLoading.$showLoading(options)
    }
  } else {
    globalLoading.$showLoading({
      overlayColor: 'rgba(255, 255, 255, .7)'
    })
  }
}

/**
 * hide loading
 * @param config axios config
 */
export function hideLoading(config: AxiosRequestConfig) {
  if (process.server || !config || !config.loading) {
    return
  }

  const { loading } = config

  if (isObject(loading)) {
    const { el } = loading as LoadingOptions

    if (el) { // Scope loading
      const $el = typeof el === 'string'
        ? document.querySelector(el) as HTMLElement | null
        : el

      // @ts-ignore
      if ($el && $el._$loading) {
        // @ts-ignore
        $el.removeChild($el._$loading)
        // @ts-ignore
        delete $el._$loading
      }
    } else {
      globalLoading.$hideLoading()
    }
  } else {
    globalLoading.$hideLoading()
  }
}
