import MessageTips from './message-tip.vue'
import { VueConstructor } from '~/node_modules/vue'

export interface MessageTipOptions {
  content?: string,
  visible?: boolean,
  delay?: number,
}

const defaultOptions: MessageTipOptions = {
  visible: false,
  delay: 3000
}

let MessageTipConstructor: any = null

export function messageTipFactory(Vue: VueConstructor, userOptions: MessageTipOptions) {
  if (Vue.prototype.$isServer) { return null }

  const errorTipNode = document.getElementsByClassName('j-klook-message-tips')
  if (errorTipNode.length) {
    // @ts-ignore
    errorTipNode[0].parentNode.removeChild(errorTipNode[0])
  }

  if (!MessageTipConstructor) {
    MessageTipConstructor = Vue.extend(MessageTips)

    MessageTipConstructor.prototype.close = function close() {
      this.visible = false
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      this.$destroy()
    }
  }

  const options = { ...defaultOptions, ...userOptions }
  const instance = new MessageTipConstructor({
    el: document.createElement('div'),
    data: options
  })

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })

  return instance
}
