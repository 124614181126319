import { MutationTree, ActionTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { RootState } from '~/store'
import { SearchResult } from '~/types/traveller/search-result'
import apis from '~/share/data/apis'

export const state = () => ({
  status: 'firstLoading', // 接口请求状态  firstLoading(刷新整个页面) | pageLoading(分页) | changeLoading(底部数量按钮loading)
  fromType: '', // city | country | ''
  requestId: '',
  tagSearchNum: 0,
  searchResult: {
    aggr_condition: { // 筛选项
      destinations: [], // 城市国家筛选
      frontend_tree: [], // 类目筛选
      sort: [], // 排序
      time: null, // 时间筛选
      price: null, // 价格范围筛选
      srv_option: null, // srv活动筛选
      nsw_option: null, // nsw活动筛选
      yfq_option: null, // yfq活动筛选
      gvq_option: null // gvq活动筛选

    },
    relation_result: { // 搜索无结果兜底数据
      cards: [],
      total: 0
    },
    search_result: { // 搜索结果请求的列表
      cards: [],
      total: 0
    },
    top_one_list: [] // 热门词汇
  },
  filterData: {
    sort: 'most_relevant', // 选中的sort值
    query: '', // 搜索关键字
    city_ids: '',
    frontend_ids: '',
    country_ids: '',
    start_time: '',
    price_from: '',
    price_to: '',
    instant: false,
    nsw_option: false,
    srv_option: false,
    yfq_option: false,
    gvq_option: false
  }
})

export const mutations: MutationTree<SearchResult.State> = {
  SET_REQUEST_ID(state, payload) {
    state.requestId = payload
  },

  SET_TAG_SEARCH_NUMBER(state, payload) {
    state.tagSearchNum = payload
  },

  // 设置fromType值
  SET_FROM_TYPE(state, payload) {
    state.fromType = payload
  },

  // 设置filterData值
  SET_FILTER_DATA(state, payload) {
    state.filterData = payload
  },
  // 设置搜索结果接口status值
  SET_PAGE_STATUS(state, payload) {
    state.status = payload
  },
  // 设置搜索结果值  aggr_condition  relation_result  search_result  top_one_list
  SET_AGGR_CONDITION(state, payload) {
    state.searchResult.aggr_condition = payload
  },

  SET_RELATION_RESULT(state, payload) {
    state.searchResult.relation_result = payload
  },

  SET_SEARCH_RESULT_CARDS(state, payload) {
    state.searchResult.search_result.cards = payload
  },

  SET_SEARCH_RESULT_TOTAL(state, payload) {
    state.searchResult.search_result.total = payload
  },

  SET_TOP_ONE_LIST(state, payload) {
    state.searchResult.top_one_list = payload
  }
}

export const actions: ActionTree<SearchResult.State, RootState> = {
  // 获取搜索结果
  async getSearchResult({ commit, state }, { params = {}, loading_type = '', is_only_total = false, page_num = 1 }) {
    if (['pageLoading', 'changeLoading'].includes(state.status)) { return false }
    commit('SET_PAGE_STATUS', loading_type)
    let _params: any = cloneDeep(params)
    if (is_only_total) {
      _params = merge({}, state.filterData, _params)
      const price = state.searchResult.aggr_condition.price
      if (_params.price_from === price.from + '' && _params.price_to === price.to + '') {
        delete _params.price_from
        delete _params.price_to
      }
      _params.sort = state.filterData.sort
      _params.query = state.filterData.query
      _params.country_ids = state.filterData.country_ids
    }
    for (const key in _params) {
      if (!_params[key]) {
        delete _params[key]
      }
    }
    if (_params.start_time) {
      _params.start_time = _params.start_time.substring(0, 10) + 'T00:00:00Z' // 后端要求的数据结构特殊处理
    }
    try {
      let url = ''
      if (state.fromType === 'city') {
        url = `${apis.getSearchResult}city_search`
      } else if (state.fromType === 'country') {
        url = `${apis.getSearchResult}country_search`
      } else {
        url = `${apis.getSearchResult}complete_search`
      }

      const response = await this.$axios
        .get(url, {
          params: { ..._params, page_num, page_size: 10, is_only_total },
          throwError: true
        })
      if (response && response.data && response.data.success && response.data.result) {
        const result = response.data.result
        commit('SET_PAGE_STATUS', 'success')
        if (response.headers && response.headers['x-klook-request-id']) {
          commit('SET_REQUEST_ID', response.headers['x-klook-request-id'])
        }
        if (result.search_result) {
          commit('SET_TAG_SEARCH_NUMBER', result.search_result.total)
          if (!is_only_total) {
            commit('SET_SEARCH_RESULT_TOTAL', result.search_result.total)
            commit('SET_AGGR_CONDITION', result.aggr_condition)
            commit('SET_RELATION_RESULT', result.relation_result)
            commit('SET_TOP_ONE_LIST', result.top_one_list)
            if (page_num === 1) {
              commit('SET_SEARCH_RESULT_CARDS', result.search_result.cards)
            } else {
              const _cards = [...state.searchResult.search_result.cards, ...result.search_result.cards]
              commit('SET_SEARCH_RESULT_CARDS', _cards)
            }
          }
        } else {
          commit('SET_AGGR_CONDITION', result.aggr_condition)
          commit('SET_RELATION_RESULT', result.relation_result)
          commit('SET_TOP_ONE_LIST', result.top_one_list)
        }
        return result
      }
    } catch (e) {
      commit('SET_PAGE_STATUS', 'error')
    }
  }
}
