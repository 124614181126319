const prefix = 'https://res.klook.com/image/upload/fl_lossy.progressive,'

export type ImageType = 'activity_card'
  | 'hot_destination'
  | 'city_top_banner'
  | 'theme_act_pic'
  | 'm_activity_card_xs'
  | 'm_activity_card_xs_160'
  | 'm_activity_card_xs_288'
  | 'm_country_destination'
  | 'my_reviews'
  | 'm_hot_destination'
  | 'fnb_mvp_activites'
  | 'other_about'
  | 'recently_purchase'

// 宽高可以*2，不然图片模糊
export const typeUrlMap: Record<ImageType, string> = {
  activity_card: `${prefix}q_65/c_fill,w_800,h_448/activities/`,
  hot_destination: `${prefix}q_65/c_fill,w_480,h_384/cities/`,
  city_top_banner: `${prefix}q_65/c_fill,w_1920,h_450/cities/`,
  theme_act_pic: `${prefix}q_65/c_fill,w_499,h_398/activities/`,
  m_activity_card_xs: `${prefix}q_65/c_fill,w_640,h_368/activities/`,
  m_activity_card_xs_160: `${prefix}q_65/c_fill,w_160,h_160/activities/`,
  m_activity_card_xs_288: `${prefix}q_65/c_fill,w_288,h_288/activities/`,
  m_country_destination: `${prefix}q_95/c_fill,w_336,h_188/cities/`,
  my_reviews: `${prefix}q_95/c_fill,w_240,h_160/activities/`,
  m_hot_destination: `${prefix}q_95/c_fill,w_400,h_533/cities/`,
  fnb_mvp_activites: `${prefix}q_65/c_fill,w_372,h_372/activities/`,
  other_about: `${prefix}q_65/c_fill,w_372,h_372/activities/`,
  recently_purchase: `${prefix}q_65/c_fill,w_192,h_144,f_auto/activities/`
}

/**
 * 格式化图片 url
 * @XXX: 从旧项目迁移过来的函数，但使用上不太友好
 */
export function formatPicUrl(url: string, type: ImageType, webp: number, retina: number) {
  if (/^https?:\/\//.test(url)) {
    return url
  }

  const prefix = typeUrlMap[type] || ''
  let fullUrl = `${prefix}${url}`

  if (webp && fullUrl.indexOf('res.klook.com') > 0) {
    fullUrl = fullUrl.replace(/.(jpg|png|jpeg|gif)$/, '.webp')
  }

  // 只对活动卡片做 retina 判断
  if (type === 'activity_card') {
    fullUrl = fullUrl.replace('w_800,h_448', 'w_750,h_420')

    if (!retina) {
      fullUrl = fullUrl.replace('w_750,h_420', 'w_375,h_210')
    }
  }

  if (type === 'm_activity_card_xs_288') {
    if (!retina) {
      fullUrl = fullUrl.replace('w_288,h_288', 'w_144,h_144')
    }
  }

  return fullUrl
}
/**
 * @param url 图片url
 * @param width 图片宽度
 * @param height 高度
 */
interface ImageUrlIF {
  url: string,
  width: number,
  height: number,
}
// /c_fill,w_981,h_621/activities/rgs8blo79h2dt4c0rjha.webp
export function replaceImageSize(obj: ImageUrlIF) {
  let newUrl = ''
  const { url, width, height } = obj
  if (!url) { return newUrl }
  newUrl = url
  newUrl = newUrl.replace(/w_(\d+)/, (...args: string[]) => {
    return 'w_' + (width || args[1])
  })
  newUrl = newUrl.replace(/h_(\d+)/, (...args: string[]) => {
    return 'h_' + (height || args[1])
  })
  return newUrl
}
/**
 * @param originUrl 图片url
 * @param targetUrl 替换url
 * @param width 图片宽度
 * @param height 高度
 * @param webp 是否支持 webp
 */
export function setNewImageSize(originUrl: string, targetUrl: string, width: number, height: number, webp: number, isFill: boolean = true) {
  let newImgUrl = originUrl || ''

  if (targetUrl && originUrl) {
    // 设置清晰度
    const temp = targetUrl + 'fl_lossy.progressive,q_85/'
    const newHeight = height > 0 ? ',h_' + height + '/' : '/'

    newImgUrl = newImgUrl.replace(targetUrl, temp)
    newImgUrl = newImgUrl.replace(temp, temp + (isFill ? 'c_fill,' : '') + 'w_' + width + newHeight)

    // Apply .webp
    if (webp && newImgUrl.indexOf('res.klook.com') > 0) {
      newImgUrl = newImgUrl.replace(/.(jpg|png|jpeg|gif)$/, '.webp')
    }
  }

  return newImgUrl
}
/**
 * 格式化头像的 url
 */
export function formatAvatarUrl(url: string) {
  const cdn = 'https://cdn.klook.com/upload/img200X200/'
  const env = process.env.APP_ENV

  // 默认头像
  if (!url || url === cdn || url === 'avatar.png') {
    return `${cdn}avatar.png`
  }

  if (/^https?:\/\//.test(url)) {
    return url
  }

  if (env === 'production') {
    return `${cdn}${url}`
  }

  return url
}

/**
 * seo 清理图片地址的特殊字符
 * */
export function clearImgSpecialCharacters(name: string = ''): string {
  const escReg = /[.'" /\\&%#]/g

  const imageInfo = getImageInfo(name)

  if (imageInfo.name) {
    return imageInfo.name.replace(escReg, '') + imageInfo.ext
  }
  return name.replace(escReg, '')
}

/**
 * 获取图片文件名和后缀
 */
export function getImageInfo(name: string = '') {
  const nameReg = /(.*)(\.jpg|\.jpeg|\.png)$/i

  const matched = nameReg.exec(name)

  return matched ? { name: matched[1], ext: matched[2] } : {}
}

/**
 * 获取图片名
 */
export function getImageName(image: string) {
  const { name } = getImageInfo(image)
  return name ? clearImgSpecialCharacters(name) : ''
}

/**
 * 图片 webp 格式
 * @param url 图片 url
 * @param webp 是否支持 webp
 */
export function setImageFormat(url: string, webp: number) {
  let destUrl = url
  if (webp && destUrl && destUrl.includes('res.klook.com')) {
    destUrl = destUrl.replace(/.(jpg|png|jpeg|gif)$/, '.webp')
  }

  return destUrl
}

/**
 * 处理图片URL：宽高参数等
 * @param url
 * @param options
 * @returns
 */
export function formatImgUrl(url: string, options: any = {}) {
  if (!url?.includes?.('res.klook.com')) {
    return url
  }

  const params = []
  if (options.width) {
    params.push(`w_${options.width}`)
  }

  if (options.height) {
    params.push(`h_${options.height}`)
  }

  if (options.isFill) {
    params.push('c_fill')
  }

  const paramStr = params.length ? `/${params.join(',')}` : ''
  const sectionStr = options.sectionStr || 'image/upload'

  return url.replace(sectionStr, `${sectionStr}/fl_lossy.progressive,q_85${paramStr}`)
}

/**
 * w: 宽度
 * h: 高度
 * quality: 图像质量参数，取值范围 0-100，100 为原图
 * waterMark: 是否添加水印，默认参数为 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/'
 * blur: 高斯模糊，0 为不模糊
 * customize: 自定义参数  https://cloudinary.com/documentation/image_transformation_reference
 */

interface SizeMap {
  [prop: string]: {
    w?: number
    h?: number
    quality?: number
    blur?: number
    waterMark?: boolean
    customize?: string
  }
}

const defaultOptions = {
  quality: 85,
  waterMark: false,
  blur: 0,
  customize: ''
}

export const imageOptionsMap: SizeMap = {
  desktopActivityCard: {
    ...defaultOptions,
    w: 550,
    h: 308
  },
  homePageThemeListBg: {
    ...defaultOptions,
    w: 1160,
    h: 370
  },
  homePageFnbCard: {
    ...defaultOptions,
    w: 420,
    h: 280
  },
  HomePagePromoteCard: {
    ...defaultOptions,
    w: 746,
    h: 560
  },
  HomePagePromoteVideoBg: {
    ...defaultOptions,
    w: 770,
    h: 371
  },
  homePageCampaign: {
    ...defaultOptions,
    w: 1160,
    h: 180
  },
  hotCityCard: {
    ...defaultOptions,
    w: 352,
    h: 470
  },
  mobileActivityCardLarge: {
    ...defaultOptions,
    w: 375,
    h: 210
  },
  promote: {
    ...defaultOptions,
    w: 800,
    h: 510
  },
  mobileActivityCardXs: {
    ...defaultOptions,
    w: 200,
    h: 300
  },
  desktopActivityDetailImages: {
    ...defaultOptions,
    w: 220,
    h: 120
  },
  headerRecentlyView: {
    ...defaultOptions,
    w: 160,
    h: 120
  },
  desktopEventDetailBanner: {
    ...defaultOptions,
    w: 1160,
    h: 460,
    quality: 0
  },
  mobileEventDetailBanner: {
    ...defaultOptions,
    w: 375,
    h: 196,
    quality: 0
  },
  desktopEventDetailMap: {
    ...defaultOptions,
    w: 790,
    h: 316,
    quality: 0
  },
  mobileEventDetailMap: {
    ...defaultOptions,
    w: 343,
    h: 316,
    quality: 0
  },
  hotDestinationsImg: {
    ...defaultOptions,
    w: 260,
    h: 148
  },
  cityHeaderMainImg: {
    ...defaultOptions,
    w: 904,
    h: 300
  },
  cityHeaderGalleryImg: {
    ...defaultOptions,
    w: 252,
    h: 168
  },
  desktopChatEntranceIcon: {
    ...defaultOptions,
    w: 64,
    h: 64
  },
  desktopChatEntranceIconWithText: {
    ...defaultOptions,
    w: 48,
    h: 48
  },
  // 3倍屏
  mobileChatEntranceIcon: {
    ...defaultOptions,
    w: 96,
    h: 96,
    quality: 100
  },
  mobileChatEntranceIconWithText: {
    ...defaultOptions,
    w: 72,
    h: 72,
    quality: 100
  },
  desktopRelateActivityCard: {
    ...defaultOptions,
    w: 100,
    h: 100,
    quality: 80
  },
  mobileActivityCard: {
    ...defaultOptions,
    w: 250,
    h: 220
  },
  experienceMobileActivityCardLarge: {
    ...defaultOptions,
    w: 350,
    h: 197
  },
  experienceDesktopLargeCard: {
    ...defaultOptions,
    w: 700,
    h: 550
  },
  desktopPassAttractionsCard: {
    ...defaultOptions,
    w: 118,
    h: 78
  },
  mobilePassAttractionsCard: {
    ...defaultOptions,
    w: 82,
    h: 82
  },
  mayYouAlsoLike: {
    ...defaultOptions,
    w: 413
  }
}
