









import { Component, Vue } from 'nuxt-property-decorator'
import Skeleton from '~/components/common/off-screen/placeholder/skeleton.vue'

@Component({
  components: {
    Skeleton
  }
})
export default class Placeholder extends Vue {}
