import { parse } from 'qs'
import { Plugin, Context } from '@nuxt/types'

const innerState = {
  client_id: ''
}

function gpush(args: Record<string, any>) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(args)
};
function _gtag(..._args: any[]) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
};

function calculateSpm(ctx: Context, isFirst = false) {
  let spm = ''
  let spmInfo = ''

  if (!isFirst) {
    const meta = ctx.app?.router?.app?.$meta?.()?.refresh()
    const bodyAttrs = meta?.metaInfo?.bodyAttrs
    if (bodyAttrs) {
      const spmPage = bodyAttrs['data-spm-page']
      spmInfo = Array.isArray(spmPage) ? spmInfo[0] || '' : spmPage || ''
    }
  } else {
    spmInfo = document.querySelector('[data-spm-page]')?.getAttribute('data-spm-page') || ''
  }
  const spmArr = spmInfo.split('?')
  spm = spmArr[0] || ''

  if (!spm) {
    const meta = ctx.route.meta
    spm = meta?.trackedPageName || meta?.[0]?.trackedPageName || ''
  }

  const oid = parse(spmArr[1])?.oid || ''

  return {
    oid,
    spm
  }
}

function _mime(option: 'type', value: string) {
  const mimeTypes = navigator.mimeTypes
  for (const mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true
    }
  }
  return false
}

function getCurrentBrowser() {
  const ua = navigator.userAgent.toLocaleLowerCase()
  const isMobile = /mobile/.test(ua) && (/iphone|ipad/.test(ua) || /android/.test(ua))
  let browserType = ''

  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = 'IE'
  } else if (ua.match(/firefox/) != null) {
    browserType = 'firefox'
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = 'UC'
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = 'opera'
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = 'baidu'
  } else if (ua.match(/metasr/) != null) {
    browserType = 'sougou'
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = 'QQ'
  } else if (ua.match(/maxthon/) != null) {
    browserType = 'maxthon'
  } else if (ua.match(/chrome/) != null) {
    const is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
    if (is360) {
      browserType = '360'
    } else {
      browserType = 'chrome'
    }
  } else if (/version/.test(ua) && /safari/.test(ua)) {
    browserType = 'Safari'
  } else {
    browserType = 'others'
  }

  browserType = isMobile ? 'Mobile' + browserType : browserType

  return browserType
}

export function setGa4Data(ctx: Context, isFirst = false) {
  const {
    store
  } = ctx
  const auth = store.state.auth || {}
  const klook = (store.state.klook || {})
  const { spm, oid } = calculateSpm(ctx, isFirst)
  const uid = ctx.app?.$cookies?.get('user_id') || auth?.globalId || ''
  const gaInfo = {
    site_name: location.host,
    language: klook.language,
    currency: klook.currency,
    backend_country: auth.user?.countryCode || '',
    ip_country_code: klook.ipCountry,
    platform: klook.platform,
    user_agent: navigator.userAgent,
    browser: getCurrentBrowser(),
    page_object_id: oid,
    // page_open_id: '',
    uid,
    spm
  }
  gpush(gaInfo)
}

function getGaData(key: string) {
  return new Promise((resolve) => {
    if (!process.env.GA4_ID || !key) {
      resolve('')
    } else {
      _gtag('get', process.env.GA4_ID, key, resolve)
    }
  })
}
export const getGaDataWithCache = (key: string) => {
  if (key === 'client_id' && innerState.client_id) {
    return Promise.resolve(innerState.client_id)
  }
  return getGaData(key).then((v) => {
    if (key === 'client_id' && v) {
      innerState.client_id = v as string
    }
    return v
  })
}

const ga4: Plugin = function (ctx, inject) {
  let isFirstRender = true
  inject('getGaData', getGaDataWithCache)
  setGa4Data(ctx, isFirstRender)
  ctx.app.$fireGtm()

  // Page tracking
  ctx.app.router!.afterEach((to) => {
    // 如果是初次渲染（服务端渲染），会导致上报两次 PageView 事件，这里做个拦截
    if (isFirstRender) {
      isFirstRender = false
      return
    }

    setTimeout(() => {
      setGa4Data(ctx)
      if (!to || (to.meta || {}).autoGATrack !== false) {
        ctx.app.$sendGTM({
          event: 'virtualPageView',
          pageType: 'PageView',
          pageUrl: to.fullPath,
          pageName: to.meta.gaPageName || undefined
        })
      }
    }, 0)
  })
}

export default ga4
