import { Plugin } from '@nuxt/types'
import ClientReport from '@klook/client-report'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $clientReport: ClientReport
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  interface Store<S> {
    $clientReport: ClientReport
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $clientReport: ClientReport
  }
}

const plugin: Plugin = function (ctx, inject) {
  inject('clientReport', new ClientReport({
    url: process.env.LOGQUERY_URL_V3!,
    getPageData() {
      return {
        pageName: ctx.route.meta?.[0]?.trackedPageName || ctx.route.name
      }
    },
    platform: ctx.store.state.klook.platform,
    extraMsg: {
      ...ctx.store.state.klook?.pageTemplate ? {
        page_template: ctx.store.state.klook?.pageTemplate
      } : {}
    }
  }))
}

export default plugin
