import { VueConstructor } from 'vue'
import ErrorTip from './error-tip.vue'

export interface ErrorTipOptions {
  content?: string,
  visible?: boolean,
  delay?: number,
}

const defaultOptions: ErrorTipOptions = {
  visible: false,
  delay: 3000
}

let ErrorTipConstructor: any = null

export function errorTipFactory(Vue: VueConstructor, userOptions: ErrorTipOptions) {
  if (Vue.prototype.$isServer) { return null }

  const errorTipNode = document.getElementsByClassName('j-error-tip-wrap')
  if (errorTipNode.length) {
    return
  }

  if (!ErrorTipConstructor) {
    ErrorTipConstructor = Vue.extend(ErrorTip)

    ErrorTipConstructor.prototype.close = function close() {
      this.visible = false
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      this.$destroy()
    }
  }

  const options = { ...defaultOptions, ...userOptions }
  const instance = new ErrorTipConstructor({
    el: document.createElement('div'),
    data: options
  })

  // Change context
  // instance.$root = $parent.$root
  // instance.$parent = $parent

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })

  return instance
}
