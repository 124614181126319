<template lang="html">
  <transition name="top">
    <div v-show="visible" class="cp_transition_tip">{{ message }}</div>
  </transition>
</template>

<script type="ts">
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class MobileWarnTip extends Vue {
}
</script>
<style lang="scss">
  .cp_transition_tip {
    position: fixed;
    top: 48px;
    width: 100%;
    height: 48px;
    font-size: $fontSize-body-s;
    padding: 12px 16px;
    background-color: $color-brand-secondary;
    color: $color-text-primary-onDark;
    display: flex;
    align-items: center;
  }

  .top-enter,
  .top-leave-to {
    transform: translate3d(0, -100%, 0);
  }

  .top-leave,
  .top-enter-to {
    transform: translate3d(0, 0, 0);
  }

  .top-enter-active,
  .top-leave-active {
    transition: all 0.2s;
  }
</style>
