import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import * as types from './types'
import { MutationTree } from '~/node_modules/vuex'
import { Activity } from '~/types/traveller/activity'

const mutations: MutationTree<Activity.State> = {
  [types.SET_PKGS_ADDON_SKU](state, data) {
    const { params, res } = data || {}
    const { pid } = params || {}
    if (pid) {
      // 传入了packageId则以pid添加缓存
      const d = cloneDeep(state.pkgsAddonSku)
      d[pid] = res
      state.pkgsAddonSku = d
    }
  },
  [types.SET_PKGS_ADDON_SCHEDULES_AND_UNITS](state, data) {
    const { params, res } = data || {}
    const { pid } = params || {}
    if (pid) {
      // 传入了packageId则以pid添加缓存
      const d = cloneDeep(state.pkgsAddonSchedulesAndUnits)
      d[pid] = res
      state.pkgsAddonSchedulesAndUnits = d
    }
  },
  [types.SET_PKGS_BASIC_INFO](state, data) {
    const { params, res } = data || {}
    const { pid } = params || {}
    if (pid) {
      // 传入了packageId则以pid添加缓存
      const d = cloneDeep(state.pkgsBasicInfo)
      d[pid] = res
      state.pkgsBasicInfo = d
    }
  },
  [types.SET_COMMON_LOADING_STATE](state, data) {
    state.commonLoadingState = data
  },
  [types.SET_TAG_FILTER_LIST](state, data) {
    state.tagFilter2state.list = data
  },
  [types.SET_TAG_FILTER_VALUE](state, data) {
    state.tagFilter2state.value = data
  },
  [types.SET_PRE_SETTLEMENT_API_DATA](state, data) {
    const { params, res } = data || {}
    state.preSettlementApiData = res
    const { pid } = params || {}
    if (pid) {
      // 传入了packageId则以pid添加缓存
      const d = cloneDeep(state.pkgsPrePriceApiData)
      d[pid] = res
      state.pkgsPrePriceApiData = d
    }
  },
  [types.SET_EXPERIENCES_ABTEST](state, data) {
    state.useAbTestMobilePage = data
  },
  [types.SET_ACTIVITY_INFO](state, { activityId, preview, source }) {
    state.activityId = activityId
    state.preview = preview
    state.source = source
  },
  [types.GET_ACTIVITY_DETAIL](state, data) {
    state.useSection = data.use_section
    state.sectionContent = data.section_content
    state.activityId = data.id
    state.activityName = data.activity_name || state.activityName
    state.instant = data.instant
    state.isCurrentLangPublish = data.is_current_lang_publish || false
    state.published = data.published
    state.countryId = data.country_id
    state.countryName = data.country_name
    state.seoUrl = data.seo_url
    state.countrySeoUrl = data.country_seo_url
    state.cityId = data.city_id
    state.cityName = data.city_name
    state.citySeo = data.city_seo
    state.templateId = data.template_id
    state.address = data.address
    state.videoUrl = data.video_url
    state.mainTagName = data.main_tag_name
    state.mainTagId = data.main_tag_id
    state.templateName = data.template_name
    state.bannerImage = data?.banner_url?.replace(/(.jpg|.jpeg|.png)$/i, '')
    state.lastUpdatedTime = data.last_updated_time
    state.title = data.title
    state.subtitle = data.subtitle
    state.frontendTree = data.frontend_tree || []
    const shareAct = data.share_activity || {}
    state.shareActivity = {
      title: shareAct.title,
      subTitle: shareAct.sub_title,
      image: shareAct.image,
      url: shareAct.url
    }
    state.favorite = data.favorite

    const { latest_best_review: latestBestReview } = data
    state.latestBestReview = latestBestReview && {
      id: latestBestReview.id,
      avatar: latestBestReview.avatar,
      author: latestBestReview.author,
      date: latestBestReview.date,
      rating: latestBestReview.rating,
      content: latestBestReview.content,
      packageName: latestBestReview.package_name,
      needTranslateButton: latestBestReview.need_translate_button,
      translateContent: latestBestReview.translate_content,
      reviewImage:
        latestBestReview.review_image &&
        latestBestReview.review_image.map((v: any) => ({
          imageId: v.image_id,
          imgUrl: v.img_url,
          imgResizeUrl: v.img_resize_url
        })),
      hasReply: latestBestReview.has_reply,
      reply: latestBestReview.reply
        ? {
          content: latestBestReview.reply.content,
          translateContent: latestBestReview.reply.translate_content
        }
        : {}
    }

    state.reviewCount = data.review_count
    state.participate = data.participate
    state.sellingPrice = data.selling_price
    state.marketPrice = data.market_price
    state.score = data.score
    state.participantsFormat = data.participants_format
    state.bestPriceGuarantee = data.best_price_guarantee
    state.activityCancellationType = data.activity_cancellation_type
    state.actBannerV2List = isEmpty(data.act_banner_v_2_list)
      ? []
      : data.act_banner_v_2_list.map((v: any) => ({
        prefix: v.prefix,
        preProcess: v.pre_process,
        suffix: v.suffix,
        imageDesc: v.image_desc
      }))
    state.discount = data.discount
    const seo = data.seo || {}
    state.seo = {
      ...seo,
      topKeywords: seo.top_keywords
    }
    state.multiLanguageIcons = isEmpty(data.multi_language_icons)
      ? []
      : data.multi_language_icons.map((v: any) => ({
        iconKey: v.icon_key,
        iconDesc: v.icon_desc
      }))

    // admin 配置 header icon
    if (data.use_section) {
      state.multiLanguageIcons = isEmpty(data.section_content.icons)
        ? []
        : data.section_content.icons.map((v: any) => ({
          iconType: v.icon_key,
          iconUrl: v.icon_url,
          iconDesc: v.icon_desc
        }))
    }
    const cardTags = data.card_tags || {}
    state.cardTags = {
      percentageOff: cardTags.percentage_off,
      percentageTag: cardTags.promotion_tag,
      skipTheLine: cardTags.skip_the_line,
      newActivity: cardTags.new_activity,
      bestseller: cardTags.bestseller,
      editorChoice: cardTags.editor_choice
    }
    // state.bestPackageId = data.best_package_id
    state.summaryRenderObj = data.use_section
      ? data.section_content.highlight_render_obj
      : data.summary_render_obj
    const packageIdList: number[] = []
    const packages = (data.packages || []).map((v: any) => {
      packageIdList.push(v.package_id)
      return {
        ...v,
        price_show: v.price_show,
        has_discount: v.has_discount,
        redeem_status: v.redeem_status,
        product_tags: v.product_tags,
        specInfoList: v.spec_info || [],
        openDateTip: v.usage_validity_tips,
        auto_select_first_date: v.auto_select_first_date,
        usageValidity: v.usage_validity_render_obj,
        hasStocks: v.has_stocks,
        packageId: v.package_id,
        specAttrIds: v.spec_attr_id || [],
        sellPrice: v.sell_price,
        marketPrice: v.market_price,
        credits: v.credits,
        maxPax: v.max_pax,
        minPax: v.min_pax,
        isOpenTicket: v.is_open_ticket,
        discountDesc: v.discount_desc,
        availableDate: v.available_date,
        from_price: v.from_price,
        to_price: v.to_price,
        promotionDiscount: v.promotion ? v.promotion.discount : 0,
        needPromotionPrice: v.promotion
          ? v.promotion.need_promotion_price
          : false,
        iconList: v.icon_list,
        packageName: v.package_name,
        packageSubName: v.sub_name || '',
        packageOption: v.package_option || '',
        packageTicketType: v.package_ticket_type,
        reminder: v.reminder
      }
    })
    if (data?.isMergePackages) {
      const oldList: any = cloneDeep(state.packages || [])
      state.packages = packages.map((o: any) => {
        const obj = oldList.find((pkg: any) => pkg.packageId === o.packageId) || {}
        return {
          ...obj,
          ...o
        }
      })
    } else {
      state.packages = packages
    }
    state.packageIdList = packageIdList
    state.specList = (data.spec || []).map((v: any) => {
      return {
        id: v.id,
        textType: v.text_type,
        name: v.name,
        attrs: (v.attr || []).map((item: any) => ({
          id: item.id,
          name: item.name,
          tips: item.tips,
          tipsRenderObj: item.tips_render_obj
        }))
      }
    })

    const headingEditable = data.heading_editable || {}
    state.headingEditable = {
      // 套餐详情
      packageOptions: headingEditable.package_options,
      // 活动评论
      reviews: headingEditable.reviews,
      // 预定详情
      bookingInformation: headingEditable.booking_information,
      // 活动概览
      whatToExpect: headingEditable.what_to_expect,
      // faq
      faqs: headingEditable.faqs,
      // 最新评价
      mostRecentReview: headingEditable.most_recent_review
    }

    state.fastEntryList = (data.fast_entry_list || []).map((v: any) => ({
      ...v,
      entryType: v.entry_type,
      subTitle: v.sub_title
    }))

    state.bestPackageId = data.best_package_id

    state.pickupDateDescription = data.date_description

    state.topActivities = data.top_activities
      ? data.top_activities.slice(0, 3)
      : null
    state.otherFaq = (data.covid_faq || []).concat(data.seo_faq || [])
    state.whatWeLoveRenderObj = data.what_we_love_render_obj
    state.reopen = data.reopen

    state.activitySeoLink = data.seo_static_link
      ? data.seo_static_link.map((v: any) => ({
        heading: v.heading,
        linking: v.linking,
        isShowMore: false
      }))
      : []
    // 活动详情中的预定详情
    state.activityBookInfo = [
      {
        type: 'activityConfirmationDetail',
        title: 'activity.v2.label.actinfo.confirmation',
        data: data.confirmation_details_render_obj
      },
      {
        type: 'activityInclusiveOf',
        title: 'activity.v2.label.actinfo.inclusive_of',
        data: data.inclusive_of_render_obj
      },
      {
        type: 'activityNotInclusiveOf',
        title: 'activity.v2.label.actinfo.not_inclusive_of',
        data: data.not_inclusive_of_render_obj
      },
      {
        type: 'activityPackageOptions',
        title: 'activity.v2.label.actinfo.package_option',
        data: data.package_options_render_obj
      },
      {
        type: 'activityItinerary',
        title: 'activity.v2.label.actinfo.itinerary',
        data: data.itinerary_render_obj
      },
      {
        additional: [
          {
            type: 'activityInsiderTips',
            title: 'activity.v2.label.actinfo.insider_tips',
            data: data.insider_tips_render_obj
          },
          {
            type: 'activityAdditionalInformation',
            title: 'activity.v2.label.actinfo.addtional_infomation',
            data: data.additional_information_render_obj
          }
        ]
      }
    ]
    state.mapBoxImageUrl = data.map_box_image_url

    if (data.image_item) {
      const {
        act_images: actImage,
        hide_water_mark: isNoWatermarkActivity
      } = data.image_item
      state.descriptionImages =
        actImage &&
        actImage.map((v: any) => ({
          url: v.image_url,
          desc: v.image_desc,
          alt: v.image_alt,
          width: v.width,
          height: v.height
        }))

      state.isNoWatermarkActivity = !!isNoWatermarkActivity
    }

    if (data.srv_info) {
      const {
        srv_title: title,
        srv_content_render_markdown_obj: contentObj
      } = data.srv_info
      state.srvInfo = {
        srvTitle: title,
        srvContentObj: contentObj
      }
    }

    if (data.eligibility_hint) {
      state.eligibilityHint = {
        title: data.eligibility_hint.title,
        icon: data.eligibility_hint.icon,
        titleColor: data.eligibility_hint.title_color,
        backgroundColor: data.eligibility_hint.background_color,
        contentObj: data.eligibility_hint.content_render_markdown_obj
      }
    }

    state.isNswActivity = data.is_nsw_activity
    state.isJumpOldSettlement = data.is_jump_old_settlement

    state.images = (data.images || []).map((v: any) => ({
      url: v.image_url,
      desc: v.image_desc,
      alt: v.image_alt,
      width: v.width,
      height: v.height
    }))
    const imageItem = data.image_item || {}
    state.usageImages = imageItem.usage_images
    state.bannerImages = imageItem.banner

    state.klookInsider = data.klook_insider

    state.isCountryPublish = data.is_country_publish
    state.isCityPublish = data.is_city_publish

    state.chatInfo = {
      chatUrl: (data.chat_info || {}).chat_url,
      isOpenChat: (data.chat_info || {}).is_open_chat
    }
    state.isSrvActivity = data.is_srv_activity

    state.rebate = data.rebate

    state.bannerUrl = data.banner_url
    state.enableTranslationButton = data.translation_bot
    state.specPriceDesc = data.spec_price && data.spec_price.desc
    state.salesChannels = data.sales_channels
    state.fromPrice = Number(data.from_price) || 0
    state.toPrice = Number(data.to_price) || 0
    state.promotionInfoList = data.promotions || []
    state.havePackageDiscount = data.packages?.some((o: any) => {
      return get(o, 'product_tags.discount_tags.0', false)
    })
    state.addressDesc = data.address_desc

    // 目前用来标记是否是新的样式的openTicket
    state.activityTicketType = data.activity_ticket_type

    state.crosellBanner = data.crosell_banner

    state.tagsInfo = data.tags_info
  },

  [types.GET_ACTIVITY_CARDS](state, data) {
    state.recommendActivities = data.recommend_activities
      ? data.recommend_activities.slice(0, 3)
      : null
    state.relatedActivities = (data.related_activities || []).filter(
      (v: any) => !v.sold_out
    )
    state.relatedAndRecommendActivities = data.related_and_recommend_activities
      ? data.related_and_recommend_activities.map((v: any) => {
        return {
          ...v,
          sell_price: Number(v.sell_price),
          market_price: Number(v.market_price)
        }
      })
      : []
  },

  [types.GET_PROMOTION_DETAIL](state, { status, detail }) {
    if (status) {
      state.fetchPromotionDetailStatus = status
    }

    if (detail) {
      state.promotionDetail = detail
    }

    // 存到缓存
    // if (status === 'success' && cacheKey) {
    //   state.promotionDetailMap = {
    //     ...state.promotionDetailMap,
    //     [cacheKey]: detail
    //   }
    // }
  },

  [types.GET_REVIEW_IMAGES_INFO](state, data) {
    state.customerImageAmount = data.count
    state.reviewGalleryShouldShow = data.reviewGalleryShouldShow
    state.reviewGalleryList = data.imageInfo
  },

  [types.CMS_AND_PREVIEW](state, data) {
    state.preview = data.preview ? 1 : 0
    state.noindexIds = isEmpty(data.CMSGenericItem)
      ? ''
      : data.CMSGenericItem.noindex_ids
  },

  [types.GET_PACKAGE_SCHEDULE](state, data) {
    const { schedules, status } = data
    if (schedules) {
      state.schedules = schedules
    }

    if (status) {
      state.fetchScheduleStatus = status
    }
  },

  [types.GET_PRICE_PACKAGE_SCHEDULE](state, data) {
    const { schedules, status } = data
    if (schedules) {
      state.priceSchedules = schedules
    }

    if (status) {
      state.fetchPriceScheduleStatus = status
    }
  },

  [types.SET_PRICE_PACKAGE_SCHEDULE_STATE](state, status) {
    state.fetchPackagePriceScheduleStatus = status
  },

  [types.SET_PACKAGE_SELECTED_DATE](state, data) {
    state.packageSelectedDate = data
  },

  [types.SET_PACKAGE_ID](state, data) {
    state.packageId = data.packageId
  },

  [types.RESET_CACHED_PACKAGE_ID](state) {
    state.cachedPackageId = 0
    state.cachedShouldResetPackageSelectedDate = false
  },

  [types.SET_CACHED_PACKAGE_ID](state, data) {
    state.cachedPackageId = data.packageId
  },

  [types.SET_CACHED_SHOULD_RESET_DATE](state, data) {
    state.cachedShouldResetPackageSelectedDate = data
  },

  [types.RESET_CACHED_PACKAGE](state) {
    state.cachedPackageId = 0
    state.cachedShouldResetPackageSelectedDate = false
  },

  [types.GET_PACKAGE_DETAIL](state, data) {
    const { status, detail } = data

    if (status) {
      state.fetchPackageDetailStatus = status
    }

    if (detail) {
      detail.use_section = true
      state.packageDetail = {
        price_show: detail.price_show,
        has_discount: detail.has_discount,
        redeem_status: detail.redeem_status,
        product_tags: detail?.product_tags,
        usageValidity: detail.usage_validity_render_obj,
        useSection: detail.use_section,
        sectionContent: detail.section_content,
        enableTranslationButton: detail.enable_translation_button,
        translationButtonStatus: detail.translation_status,
        packageName: detail.package_name,
        packageSubName: detail.package_sub_name,
        packageId: detail.id,
        activityId: detail.activity_id,
        passStandardActivityInfo: detail.pass_standard_activity_info,
        spec: detail.spec,
        activityName: detail.activity_name
      }
    }
  },

  [types.CLEAR_PACKAGE](state) {
    const enableTranslationButton: boolean = state.packageDetail.enableTranslationButton || false
    const translationButtonStatus: boolean = state.packageDetail.translationButtonStatus || false
    state.packageDetail = {
      spec: [],
      activityName: '',
      price_show: null,
      has_discount: false,
      redeem_status: 0,
      product_tags: {},
      useSection: true,
      sectionContent: {},
      enableTranslationButton,
      translationButtonStatus,
      packageName: '',
      packageSubName: '',
      packageId: 0,
      activityId: 0,
      passStandardActivityInfo: null
    }

    state.packageId = 0
  },

  [types.CLEAR_PROMOTION_DETAIL](state) {
    state.promotionDetail = {
      currency: '',
      discount: 0,
      is_subscribed: false,
      original_selling_price: '',
      package_id: 0,
      selling_price: '',
      market_price: '',
      promotion_event: {}
    }
  },

  [types.CLEAR_PACKAGE_OPTIONS](state) {
    // todo 具体清除哪些数据，待确定
    state.packageId = 0
    state.chosenIds = []
  },

  [types.SET_PACKAGE_TIME](state, data = {}) {
    state.selectedTime = {
      ...data,
      arrangementId: data.arrangement_id || data.arrangementId,
      stock: data.stock,
      date: data.date,
      /**
       * 最多可购买数量
       * 取决于当前库存和套餐设置的最大可购买数量的最大值
       * 因为在unit的计算中要使用到，所以放到这里先处理出来
       */
      maxPurchased: Math.min(data.stock, data.maxPax)
    }
  },

  [types.SET_PACKAGE_UNITMAP](state, data) {
    const { prices, inventories = {}, credits_tag } = data
    const {
      selectedTime: { arrangementId = 'default', maxPurchased }
    } = state

    // 必买项总最小购买数量
    const requiredPax = prices
      .filter((v: any) => v.required && (v.price_min_pax || v.min_pax))
      .reduce((acc: number, v: any) => acc + (v.price_min_pax || v.min_pax), 0)

    const unitList = prices.map((v: any) => {
      /**
       * 判断unit是否售罄
       * unit的库存有两种，虚拟库存stock和共享库存（特殊库存）inventories，都是套餐级别的设置
       * inventories的优先级大于stock，如果设置了inventories，stock的值会返回inventories设置的值
       * 如果inventories没有设置则返回stock的值
       * inventories可以设置套餐中单个unit的库存或者多个unit的库存的总和
       * stock是套餐unit的总库存
       * 必买项的最小购买数量理论上不应该小于库存，因为如果是这样，那么这个日期应该是售罄状态而不可选
       * 如果最小购买数量 大于 虚拟库存去除所有必买项的总的最小购买数量，则该unit售罄
       * 如果unit的最小购买数量 大于 特殊库存 则该unit售罄（实际情况是应该判断同一inventory_id的unit是否有库存，交由后端判断）
       */
      const priceMinPax = v.price_min_pax || v.min_pax || 0

      // 非必买项最大库存
      const notRequiredMaxPax = maxPurchased - requiredPax

      let isUnitSoldout = false
      if (notRequiredMaxPax < 0) {
        // 必买项超过库存则所有unit售罄，当前日期售罄
        isUnitSoldout = true
      } else {
        // 当前unit最小购买数量 大于 虚拟库存去所有除必买项的总的最小购买数量
        isUnitSoldout =
          !(v.required && priceMinPax) &&
          Math.max(priceMinPax, 1) > notRequiredMaxPax
      }

      // 特殊库存
      const isInventorySoldOut =
        Math.max(priceMinPax, 1) > inventories[v.inventory_id]

      const count = v.required ? priceMinPax : 0
      return {
        credits_tag,
        price_show: v.price_show,
        has_discount: v.has_discount,
        redeem_status: v.redeem_status,
        product_tags: v.product_tags,
        priceMinPax,
        priceMaxPax: v.price_max_pax || v.max_pax || 0,
        count,
        credits: v.credits,
        priceId: v.price_id || v.id,
        sellingPrice: v.selling_price || v.price || 0,
        marketPrice: v.market_price || 0,
        originalPrice: v.original_price || 0,
        promotionDiscount: v.discount || 0,
        priceName: v.price_name || v.name,
        isSoldOut: isUnitSoldout || isInventorySoldOut,
        isFree: parseFloat(v.selling_price) === 0,
        specPriceDesc: v.spec_price && v.spec_price.desc,
        requireBuy: v.required && priceMinPax,
        requireMinPax: !v.required && count >= priceMinPax && priceMinPax > 1,
        required: v.required,
        skuId: v.sku_id,
        srvSkuInfo: v.srv_sku_info,
        inventoryId: v.inventory_id,
        eventId: v.promotion_event ? v.promotion_event.event_id : 0,
        isInPromotion: v.promotion_event ? !!v.promotion_event.event_id : false,
        isDefault: arrangementId === 'default' // default 的时候 pc 端不显示价格(防止价格闪一下)
      }
    })

    // promotion 活动页不加缓存
    state.unitsMap = {
      // ...state.unitsMap,
      [arrangementId]: {
        inventories,
        unitList
      }
    }
  },

  [types.CHANGE_UNIT_COUNT](
    state: Activity.State,
    { priceId, type }: { priceId: number; type: 'add' | 'minus' }
  ) {
    const {
      unitsMap,
      selectedTime: { arrangementId }
    } = state

    const units = unitsMap[arrangementId]
    if (!units) {
      return
    }

    const { unitList } = units
    const unit = unitList.find(v => v.priceId === priceId)

    if (!unit) {
      return
    }
    const { count, priceMinPax, required } = unit

    if (type === 'add') {
      if (count === 0) {
        unit.count += Math.max(priceMinPax, 1)
      } else {
        unit.count++
      }
    } else {
      if (count === 0) {
        return
      }

      if (count > priceMinPax) {
        unit.count--
      } else if (!required) {
        unit.count -= priceMinPax
      }
    }
  },

  // multi pass 使用，强制把 unit 数量选为1，不管其它任何限制条件，只管是否售罄
  // 注意，如果这里改了，BookingUnitSelector 里面也要改
  [types.SET_UNITS_COUNT_TO_ONE](state: Activity.State) {
    const {
      unitsMap,
      selectedTime: { arrangementId }
    } = state

    const units = unitsMap[arrangementId]
    if (units && units.unitList) {
      units.unitList.forEach((unit: any) => {
        if (!unit.isSoldOut) {
          unit.count = 1
        }
      })
    }
  },

  // 目前只在tobook页刷新时使用！
  [types.BATCH_SET_UNITS_COUNT](
    state: Activity.State,
    payload: { priceId: number; count: number }[]
  ) {
    const {
      unitsMap,
      selectedTime: { arrangementId }
    } = state

    const units = unitsMap[arrangementId]
    if (!units) {
      return
    }

    const { unitList } = units

    // 先校验payload里面的unit是否全部存在
    const priceIdList = unitList.map((u: any) => u.priceId)
    const isAllExist = payload.every((u: any) =>
      priceIdList.includes(u.priceId)
    )
    if (!isAllExist) {
      return
    }

    // 遍历设置count，如果count小于priceMinPax，则使用priceMinPax
    payload.forEach((u: any) => {
      const unit = unitList.find(v => v.priceId === u.priceId)
      if (!unit) {
        return
      }

      const { priceMinPax } = unit
      unit.count = Math.max(priceMinPax, u.count)
    })
  },

  [types.SET_PACKAGE_SCHEDULE](state, { packageId, schedules, minPax }) {
    const obj: any = {}
    state.packageSchedulesMap = {
      [packageId]: schedules.map((v: any) => {
        const data = {
          date: v.date,
          time: dayjs(v.date).format('HH:mm'),
          arrangementId: v.arrangement_id,
          disabled: !v.stock || v.stock < minPax,
          stock: v.stock
        }

        const day = dayjs(v.date).format('YYYY-MM-DD')
        obj[day] || (obj[day] = [])
        obj[day].push(data)

        return data
      })
    }
    state.packageSchedulesFormatInDayMap = {
      [packageId]: obj
    }
  },

  [types.SET_PRICE_PACKAGE_SCHEDULE](state, { packageId, schedules, units }) {
    const { packagePriceSchedulesMap } = state
    if (packagePriceSchedulesMap[packageId]) {
      return
    }

    state.packagePriceSchedulesMap = {
      ...packagePriceSchedulesMap,
      [packageId]: {
        schedules,
        units
      }
    }
  },

  [types.SET_ATTRIBUTES](state, chosenIds) {
    state.chosenIds = chosenIds
  },

  [types.RESET_BOOKING_OPTIONS](state, data) {
    const { packageSelectedDate, selectedTime } = data

    state.packageSelectedDate = packageSelectedDate
    state.selectedTime = selectedTime
  },

  [types.SET_PRESALE_PACKAGES](state, data) {
    state.presalePackages = data
  },

  [types.SET_ACTIVITY_INSTANT](state, data) {
    state.instant = data
  },

  [types.SET_ACTIVITY_REVIEWS](state, data) {
    const reviewsList = (data.item || []).map((v: any) => {
      const { review_image: reviewImage } = v
      return {
        id: v.id,
        author: v.author,
        date: v.date,
        avatar: v.avatar,
        rating: v.rating,
        content: v.content,
        translateContent: v.translate_content,
        needTranslateButton: v.need_translate_button,
        packageName: v.package_name,
        reviewImage:
          reviewImage &&
          reviewImage.map((img: any) => ({
            imgResizeUrl: img.img_resize_url,
            imageId: img.image_id,
            imgUrl: img.img_url
          })),
        hasReply: v.has_reply,
        reply: v.reply
          ? {
            content: v.reply.content,
            translateContent: v.reply.translate_content
          }
          : {},
        hasLiked: v.has_liked,
        likeCount: v.like_count
      }
    })

    const reviews = reviewsList.reduce(
      (acc: any, v: any) => ({
        reviewMap: {
          ...acc.reviewMap,
          [v.id]: v
        },
        reviewIdList: [...acc.reviewIdList, v.id]
      }),
      {
        reviewMap: {},
        reviewIdList: []
      }
    )

    state.reviewIdList = Array.from(
      new Set([...state.reviewIdList, ...reviews.reviewIdList])
    )
    state.reviewMap = { ...state.reviewMap, ...reviews.reviewMap }
    state.reviewTotal = data.total
  },

  [types.CLEAR_REVIEW_LIST](state) {
    state.reviewIdList = []
    state.reviewMap = {}
    state.reviewTotal = 0
  },

  [types.SET_REVIEWS_LIKED](state, id) {
    const { reviewMap } = state

    const review: any = reviewMap[id]

    if (review) {
      review.likeCount += 1
      review.hasLiked += true
      state.reviewMap = {
        ...reviewMap,
        [review.id]: review
      }
    }
  },

  [types.TRIGGER_REVIEW_LIST_VISIBLE](state, visible) {
    state.reviewListVisible = visible
  },

  [types.GET_ACTIVITY_FAQ](state, data) {
    state.activityFaq = data
    state.activityFaqRenderObj = data
  },

  [types.ACTIVITY_FAQ_VISIBLE](state, visible) {
    state.activityFaqVisible = visible
  },

  [types.SET_REOPEN_MODAL_VISIBLE](state, visible) {
    state.activityReopenModalVisible = visible
  },

  [types.SET_REOPEN_REMIND](state, remind) {
    state.reopen.remind = remind
  },

  [types.SHOW_DATE_PICKER](state, payload) {
    if (payload === true || payload === false) {
      state.dateShow = payload
    } else {
      state.dateShow = !state.dateShow
    }
  },

  [types.KEEP_SOURCE_ACTIVITY_DETAIL](state, data) {
    state.sourceActivityDetail = JSON.stringify(data)
  },

  [types.KEEP_SOURCE_PACKAGE_DETAIL](state, data) {
    state.sourcePackageDetail = JSON.stringify(data)
  },

  [types.SAVE_ACTIVITY_TRANSLATE](state, data) {
    if (!state.translateActivityResult) {
      state.translateActivityResult = data
    }
  },

  [types.SAVE_PACKAGE_TRANSLATE](state, data) {
    if (state.packageId && !state.translatePackageResult[state.packageId]) {
      state.translatePackageResult[state.packageId] = data
    }
  },

  [types.CHANGE_TRANSLATE_STATUS](state, data) {
    state.activityContentTranslated = data
  },

  [types.CLEAR_ALL_CLICK](state, payload) {
    state.isClearAllClick = payload
  },
  [types.SET_MODIFICATION_SHOPPING_CART](state) {
    state.modificationShoppingCart = true
  },
  [types.SET_ROUTE_NAME](state, payload) {
    state.routeName = payload
  },
  [types.CHANGE_SCHEDULE_MODE](state, payload) {
    state.isUsingPackagePriceSchedule = payload
  },
  [types.SET_ACTIVITY_PREINFO](state, payload) {
    state.preInfo = payload
  },
  [types.SET_ACTIVITY_DETAIL](state, payload) {
    state.activityDetail = payload
  },
  [types.SET_USER_AGENT](state, payload) {
    state.userAgent = payload
  },
  [types.SET_PREVIEW_EXPIRED](state, payload) {
    state.previewExpired = payload
  },
  [types.SET_CURR_PACKAGE_OPTION_TAB](state, tabName) {
    state.currPackageOptionTab = tabName
  }
}

export default mutations
