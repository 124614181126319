import { MutationTree, ActionTree } from 'vuex'
import { KlookPreferred } from '~/types/traveller/klook-preferred/index.ts'
import apis from '~/share/data/apis'
import { RootState } from '~/store'

const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST'
const SET_PREFERRED_INFO = 'SET_PREFERRED_INFO'
const SET_RSNGES = 'SET_RSNGES'
const SET_BANNER = 'SET_BANNER'
const cms = require('~/share/data/cms/index')

export interface KlookPreferredState {
  preferredBannerList: KlookPreferred.PreferredBannerList
  preferredActivities: KlookPreferred.PreferredActivities
  sectionDescription: KlookPreferred.SectionDescription
  sectionDisclaimer: KlookPreferred.SectionDisclaimer
  sectionName: KlookPreferred.SectionName
  total: KlookPreferred.Total
  ranges: KlookPreferred.Ranges
  banner: KlookPreferred.Banner
}

export const state = () => ({
  preferredBannerList: [],
  preferredActivities: [],
  sectionDescription: '',
  sectionDisclaimer: '',
  sectionName: '',
  total: 0,
  banner: [],
  ranges: []
})

export const mutations: MutationTree<KlookPreferredState> = {
  [SET_ACTIVITY_LIST](state, data) {
    if (data.page === 1) {
      state.preferredActivities = data.data.activities || []
    } else {
      state.preferredActivities = state.preferredActivities.concat(data.data.activities || [])
    }
    state.total = data.data.total
  },
  [SET_PREFERRED_INFO](state, data) {
    state.sectionDescription = data.section_description
    state.sectionDisclaimer = data.section_disclaimer
    state.sectionName = data.section_name
  },
  [SET_RSNGES](state, data) {
    state.ranges = data.ranges || []
  },
  [SET_BANNER](state, data) {
    state.banner = data
  }
}

export const actions: ActionTree<KlookPreferredState, RootState> = {
  getPreferredActivities({ commit }, { countryIds, cityIds, page, limit }) {
    let preferredActivity: any = {}
    return this.$axios
      .$get(apis.preferredActivities, {
        params: {
          country_ids: countryIds,
          city_ids: cityIds,
          page,
          limit
        }
      })
      .then((res: Data.Res) => {
        if (res.success && res.result) {
          const data = res.result
          preferredActivity = data.activity
          commit(SET_ACTIVITY_LIST, {
            page,
            data: preferredActivity
          })
          commit(SET_PREFERRED_INFO, data)
          return true
        } else {
          return null
        }
      })
      .catch(() => {
        return null
      // Ignore
      })
  },
  getRanges({ commit }) {
    return this.$axios
      .$get(apis.preferredRange)
      .then((res: Data.Res) => {
        if (res.success && res.result) {
          commit(SET_RSNGES, { ranges: res.result.filter })
        } else {
          return null
        }
      })
      .catch(() => {
        return null
      // Ignore
      })
  },
  async getHomePageBanner({ commit, dispatch }) {
    const resp = await dispatch('getCMSGenericItemList', {
      type: cms.types.TYPE_HOME_BANNER
    }, { root: true })
    if (resp && resp.photos) {
      commit(SET_BANNER, resp.photos)
    }
  },
  search({ commit }) {
    return this.$axios.$get(apis.searchHit, { params: { query: '香港', start: 1, size: 40 } })
      .then((res: Data.Res) => {
        if (res.success && res.result) {
          commit(SET_ACTIVITY_LIST, {
            page: 2,
            data: res.result
          })
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      // Ignore
      })
  }
}
