import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/id'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/ru'
import 'dayjs/locale/th'
import 'dayjs/locale/vi'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/ms-my'

import updateLocale from 'dayjs/plugin/updateLocale'

import { languageDayjsLocaleMap } from './language'

dayjs.extend(updateLocale)

export {
  languageDayjsLocaleMap
}

type DateType = 1 | 2 | 3
interface DateTypeValue {
  today: string
  tomorrow: string
  soldOut: string
  date: string
}
type TypeDateFormatMap = Record<DateType, DateTypeValue>
export const typeDateFormatMap: TypeDateFormatMap = {
  1: {
    today: 'book.same.day',
    tomorrow: 'book.tomorrow',
    soldOut: 'book.time.soldout',
    date: 'book.time.text'
  },
  2: {
    today: 'activity.v2.label.today_available',
    tomorrow: 'book.tomorrow',
    soldOut: 'activity.v2.label.activity_unavaiable',
    date: ''
  },
  3: {
    today: 'jr.book.same.day',
    tomorrow: 'jr.book.tomorrow',
    soldOut: 'jr.book.sold.out',
    date: ''
  }
}

type TimeType = 'h:mma' | 'h:mm A' | 'A h:mm' | 'HH:mm'
type TimeFormatLanguageMap = Record<TimeType, Data.Language[]>
export const timeFormatLanguageMap: TimeFormatLanguageMap = {
  'h:mma': [
    'en',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-HK',
    'en-IN',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-US'
  ],
  'h:mm A': ['zh-CN', 'zh-TW', 'zh-HK'],
  'A h:mm': ['ko'],
  'HH:mm': ['th', 'vi', 'id', 'ja', 'ms-MY']
}

/**
 * 获取 Dayjs 的语言
 */
function getDayjsLocale(language: Data.Language) {
  return languageDayjsLocaleMap[language] || language
}

/**
 * 通过语言获取时间格式
 */
function getTimeFormatByLanguage(langauge: Data.Language) {
  const keys = Object.keys(timeFormatLanguageMap) as TimeType[]

  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index]
    const langauges = timeFormatLanguageMap[key]

    if (langauges.includes(langauge)) {
      return key
    }
  }

  return ''
}

/**
 * 日期转时间戳（兼容ios）
 */
export function dateToTimeStamp(date: string) {
  const IOS_RE = /iPad|iPhone|iPod/i
  const ua = navigator.userAgent
  const isIOS = IOS_RE.test(ua)
  if (isIOS) {
    return new Date(date.replace(/-/g, '/')).getTime()
  } else {
    return new Date(date).getTime()
  }
}

export type DateFormatType = 1 | 2 | 3 | 4 | 5 | 6
/**
 * time目前有四种显示格式
 * 1、2:00pm
 * 2、2 PM
 * 3、下午 2:00
 * 4、14:00
 **/
export function getTimeStyleType(lang: string) {
  const lang_map: any = {
    1: ['en', 'en-AU', 'en-CA', 'en-GB', 'en-HK', 'en-IN', 'en-MY', 'en-NZ', 'en-PH', 'en-SG', 'en-US', 'ms-MY'],
    2: ['zh-CN', 'zh-TW', 'zh-HK'],
    3: ['ko'],
    4: ['th', 'vi', 'id', 'ja', 'de', 'it', 'fr', 'ru', 'es']
  }
  let type: any = 1

  for (const key in lang_map) {
    if (lang_map[key].includes(lang)) {
      type = key
    }
  }

  return type
}

export function getTimeFormatStr(lang: string) {
  const type = getTimeStyleType(lang)
  let str
  switch (type) {
    case '1':
      str = ' h:mma'
      break
    case '2':
      str = ' h:mm A'
      break
    case '3':
      str = ' A h:mm'
      break
    case '4':
      str = ' HH:mm'
      break
  }
  return str
}

/**
 * 获取标准日期格式
 *
 * type:
 * 1: 2018年08月08日 (default),
 * 2: 2018年08月08日 18:08,
 * 3: 08月08日,
 * 4: 下午 06:08,
 * 5: 2018年08月08日 下午 06:08
 */
export function getStandardDateFormat(
  date: string | Date,
  $t: Function,
  language: Data.Language,
  type: DateFormatType = 1,
  week = '', // d || dd || ddd || dddd 星期几的展示  默认不带
  options?: any // { isOldType = false, isMeridiem = false }
) {
  const { isMeridiem, isOldType } = options || {}
  let datetime = date

  dayjs.locale(isOldType ? language : getDayjsLocale(language))

  // Fix safari
  if (typeof datetime === 'string') {
    datetime = new Date(datetime.replace(/-/g, '/'))
  }

  // type 1, YYYY-MM-DD
  let formatStr = $t('global.standard.date.format')

  if (type === 2) {
    // Not 00:00
    if (datetime.getHours() > 0 || datetime.getMinutes() > 0) {
      formatStr += (isOldType ? getTimeFormatStr(language) : ' HH:mm')
    }
  }

  if (type === 3) {
    formatStr = $t('global.standard.date.format_hide_year')
  }

  if (type === 4) {
    formatStr = getTimeFormatByLanguage(language)
  }

  if (type === 5) {
    formatStr += ` ${getTimeFormatByLanguage(language)}`
  }

  if (type === 6) {
    formatStr = $t('global.standard.date.format')
  }
  // 增加星期几
  if (week && /d+/.test(week)) {
    formatStr += week
  }

  if (isMeridiem) {
    upDateMomentMeridiem(language, $t)
  }

  if (type === 6) {
    // support 00:00
    if (datetime.getHours() >= 0 || datetime.getMinutes() >= 0) {
      formatStr += ' HH:mm'
    }
  }
  return dayjs(datetime).format(formatStr)
}

export function formatActLocalDate(
  date: string,
  $t: Function,
  language: Data.Language,
  options?: any
) {
  const reg = /([-+Z][01][0-9]:[0-5][0-9]|[Zz])/ // 时区
  const dateStr = date.replace(reg, '').split('T').join(' ')
  return getStandardDateFormat(dateStr, $t, language, 2, '', options)
}

export function upDateMomentMeridiem(lang: Data.Language, $t: Function) {
  if (getTimeStyleType(lang) === '3') {
    dayjs.updateLocale(lang, {
      meridiem: function meridiem(hour: number) {
        if (hour < 12) {
          return $t('14311')
        } else {
          return $t('15935')
        }
      }
    })
  }
}

/**
 * 根据文案占位符，格式化日期
 *      participation_date_desc: {start_time}到{end_time}之间可以用
 *      participation_date_map:{
 *      start_time: 2021-01-31 14:12:12,
 *      end_time: 2021-03-31 14:12:12
 *      }
 */

export function formatDateDesc(
  dateDesc: string,
  dateMap: any,
  $t: Function,
  language: Data.Language,
  options: any = { isMeridiem: false, isOldType: false }
) {
  if (!Object.keys(dateMap || {}).length) {
    return dateDesc
  }

  // 除了en-US, 其他语言都用24小时制
  const isENUS = language === 'en' || language === 'en-US'
  if (!isENUS) {
    options.isOldType = false
  }

  for (const key in dateMap) {
    dateDesc = dateDesc.replace(key, formatActLocalDate(dateMap[key], $t, language, options))
  }

  return dateDesc
}

/**
 * 获取标准日期区间格式
 * e.g.
 * '2020-08-08 08:08||18:08' -> '2020年08月08日 上午 08:08 - 下午 06:08'
 * '2020-08-08 2020-08-08' -> '2020年08月08日'
 */
export function getStandardDateRangeFormat(
  range: string,
  $t: Function,
  language: Data.Language
) {
  const terms = range.split(' ')
  const firstTerm = terms[0]
  const lastTerm = terms[1] || ''

  if (!firstTerm) {
    return ''
  }

  if (firstTerm === lastTerm) {
    return getStandardDateFormat(firstTerm, $t, language)
  }

  if (lastTerm.includes('||')) {
    const [startTime, endTime] = lastTerm.split('||')
    const startDatetime = `${firstTerm} ${startTime}`
    const endDatetime = `${firstTerm} ${endTime}`

    return (
      `${
      getStandardDateFormat(startDatetime, $t, language, 5)
      } - ${
      getStandardDateFormat(endDatetime, $t, language, 4)
      }`
    )
  }

  return getStandardDateFormat(firstTerm, $t, language, 5)
}

/**
 * 获取友好的日期格式
 */
export function getFriendlyDateFormat(
  date: any,
  $t: Function,
  language: Data.Language,
  type: DateType = 1
) {
  if (!date) {
    return {
      format: $t(typeDateFormatMap[type].soldOut),
      isRecently: false
    }
  }

  let datetime = date

  // Fix safari
  if (typeof date === 'string') {
    datetime = new Date(date.replace(/-/g, '/'))
  }

  const startTime = dayjs(datetime).format('YYYY-MM-DD')
  const today = dayjs()

  // today
  if (startTime === today.format('YYYY-MM-DD')) {
    return {
      format: $t(typeDateFormatMap[type].today),
      isRecently: true
    }
  }

  // tomorrow
  if (startTime === today.add(1, 'day').format('YYYY-MM-DD')) {
    return {
      format: $t(typeDateFormatMap[type].tomorrow),
      isRecently: true
    }
  }

  // type 2 and 3
  if (!typeDateFormatMap[type].date) {
    return {
      format: getStandardDateFormat(date, $t, language),
      isRecently: false
    }
  }

  // type 1
  return {
    format: $t(typeDateFormatMap[type].date, [
      getStandardDateFormat(datetime, $t, language)
    ]),
    isRecently: false
  }
}

/**
 * 新日期格式化方法，用于新多语言系统
 * @param language 对应的语言
 * @param datetime 要格式化的日期
 * @param formatStr 具体要格式化的格式
 */
export function getNewDateFormat(language: Data.Language, datetime: string, formatStr: string) {
  dayjs.locale(getDayjsLocale(language))
  return dayjs(datetime).format(formatStr)
}

export function getNewFriendlyDateFormat(
  date: string | Date,
  $t: Function,
  language: Data.Language
) {
  date = dayjs(date).format('YYYY-MM-DD hh:mm:ss').toString()
  const diff = dayjs().diff(dayjs(date), 'day')

  if (diff === 0) {
    return $t('today')
  }

  if (diff === 1) {
    return $t('75802')
  }

  if (diff < 7) {
    return $t('75392', { num: diff })
  }

  // 是今年的话就不展示年
  if (dayjs().format('YYYY') === dayjs(date).format('YYYY')) {
    return getStandardDateFormat(date, $t, language, 3)
  }

  return getStandardDateFormat(date, $t, language)
}

// 不足2位则补0，小于等于0则显示00
export function formatTwoDigits(n: number) {
  if (n <= 0) {
    return '00'
  }
  if (n >= 100) {
    return String(n)
  }

  return `00${n}`.substr(-2)
}

export function getCountdownTime(latest_date: string) {
  const now = dayjs()
  const end = dayjs(latest_date)
  const hour = end.diff(now, 'hour')
  const minute = end.diff(now, 'minute') % 60
  const second = end.diff(now, 'second') % 60
  return formatTwoDigits(hour) + ':' + formatTwoDigits(minute) + ':' + formatTwoDigits(second)
}
