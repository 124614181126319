import { Plugin } from '@nuxt/types'
import { initRiskDeepKnow } from '@klook/risk-deepknow-sdk'

const plugin: Plugin = (ctx) => {
  const { klook } = ctx.store.state
  initRiskDeepKnow({
    isPrdEnv: process.env.NODE_ENV === 'production',
    platform: klook.platformMp || klook.platform
  })
}

export default plugin
