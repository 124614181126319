import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import OffScreenFactory from '~/components/common/off-screen'
import { isSearchBot } from '~/share/utils'

const plugin: Plugin = (ctx) => {
  const isBot = process.client ? ctx.store.state.klook.isBot : isSearchBot(ctx.req.headers['user-agent'])

  const component = OffScreenFactory(isBot)

  Vue.component('off-screen', component)
}

export default plugin
