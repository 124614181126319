/**
 * needLogin middleware
 * 当页面需要登录的时候，需要加载此middleware到page component
 * @Component({
    async fetch({ store, query }) {
      const { mock, lng, lat } = query
      await store.dispatch('traveller/home-page/getHomePageData', { mock, lng, lat })
    },
    middleware: ['needLogin']
  })
 */
import { Middleware } from '@nuxt/types'
import api from '~/share/data/apis'

const middleware: Middleware = async function (ctx, next) {
  const { klook } = ctx.store.state
  const lang = klook.language
  let path = 'signin'
  if (klook.utilConfig?.is_guest_checkout) {
    path = 'guest_booking'
  }
  const backUrl = `signin_jump=${encodeURIComponent(ctx.route.fullPath)}`
  const url = lang === 'en' ? `/${path}/?${backUrl}` : `/${lang}/${path}/?${backUrl}`
  try {
    const resp = await ctx.app.$axios.$get(api.verify)
    if (!resp.success) { // 登录态失效
      ctx.redirect(url)
    }
  } catch (_error) { // 接口异常
    ctx.redirect(url)
  }
  next()
}

export default middleware
