import url from 'url'
import uuid from 'uuid/v4'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import get from 'lodash/get'
import { stringify } from 'qs'
import some from 'lodash/some'
import { currencySymbolMap, currencyRateMap } from '~/share/data/currency'
import { imageOptionsMap } from '~/share/data/image'
import apiNameMap from '~/share/data/apis'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getLoginUrl = ({ klook, fullPath }: { klook: Data.Klook, fullPath: string }) => {
  const lang = klook.language
  let path = 'signin'
  if (klook.utilConfig?.is_guest_checkout) {
    path = 'guest_booking'
  }
  const backUrl = `signin_jump=${encodeURIComponent(fullPath)}`
  const url = lang === 'en' ? `/${path}/?${backUrl}` : `/${lang}/${path}/?${backUrl}`
  return url
}

export const getExt = (obj: any) => {
  return encodeURIComponent(JSON.stringify(obj))
}

export function GTMHookItem(
  tags: any,
  nonInteractive = false,
  customKeys = {}
) {
  if (!tags) {
    return
  }
  const { dataLayer } = (window || {}) as any
  if (dataLayer) {
    const item: any = {
      event: 'trackEventCustom',
      eventNonInteractive: nonInteractive
    }
    const tagsArr: any[] = tags.split('|')

    item.eventCategory = tagsArr[0]
    item.eventAction = tagsArr[1]
    item.eventLabel = tagsArr[2] === 'undefined' ? undefined : tagsArr[2]
    item.eventValue = tagsArr[3]

    dataLayer.push({ ...item, ...customKeys })
  }
}

export function isVaildPassportName(name: string) {
  return /^[a-zA-Z\u0020]*$/.test(name)
}
export const hasClass = (el: HTMLElement, name: string) => {
  const re = new RegExp('\\b' + name + '\\b')
  return re.test(el.getAttribute('class') || '')
}
export const addClass = (el: HTMLElement, name: string) => {
  if (!hasClass(el, name)) {
    el.setAttribute('class', `${el.getAttribute('class')} ${name}`)
  }
}

export const removeClass = (el: HTMLElement, name: string) => {
  const re = new RegExp('\\b' + name + '\\b')
  const str = el.getAttribute('class') || ''
  el.setAttribute('class', str.replace(re, ''))
}

/**
 * 获取时区信息  +08：00
 */
export function getTimeZone() {
  return dayjs().format('Z')
}

/**
 * Generate a href
 */
export function href(
  pathname: string,
  language: Data.Language,
  baseLink: string = process.env.BASE_LINK || ''
) {
  let lang = ''
  if (
    language &&
    language !== 'en' &&
    pathname &&
    !pathname.startsWith('/' + language)
  ) {
    lang = `/${language}`
  }

  return baseLink + lang + pathname
}

/**
 * 生成随机字符串，截取 UUID 前 8 位
 */
export function randomString() {
  return uuid().slice(0, 8)
}

/**
 * url 模板
 *
 * example:
 * urlTemplate('/api/:foo/:bar', { foo: 1, bar: 2 }) => '/api/1/2'
 */
export function urlTemplate(url: string, params: any) {
  return url.replace(/:([a-z]+)/gi, (_m, p1) => {
    if (!params[p1]) {
      throw new Error(`url parameter not find: ${url}`)
    }

    return params[p1]
  })
}

/**
 * 千分位格式化
 *
 * example:
 * 10000 -> 10,000
 */
export function formatPriceThousands(value: number | string) {
  const price = (value || '0').toString()
  let result = ''

  if (!price.includes('.')) {
    result = price.replace(/(?=(?!(\b))(\d{3})+$)/g, ',')
  } else {
    const terms = price.split('.')
    result = `${terms[0].toString().replace(/(?=(?!(\b))(\d{3})+$)/g, ',')}.${
      terms[1]
    }`
  }

  return result
}

/**
 * 格式化 reviews
 * @param reviewsCount
 */
export function formatReviewThousands(reviewsCount: number | string) {
  const review = (reviewsCount || '0').toString()
  let tmp = '0'
  if (!review.includes('.')) {
    tmp = review.replace(/(?=(?!(\b))(\d{3})+$)/g, ',')
  }
  if (review.length > 6) {
    tmp = '999K+'
  }
  return tmp
}

export function parseParticipants(num: number) {
  let str

  if (num >= 10000) {
    str = '10k+'
  } else if (num >= 1000 && num < 10000) {
    // eslint-disable-next-line unicorn/prefer-exponentiation-operator
    str = Math.floor((num / 1000) * Math.pow(10, 1)) / Math.pow(10, 1) + 'k'
  } else if (num < 1000) {
    str = num
  }

  return str
}

/**
 * 获取路由的 query 参数
 */
export function getQueryKey(key: string, query: any): string {
  if (!query || !key) {
    return ''
  }
  const value = query[key] || ''

  if (Array.isArray(value) && value.length) {
    return value[0]
  }

  return value
}

export function b64EncodeUnicode(str: string) {
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (
      _: any,
      p1: any
    ) {
      const s: any = '0x' + p1
      return String.fromCharCode(s)
    })
  )
}

/**
 * 高亮关键词
 */
export function highlight(str: string, keyword: string) {
  if (keyword) {
    const reg = new RegExp(`(${keyword})`, 'gi')
    return str.replace(reg, '<b class="highlight">$1</b>')
  }

  return str
}

interface TransformCase {
  camel2under: (data: any) => any // 改变数据转换成驼峰规则 并返回自身
  under2camel: (param: any) => any // 改变数据转换成下划线规则 并返回自身
}

export const transformCase: TransformCase = [
  {
    name: 'camel2under',
    reg: /([a-z][A-Z])/g,
    transform: (_str: string, $1: string) => $1[0] + '_' + $1[1].toLowerCase()
  },
  {
    name: 'under2camel',
    reg: /(_[a-z])/g,
    transform: (_str: string, $1: string) => $1[1].toUpperCase()
  }
].reduce((res, { name, reg, transform }) => {
  const changeCase = (data: any) => {
    switch (Object.prototype.toString.call(data).slice(8, -1)) {
      case 'Array':
        data.forEach(changeCase)
        break
      case 'Object':
        Object.entries(data).forEach(([key, value]) => {
          changeCase(value)
          if (reg.test(key)) {
            data[key.replace(reg, transform)] = value
            delete data[key]
          }
        })
    }
    return data
  }
  return Object.assign(res, {
    [name]: changeCase
  })
}, {} as TransformCase)

// 只有在mounted中使用该class 否则会在node端编译错误  node端没有window.IntersectionObserver
// 目标dom进入root视点函数
export class EasyIntersectionObserver {
  public el: Element[] = []
  public io: IntersectionObserver

  constructor(
    el: Element | NodeListOf<Element> | string | Array<Element>,
    cb: IntersectionObserverCallback,
    options?: IntersectionObserverInit
  ) {
    this.io = new window.IntersectionObserver(cb, options)
    el && this.getEl(el)
  }

  // 该方法可以替换监控的对象  或者初始化没有监控对象  getEl可以触发监控
  getEl(el: Element | NodeListOf<Element> | string | Array<Element>) {
    if (this.el && this.el.length) {
      this.unobserve()
    }
    let _el: Element[] = []
    if ((el as NodeListOf<Element> | string).length) {
      if (typeof el === 'string') {
        el = document.querySelectorAll(el) || []
      }
      _el = Array.from(el as NodeListOf<Element>)
    } else if (el) {
      _el = [el as Element]
    }
    ;(this.el = _el).length && this.observe()
  }

  // 开始观察
  observe(_el?: Element) {
    if (_el) {
      if (!this.el.includes(_el)) {
        this.el.push(_el)
        this.io.observe(_el)
      }
    } else {
      this.el.forEach((_el: Element) => this.io.observe(_el))
    }
  }

  // 停止观察
  unobserve(index?: number) {
    if (typeof index !== 'number') {
      this.el.forEach(_el => this.io.unobserve(_el))
      this.el = []
    } else {
      const [_el] = this.el.splice(index, 1)
      this.io.unobserve(_el)
    }
  }

  // 关闭观察器
  disconnect() {
    this.unobserve()
    this.io.disconnect()
  }
}

interface DiffDay {
  (...dates: Date[]): number
  (date1: Date, date2: Date, isAbsVale: any): number
}

export const diffDay: DiffDay = (
  date1: Date,
  date2: Date,
  isAbsVale: any = true
) => {
  const dates = [date1, date2]
  if (
    dates.length === 2 &&
    dates.every(
      d => Object.prototype.toString.call(d).slice(8, -1) === 'Date'
    )
  ) {
    const [d1, d2] = dates.map(d =>
      Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
    )
    let res = d1 - d2
    if (isAbsVale) {
      res = Math.abs(res)
    }
    return Math.floor(res / 86400000)
  }
  return 0
}
interface QueryString {
  /** @function queryString
   * 内部根据第一个参数类型为 string 或者 object(已过滤函数和数组等) 调用 str2obj 或 obj2str并传参剩余参数 否则返回第一个参数
   *  直接调用即可 例如 queryString('?aaa=123&bbb=456') queryString({aaa: 123})
   */
  (data: any, ...options: Array<boolean | undefined>): any

  /** @function queryString.str2obj
   * @param parseNum: boolean 是否解析number
   * @param deep: boolean 是否把重复出现的key组成一个数组
   * 例如 aaa=aaa&bbb=123&bbb1=456&bbb=789&ddd=123将被解析为
   * {
   *  aaa: 'aaa',
   *  bbb: [123, 789],
   *  bbb1: '456', //parseNum 为false
   *  ddd: 123 //parseNum 为true
   * }
   */
  str2obj(query: string, parseNum?: boolean, deep?: boolean): object

  /** @function queryString.obj2str
   * @param hasQuestion: boolean 是否需要返回的结果以 ？开头（false 不以&开头）
   * @param deep: boolean 是否递归深度解析
   * 例如 {
   *  aaa: 'aaa',
   *  bbb: [123, {bbb1: 456}, [789]],
   *  ccc: {
   *    ddd: 123
   *  }
   * } 将被解析为 aaa=aaa&bbb=123&bbb1=456&bbb=789&ddd=123
   */
  obj2str(params: object, hasQuestion?: boolean, deep?: boolean): string
}

export const queryString: QueryString = Object.assign(
  (data: any, ...options: Array<boolean | undefined>) => {
    switch (Object.prototype.toString.call(data).slice(8, -1)) {
      case 'Object':
        return queryString.obj2str(data as object, ...options)
      case 'String':
        return queryString.str2obj(data as string, ...options)
      default:
        return data
    }
  },
  {
    str2obj(query: string, parseNum = false, deep: boolean = false): object {
      return query
        .slice(query.indexOf('?') + 1)
        .split('&')
        .reduce((res, str: string) => {
          // eslint-disable-next-line prefer-const
          let [key, val] = str.split('=')
          if (parseNum && /^-?\d+(\.\d+)?$/.test(val)) {
            // @ts-ignore
            val = +val
          }
          if (!res[key] || !deep) {
            res[key] = val
          } else if (Array.isArray(res[key])) {
            res[key].push(val)
          } else {
            res[key] = [res[key], val]
          }
          return res
        }, {} as any)
    },
    obj2str(
      params: { [key: string]: any },
      hasQuestion = false,
      deep = false
    ): string {
      let res = ''
      for (const key in params) {
        let val = params[key]
        if (!val && typeof val !== 'number') {
          val = ''
        }
        res +=
          '&' +
          (deep && typeof val === 'object'
            ? Array.isArray(val)
              ? val
                .map((item: any) =>
                  queryString.obj2str({ [key]: item }, false, true)
                )
                .join('&')
              : queryString.obj2str(val, false, true)
            : `${key}=${val.toString()}`)
      }
      return res.replace(/^&/, hasQuestion ? '?' : '')
    }
  }
)

/** 截取 querstring 单个字段的值
 * @param name: string
 * @param query?: string 被解析的字符串，没有传 并且在客户端会按照location.search 解析
 */
export function parseQueryString(name: string, query?: string): string {
  if (!query) {
    if (arguments.length === 1 && typeof window === 'object') {
      query = window.location.search
    } else {
      return ''
    }
  }
  const [, res = ''] =
    new RegExp('[?|&]*' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(query) || []
  return res && decodeURIComponent(res.replace(/\+/g, '%20'))
}

export const isDayjsBetween = (
  date: any,
  dateStart: Array<dayjs.Dayjs | string> | dayjs.Dayjs | string,
  dateEnd?: dayjs.Dayjs | string
) => {
  const dateArr = (dateEnd
    ? [dateStart, dateEnd]
    : Array.isArray(dateStart)
      ? dateStart
      : []) as Array<dayjs.Dayjs | string>
  if (
    dateArr.length ===
    2 /* && dateArr.every(date => date && (typeof date === 'string' || dayjs.isDayjs(date))) */
  ) {
    if (!dayjs.isDayjs(date)) {
      date = dayjs(date || undefined) // 今天
    }
    return !['Before', 'After'].some((method, index) =>
      date[('is' + method) as 'isBefore' | 'isAfter'](dateArr[index], 'day')
    )
  }
  // eslint-disable-next-line no-console
  console.warn('isDayjsBetween params error')
  return false
}

export const formatPrice = (price: number | string) => {
  price = price + ''
  return /^-*\d+(\.\d+)*/.test(price)
    ? price.replace(/(?=(?!(\b))(\d{3})+(\.\d+)?$)/g, ',')
    : '0'
}

// 数组的 filter + map
export const filterMap = <T = any>(
  arr: T[],
  cb: (currentValue: T, currentIndex: number, array: T[]) => any,
  ctx: any = null
): any[] =>
  arr?.length
    ? arr.reduce((res, ...args) => {
      const val = cb.apply(ctx || res, args)
      if (val) {
        res.push(val)
      }
      return res
    }, [] as any[])
    : arr

export function parseUrlQuery() {
  const url = window.location.search
  const theRequest: any = {}
  if (url.includes('?')) {
    const str = url.split('?')[1]
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[
        decodeURIComponent(strs[i].split('=')[0])
      ] = decodeURIComponent(strs[i].split('=')[1])
    }
  }
  return theRequest
}

export function loadScript(url: string) {
  return new Promise((resolve) => {
    const head =
      document.getElementsByTagName('head')[0] || document.documentElement
    const script: any = document.createElement('script')

    script.src = url

    let loadFlag = false

    script.onload = script.onreadystatechange = function () {
      if (
        !loadFlag &&
        (!this.readyState ||
          this.readyState === 'loaded' ||
          this.readyState === 'complete')
      ) {
        loadFlag = true

        resolve()

        script.onload = script.onreadystatechange = null
        if (head && script.parentNode) {
          head.removeChild(script)
        }
      }
    }

    head.insertBefore(script, head.firstChild)
  })
}

export function isEmptyObject(obj: object) {
  return obj === null || undefined
    ? true
    : (() => {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false
        }
      }
      return true
    })()
}

// 找到头部元素，并判断是否需要底部 box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2)
export function changeFixedHeaderStyle(needShadow: boolean) {
  const fixedHeader = document.querySelector(
    'header.layout-default_header'
  ) as HTMLElement
  if (fixedHeader) {
    fixedHeader.style.boxShadow = needShadow ? '' : 'none'
  }
}

export function isSearchBot(ua: string = '') {
  return /(Sogou web spider)|(bingbot)|(Googlebot)|(Baiduspider)|(AdsBot)|(TweetmemeBot)|(Slackbot)|(James BOT)|(Applebot)|(Facebot)|(YandexMobileBot)|(AhrefsBot)|(contxbot)|(Livechat OpenGraph Robot)|(Mail.RU_Bot)|(archive.org_bot)|(MojeekBot)|(Discordbot)|(startmebot)|(Yeti\/)/i.test(
    ua
  )
}

export function formatThousands(
  n: number | string = 0,
  formatter: string = ','
): string {
  const number = n.toString()
  // 如果价格中包含 ',' 则直接返回价格
  if (number.includes(',')) {
    return number
  }
  if (number.includes('.')) {
    const numberArray = number.split('.')
    return `${numberArray[0].replace(/(?=(?!(\b))(\d{3})+$)/g, formatter)}.${
      numberArray[1]
    }`
  }

  return number.replace(/(?=(?!(\b))(\d{3})+$)/g, formatter)
}

/**
 * 百分比
 */
export function percent(numerator: number, denominator: number) {
  if (denominator === 0) {
    return 0
  }

  return Math.round((numerator / denominator) * 100)
}

// 价格等数字千分位分隔符
export function formatNumberInThousands(number: string | number): string {
  if (!number) {
    return '0'
  }

  const numberString = number.toString()
  if (!numberString.includes('.')) {
    return formatThousands(numberString)
  } else {
    const numbers = numberString.split('.')
    return `${formatThousands(numbers[0])}.${numbers[1]}`
  }
}

// 千位以上数字取约数
export function approximateThousands(n: number): string {
  if (n < 1e4) {
    return n + ''
  } else if (n < 1e6) {
    return Math.floor(n / 1000) + 'k'
  } else {
    return '999k+'
  }
}

// 千位以上数字取约数（新版-shirley版）
export function approximateThousandsNew(n: number, hasSuffix = true): string {
  n = Number(n)
  const suffix = hasSuffix ? '+' : ''

  if (n < 1e3) {
    return n + ''
  } else if (n < 1e6) {
    return Math.floor(n / 1e3) + 'K' + suffix
  } else if (n < 1e9) {
    return Math.floor(n / 1e6) + 'M' + suffix
  } else {
    return Math.floor(n / 1e9) + 'B' + suffix
  }
}

// 穷举数组的所有组合，不考虑顺序不同
export function group(m: any[]): any[] {
  const result = []
  const len = m.length
  for (let i = 1; i < 2 ** len; i++) {
    const eligibleCollections = []
    // i的第n位是否位1
    for (let j = 0; j < len; j++) {
      if ((i >> j) & 1) {
        eligibleCollections.push(m[j])
      }
    }
    result.push(eligibleCollections)
  }
  return result
}

/**
 * @Author: mingming.chen
 * @Date: 2020-02-04
 * @description: 根据星级增加描述
 * @function: starDesc
 * @param {Function} $t 翻译函数
 * @param {Number} rating
 * @param {boolean} ratingHide 评分低于ratingHide 不展示描述
 * @return:描述
 */
export function starAppraise($t: Function, rating: number, ratingHide: number) {
  const starAppraiseObj: any = {
    1: 'review_not_recommended',
    2: 'review_unsatisfactory',
    3: 'review_average',
    4: 'review_good',
    5: 'review_highly_recommended'
  }
  if (rating >= ratingHide) {
    const t = $t(starAppraiseObj[rating / 20])
    return t
  }
}

/**
 * @Author: qiliang.yang
 * @Date: 2020-04-08
 * @description: 加载 css 文件
 * @param {String} url css 文件地址
 */

export function loadCss(url: string) {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  document.getElementsByTagName('head')[0].appendChild(link)
}

/**
 * @Author: qiliang.yang
 * @Date: 2020-04-15
 * eg: strFormat('<{0} class="{1}">{2}</{0}>',tag, className, content,tag)
 */

export function strFormat() {
  const format = /{([\d\w.]+)}/g
  let args = Array.prototype.slice.call(arguments)
  let v
  const str = args.shift() + ''
  if (args.length === 1 && typeof args[0] === 'object') {
    args = args[0]
  }
  format.lastIndex = 0
  return str.replace(format, function (m, n) {
    v = args[n]
    return v === undefined ? m : v
  })
}

/*
 * 是否是内部链接
 * */
export function isInternalLink(link: string) {
  return link.includes('klook.')
}

/**
 * 货币汇率转换
 * @param price 价格
 * @param current 当前货币
 * @param target 转换后的目标货币
 */
export function currencyExchange(
  price: number | string,
  current: string,
  target: string = 'HKD'
): number {
  const HKDRate = currencyRateMap[`${current}HKD`] || 1
  const targetRate = currencyRateMap[`${target}HKD`] || 1

  return (Number(price) * HKDRate) / targetRate
}
export function copyText(text: string, callback: any) {
  const input = document.createElement('input')
  document.body.appendChild(input)
  input.setAttribute('value', text)
  input.select()
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    callback()
  }
  document.body.removeChild(input)
}

export function getKlookReferer() {
  if (/https?:\/\/(.*.klook.(io|com)|localhost:3004)/.exec(document.referrer)) {
    return document.referrer
  }
  return ''
}

export function checkPhone(phone: string) {
  if (!phone || typeof phone !== 'string') {
    return false
  }
  const temp = phone.split('-')
  const reg = +temp[0] === 86 ? /^\d{11}$/ : /^\d{6,18}$/
  return reg.test(temp[1])
}

export function checkEmail(email: string) {
  const reg = /^[a-zA-Z0-9_-]+(\.([a-zA-Z0-9_-])+)*@[a-zA-Z0-9_-]+[.][a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*$/

  return reg.test(email)
}

export function accAdd(arg1: string | number, arg2: string | number) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = 10 ** Math.max(r1, r2)
  return (accMul(arg1, m) + accMul(arg2, m)) / m
}

export function accMinus(arg1: number, arg2: number) {
  let r1, r2, m
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  // eslint-disable-next-line prefer-const
  m = 10 ** Math.max(r1, r2)
  return (accMul(arg1, m) - accMul(arg2, m)) / m
}

export function accMul(arg1: string | number, arg2: string | number) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    // console.error(e);
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    // console.error(e);
  }
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m
}

export function accAddToStr(arg1: string | number, arg2: string | number) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const n = Math.max(r1, r2)
  const m = 10 ** n
  return ((accMul(arg1, m) + accMul(arg2, m)) / m).toFixed(n)
}

/**
 * 字符串转数字
 * @param number 字符串数字
 */
export function toNumber(number: string | number) {
  const n = Number(number)
  return isNaN(n) ? 0 : n
}

interface position {
  coords: {
    latitude: number
    longitude: number
  }
}
/**
 * 获取定位经纬度
 * @return {
 *   coords: {
        latitude: number,
        longitude: number
      }
 * }
 */
export async function getLocation(): Promise<position | undefined> {
  // 模拟定位，url带setlatlng参数时用参数的值作为当前位置
  // "22.29763,114.17215"
  if (
    window.location.href.includes('setlatlng') &&
    process.env.NODE_ENV !== 'production'
  ) {
    const latlng = parseUrlQuery().setlatlng
    const latlngArr = latlng.split(',')
    return {
      coords: {
        latitude: latlngArr[0],
        longitude: latlngArr[1]
      }
    }
  }

  if (navigator.geolocation) {
    try {
      return await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          maximumAge: 60000,
          timeout: 10000
        })
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('geolocation error', e)
    }
  }
}

export function imageFormat(
  imgSrc: string,
  type: string = 'default',
  retina?: boolean,
  highQuality?: boolean
) {
  let newImgUrl = imgSrc

  const option = imageOptionsMap[type]

  if (option) {
    // 引用类型会导致w, h增加
    const imageOptions = { ...option }

    if (retina) {
      const { w, h } = imageOptions
      w && (imageOptions.w = w * 2)
      h && (imageOptions.h = h * 2)
    }

    const options = imageOptions
    const w = options.w && options.w > 0 ? `,w_${options.w}` : ''
    const h = options.h && options.h > 0 ? `,h_${options.h}` : ''
    const size = w || h ? `c_fill${w}${h}` : ''
    const blur =
      options.blur && options.blur > 0 ? `e_blur:${options.blur}` : ''
    let quality =
      options.quality && options.quality > 0
        ? `q_${options.quality}`
        : 'q_auto'
    if (!highQuality) {
      quality = `fl_lossy.progressive,${quality}`
    }
    const customize =
      options.customize && options.customize !== '' ? options.customize : ''
    let mark = options.waterMark
      ? 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3'
      : ''

    // 判断原链接是否有 size、blur、quality、water mark 参数
    // 若原链接有以上参数，则利用配置参数替换原链接参数

    // 匹配图片大小
    if (size) {
      newImgUrl = newImgUrl.replace(/\/[^/]*c_fill*[^/]*\//, '/')
    }
    // 匹配高斯模糊
    if (blur) {
      newImgUrl = newImgUrl.replace(/\/[^/]*e_blur:(\d*)*[^/]*\//, '/')
    }
    // 匹配图片质量
    if (quality) {
      newImgUrl = newImgUrl.replace(/\/[^/]*fl_lossy\.progressive*[^/]*\//, '/')
    }
    // 匹配水印
    const matchWaterMark = /\/[^/]*_water*[^/]*\//.test(newImgUrl)
    if (matchWaterMark) {
      mark = '/'
    }
    const prefix = [size, quality, blur, mark, customize]
      .filter(item => item !== '')
      .join('/')
    const temp = `image/upload/${prefix}/`
    newImgUrl = newImgUrl.replace('image/upload/', temp)
  }

  return newImgUrl
}

// export function isMainSite(host: string, cookies: any) {
//   if (process.env.APP_ENV === 'production') {
//     return host === 'klook.com'
//   } else {
//     return cookies.get('web_site_type') === 'com'
//   }
// }

// export function isCnSite(host: string, cookies: any) {
//   if (process.env.APP_ENV === 'production') {
//     return host === 'klook.cn'
//   } else {
//     return cookies.get('web_site_type') === 'cn'
//   }
// }

export function isLandingPage() {
  return (
    !document.referrer ||
    (!document.referrer.includes('klook.com') &&
      !document.referrer.includes('klook.cn'))
  )
}

export const isAffiliate = (vm: any) => {
  return !isEmpty(vm.klook.affiliateConf)
}

export const getAffiliateItemConf = (
  vm: any,
  key: string,
  defaultVal: boolean = true
) => {
  if (!isAffiliate(vm)) {
    return defaultVal
  }
  return get(vm.klook.affiliateConf, key, defaultVal)
}

export const whiteListVertical = (vm: any, menus: any) => {
  if (isAffiliate(vm)) {
    const vertical_menu_types = getAffiliateItemConf(
      vm,
      'partner_web_config.vertical_menu_types',
      false
    )
    if (vertical_menu_types) {
      menus = treeFilter(
        menus,
        (node: any) =>
          node.partner_extra_info &&
          node.partner_extra_info.category &&
          vertical_menu_types.includes(node.partner_extra_info.category)
      )
    }
  }
  return menus
}

/**
 * 递归过滤节点，生成新的树结构
 * @param {Node[]} nodes 要过滤的节点
 * @param {node => boolean} predicate 过滤条件，符合条件的节点保留
 * @return 过滤后的节点
 */
export const treeFilter = (nodes: any, predicate: any) => {
  // 如果已经没有节点了，结束递归
  if (!(nodes && nodes.length)) {
    return []
  }

  function nodeOrChild(node: any) {
    if (predicate(node)) {
      return true
    }
    if (node.sub_menu && node.sub_menu.items && node.sub_menu.items.length) {
      for (const childnode of node.sub_menu.items) {
        if (predicate(childnode)) {
          return true
        }
      }
    }
    return false
  }

  const newChildren = []
  for (const node of nodes) {
    if (nodeOrChild(node)) {
      // 如果节点符合条件，直接加入新的节点集
      newChildren.push(node)
      node.sub_menu &&
        (node.sub_menu.items = treeFilter(node.sub_menu.items, predicate))
    }
  }
  return newChildren
}

export const getDateFormat = (date: any) => {
  const year = date.getFullYear() // 获取完整的年份(4位,1970-????)
  let month = date.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
  month = month < 10 ? '0' + month : month
  let d = date.getDate() // 获取当前日(1-31)
  if (d < 10) {
    d = '0' + d
  }
  return year + '-' + month + '-' + d
}

export const getHDKPrice = (price: any, currency: any, currencyRate: any) => {
  if (currency === 'HKD') {
    return price
  }
  return (price * currencyRate[currency + 'HKD']).toFixed(1)
}
// 链接去掉https://www.klook.com
export const replaceUrlHost = (url: string) => {
  const reg = /https?:\/\/(www.)?klook.com+/gi
  const isblog = /blog/.test(url)
  if (reg.test(url) && !isblog) {
    url = url.replace(reg, '')
  }
  return url
}

export function parseUrl(link: string) {
  if (!link) {
    return
  }

  try {
    // url.parse即将废弃，做一下兼容
    // eslint-disable-next-line node/no-deprecated-api
    return process.client ? url.parse(link) : new url.URL(link)
  } catch (e) {}
}

export function getPath(pathname: string): string {
  const urlParsed = parseUrl(pathname)
  if (!urlParsed) {
    return pathname
  }

  const { protocol, host } = urlParsed
  if (protocol && host) {
    const reg = new RegExp(`${protocol}//${host}`)
    return pathname.replace(reg, '')
  }

  return pathname
}

/**
 * @Author: Logan(logan.qiao@klook.com)
 * @Date: 2020/8/28 3:20 下午
 * @Description: 诸如网络请求需要用try...catch...语句捕获异常等繁琐操作（同时代码结构不够平整）
 * 该方法通过传入Promise对象，返回第一个参数为error对象的数组，将try...catch坐在内部逻辑里，外部无需try...catch包裹
 * https://klook.slab.com/drafts/%E5%9F%BA%E4%BA%8E-promise%E7%9A%84try-catch%E5%A4%84%E7%90%86-bzbm16wh
 * res = request => 都会走resolve的逻辑
 */
export function async2(p: Promise<any>): Promise<[Error | null, any]> {
  return new Promise((resolve) => {
    p.then((resp: any) => {
      resolve([null, resp])
    }).catch((err) => {
      resolve([err, null])
    })
  })
}

/**
 * 简单的价格*数量，保持价格小数位数
 * @param price 价格
 * @param count 数量 -可选-
 */
export function fixedPrice(price: any, count?: any) {
  const temp = price.toString().split('.')
  let tofixedLength = 0
  if (temp.length > 1) {
    tofixedLength = temp[1].length
  }
  const result = price * (parseInt(count) > 1 ? parseInt(count) : 1)
  return result.toFixed(tofixedLength)
}

type EditableInput = HTMLTextAreaElement | HTMLInputElement

const selectText = (
  editableEl: EditableInput,
  selectionStart: number,
  selectionEnd: number
) => {
  const isIOS = navigator.userAgent.match(/ipad|iPod|iphone/i)
  if (isIOS) {
    const range = document.createRange()
    range.selectNodeContents(editableEl)

    const selection = window.getSelection() as Selection // current text selection
    selection.removeAllRanges()
    selection.addRange(range)
    editableEl.setSelectionRange(selectionStart, selectionEnd)
  } else {
    editableEl.select()
  }
}

const copyToClipboard = (value: string): Promise<any> => {
  return new Promise((resolve) => {
    const el = document.createElement('textarea') // temporary element
    el.value = value

    el.style.position = 'absolute'
    el.style.left = '-9999px'
    el.readOnly = true // avoid iOs keyboard opening
    el.contentEditable = 'true'

    document.body.appendChild(el)

    selectText(el, 0, value.length)

    if (document.execCommand('copy')) {
      document.body.removeChild(el)
      resolve(true)
    } else {
      resolve(false)
    }
  })
}

export { copyToClipboard }

interface Obj {
  [key: string]: any
}

export const deleteObjKeys = function (Obj: Obj, keyList: string[]) {
  keyList.forEach(key => delete Obj[key])
}

// kepler 实验所需的公共函数
// 实验名称和组名称是标定一个实验命中情况的参照
export function checkExperimentHit(
  experimentsHitList: Data.IKeplerExpsGroup,
  experimentName: string
) {
  // 从当前上下文获取命中的实验列表
  if (!experimentsHitList) {
    return () => false
  }

  const hitExp = experimentsHitList[experimentName]

  if (!hitExp) {
    return () => false
  }

  return (groupName: string) => hitExp.group?.name === groupName
}

/**
 *
 * @param type 'data-spm-page', 'data-spm-module', 'data-spm-item'
 * @param element
 * @param dataSpmString
 */
export const updateDataSpm = (
  type: string,
  element: Element,
  dataSpmString: string
) => {
  element && element.setAttribute(type, `${dataSpmString || ''}`)
}

/**
 * @param str kepler_id
 */
export function hashCode(str: string): number {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    )
}

export interface StyleConfig {
  [propName: string]: any
}

export interface CardStyle {
  style_id: number
  style_name: string
  percent: number
  style_config: StyleConfig
}

export interface StandardCardStyleModel {
  card_id: number
  card_name: string
  card_version: string
  card_styles: Array<CardStyle>
}

export interface CardSchemaModel {
  style_config: any
  style_name: string
  style_id: number
  card_name: string
  card_id: number
}

export function fetchCardStyleModel(
  cardModel: StandardCardStyleModel,
  localPercent: number
): CardSchemaModel {
  let cardStyleModel: CardStyle | null = null
  let percent = 0
  if (cardModel && cardModel.card_styles) {
    if (cardModel.card_styles.length === 1) {
      cardStyleModel = cardModel.card_styles[0]
    }
    for (let i = 0; i < cardModel.card_styles.length; i++) {
      const cardStyle = cardModel.card_styles[i]
      if (cardStyle.percent === 100) {
        cardStyleModel = cardStyle
        break
      }
      if (cardStyle.style_name === 'default') {
        cardStyleModel = cardStyle
      } else if (cardStyle.percent > 0 && cardStyle.percent < 100) {
        percent += cardStyle.percent
        if (percent > 0 && percent > localPercent) {
          cardStyleModel = cardStyle
          break
        }
      }
    }
    return {
      style_config: cardStyleModel?.style_config,
      style_name: cardStyleModel?.style_name || '',
      style_id: cardStyleModel?.style_id || 0,
      card_name: cardModel.card_name,
      card_id: cardModel.card_id
    }
  } else {
    return {
      style_config: {},
      style_name: '',
      style_id: 0,
      card_name: '',
      card_id: 0
    }
  }
}

/**
 * 判断是否为skytone
 */

export const isSkytone = (vm: any) => {
  return getAffiliateItemConf(vm, 'partner_name') === 'skytone'
}

/**
 * 是否是目的地页面
 * @expmple https://www.klook.com/zh-CN/city/2-hong-kong-things-to-do/
 */
export const parseDestinationUrl = (url: string) => {
  if (process.env.NODE_ENV !== 'development' && !url.includes('klook')) {
    return null
  }

  const matches = url.match(/\/(city|coureg|country)\/(\d+)-.+/)

  if (!matches) {
    return null
  }

  return {
    type: matches[1] === 'city' ? 'city' : 'country',
    id: matches[2]
  }
}

export const queryStringify = (obj: Record<string, any>) => {
  return stringify(obj)
}

type OneLinkConfig = {
  linkId: number
  marketId: 1 | 2
  origin?: string
}
export function getOneLinkPrefix(config: OneLinkConfig) {
  const { linkId, marketId, origin } = config

  // 生成二维码链接的时候需要带上当前的域名，所以增加 origin 参数
  const browser = origin || process.env.API_URL_BROWSER
  return `${browser}v1/usrcsrv/or?i_=${linkId}&m_=${marketId}`
}

export const getMerchantId = (vm: any) => {
  let merchantId = ''
  if (isAffiliate(vm)) {
    merchantId = get(
      vm.klook.affiliateConf,
      'aid_extra_info.payment_info.merchantId',
      ''
    )
  }
  return merchantId
}

/**
 *  guest checkout
 */
export function setXosPage(apiPath: string) {
  return hasApiPageAtList(apiPath, 'guestHideApi')
}

export function hasApiPageAtList(pathStr: string, type: string) {
  // const list = hide_api_and_page_conf[type]
  /* eslint-disable-next-line */
  console.log('test code', type)

  const list: any = [] // test code
  return list && some(list, (item) => {
    return pathStr.includes(item)
  })
}

export async function initIsApplePayEnable() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const isIOS = userAgent.includes('iphone') || userAgent.includes('ipad')
  const version = isIOS && userAgent.match(/cpu iphone os (\d+)_(\d+)/i)
  const versionMatch =
    version && version.length && version[1] + version[2] > '111'
  if (isIOS && window.ApplePaySession && versionMatch) {
    try {
      return await initMerchantIdentifier()
    } catch (err) {
      /* eslint-disable no-console */
      console.log((err as any).message)
      /* eslint-enable no-console */
      return false
    }
  } else {
    return false
  }
}

export async function initMerchantIdentifier(sdkType = 'applepay_ntt_data') {
  const isPrd = process.env.APP_ENV === 'production'
  let merchantIdentifier
  switch (sdkType) {
    case 'applepay_ntt_data': {
      merchantIdentifier = isPrd
        ? 'merchant.com.ntt.klooktravel'
        : 'merchant.com.ntt.klooktravel.sandbox'
      break
    }
    case 'applepay_adyen': {
      merchantIdentifier = isPrd
        ? 'merchant.com.adyen.klooktravel'
        : 'merchant.com.klooktravel.test'
    }
  }
  const result = await window.ApplePaySession.canMakePaymentsWithActiveCard(
    merchantIdentifier
  )
  return result
}

// Find component downward
export function findComponentDownward(context: any, componentName: string): any {
  const childrens = context.$children
  let children = null

  if (childrens.length) {
    for (const child of childrens) {
      const name = child.$options.name
      if (name === componentName) {
        children = child
        break
      } else {
        children = findComponentDownward(child, componentName)
        if (children) { break }
      }
    }
  }
  return children
}

export const logWhiteLabelError = (ctx: any, logError: any) => {
  const { logquery } = ctx?.req || {}
  if (!logquery) { return false }
  logquery.service({
    isMasked: true,
    timestamp: Date.now(),
    level: 'E',
    funcName: 'wlSdk.responseError',
    message: JSON.stringify(logError)
  })
  return true
}

export const checkFlickketSendCode = (str?: string, reg?: RegExp) => {
  const regFlickketSendCode = reg || /(flickket\.com$)/ // 产线
  const host = str || ((window && window.location) ? window.location.host : '')
  const platfromEnv = parseQueryString('platform_env')
  return platfromEnv === 'flickket' || regFlickketSendCode.test(host)
}

export const formatCurrencySymbolPriceThousands = (currency: Data.Currency, price: string | number) => {
  const currencySymbol = currencySymbolMap[currency] || ''
  const priceThousands = formatPriceThousands(price) || ''
  return `${currencySymbol} ${priceThousands}`
}

export const routerPush = (vm: any, to: any) => {
  // 由于 wl 的header和footer要根据sdk初始化，所以wl一律使用window.location.href跳转
  if (vm?.klook?.utilConfig?.whiteLabelUtilConfig) {
    window.location.href = vm.$router.resolve(to).href
  } else {
    vm.$router.push(to)
  }
}

export const getCss = (dom: any, key: any) => {
  if (!dom) { return }
  return dom?.currentStyle ? dom.currentStyle[key] : getComputedStyle(dom, null)[key]
}

// 将hex颜色转成rgb
export const hexToRgba = (hex: string, opacity: string) => {
  const red = parseInt('0x' + hex.slice(1, 3))
  const green = parseInt('0x' + hex.slice(3, 5))
  const blue = parseInt('0x' + hex.slice(5, 7))
  const RGBA = `rgba(${red}, ${green}, ${blue}, ${opacity})`
  return RGBA
}

export const parseStr = (str = '', obj: any) => {
  return str.replace(/\{\{([^}]+)\}\}/g, (...args) => {
    return obj[args[1]]
  })
}

export const getElementTop = (el: any, parentEl?: any) => {
  let actualTop = el?.offsetTop
  let current = el?.offsetParent
  while (current !== null) {
    if (current === parentEl) {
      current = null
    } else {
      actualTop += current.offsetTop
      current = current.offsetParent
    }
  }
  return actualTop
}

export const isInViewPortOne = (el: any, offsetHeight = 0) => {
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  const offsetTop = getElementTop(el)
  const scrollTop = document.documentElement.scrollTop
  const top = offsetTop - scrollTop
  return top <= viewPortHeight + offsetHeight // +offsetHeight提前加载
}

export const isInViewPortAll = (el: any) => {
  if (!window || !document || !el?.getBoundingClientRect) { return false }
  const viewPortHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  const viewPortWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  const { top, right, bottom, left } = el.getBoundingClientRect()
  return (
    top >= 0 && left >= 0 && bottom <= viewPortHeight && right <= viewPortWidth
  )
}

export const translateArrangementId = (id: number) => {
  if (!id) { return }
  const str = '' + id
  const package_id = Number(str.substring(10))
  const start_time = dayjs(Number(str.substring(0, 10) + '000')).utc().format('YYYY-MM-DD HH:mm:ss')
  return {
    package_id,
    start_time
  }
}

export const getBookingNowParams = (arrangement_id: number, sku_list: any[], specParams?: Record<string, any>) => {
  const { wifiSimParams, otherParams } = specParams || {}
  const params = {
    sku_list: sku_list?.map((o: any) => {
      return {
        sku_id: o.sku_id,
        count: o.count
      }
    }),
    ...translateArrangementId(arrangement_id),
    ...(wifiSimParams || {}),
    ...(otherParams || {})
  }
  return {
    booking_package_list: [params]
  }
}

export const getPkgTotalPrice = (units: any[]) => {
  if (isEmpty(units)) {
    return 0
  }

  // 小数位
  const parts = (units[0]?.sellingPrice || '')?.toString().split('.')
  const decimal = parts[1] || ''

  const price = units.reduce((acc, v) => acc + v.sellingPrice * v.count, 0)

  return price.toFixed(Number(decimal.length))
}

export const getAddCount = (units: any[], key = 'count') => {
  return units?.reduce((count: number, o: any) => {
    count += o[key]
    return count
  }, 0) || 0
}

interface CheckPkgConfigIf {
  tipsFn: Function
  $t: Function
  pkgDate: string
  pkgTime: string
  pkgUnitCount: number
  pkgMinPax: number
}
export const checkPkgConfirm = (data: CheckPkgConfigIf, options?: any) => {
  if (typeof data?.tipsFn !== 'function') { return false }
  const { $t, tipsFn, pkgDate, pkgTime, pkgUnitCount, pkgMinPax } = data || {}
  const defOpt = {
    showMessage: false,
    defResult: false,
    tipsMap: {
      pkgDate: $t('activity.booking.options.select.date'),
      pkgTime: $t('activity.booking.options.select.time'),
      pkgUnitCount: $t('activity.booking.options.select.quantity'),
      pkgMinPax: $t('activity.error.price_min', [pkgMinPax])
    }
  }
  const opt = merge({}, defOpt, options || {})
  const { defResult, tipsMap, showMessage } = opt

  if (!pkgDate) {
    showMessage && tipsFn(tipsMap.pkgDate)
    return defResult
  }

  if (!pkgTime) {
    showMessage && tipsFn(tipsMap.pkgTime)
    return defResult
  }

  if (!pkgUnitCount) {
    showMessage && tipsFn(tipsMap.pkgUnitCount)
    return defResult
  }

  if (pkgUnitCount < Number(pkgMinPax)) {
    showMessage && tipsFn(tipsMap.pkgMinPax)
    return defResult
  }

  return {
    data,
    options,
    opt
  }
}

export const getLangPathStr = (klookLanguage: string, path = '') => {
  const langPath = klookLanguage || ''
  if (!langPath || langPath === 'en') {
    return '/' + path
  }
  return '/' + langPath + '/' + path
}

export const checkEventIncludeId = (eTarget: any, findId: string): boolean => {
  if (!eTarget || eTarget?.nodeName === 'BODY') {
    return false
  }
  if (eTarget.id === findId) {
    return true
  }
  return checkEventIncludeId(eTarget.parentElement, findId)
}

export function resizeImg(src: string, width: number, height: number) {
  // 容错
  if (!src) {
    return src
  }

  // 后端返回了尺寸
  if (src.includes('c_fill')) {
    return src
  }

  return src.replace('image/upload/', `image/upload/q_85/c_fill,w_${width},h_${height}/`)
}

export const getSearchType = (params: any) => {
  const { city_id, country_id, category_id, pid } = params || {}
  const cid = city_id ? Number(city_id) : 0
  const gid = country_id ? Number(country_id) : 0
  const cateid = category_id ? Number(category_id) : 0
  const poiId = pid ? Number(pid) : 0

  if (poiId) {
    return 'Experiences Explorer Page'
  } else if (cid <= 0 && gid <= 0) {
    return cateid > 0 ? 'Experiences Category Global Page' : 'Experiences Vertical Page'
  } else if (gid > 0) {
    return cateid > 0 ? 'Experiences Country Cate Page' : 'Experiences Country Page'
  } else if (cid > 0) {
    return cateid > 0 ? 'Experiences City Cate Page' : 'Experiences City Page'
  }

  return ''
}

export const fetchInternalLink = async (params: any, $axios: any) => {
  let res = null
  try {
    res = await $axios
      .$get(apiNameMap.getInternalLink,
        {
          timeout: process.client ? 8000 : 300,
          params: {
            country_id: params?.country_id || undefined,
            city_id: params?.city_id || undefined,
            search_type: getSearchType(params)
          }
        })
  } catch (error) {
    //
  }
  return res
}

export async function fetchPreInfo(params: any, $axios: any) {
  const res = {
    redirect_url: '',
    redirect_query: {},
    requestErrorCode: ''
  }

  try {
    const { success, result, error } = await $axios
      .$get(apiNameMap.getSeoPreInfo,
        {
          timeout: process.client ? 8000 : 3000,
          params: {
            frontend_id: params.frontendId,
            city_id: params.cityId,
            country_id: params.countryId,
            source_url: params.path,
            page_id: params.page_id,
            poi_id: params.poi_id,
            tag_id: params.tag_id
          }
        })

    if (success && result) {
      const url = result.redirect_url
      const [redirectUrl, query = ''] = url.split('?')
      res.redirect_url = redirectUrl
      query && (res.redirect_query = formatRedirectUrlQuery(query))
    } else if (error) {
      res.requestErrorCode = (error as any)?.code
    }
  } catch (error) {
    res.requestErrorCode = (error as any)?.code
  }

  return res
}

function formatRedirectUrlQuery(query: string) {
  const returnQuery: any = {}

  // 兼容 query = 空的场景
  if (!query) {
    return returnQuery
  }

  const list = query.split('&') || []

  list?.length && list.forEach((item: string) => {
    const [key, value] = item.split('=')
    returnQuery[key] = value
  })

  return returnQuery
}

export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255
  g /= 255
  b /= 255
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0
  let s = 0
  const l = (max + min) / 2
  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }
  return [h, s, l]
}

export const autoHexToRgb = (R: number, G: number, B: number) => {
  return {
    HSL: rgbToHsl(R, G, B),
    fontColor: rgbToHsl(R, G, B)[2] > 0.6 ? '#000000' : '#ffffff'
  }
}

export const hexToDec = (hex: string) => {
  const R = parseInt('0x' + hex.slice(1, 3))
  const G = parseInt('0x' + hex.slice(3, 5))
  const B = parseInt('0x' + hex.slice(5, 7))
  return [R, G, B]
}

export const autoColorToRgb = (hex: string) => {
  const [R, G, B] = hexToDec(hex)
  return {
    HSL: rgbToHsl(R, G, B),
    fontColor: rgbToHsl(R, G, B)[2] > 0.6 ? '#000000' : '#ffffff'
  }
}

export function isExperiencesListEndPath(path: string) {
  const reg = /.*\/experiences\/list\/$/

  return reg.test(path)
}

export function isExperienceEndPath(path: string) {
  const reg = /.*\/experiences\/$/

  return reg.test(path)
}

export async function fetchLandingPageInfo(params: any, $axios: any) {
  const res = {
    redirect_url: '',
    redirect_query: {}
  }

  const { success, result } = await $axios
    .$get(apiNameMap.getAttractionsLandingPagePreInfo,
      {
        timeout: process.client ? 8000 : 300,
        params: {
          frontend_id: params.frontendId,
          city_id: params.cityId,
          country_id: params.countryId,
          source_url: params.path
        }
      })

  if (success && result) {
    const url = decodeURIComponent(result.redirect_url)
    const [redirectUrl, query = ''] = url.split('?')
    res.redirect_query = formatRedirectUrlQuery(query)
    res.redirect_url = redirectUrl
  }

  return res
}

export const getIsAttractionsRoute = (routePath: string, regList: RegExp[] = [/^\/attractions\//]) => {
  const isb = regList.some((reg: RegExp) => {
    return reg.test(routePath)
  })
  return isb
}

export function isChromeOrSafari() {
  const u = navigator.userAgent.toLocaleLowerCase()
  // 判断是否Safari浏览器
  const isSafari = u.includes('safari') && !u.includes('chrome')
  const isChrome = u.includes('chrome')
  // 排除qq浏览器（qq浏览器，返回webkit内核）
  const isQQ = u.includes('qqbrowser')

  return (isSafari || isChrome) && !isQQ
}
