import CryptoJS from 'crypto-js'

const crpytoConfig = {
  AES_KEY: 'exp-activity-aes',
  AES_IV: 'qwertyuiopasdfghjklzxcvbnm'
}

/**
 * @description aes加密
 * @param {string} word 需要加密的字符串
 */
export function aesEncryptBase64(word: string) {
  const { AES_IV, AES_KEY } = crpytoConfig
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(AES_IV)
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}

/**
 * @description aes解密
 * @param {string} word 需要解密的字符串
 */
export function aesDecryptBase64(word: string) {
  const { AES_IV, AES_KEY } = crpytoConfig
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(AES_IV)
  const encryptedHexStr = CryptoJS.enc.Base64.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypt.toString(CryptoJS.enc.Utf8).toString()
}

/**
 * @description aes加密
 * @param {string} word 需要加密的字符串
 */
export function aesEncryptHex(word: string) {
  const { AES_IV, AES_KEY } = crpytoConfig
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(AES_IV)
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex)
}

/**
 * @description aes解密
 * @param {string} word 需要解密的字符串
 */
export function aesDecryptHex(word: string) {
  const { AES_IV, AES_KEY } = crpytoConfig
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(AES_IV)
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypt.toString(CryptoJS.enc.Utf8).toString()
}
