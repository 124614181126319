


























import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class Message extends Vue {
  // @XXX: hack this
  handleBtnYesClick(this: any) {
    this.confirmCallback(true)
    this.close()
  }

  // @XXX: hack this
  handleBtnNoClick(this: any) {
    this.confirmCallback(false)
    this.close()
  }
}
