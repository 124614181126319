// @ts-ignore
import { tokens } from '@klook/klook-ui/lib/utils/design-token'

const isServer = function () {
  return typeof window === 'undefined'
}

const getEnv = function () {
  return process.env.NODE_ENV || 'development'
}

const getPublishIds = function () {
  if (isServer()) {
    return getEnv() === 'production' ? '10' : '5'
  } else {
    return ''
  }
}

interface Obj {
  [key: string]: any
}

const getFormattedColorMap = function (colorMap: Obj) {
  const newColorMap: Obj = {}
  Object.keys(colorMap).forEach((key) => {
    if (key.indexOf('$') === 0) {
      newColorMap[key.slice(1)] = colorMap[key]
    } else {
      newColorMap[key] = colorMap[key]
    }
  })

  return newColorMap
}

export default {
  apiUrl: 'https://www.klook.com/v1/locpublishserv/publish/texts',
  // locale cms request publish ids
  localesRequestPublishIds: getPublishIds(),
  rootDir: __dirname,
  isDebugMode: false,
  systemId: 16,
  fallbackLocale: 'en',
  testMessages: {
    en: {
      test: 'test'
    },
    'zh-HK': {
      test: '测试'
    }
  },
  interpolation: {
    prefix: '{',
    postfix: '}',
    linkKeyWord: 'MULTILANG_URL_PLACEHOLDER'
  },
  colorMap: getFormattedColorMap(tokens)
}
