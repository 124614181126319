import { ActionTree } from 'vuex'

export interface RootState {}
export const actions: ActionTree<RootState, RootState> = {
  getCityById(_store, id) {
    return this.$axios.$get(`/v1/usrcsrv/city/${id}`).then((res: any) => {
      if (res.success && res.result) {
        return res.result
      } else {
        return Promise.reject(new Error('getCityById error'))
      }
    })
  },

  getCouregById(_store, id) {
    return this.$axios.$get(`/v1/usrcsrv/country/${id}/basic_info`).then((res: any) => {
      if (res.success && res.result) {
        return res.result
      } else {
        return Promise.reject(new Error('getCouregById error'))
      }
    })
  }
}
