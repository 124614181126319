export default async function loginSDK(this: Vue, options: Data.LoginSDKOptions) {
  if (process.server) {
    return
  }

  const { purpose, success, cancel, fallback } = options

  if (this.$store?.getters?.whiteLabelConfig) {
    // const fallbackValue = fallback ? await fallback() : true
    // // 只有 fallbackValue 为 false 的时候才不跳登录页
    // if (fallbackValue || (typeof fallbackValue !== 'boolean')) {
    //   await this.$store.getters.whiteLabelConfig.sdk.login({
    //     redirectUrl: window.location.href
    //   })
    // }
    // 因为有弹窗的情况，所以先不支持 fallback(跳页才需要fallback)
    await this.$store.getters.whiteLabelConfig.sdk.login({
      redirectUrl: window.location.href
    })
    return
  }

  // 如果是 whitelabel 或者 guest 的话，就跳过 sdk 登录
  if (this.$store?.getters?.isGuestCheckout) {
    const fallbackValue = fallback ? await fallback() : true
    // 只有 fallbackValue 为 false 的时候才不跳登录页
    if (fallbackValue || (typeof fallbackValue !== 'boolean')) {
      window.location.href = this.$href('/login')
    }
    return
  }

  let supportLogin = true
  try {
    const { loginWithSDK } = await import('@klook/klook-traveller-login')
    const { platform, isKlookApp, language, currency, market } = this.$store.state.klook
    // SDK 有 type bug，等 SDK 修复后删掉下面的 as boolean
    supportLogin = (await loginWithSDK({
      market,
      platform,
      isKlookApp,
      language,
      currency,
      purpose,
      bizName: 'ThingToDo',
      success: async () => {
        await this.$store.dispatch('auth/getProfile')
        // 登录成功回调
        success && (await success())
      },
      cancel: async () => {
        // 取消登录回调
        cancel && (await cancel())
      }
    })) as boolean
  } catch (error) {
    supportLogin = false
    console.error(`登录 SDK(@klook/klook-traveller-login) 初始化出错: ${error}`)
  }

  if (!supportLogin) {
    const fallbackValue = fallback ? await fallback() : true
    // 只有 fallbackValue 为 false 的时候才不跳登录页
    if (fallbackValue || (typeof fallbackValue !== 'boolean')) {
      window.location.href = this.$href('/signin')
    }
  }
}
