




import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Skeleton extends Vue {
    @Prop() width!: string
    @Prop() gap!: string
    @Prop({ default: 14, type: [Number, String] }) height!: number | string

    get styles() {
      const styles: any = {}

      if (this.width) {
        styles.width = this.width
      }

      if (this.height) {
        styles.height = `${this.height}px`
      }

      if (this.gap) {
        styles.marginBottom = `${this.gap}px`
      }

      return styles
    }
}
