import { VueConstructor } from 'vue'
import MobileWarnTip from './mobile-warn-tip.vue'

interface Options {
  visible?: boolean,
  message?: string,
  time?: number
}

const install = (Vue: VueConstructor) => {
  if ((Vue.prototype as any).$mobileWarnTip) {
    return
  }

  const defaultOptions: Options = {
    message: 'something wrong happened? Check this message out!',
    time: 5000,
    visible: false
  }
  const TipConstructor = Vue.extend(MobileWarnTip)
  const tip = (userOptions: Options) => {
    if (Vue.prototype.$isServer) { return null }

    const options = { ...defaultOptions, ...userOptions }
    const instance = new TipConstructor({
      el: document.createElement('div'),
      data: options
    })

    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
      instance.visible = true
      setTimeout(() => {
        instance.visible = false
        if (instance.$el && instance.$el.parentNode) {
          instance.$el.parentNode.removeChild(instance.$el)
        }
        instance.$destroy()
      }, instance.time)
    })

    return instance
  }
  Vue.prototype.$mobileWarnTip = function (userOptions: Options) {
    const options = {
      ...userOptions
    }
    return tip(options)
  }
}

export default {
  install
}
