const apiNameMap = {
  getOrderDetailDeeplinkRedirect: '/v1/usrcsrv/cnmigrate/order_detail/deeplink/redirect',
  getInternalLink: '/v1/experiencesrv/vertical/basic_service/internal_link', // internal link
  getSeoInfoV2: '/v1/experiencesrv/vertical/basic_service/seo_info_v2', // explore page
  getThemeActivityDetail: '/v1/experiencesrv/explorepage/explore_service/theme_activity_detail', // explore page
  getThemeActivityList: '/v1/experiencesrv/explorepage/explore_service/theme_activity_list', // explore page
  getThemeBasicInfo: '/v1/experiencesrv/explorepage/explore_service/theme_basic_info', // explore page
  otaConfig: '/v1/usrcsrv/ota/config', // GET
  verify: '/v3/userserv/user/profile_service/get_simple_profile_by_token', // GET 获取用户信息 - 简易版，用于校验 token
  profile: '/v3/userserv/user/profile_service/get_my_profile_by_token', // GET 获取用户信息
  simpleProfile: '/v3/userserv/user/profile_service/get_simple_profile_by_token', // GET 获取用户信息
  jsessionId: '/v1/usrcsrv/session/getkey', // GET jsessionid
  unreview: '/v1/usrcsrv/myreviews/unreview', // GET 获取用户未评价数
  updateBanner: '/v1/usrcsrv/component/campaign/banner', // GET 更新首页banner
  taiwanCoupon: '/v1/cashier/asset_voucher/klook_guolv/query', // GET 个人中心获取台湾国旅券
  menus: '/v1/usrcsrv/vertical/menu?source=3', // GET 分类菜单
  regions: '/v1/websrv/ranges', // GET 地区列表
  hotDestinations: '/v1/usrcsrv/generic/types/102', // GET 热门目的地
  search: '/v1/usrcsrv/search/suggest', // GET 搜索
  search_suggest: '/v1/searchapisrv/activity/custom_service/suggest_v2', // GET 搜索建议
  changeLanguage: '/v3/userserv/user/profile_service/change_language', // POST 设置语言到个人信息里
  changeCurrency: '/v3/userserv/user/profile_service/change_currency', // POST 设置货币到个人信息里
  dealsPage: '/v1/usrcsrv/jenga/page/deals_page', // GET deals page页面信息
  memberShipOrderDetail: '/v1/mpsrv/jenga_page/order_detail', // GET memberShip orderDetail页面信息
  // memberShipOrderDetailMock: 'https://www.fastmock.site/mock/e495ec3582124ae4e701c574f3ae6e63/nuxt/memberShipOrderDetailMock',
  memberShip: '/v1/mpsrv/jenga/page/product_page', // GET memberShip page页面信息
  memberShipBenefit: '/v1/mpsrv/benefit/info', // GET memberShip Benefit detail
  memberShipAddCart: '/v1/mpsrv/order/add/shoppingcart', // POST memberShip booknow
  memberOrderSettlement: '/v1/mpsrv/order/settlement', // POST order settlement
  memberOrderCouponList: '/v1/mpsrv/order/benefit/detail',
  // memberOrderSettlement: 'https://www.fastmock.site/mock/e495ec3582124ae4e701c574f3ae6e63/nuxt/v1/mpsrv/order/add/shoppingcart', // POST order settlement
  memberOrderGenerate: '/v1/mpsrv/order/generate', // order generate
  memberRefundReasons: '/v1/mpsrv/get/refund/reasons', // GET membership获取退款理由接口
  memberRefundApply: '/v1/mpsrv/order/refund/apply', // POST membership申请退款接口
  memberRefundDetail: '/v1/mpsrv/order/refund/detail', // GET membership退款详情接口
  // memberRefundReasonsMock: 'https://www.fastmock.site/mock/e495ec3582124ae4e701c574f3ae6e63/nuxt/get-reason',
  wishListAdd: '/v3/usrcsrv/publish/wishlist/add',
  wishListCancel: '/v3/usrcsrv/publish/wishlist/cancel',

  commonCoupon: '/v1/usrcsrv/refer/amount', // GET 通用的优惠券
  inviteCoupon: '/v1/usrcsrv/refer/friends', // GET 邀请的优惠券
  newInviteCoupon: '/v1/couponapisrv/refer/description', // 邀请的优惠券新接口
  recentlyView: '/v1/usrcsrv/home/recently/viewed', // GET 最近查看的活动和城市
  shoppingCartCount: '/v3/order/shoppingcart/count', // GET 购物车商品数量
  refreshShoppingCartPrice: '/v1/experiencesrv/order/settlement_service/shopping_cart_pre_settlement',
  shoppingCartList: '/v3/order/shoppingcart', // GET 购物车商品列表
  conditions: '/v1/usrcsrv/generic/types/:type', // GET 使用条款、隐私策略、Cookie 策略、价格保障
  emailCreateSubscribe: '/v1/websrv/subscribe/:email/create', // POST 关联订阅邮件
  emailSendSubscribe: '/v1/websrv/subscribe/:email/send', // POST 订阅邮件
  logout: '/v3/usrcsrv/user/logout', // POST 退出登录
  downloadApk: '/web2/download.action', // GET 下载 APK
  destinationNav: '/v1/usrcsrv/destination/guide', // GET 获取目的地导航数据
  affiliateConf: '/v1/affinternalsrv/aid2uid', // aid配置接口

  faqDetail: '/v1/usrcsrv/faq/detail', // GET Faq 详情
  faqCategory: '/v1/usrcsrv/faq/category/get', // GET Category 分类
  faqSearchSuggest: '/v1/usrcsrv/faq/suggest', // GET 搜索建议
  categoryFaqs: '/v1/usrcsrv/faq/get/by/category', // GET Category 分类下的 Faq
  mostHelpfulFaqs: '/v1/usrcsrv/faq/most/helpful/get', // FAQ 首页重构，获取最有帮助的faq
  faqFeedback: '/v1/usrcsrv/faq/comment/add', // POST 反馈
  faqHelpful: '/v1/usrcsrv/faq/helpful/info/add', // POST点赞点踩

  experienceGetBasicInfoAndAddonSchedules: '/v1/experiencesrv/packages/detail_service/get_basic_info_and_addon_schedules', // experiences 结算页 addon新接口
  experiencePostBindAddonPackages: '/v1/experiencesrv/order/pre_order_service/bind_addon_packages', // experiences 结算页 addon confirm接口
  experienceGetAddonPreSettlement: '/v1/experiencesrv/order/settlement_service/addon_pre_settlement', // experiences 结算页 addon新接口
  experienceGetAddonSku: '/v1/experiencesrv/activity/sku_service/addon_arrangement_sku_list', // experiences 结算页 addon新接口
  experiencePostPreSettlement: '/v1/experiencesrv/order/settlement_service/pre_settlement', // experiences post 增减unit 试算接口
  experienceGetBasicInfoByPkgId: '/v1/experiencesrv/packages/detail_service/get_basic_info_by_pkg_id', // experiences 预订下单页：套餐基础信息
  experienceGetBasicInfosByActId: '/v1/experiencesrv/activity/package_service/get_basic_infos_by_act_id', // experiences 预订下单页：套餐列表信息
  experienceFastPassPackageInfos: '/v1/experiencesrv/dock/fast_pass_service/get_package_basic_infos_by_act_id', // experiences Express Go 预订下单页：套餐列表信息
  experiencePromoSystemTagInfo: '/v1/experiencesrv/activity/component_service/get_promo_system_tag_info', // experiences 活动详情；促销领券详情接口
  experienceActivityDetail: '/v1/experiencesrv/activity/component_service/detail', // experiences 活动详情；楼层化接口
  experienceActivityAdminPreview: '/v1/experiencesrv/dock/admin_preview_service/activity_detail', // experiences 活动详情；楼层化接口
  experienceFastPassActivityDetail: '/v1/experiencesrv/dock/fast_pass_service/activity_detail', // experiences 扫码活动详情; 楼层化接口; query 参数：sales_channel=fast_pass&activity_id=524
  experienceActivityDetailForWhiteLabel: '/v1/experiencesrv/dock/white_label_service/activity_detail',
  activityDetail: '/v1/usrcsrv/activity/:activityId/detail', // GET 活动详情页活动数据
  activitySchedules: '/v1/usrcsrv/activity/:activityId/schedules', // GET 活动详情页日期
  activityReviewGallery: '/v1/usrcsrv/activities/:activityId/images/show', // GET 活动详情页reviews的图片的信息
  getActivityCards: '/v1/experiencesrv/activity/detail/cards', // GET 活动详情页活动卡片
  activityReviewGalleryImages: '/v1/usrcsrv/activities/:activityId/images/get', // GET 活动详情页reviews图片
  // activityPackageDetail: '/v1/usrcsrv/packages/:packageId/base/published', // GET 活动详情页套餐详情
  activityPackageDetail: '/v1/experiencesrv/activity/package_service/get_package_detail', // GET 活动详情页套餐详情 后端开关控制是否调用V2接口
  getAttractionPackageDetail: '/v1/experiencesrv/activity/package_service/get_attraction_package_detail', // GET 活动详情页套餐详情V3, 23.12.1
  // activityPackageDetail: '/v1/experiencesrv/activity/package_service/get_package_detail_v2', // GET 活动详情页套餐详情 V2
  // activityPackageDetailPreview: '/v1/usrcsrv/packages/:packageId/base/all', // activityPackageDetail 替代 GET 活动页 preview 模式下的套餐详情
  getActivityReviewsList: '/v1/experiencesrv/activity/component_service/activity_reviews_list', // GET 活动详情页 reviews
  activityReviewsLikeAdd: '/v1/usrcsrv/review/like/add', // 活动详情页评论点击有帮助
  activityReviewsLikeRemove: '/v1/usrcsrv/review/like/remove', // 取消点赞
  reviewFilter: '/v1/usrcsrv/review/filter/info', // GET 评论过滤
  activityCard: '/v1/usrcsrv/activities/:activityIdList/cards',
  activityTranslate: '/v2/usrcsrv/translate', // POST 活动页自动翻译
  activityPriceSchedules: '/v2/usrcsrv/activity/schedules', // GET 获取价格日历
  getActivitySdkInfo: '/v1/attractionbffsrv/clarity/clarity_service/get_clarity_activity_ids', // GET 获取价格日历

  // combon 2.1
  bookingOptionInfo: '/v1/attractionbffsrv/combo/service/get_combo_booking_options',
  bookingOptionTrial: '/v1/attractionbffsrv/combo/service/booking_option_trial',

  // 备注：这里更换了新的接口获取促销
  // activityPromotions: '/v1/usrcsrv/activity/package_promo_price', // GET 获取活动的促销
  activityPromotions: '/v1/experiencesrv/activity/package_service/get_promo_tag_and_promo_price', // GET 获取活动的促销
  activityPackagePromotion: '/v1/usrcsrv/package/promo_price', // GET 获取套餐的促销
  activityPackagePriceList: '/v1/experiencesrv/packages/credits_and_promo_price', // POST 获取套餐卡片价格在某日期下的价格信息
  activityRedirectInfo: '/v1/experiencesrv/activity/detail_service/pre_info', // POST 活动页业务线分流
  activityTemplate: '/v1/prodbasesrv/activities/:activityId/basic', // POST 活动页业务线分流
  expressCheckOutRedirect: '/v1/experiencesrv/dock/fast_pass_service/express_check_out_redirect', // express_check_out入口页重定向
  activityPackageSkuInfoList: '/v1/experiencesrv/activity/package_service/get_package_card_sku_info_by_package_ids', // POST 获取套餐卡片在某日期下的sku信息

  // attractions included
  getPassStandardActivityPreview: '/v1/experiencesrv/activity/package_service/get_pass_standard_activity_preview', // GET 查询 Pass 活动/套餐关联的 Standard 活动的预览信息
  getPassStandardActivityPreviewNew: '/v1/attractionbffsrv/travelpass/pass_service/get_attraction_included_by_pass_id',
  getPassStandardActivityDetail: '/v1/experiencesrv/activity/detail_service/get_pass_standard_activity_detail', // GET 查询 Pass 活动/套餐关联的 Standard 活动的预览信息
  getPassStandardActivityDetailNew: '/v1/attractionbffsrv/travelpass/pass_service/get_standard_activity_detail_by_pass_id', // GET 查询 Pass 活动/套餐关联的 Standard 活动的预览信息
  getPassStandardschedules: '/v1/attractionbffsrv/travelpass/pass_service/get_package_schedules_by_package_and_sku_ids', // GET 查询 Pass 活动/套餐关联的 Standard 活动的预览信息

  // travel pass
  multiPassReservationPackages: '/v1/experiencesrv/travelpass/service/get_reservation_packages',
  getBookingDetailsRefundAbleSkus: '/v1/experiencesrv/order/refund_service/get_refund_able_skus',
  calcBookingDetailsRefundAmount: '/v1/experiencesrv/order/refund_service/calc_refund_amount',
  submitBookingDetailsRefund: '/v1/experiencesrv/order/refund_service/submit_refund',
  multiPassReservationPackagesV2: '/v1/experiencesrv/travelpass/service/get_reservation_packages_v2',
  multiPassReservationPackagesGroup: '/v1/attractionbffsrv/travelpass/pass_service/get_reservation_list',
  // getPassSchedulesAndUnits: '/v1/experiencesrv/travelpass/service/get_pass_schedules_and_units',
  getPassSchedulesAndUnits: '/v1/attractionbffsrv/travelpass/pass_service/get_reservation_schedules_and_units', // pass预约列表优化，新
  getUnitsArrangements: '/v1/usrcsrv/arrangements/units',
  generateBookingGuid: '/v1/experiencesrv/order/shopping_cart_service/add',

  // manage booking
  experienceManageBookingInfos: '/v2/usrcsrv/order/alter/ticket/infos',
  experienceManageBookingRevert: '/v2/usrcsrv/order/alter/ticket/revert',
  experienceManageBookingSubmit: '/v2/usrcsrv/order/alter/ticket/submit',
  experienceManageBookingModify: '/v1/usrcsrv/packages/:packageId/schedules/modify',
  experienceGetSchedulesByBookingAlter: '/v1/experiencesrv/packages/detail_service/get_schedules_by_booking_alter', // Get: booking_ref_no=NHG201359

  // checkout
  experienceNewOrderInfoUrl: '/v3/usrcsrv/order/settlement', // 新增 otherinfo 字段
  experienceOrderSettlement: '/v1/experiencesrv/order/settlement_service/settlement',
  experienceGetOrderDateAndTime: '/v1/experiencesrv/packages/detail_service/get_package_schedules_by_sku_quantity',
  experienceOrderCartSettlement: '/v1/experiencesrv/order/settlement_service/shopping_cart_settlement',
  experienceOrderSettlementWhiteLabel: '/v1/experiencesrv/order/white_label_settlement_service/settlement',
  experienceOrderCartSettlementWhiteLabel: '/v1/experiencesrv/order/white_label_settlement_service/shopping_cart_settlement',
  experienceOrderReSettlement: '/v1/experiencesrv/order/settlement_service/re_settlement',
  experienceOrderCartReSettlement: '/v1/experiencesrv/order/settlement_service/shopping_cart_re_settlement',
  experienceOrderReSettlementWhiteLabel: '/v1/experiencesrv/order/white_label_settlement_service/re_settlement',
  experienceOrderCartReSettlementWhiteLabel: '/v1/experiencesrv/order/white_label_settlement_service/shopping_cart_re_settlement',
  experienceOtherInfoList: '/v1/experiencesrv/order/generate_service/get_traveler_list',
  experienceOrderGenerate: '/v1/experiencesrv/order/generate_service/generate',
  experienceOrderDetail: '/v2/usrcsrv/order/detail',
  experienceOrderMailList: '/v1/experiencesrv/order/generate_service/get_postal_address_list',
  experienceUpdateOtherInfoList: '/v1/experiencesrv/order/generate_service/insert_or_update_traveler_info',
  experienceNswRedeem: '/v1/couponapisrv/nsw/redeem',
  experienceOrderCoupons: '/v2/couponapisrv/canonical/order/coupons',
  couponAndPaymentCoupon: '/v2/couponapisrv/canonical/order/coupon_and_payment_coupon',
  experienceOrderRedeem: '/v2/couponapisrv/canonical/order/redeem',
  experienceOrderCouponsWhiteLabel: '/v1/experiencesrv/dock/white_label_service/get_coupons',
  experienceOrderRedeemWhiteLabel: '/v1/experiencesrv/dock/white_label_service/redeem_coupon',
  getSupplierTimeslots: '/v1/experiencesrv/dock/supplier_service/supplier_timeslots',

  // 离线凭证
  voucherOfflineRedeem: '/v1/order/voucher/offline/redeem',

  // transport ticket ptp
  ptpBasicInfo: '/v1/reappserv/ptp/basic_info', // GET 基础信息
  ptpSearchForm: '/v1/reappserv/ptp/:productType/search/form', // GET 获取搜索表单配置
  ptpSearchId: '/v1/reappserv/ptp/:productType/route/search', // POST 获取搜索 id
  ptpSearchResult: '/v3/reappserv/ptp/:productType/route/search/result', // POST 获取搜索结果及 sort
  ptpSearchFilter: '/v3/reappserv/ptp/:productType/route/search/filter', // GET  获取筛选条件
  ptpPolicy: '/v1/reappserv/ptp/:productType/route/ticket/policy', // GET 获取 ticket policy
  ptpFaq: '/v1/reappserv/rail/:productType/richcontent?action=getFaq', // GET 获取 faq
  ptpRouteDetail: '/v1/reappserv/ptp/:productType/route/detail', // GET 获取路线时刻详情
  ptpRouteSections: '/v3/reappserv/ptp/:productType/route/sections', // GET 获取路线详情接口骨架
  ptpPackageSelectApiName: 'route_detail_package_ticket', // 筛选路线详情接口骨架返回的接口，获取 package select 接口路径
  ptpTicketSelectApiName: 'route_detail_select_ticket', // 筛选路线详情接口骨架返回的接口，获取 ticket select 接口路径
  ptpPriceAdd: '/v3/reappserv/ptp/:productType/route/price/add', // POST 车票详情页计算价格
  ptpValidate: '/v2/reappserv/ptp/:productType/route/validate', // POST 价格和库存校验接口
  ptpAddonDetail: '/v1/reappserv/ptp/:productType/addon/detail', // GET addon 套餐详情
  ptpHomeSections: '/v2/reappserv/rail/homepage/sections', // 垂直页骨架接口
  ptpDlpDetail: '/v1/reappserv/ptp/:productType/dlp_page_detail', // dlp 页面数据接口
  ptpDlpMostRoute: '/v1/reappserv/ptp/:productType/dlp_most_route', // dlp 页面路线统计接口
  ptpStationSearch: '/v3/reappserv/ptp/:productType/location/search', // ptp站点搜索
  ptpNotice: '/v1/reappserv/rail/notice?product_type=:productType&withCredentials=false',
  ptpDlpSearchForm: '/v1/reappserv/ptp/:productType/search/dlp_form', // dlp search configs
  ptpDlpSearchResult: '/v1/reappserv/ptp/:productType/route/search/dlp_result', // dlp search result
  ptpCoupons: '/v1/reappserv/ptp/:productType/order/coupon',
  ptpCouponRedeem: '/v1/reappserv/ptp/:productType/order/redeem',
  ptpSettlementSections: '/v2/reappserv/ptp/:productType/checkout/sections',
  ptpSettlementSettle: '/v1/reappserv/ptp/:productType/order/settlement',
  ptpSettlementOrder: '/v1/reappserv/ptp/:productType/order/create',
  ptpSettlementStatus: '/v1/reappserv/ptp/order/poll/status',
  ptpSettlementGetSeats: '/v3/reappserv/ptp/:productType/seat/map',
  ptpSettlementGetPickupForm: '/v1/reappserv/ptp/:productType/pick_up_option/forms',
  ptpSettlementGetPassengerType: '/v2/reappserv/ptp/:productType/passenger/type',
  ptpSettlementGetAddonDetail: '/v1/reappserv/ptp/:productType/addon/detail',
  ptpSettlementGetAddonSchedule: '/v1/reappserv/ptp/:productType/addon/schedules',

  ptpWhiteLabelHomeSections: '/v1/reappserv/ptp/whitelabel/home/skeleton', // whiteLabel 垂直页骨架接口
  ptpWhiteLabelCheckoutSections: '/v1/reappserv/ptp/whitelabel/checkout/skeleton', // whiteLabel 结算页骨架接口
  ptpWhiteLabelSearchListSections: '/v1/reappserv/ptp/whitelabel/search/result/skeleton', // whiteLabel 搜索结果页配置接口
  ptpWhiteLabelRouteDetailSkeleton: '/v1/reappserv/ptp/whitelabel/route/detail/skeleton', // whitelabel 车票详情骨架接口
  ptpWhiteLabelOrderDetailSkeleton: '/v1/reappserv/ptp/whitelabel/order/detail/skeleton', // whitelabel 订单详情骨架接口

  /***
   * 更新心愿清单
   * @Method POST
   * @Params { cancel_activity_id: activityId } 取消收藏
   * @Params { add_activity_id: activityId } 添加到收藏
   */
  updateWishList: '/v1/usrcsrv/wishlist/update',
  addWishList: '/v1/usrcsrv/wishlist/add',
  cancelWishList: '/v1/usrcsrv/wishlist/cancel',
  getWishStatus: '/v1/usrcsrv/favorite_activity', // GET 获取活动是否加入到心愿清单
  getPackageSchedulesAndUnits: '/v1/usrcsrv/packages/:packageId/schedules_and_units', // GET 套餐的可选时间和unit
  // getPackagePriceSchedulesAndUnits: '/v2/usrcsrv/packages/schedules_and_units', // GET 套餐价格日历
  getPackagePriceSchedulesAndUnits: '/v1/experiencesrv/packages/schedule_service/get_schedules_and_units', // GET 套餐价格日历
  getPackageUnits: '/v1/usrcsrv/arrangements/:arrangementId/units', // GET 获取套餐unit信息
  expPostPreOrderServiceNew: '/v1/experiencesrv/order/pre_order_service/new', // POST 立即预订
  bookingNow: '/v3/order/booking/add', // POST 立即预订
  addToShoppingCard: '/v3/order/booking/edit', // POST 加入购物车
  postExpEditeShoppingCartAndnew: '/v1/experiencesrv/order/pre_order_service/edite_shopping_cart_and_new', // POST 购物车编辑 + 跳结算页 新接口返回 pre_order_id
  getCategoryInfo: '/v3/userserv/user/profile_service/get_user_category_info', // GET 获取用户类别信息
  getPresalePackages: '/v1/usrcsrv/activity/rail/presale/info', // GET 获取预售套餐

  noticeList: '/v1/usrcsrv/notice', // GET 全局通知
  secondCateList: '/v1/usrcsrv/faq/root/category/get', // GET 获取二级分类
  carRentalsAdd: '/v1/transfercarrentalapisrv/carrental/order/noshoppingcart/get_cache_id', // 租车加入购物车
  carRentalOrderDetail: '/v1/transfercarrentalapisrv/carrental/order/detail',
  carRentalRefundReason: '/v1/transfercarrentalapisrv/carrental/order/refund/reason',
  carRentalGetAmount: '/v1/transfercarrentalapisrv/carrental/order/refund/get_amount',
  carRentalApplyAmount: '/v1/transfercarrentalapisrv/carrental/order/refund/apply_refund',
  carRentalRefundDetail: '/v1/transfercarrentalapisrv/carrental/order/refund/detail',
  orderUserCancel: '/v1/experiencesrv/order/refund_service/un_paid_cancel',
  registerGuest: '/v3/usrcsrv/user/register/guest', // 注册guest用户

  // Kepler 系统相关
  // getExperimentsHitList: '/v2/usrcsrv/hit/experiments',
  getExperimentsHitList: '/v1/keplersrv/public/hit/experiments',

  // gift card
  giftCardInformaton: '/v1/usrcsrv/egiftcard', // 购买电子礼品卡活动信息
  accountGiftCard: '/v1/usrcsrv/personal/giftcard', // 账户礼品卡信息
  redeemGiftCardCode: '/v1/order/trades/exchange', // 兑换礼品卡
  redeemCouponCode: '/v1/couponapisrv/redeem', // 兑换优惠券
  getCouponData: '/v1/usrcsrv/coupon/tag/act/entrance', // 获取优惠券信息
  getWalletsData: '/v1/order/wallets', // 礼品卡金额
  getWalletsTrades: '/v1/order/trades',

  preferredActivities: '/v1/usrcsrv/landing_page/preferred/activities', // klook-preferred 页面基础数据
  searchHit: '/v1/usrcsrv/search/hit', // 根据城市 filter 活动列表
  preferredRange: '/v1/usrcsrv/landing_page/preferred', // klook-preferred 页面的 filter 列表数据
  updateReopen: '/v1/usrcsrv/reopen/notice/update',
  activitySeoLink: 'v1/usrcsrv/activity/seo/content',

  homePage: '/v2/usrcsrv/component/page/home', // 首页
  slideWord: '/v1/cardinfocenterservicesrv/public/recommend/get_slide_words', // 轮播词
  loginWay: '/v3/userserv/user/profile_service/get_my_login_ways_by_token', // 获取用户账号绑定信息
  specialTermDetail: '/v3/userserv/user/term_service/get_special_term_detail', // 获取特殊协议
  uncheckSpecialTermDetail: '/v3/userserv/user/term_service/get_user_uncheck_special_term_info', // 获取特殊协议
  whiteLabelGuestTermDetail: '/v1/wlsrv/user/term/get_guest_term_detail', // 获取特殊协议
  updateUncheckSpecialTermDetail: '/v3/userserv/user/term_service/update_user_special_term_status', // 获取特殊协议
  getUserUncheckTermByScene: '/v1/experiencesrv/term/term_service/get_user_uncheck_term_by_scene', // 新的获取条款接口
  authorizeTerms: '/v1/experiencesrv/term/term_service/authorize_terms', // 新的上传条款接口
  homeNearbyAll: '/v2/usrcsrv/nearby/cards/all', // GET 首页所有的nearby数据
  homePopularAll: '/v2/usrcsrv/popular/cards/all', // GET 首页所有的popular activity数据
  wifiFilter: '/v1/usrcsrv/wifi/filter_options', // GET 首页所有的popular activity数据

  siteChange: '/v1/usrcsrv/transformer/jump', // 切换主站分站
  changePreferSite: '/v3/userserv/user/profile_service/change_prefer_site', // 修改偏好站点
  // partnership 手机号领券相关
  getPhoneCouponInfo: '/v1/usrcsrv/generic/types/499', // 手机号领券在CMS配置的TYPE是499
  sendSmsCodeWithCaptcha: '/v3/userserv/user/register_service/send_sms_code_with_captcha',
  checkMobileAccount: '/v3/userserv/user/register_service/check_mobile_account',
  loginBySMS: '/v3/usrcsrv/user/login/mobile_sms',
  redeemCampaign: '/v1/redeemcodesrv/redeem/campaign/:campaignName',

  // 检查账户是否存在
  accountIsExist: '/v3/userserv/user/register_service/account_is_exist',

  // google 登录
  googleLogin: '/v3/usrcsrv/user/login/google',
  googleBind: '/v3/userserv/user/profile_service/bind_google',

  // 条款相关
  getSpecialTerms: '/v3/userserv/user/term_service/get_special_term_detail',
  updateSpecialTermStatus: '/v3/userserv/user/term_service/update_user_special_term_status',

  // dot contact tracking
  SMSVefiry: '/v1/usrcsrv/dot/sms/verify', // GET 手机号验证
  getSMSCode: '/v1/usrcsrv/dot/sms/code', // GET 获取手机验证码
  submitDotEntrance: '/v1/usrcsrv/dot/entrance', // POST 入园信息提交
  submitDotExit: '/v1/usrcsrv/dot/visitor/exit', // POST 出园信息提交
  verifyPhone: '/v1/usrcsrv/dot/get/visitor', // POST 出园信息提交
  getAttraction: '/v1/usrcsrv/dot/get/attraction', // GET 查询园区信息接口

  getRecentlyPurchase: '/v1/usrcsrv/recently/purchased/act/cards', // GET 获取最近常买
  getBookingList: '/v1/usrcsrv/booking/list', // POST 订单列表
  getLogistics: '/v1/usrcsrv/logistics/detail', // GET 物流信息
  bookingPopularList: '/v1/usrcsrv/popular/cards', // GET 订单列表页popular activity数据
  deleteBooking: '/v1/usrcsrv/booking/sort/archive', // POST 删除
  cancelDeleteBooking: '/v1/usrcsrv/booking/cancel_archive', // POST 取消删除
  stickBooking: '/v1/usrcsrv/booking/sort/stick', // POST 置顶
  cancelStickBooking: '/v1/usrcsrv/booking/sort/cancel_stick', // POST 取消置顶
  comboQuery: '/v1/usrcsrv/booking/combo', // GET 联合支付/身份验证信息查询
  bookingManagement: '/v1/order/alter/ticket/infos', // POST 修改订单
  submitBookingManagement: '/v1/order/alter/ticket/submit', // POST 提交修改订单
  revertBookingManagement: '/v1/order/alter/ticket/revert', // POST 回退修改订单
  getHotKeyword: '/v1/usrcsrv/search/hot', // GET 搜索启动页接口
  getHotKeywordNew: '/v1/cardinfocenterservicesrv/search/getStartupPageInfo', // GET 新搜索启动页接口
  getHotKeywordDestination: '/v1/cardinfocenterservicesrv/search/getDestinationPageInfo', // GET 新搜索Destination启动页接口
  getSearchResult: '/v1/cardinfocenterservicesrv/search/platform/', // GET 搜索结果页接口，后加type complete_search | city_search | country_search
  // getCompleteSearchResult: 'https://www.fastmock.site/mock/e495ec3582124ae4e701c574f3ae6e63/nuxt/search/hit',

  // experience
  getExperienceCategory: '/v1/experiencesrv/category/menu', // GET 首页L0+L1选择
  getExperienceDestination: '/v1/experiencesrv/destination/guide', // GET 首页 目的地
  getExperienceActivityNav: '/v1/experiencesrv/category/activity/menu', // GET 获取分类活动列表筛选项
  getExperienceActivityBanner: '/v1/experiencesrv/vertical/basic_service/banner', // GET 获取分类活动banner
  getExperienceHotCountry: '/v1/experiencesrv/hot/country', // GET 获取分类活动列表筛选项
  getExperiencePageInfo: '/v1/experiencesrv/page_info', // GET 获取分类活动列表筛选项
  getExperiencePreInfo: '/v1/experiencesrv/pre_info', // GET 获取分类活动列表筛选项
  getExperienceHotCity: '/v1/experiencesrv/hot/city', // GET 获取分类活动列表筛选项
  getExperienceMainBanner: '/v1/experiencesrv/banner/main', // GET 获取分类活动列表筛选项
  getExperienceReviewList: '/v1/experiencesrv/vertical/home_service/review',
  getExperienceNearbyActivity: '/v1/experiencesrv/nearby/activity', // GET 附近活动
  getExperienceNearbyCity: '/v1/experiencesrv/nearby/city', // GET 附近国家
  getExperienceBlog: '/v1/experiencesrv/blog/list', // GET 附近国家
  getExperienceNearbyMenu: '/v1/experiencesrv/category/menu', // GET 附近活动tab
  getExperienceActivityHighlight: '/v1/experiencesrv/activity/highlight', // GET 获得自动运营主题活动列表
  experienceSearch: '/v1/experiencesrv/search/suggest', // GET 获得自动运营主题活动列表
  experienceCitySeo: '/v1/usrcsrv/experience/city/seo/content', // GET 获得自动运营城市seo internal link
  experienceCountrySeo: '/v1/usrcsrv/experience/country/seo/content', // GET 获得自动运营国家seo internal link
  destinationSearch: '/v1/experiencesrv/search/suggest', // GET 目的地搜索
  getBreadcrumb: '/v1/experiencesrv/breadcrumb', // GET 面包屑
  getElevyTravelers: '/v1/usrcsrv/elevy/travelers',

  experiencesrvSearch: '/v1/experiencesrv/search/activity',
  getExperienceCategoryActivity: '/v1/experiencesrv/category/activity',
  getExperienceCategoryActivityNew: '/v1/experiencesrv/search/basic_service/activity_v2',
  // filter弹窗快速获取活动count
  getExperienceCategoryActivityCount: '/v1/experiencesrv/search/basic_service/activity_count',

  sendEmail: '/v1/usrcsrv/user/feedback/email', // POST 发送邮件
  getOrderList: '/v1/order/myorders/list', // POST 获取订单列表
  getActivityDetail: '/v1/usrcsrv/activities/:id/cards', // GET 获取活动详情

  regenerateOrder: '/v1/bookingapiserv/generate/again', // 重新生成订单

  experienceMFOSections: '/v1/experiencesrv/component/page/mfo', // POST 获取订单列表
  experienceMFOActivityList: '/v1/experiencesrv/mfo/search/activity', // 活动列表

  getBookingDetailSections: '/v1/experiencesrv/order/detail_service/get_booking_detail', // GET 获取订单详情
  getBookingDetailSectionsV2: '/v1/experiencesrv/order/detail_service/get_booking_detail_v2', // GET 获取订单详情
  getBookingPaymentDetail: '/v1/experiencesrv/order/detail_service/payment_detail', // GET 获取支付明细
  getBookingPaymentDetailV2: '/v1/experiencesrv/order/detail_service/payment_detail_v2', // GET 获取支付明细
  postBookingChase: '/v1/order/booking/chase', // POST 催单
  getBookingRefundDetail: '/v1/experiencesrv/order/detail_service/refund_detail', // GET 退款详情
  getBookingRefundDetailV3: '/v1/experiencesrv/order/detail_service/refund_detail_v3', // GET 退款详情
  handleMerchantAlterTicket: '/v1/experiencesrv/order/detail_service/handle_merchant_alter_ticket',
  postBookingTicketChase: '/v1/experiencesrv/order/detail_service/ticket_chase',

  // POI相关
  getPoiDetail: '/v1/usrcsrv/component/page/poi',
  getPoiReviewList: 'v1/usrcsrv/get/poi/review/info/list',

  // Chat Entrance 相关
  getChatEntranceConfig: '/v1/faqchatcommsrv/unifiedentrance/config',
  getDoubleCheckResult: '/v1/faqchatcommsrv/unifiedentrance/doublecheck/config',
  // Chat&CEG 相关
  sendOfflineMsg: '/v1/faqchatcommsrv/comm/offline/message',
  chatAuth: '/v1/faqchatcommsrv/comm/zendesk/visitor/auth', // POST 初始化chat JWT 验证
  getOrderDetail: '/v1/usrcsrv/order/detail', // 用来检查是否是SRV活动，在Chat做特殊处理
  postFaqAnswerFeedback: '/v1/faqchatcommsrv/feedback/faqid', // faq答案反馈
  isChinaMainland: '/v3/userserv/user/profile_service/get_user_category_info', // 判断是否是中国大陆用户
  checkHasOrder: '/v1/faqchatcommsrv/comm/has_order', // 判断用户是否有订单（登录态调用）
  getSingleOrderInfo: '/v1/usrcsrv/booking/list/by_order_nos', // 获取单个order的信息
  checkHasSubmitted: '/v1/faqchatcommsrv/comm/booking/:bookingNo/has_submit', // 检查是否已经提交过表单
  uploadAttachment: '/v1/faqchatcommsrv/comm/upload/mail/attachment', // 邮件上传图片附件
  sendEmailToCEG: '/v1/faqchatcommsrv/comm/mail/send', // 发送CEG邮件
  getMembershipCard: '/v1/mpsrv/product/card', // 请求MP业务的卡片
  getFullOrderDetail: '/v1/order/myorders/orderdetail', // 与订单详情一致的接口
  // 主题
  themepage: '/v1/usrcsrv/theme/detail', // 主题页内容
  // City 相关
  cityIndexComponent: '/v1/usrcsrv/jenga/page/city_page',
  homePageComponent: '/v1/usrcsrv/jenga/page/home_page',
  jengaPage: '/v1/usrcsrv/jenga/page/',
  cityActivitiesFilter: '/v3/usrcsrv/search/city/activities',
  updateAdBanner: '/v1/usrcsrv/get/city/banner/ad', // GET 登录态更新banner
  safeGuardTravelQuery: '/v1/usrcsrv/tw_covid19_campaign/query',

  // hotel
  hotelApiRefundReson: '/v1/hotelapiserv/order/refund/reason',
  hotelApiRefundPrice: '/v1/hotelapiserv/order/refund/price',
  hotelApiRefundApply: '/v1/hotelapiserv/order/refund/apply',
  hotelApiFilter: '/v2/hotelapiserv/hotelapi/filter/hotel',
  hotelApiItemsV1: '/v1/hotelapiserv/hotelapi/hotelItems',
  hotelApiItemsV2: '/v2/hotelapiserv/hotelapi/hotelItems',
  hotelApiSuggest: '/v2/hotelapiserv/hotelapi/suggest',
  hotelApiQuickFacts: '/v1/campaignapisrv/hubpage/quick_facts',
  hotelApiFaqs: '/v1/campaignapisrv/hubpage/faqs',
  hotelApiInternalLinks: '/v1/campaignapisrv/hubpage/internal_links',

  // GuestCheckout模式下发送的获取手机验证码
  guestSendSmsCodeWithCaptcha: '/v3/userserv/user/profile_service/send_sms_code_with_captcha',
  verifyGuestBookingSmsCode: '/v3/userserv/user/profile_service/verify_guest_booking_sms_code',

  // destination
  // getCitySummary: '/v1/campaignapisrv/destination/service/get_node_summary_by_city_country_idlist',
  getCitySummary: '/v1/campaignapisrv/destination/service/get_node_by_city_country_idlist',
  getCityImages: '/v1/campaignapisrv/destination/service/get_destination_image',
  getAllCountries: '/v1/experiencesrv/area/strative_area_service/get_all_countries',

  // promo coupon
  getPromoCouponList: '/v1/promosrv/promo/deliver/program/coupon/cards',

  // 获取排队信息
  getActivityLineInfo: '/v1/experiencesrv/activity/detail_service/get_activity_line_up_info',

  // 购物车信息
  getShoppingCart: '/v3/order/shoppingcart',
  // 购物车推荐活动
  getShoppingCartRecommendActivity: '/v1/usrcsrv/shopping_card/recommend',

  // 获取购物车城市推荐
  getShoppingCartCityRecommend: '/v1/websrv/cities/recommended',

  // 价格明细(新接口)
  getShoppingCartPriceDetail: '/v1/experiencesrv/order/settlement_service/shopping_cart_pre_settlement',

  // 删除购物车活动接口
  deleteShoppingCartItem: '/v3/order/shoppingcart/delete',

  // 点击加减, 编辑购物车
  editShopCartItem: '/v3/order/booking/edit',

  // topay
  shoppingCartToPay: '/v3/order/shoppingcart/select',

  // residence
  assumedResidence: '/v1/lbssrv/public/residence/assumed_residence',
  assumedResidencev2: '/v1/lbssrv/public/residence/assumed_residence_v2',

  // 获取preinfo, 根据返回值确定是否跳转到experience/list页面
  getSeoPreInfo: '/v1/experiencesrv/vertical/basic_service/landing_page_pre_info',
  getSeoData: '/v1/experiencesrv/vertical/basic_service/seo_info',
  getSeoReviewList: '/v1/experiencesrv/vertical/basic_service/landing_page_reviews',

  // vertical重构功能相关接口
  getTourHomePageInfo: '/v1/experiencesrv/vertical/home_service/homepage',
  getSuggestDestination: '/v1/experiencesrv/vertical/suggest_service/destination_suggestions',
  getSuggestDestinationV2: '/v1/experiencesrv/vertical/suggest_service/suggest_v2_init',
  getVerticalFilterActivityCount: '/v1/experiencesrv/search/vertical_service/activity_count',
  searchSuggestDestinationResult: '/v1/experiencesrv/vertical/suggest_service/suggest_destination',
  searchSuggestDestinationResultV2: '/v1/experiencesrv/vertical/suggest_service/suggest_v2',
  searchSuggestActivityResult: '/v1/experiencesrv/vertical/suggest_service/suggest',
  searchVerticalActivityResult: '/v1/experiencesrv/search/vertical_service/activity',
  // 获取页面前置信息
  searchVerticalPageInfo: '/v1/experiencesrv/search/vertical_service/page_info',
  getRecommendActivityCount: '/v1/experiencesrv/vertical/suggest_service/get_destination_card_sub_text',
  // Attractions API
  getAttractionsDestination: '/v1/attractionbffsrv/vertical/service/destination_guide',
  getAttractionsLandingPagePreInfo: '/v1/attractionbffsrv/vertical/service/landing_page_pre_info',
  attractionsSuggest: '/v1/attractionbffsrv/vertical/service/suggest_activity_v1',
  attractionsSearch: '/v1/attractionbffsrv/vertical/service/hit',
  getAttractionsCategoryActivityNew: '/v1/attractionbffsrv/vertical/service/activity',
  getAttractionsMainBanner: '/v1/attractionbffsrv/vertical/service/main_banner',
  getAttractionsBreadcrumb: '/v1/attractionbffsrv/vertical/service/breadcrumb',
  getAttractionsActivityNav: '/v1/attractionbffsrv/vertical/service/category_activity_menu',
  getAttractionsActivityBanner: '/v1/experiencesrv/vertical/basic_service/banner', // 接口为今年 1 月的实验广告，已废弃，所以目前不动
  getAttractionsBlog: '/v1/attractionbffsrv/vertical/service/blog',
  getAttractionsReviewList: '/v1/attractionbffsrv/vertical/service/review',
  getAttractionsHotCity: '/v1/attractionbffsrv/vertical/service/hot_city',
  getAttractionsNearbyCity: '/v1/attractionbffsrv/vertical/service/nearby_city',
  getAttractionsHotCountry: '/v1/attractionbffsrv/vertical/service/hot_country',
  getAttractionsActivityHighlight: '/v1/attractionbffsrv/vertical/service/highlight_activity',
  getAttractionsCountrySeo: '/v1/attractionbffsrv/vertical/service/country_seo_content',
  getAttractionsNearbyActivity: '/v1/experiencesrv/nearby/activity', // 目前垂直页已经已经没有这块数据了，所以不做处理
  getAttractionsCitySeo: '/v1/attractionbffsrv/vertical/service/city_seo_content',
  getAttractionsPreInfo: '/v1/attractionbffsrv/vertical/service/pre_info',

  getThemeList: '/v1/experiencesrv/explorepage/explore_service/theme_list',
  getRelatePages: 'v1/experiencesrv/explorepage/explore_service/get_relate_pages',

  cancelReservation: '/v1/experiencesrv/reservation/reservation_service/cancel_reservation'
}

/**
 * guest_checkout 需要代理的接口
 * registerGuest之后会设置cookie._pt_gc, 之后需要使用该cookie的接口需要node端代理反混淆并设置为_pt
 */
export const guestApiList =
  [apiNameMap.profile,
    '/v1/order/invoices/change_invoice', // 订单详情页改开发票
    apiNameMap.carRentalsAdd,
    apiNameMap.carRentalOrderDetail,
    apiNameMap.carRentalRefundReason,
    apiNameMap.carRentalGetAmount,
    apiNameMap.carRentalApplyAmount,
    apiNameMap.carRentalRefundDetail,
    apiNameMap.orderUserCancel,
    apiNameMap.registerGuest,
    apiNameMap.expPostPreOrderServiceNew,
    apiNameMap.bookingNow,
    apiNameMap.addToShoppingCard,
    apiNameMap.getBookingList,
    apiNameMap.verify,
    apiNameMap.getRecentlyPurchase,
    apiNameMap.deleteBooking,
    apiNameMap.getLogistics,
    apiNameMap.deleteBooking,
    apiNameMap.cancelDeleteBooking,
    apiNameMap.stickBooking,
    apiNameMap.cancelStickBooking,
    apiNameMap.comboQuery,
    apiNameMap.bookingManagement,
    apiNameMap.submitBookingManagement,
    apiNameMap.revertBookingManagement,
    // hotel
    apiNameMap.hotelApiRefundReson,
    apiNameMap.hotelApiRefundPrice,
    apiNameMap.hotelApiRefundApply,
    apiNameMap.guestSendSmsCodeWithCaptcha,
    apiNameMap.verifyGuestBookingSmsCode,
    // CEG
    apiNameMap.chatAuth,
    apiNameMap.getOrderDetail,
    apiNameMap.isChinaMainland,
    apiNameMap.checkHasOrder,
    apiNameMap.getSingleOrderInfo,
    apiNameMap.checkHasSubmitted,
    apiNameMap.uploadAttachment,
    apiNameMap.sendEmailToCEG,

    // 订单详情
    apiNameMap.getBookingDetailSections,
    apiNameMap.getBookingDetailSectionsV2,
    apiNameMap.getBookingPaymentDetail,
    apiNameMap.getBookingPaymentDetailV2,
    apiNameMap.getElevyTravelers,
    apiNameMap.postBookingChase,
    apiNameMap.getBookingRefundDetail,
    apiNameMap.getBookingRefundDetailV3,
    apiNameMap.handleMerchantAlterTicket,
    apiNameMap.postBookingTicketChase,

    // 退款相关
    apiNameMap.getBookingDetailsRefundAbleSkus,
    apiNameMap.calcBookingDetailsRefundAmount,
    apiNameMap.submitBookingDetailsRefund,
    apiNameMap.multiPassReservationPackagesV2,
    apiNameMap.getPassSchedulesAndUnits,
    apiNameMap.getUnitsArrangements,
    apiNameMap.generateBookingGuid,

    // 自助修改订单
    apiNameMap.experienceManageBookingInfos,
    apiNameMap.experienceManageBookingRevert,
    apiNameMap.experienceManageBookingSubmit,
    apiNameMap.experienceManageBookingModify,
    apiNameMap.experienceGetSchedulesByBookingAlter,

    // 新结算页
    apiNameMap.changeCurrency,
    apiNameMap.experienceNswRedeem,
    apiNameMap.experienceOrderCoupons,
    apiNameMap.couponAndPaymentCoupon,
    apiNameMap.experienceOrderRedeem,
    apiNameMap.experienceOrderCouponsWhiteLabel,
    apiNameMap.experienceOrderRedeemWhiteLabel,
    apiNameMap.experienceNewOrderInfoUrl,
    apiNameMap.experienceOrderSettlement,
    apiNameMap.experienceOrderCartSettlement,
    apiNameMap.experienceOrderSettlementWhiteLabel,
    apiNameMap.experienceOrderCartSettlementWhiteLabel,
    apiNameMap.experienceOrderReSettlement,
    apiNameMap.experienceOrderCartReSettlement,
    apiNameMap.experienceOrderReSettlementWhiteLabel,
    apiNameMap.experienceOrderCartReSettlementWhiteLabel,
    apiNameMap.experienceOtherInfoList,
    apiNameMap.experienceOrderGenerate,
    apiNameMap.experienceOrderDetail,
    apiNameMap.experienceOrderMailList,
    apiNameMap.experienceUpdateOtherInfoList,
    apiNameMap.whiteLabelGuestTermDetail,
    apiNameMap.getUserUncheckTermByScene,
    apiNameMap.authorizeTerms,
    apiNameMap.getSupplierTimeslots,

    // guest user residence
    apiNameMap.assumedResidence,
    apiNameMap.assumedResidencev2,

    apiNameMap.cancelReservation
  ]

export default apiNameMap
