








import { Component, Vue, Watch } from 'nuxt-property-decorator'

@Component
export default class MessageTips extends Vue {
  visible = false
  delay = 3000

  @Watch('visible')
  change(val: boolean) {
    if (val) {
      setTimeout(() => {
        this.visible = false

        setTimeout(() => {
          (this as any).close()
        }, 1000)
      }, this.delay)
    }
  }

  hide() {
    this.visible = false
  }
}
