import { Middleware } from '@nuxt/types'
import apis from '~/share/data/apis'

/**
 * 从首页跳转到experience到页面需要更具ip重定向到experience的country， city等
 */

const middleware: Middleware = async function (ctx) {
  const { redirect, params, route, app } = ctx
  const { meta: [{ type }] } = route

  if (type !== 'redirect') {
    return
  }

  const { frontendId = 1 } = params

  // 获取页面信息，前置请求
  try {
    const { success, result } = await app.$axios.$get(apis.getExperiencePreInfo, {
      params: {
        frontend_id: frontendId
      }
    })

    if (!success || !result.redirect_url) {
      return redirect({ name: 'ExperienceAroundTheWorld' })
    }

    return redirect(result.redirect_url)
  } catch (e) {
    return redirect({ name: 'ExperienceAroundTheWorld' })
  }
}

export default middleware
