import { Context } from '@nuxt/types'
import locale from '@klook/klook-ui/lib/locale'
import Vue from 'vue'
import {
  Icon,
  Button,
  Select,
  Alert,
  Carousel,
  BottomSheet,
  InfiniteScroll,
  Tabs,
  Checkbox,
  Modal,
  DatePicker,
  Drawer,
  Switch,
  Radio,
  Pagination,
  Steps,
  Progress,
  Toast,
  Loading,
  Helpers,
  CardSwiper,
  TagSelect,
  Dropdown,
  Collapse,
  Poptip,
  Breadcrumb,
  Input,
  Counter,
  Notification,
  Announcement,
  Tag,
  Form,
  Badge,
  Tree,
  Divider,
  Slider,
  Skeleton,
  Markdown,
  Upload,
  SectionTitle,
  Link,
  Label
} from '@klook/klook-ui'
import dialog from './dialog'

export default async function (ctx: Context) {
  const { language } = ctx.store.state.klook as Data.Klook
  const lang = await import(`@klook/klook-ui/lib/locale/lang/${language}` /* webpackChunkName: "ui-lang-[request]" */)
  locale.use(lang)
  Vue.use(Label)
  Vue.use(Icon)
  Vue.use(Alert)
  Vue.use(Button)
  Vue.use(Divider)
  Vue.use(Select)
  Vue.use(Carousel)
  Vue.use(BottomSheet)
  Vue.use(InfiniteScroll)
  Vue.use(Input)
  Vue.use(Counter)
  Vue.use(Checkbox)
  Vue.use(Drawer)
  Vue.use(Modal)
  Vue.use(DatePicker)
  Vue.use(Switch)
  Vue.use(Radio)
  Vue.use(Pagination)
  Vue.use(Steps)
  Vue.use(Progress)
  Vue.use(Toast)
  Vue.use(Loading)
  Vue.use(Helpers)
  Vue.use(Poptip)
  Vue.use(CardSwiper)
  Vue.use(TagSelect)
  Vue.use(Tabs)
  Vue.use(Breadcrumb)
  Vue.use(Dropdown)
  Vue.use(Collapse)
  Vue.use(Notification)
  Vue.use(Input)
  Vue.use(Form)
  Vue.use(Announcement)
  Vue.use(Tag)
  Vue.use(Badge)
  Vue.use(Divider)
  Vue.use(Slider)
  Vue.use(Tree)
  Vue.use(Skeleton)
  Vue.use(Markdown)
  Vue.use(Upload)
  Vue.use(SectionTitle)
  // 弥补klook-ui modal组件快捷调用 不支持 个性化配置
  // Vue.prototype.$dialog 使用方法同$alert, $confirm
  Vue.use(dialog)
  Vue.use(Link)
}
