import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import { Plugin } from '@nuxt/types'
import { imageFormat } from '~/share/utils'

const lazyErrorImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURenp6VcjxIwAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAAElFTkSuQmCC'

// const lazyLoadingImage = 'https://res.klook.com/image/upload/image_logo_mx7wgd.png'

const plugin: Plugin = ({ store }) => {
  const { platform, retina } = store.state.klook

  Vue.use(VueLazyload, {
    observer: true,
    adapter: {
      loaded(detail: any) {
        const { el, bindType } = detail
        const ratio = el.getAttribute('ratio')
        const gradient = el.dataset?.gradient || ''

        if (process.client && ratio) {
          const logo = el?.querySelector?.('.v-lazy-logo') || el.parentElement?.querySelector?.('.v-lazy-logo')
          logo && (logo.style.display = 'none')
        }

        if (process.client && gradient && bindType === 'background-image') {
          el.style[bindType] = `${gradient},${el.style[bindType]}`
        }
      }
    },
    error: lazyErrorImage,
    filter: {
      webp(listener: any, options: any) {
        const type = listener.el.dataset?.imgType || ''
        const isRetina = platform === 'mobile' && retina
        const src = imageFormat(listener.src, type, isRetina)
        if (!options.supportWebp) {
          return
        }
        const isCDN = /res\.klook\.com/
        if (isCDN.test(src)) {
          listener.src = src.replace(/\.\w+$/, '.webp')
        }
      }
    }
  })

  return Promise.resolve()
}

export default plugin
