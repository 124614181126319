import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '~/store'

export type ExpCommonState = {
  vertical: string
}

const SET_ACTIVITY_VERTICAL = 'SET_ACTIVITY_VERTICAL'

export const state = () => ({
  vertical: '' // 'attraction'、't&a'
})

export const mutations: MutationTree<ExpCommonState> = {
  updateVertical(state, val: string) {
    state.vertical = val
  }
}

export const actions: ActionTree<ExpCommonState, RootState> = {
  setActivityVertical({ commit }, val) {
    commit(SET_ACTIVITY_VERTICAL, val)
  }
}

export const getters: GetterTree<ExpCommonState, RootState> = {
  isAttraction(state) {
    return state.vertical === 'attraction'
  }
}
