import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_middleware_b3053524 from 'nuxt_plugin_middleware_b3053524' // Source: ./auth/middleware.js (mode: 'all')
import nuxt_plugin_router_0b7ec99e from 'nuxt_plugin_router_0b7ec99e' // Source: ./router.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_26d8344e from 'nuxt_plugin_cookieuniversalnuxt_26d8344e' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_gtm_ca5fe93e from 'nuxt_plugin_gtm_ca5fe93e' // Source: ../plugins/analytics/gtm (mode: 'client')
import nuxt_plugin_clientreport_94be9480 from 'nuxt_plugin_clientreport_94be9480' // Source: ../plugins/client-report (mode: 'client')
import nuxt_plugin_traffic_b19d5472 from 'nuxt_plugin_traffic_b19d5472' // Source: ../plugins/traffic (mode: 'client')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_tokens_37929ee0 from 'nuxt_plugin_tokens_37929ee0' // Source: ../plugins/tokens (mode: 'all')
import nuxt_plugin_auth_7f755f62 from 'nuxt_plugin_auth_7f755f62' // Source: ../plugins/auth.ts (mode: 'client')
import nuxt_plugin_ga4_65aecd6e from 'nuxt_plugin_ga4_65aecd6e' // Source: ../plugins/ga4.ts (mode: 'client')
import nuxt_plugin_utils_3336a20a from 'nuxt_plugin_utils_3336a20a' // Source: ../plugins/utils (mode: 'all')
import nuxt_plugin_components_6fb0430c from 'nuxt_plugin_components_6fb0430c' // Source: ../plugins/components (mode: 'all')
import nuxt_plugin_directives_d0867c0c from 'nuxt_plugin_directives_d0867c0c' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_klookui_60b95e40 from 'nuxt_plugin_klookui_60b95e40' // Source: ../plugins/klook-ui.ts (mode: 'all')
import nuxt_plugin_index_7ee03dfa from 'nuxt_plugin_index_7ee03dfa' // Source: ../plugins/seo/index.ts (mode: 'all')
import nuxt_plugin_datalayer_6026d85c from 'nuxt_plugin_datalayer_6026d85c' // Source: ../plugins/datalayer.ts (mode: 'all')
import nuxt_plugin_clientinit_31582d11 from 'nuxt_plugin_clientinit_31582d11' // Source: ../plugins/client-init.ts (mode: 'client')
import nuxt_plugin_infiniteload_e0592578 from 'nuxt_plugin_infiniteload_e0592578' // Source: ../plugins/infinite-load.ts (mode: 'client')
import nuxt_plugin_nuxtswiperplugin_622602ba from 'nuxt_plugin_nuxtswiperplugin_622602ba' // Source: ../plugins/nuxt-swiper-plugin.js (mode: 'client')
import nuxt_plugin_vlazyload_6c112c06 from 'nuxt_plugin_vlazyload_6c112c06' // Source: ../plugins/v-lazyload.ts (mode: 'client')
import nuxt_plugin_vuesticky_1d008431 from 'nuxt_plugin_vuesticky_1d008431' // Source: ../plugins/vue-sticky.js (mode: 'client')
import nuxt_plugin_whitelabelsdkinit_53e2d152 from 'nuxt_plugin_whitelabelsdkinit_53e2d152' // Source: ../plugins/white-label-sdk-init.ts (mode: 'client')
import nuxt_plugin_riskdeepknow_3e469269 from 'nuxt_plugin_riskdeepknow_3e469269' // Source: ../plugins/risk-deep-know.ts (mode: 'client')
import nuxt_plugin_sentry_e4786790 from 'nuxt_plugin_sentry_e4786790' // Source: ../plugins/sentry.ts (mode: 'client')
import nuxt_plugin_offscreen_038e1280 from 'nuxt_plugin_offscreen_038e1280' // Source: ../plugins/off-screen (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn(`<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead`)
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>`
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error('inject(key, value) has no value provided')
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__klook_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__KLOOK__ && window.__KLOOK__.state) {
      store.replaceState(window.__KLOOK__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_middleware_b3053524 === 'function') {
    await nuxt_plugin_middleware_b3053524(app.context, inject)
  }

  if (typeof nuxt_plugin_router_0b7ec99e === 'function') {
    await nuxt_plugin_router_0b7ec99e(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_26d8344e === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_26d8344e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_ca5fe93e === 'function') {
    await nuxt_plugin_gtm_ca5fe93e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientreport_94be9480 === 'function') {
    await nuxt_plugin_clientreport_94be9480(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_traffic_b19d5472 === 'function') {
    await nuxt_plugin_traffic_b19d5472(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_tokens_37929ee0 === 'function') {
    await nuxt_plugin_tokens_37929ee0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_auth_7f755f62 === 'function') {
    await nuxt_plugin_auth_7f755f62(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ga4_65aecd6e === 'function') {
    await nuxt_plugin_ga4_65aecd6e(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_3336a20a === 'function') {
    await nuxt_plugin_utils_3336a20a(app.context, inject)
  }

  if (typeof nuxt_plugin_components_6fb0430c === 'function') {
    await nuxt_plugin_components_6fb0430c(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_d0867c0c === 'function') {
    await nuxt_plugin_directives_d0867c0c(app.context, inject)
  }

  if (typeof nuxt_plugin_klookui_60b95e40 === 'function') {
    await nuxt_plugin_klookui_60b95e40(app.context, inject)
  }

  if (typeof nuxt_plugin_index_7ee03dfa === 'function') {
    await nuxt_plugin_index_7ee03dfa(app.context, inject)
  }

  if (typeof nuxt_plugin_datalayer_6026d85c === 'function') {
    await nuxt_plugin_datalayer_6026d85c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientinit_31582d11 === 'function') {
    await nuxt_plugin_clientinit_31582d11(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infiniteload_e0592578 === 'function') {
    await nuxt_plugin_infiniteload_e0592578(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtswiperplugin_622602ba === 'function') {
    await nuxt_plugin_nuxtswiperplugin_622602ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vlazyload_6c112c06 === 'function') {
    await nuxt_plugin_vlazyload_6c112c06(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesticky_1d008431 === 'function') {
    await nuxt_plugin_vuesticky_1d008431(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_whitelabelsdkinit_53e2d152 === 'function') {
    await nuxt_plugin_whitelabelsdkinit_53e2d152(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_riskdeepknow_3e469269 === 'function') {
    await nuxt_plugin_riskdeepknow_3e469269(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentry_e4786790 === 'function') {
    await nuxt_plugin_sentry_e4786790(app.context, inject)
  }

  if (typeof nuxt_plugin_offscreen_038e1280 === 'function') {
    await nuxt_plugin_offscreen_038e1280(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
