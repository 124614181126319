import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import uuid from 'uuid/v4'
import { isTicketType } from '~/share/data/activityTemplateIdConfig'
import { getStandardDateFormat } from '~/share/data/datetime.ts'
import { Chat } from '~/types/traveller/chat'
// 扩展dayjs的方法
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(customParseFormat)

const TIMETYPE = 'chat.time'

export function isManualAgent(nick: string = '') {
  return nick.startsWith('agent')
}

export function isManualVisitor(nick: string = '') {
  return nick.startsWith('visitor')
}

export function isManualSystem(nick: string = '') {
  return nick.startsWith('system')
}

export function isTrigger(nick: string) {
  return nick.startsWith('agent:trigger')
}

function TimeMessage(timestamp: string|Date, $t: Function, language: Data.Language) {
  const currentTimestamp = +timestamp - 1
  return {
    id: uuid(),
    timestamp: currentTimestamp,
    type: TIMETYPE,
    content: formatTime(timestamp, $t, language)
  }
}

// 消息列表添加时刻提示
export function addChatTime(chatList: any[], $t: Function, language: Data.Language) {
  let currentTimestamp = 0

  return chatList.reduce((acc, cv) => {
    const { timestamp } = cv
    if (!timestamp) {
      return acc
    }

    // 第一条消息上插入时间戳
    if (isEmpty(acc)) {
      currentTimestamp = timestamp
      return [TimeMessage(timestamp, $t, language)].concat(cv)
    }

    // 3min === 180000ms, 每隔3分钟插入一个时刻
    if (timestamp - currentTimestamp >= 180000) {
      currentTimestamp = timestamp
      return acc.concat([TimeMessage(timestamp, $t, language), cv])
    }

    return acc.concat(cv)
  }, [])
}

const weekMap: any = {
  1: '12903',
  2: '13639',
  3: '15554',
  4: '13459',
  5: '14881',
  6: '14006',
  0: '15020'
}

const REFERENCE = dayjs()
const TODAY = REFERENCE.clone().startOf('day')
const YESTERDAY = REFERENCE.clone().subtract(1, 'day').startOf('day')
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'day').startOf('day')

function isToday(date: string|Date) {
  return dayjs(date).isSame(TODAY, 'd')
}

function isYesterday(date: string|Date) {
  return dayjs(date).isSame(YESTERDAY, 'd')
}

function isWithinAWeek(date: string|Date) {
  return dayjs(date).isAfter(A_WEEK_OLD)
}

export function formatTime(timestamp: string|Date, $t: Function, language: Data.Language) {
  const time = getStandardDateFormat(timestamp, $t, language, 4)

  if (isToday(timestamp)) {
    // 一天之内
    return `${$t('jr.book.same.day')} ${time}`
  } else if (isYesterday(timestamp)) {
    // 两天之内
    return `${$t('7873')} ${time}`
  } else if (isWithinAWeek(timestamp)) {
    // 一星期之内
    // 默认，七天之后
    const day = new Date(timestamp).getDay()
    const weekday = $t(weekMap[day])
    return `${weekday} ${time}`
  }

  return getStandardDateFormat(timestamp, $t, language, 2)
}

export function isFileImage(message: any) {
  // eslint-disable-next-line camelcase
  const mimeType = message.attachment?.mime_type || ''
  if (!mimeType) { return false }
  return mimeType.startsWith('image')
}

// 把网址替换成a链接的形式
export function formatLink(content: string) {
  const reg = /((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?)/ig
  return content.replace(reg, '<a href="$1" style="color: #4985E6; text-decoration: underline;" target="_blank">$1</a>')
}

// 过滤一些特殊的标签以免标签闭合xss攻击
export function htmlExcape(content: string) {
  return content.replace(/[<>&"']/g, function (c) {
    const string: any = { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;', "'": '&#39;' }
    return string[c]
  })
}

export function isiOS(): boolean {
  const u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

export function lockBody(targetElement: HTMLElement) {
  if (!isiOS()) { return }

  let initialClientY = 0
  targetElement.addEventListener('touchstart', (event) => {
    if (event.targetTouches.length === 1) {
      initialClientY = event.targetTouches[0].clientY
    }
  })

  targetElement.addEventListener('touchmove', (event) => {
    if (event.targetTouches.length === 1) {
      // detect single touch.
      const clientY = event.targetTouches[0].clientY - initialClientY

      if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll.
        return event.preventDefault()
      }

      if (targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight && clientY < 0) {
        // element is at the top of its scroll.
        return event.preventDefault()
      }

      event.stopPropagation()
      return true
    }
  })
}

export function getDefaultLanguage(language: Data.Language) {
  const systemLanguageMapChat: any = {
    'zh-CN': 'chinese-simplified',
    'zh-TW': 'chinese-traditional',
    'zh-HK': 'chinese-traditional',
    ko: 'korean',
    th: 'thai'
  }

  return systemLanguageMapChat[language] || 'english'
}

export function isDepartmentOffline(start = '08:00:00', end = '21:00:00', workDays: Array<number> = [1, 2, 3, 4, 5, 6, 0]) {
  const format = 'HH:mm:ss'
  const startTime = start
  const endTime = end

  const timeUTC = dayjs(dayjs.utc().utcOffset(+8), format)
  const currentTime = [timeUTC.hour(), timeUTC.minute(), timeUTC.second()].join(':')

  const time = dayjs(currentTime, format)
  const workTimeStart = dayjs(startTime, format)
  const workTimeEnd = dayjs(endTime, format)
  // number: 从0(星期天)到6(星期六), 默认周一到周五是工作日
  const isWorkDay = workDays.includes(dayjs().day())
  return !time.isBetween(workTimeStart, workTimeEnd) || !isWorkDay
}

// 判断是否是有工作时间限制的部门，否则为8:00-22:00在线部门

export function getOfflineDepartment(isChinaMainland: boolean, language: string) {
  const configs: any = {
    english: {
      workTime: {
        start: '00:00:00',
        end: '23:59:59'
      }
    },
    'chinese-simplified': {
      workTime: {
        start: '08:00:00',
        end: '21:00:00'
      },
      departmentName: process.env.APP_ENV === 'production' ? '仅限大陆用户' : 'gds'
    },
    korean: {
      workTime: {
        start: '09:00:00',
        end: '17:00:00'
      }
    },
    thai: {
      workTime: {
        start: '10:00:00',
        end: '18:00:00'
      },
      workDays: [1, 2, 3, 4, 5]
    }
  }

  const config = configs[language]
  if (config) {
    // 大陆用户单独处理
    if (language === 'chinese-simplified' && !isChinaMainland) {
      return {
        department: '',
        isOffline: isDepartmentOffline('08:00:00', '22:00:00')
      }
    }
    return {
      department: isChinaMainland ? config.departmentName : '',
      isOffline: isDepartmentOffline(config.workTime.start, config.workTime.end, config.workDays)
    }
  }

  // 如果没有找到对应的语言配置那么就是8点-22点
  return {
    department: '',
    isOffline: isDepartmentOffline('08:00:00', '22:00:00')
  }
}

/**
 * 获取webview打开的版本号，返回版本号如"4.2.0",若不是app打开返回false
 */
function getAppVersion() {
  const userAgent = navigator.userAgent
  const reg = /klook\/(\d+\.)+\d+/
  const matchArr = userAgent.match(reg)
  if (matchArr) {
    const versionStr = matchArr[0]
    const versionArr = versionStr.split('/')
    return versionArr[1]
  } else {
    return false
  }
}

export function appVersionIsGreaterThan(baseVersion: Number) {
  const appVersion = getAppVersion()
  if (isString(appVersion)) {
    return Number(appVersion.split('.').join('')) >= baseVersion
  }
}

export function loadSuccess() {
  const appVersion = getAppVersion()
  if (isString(appVersion)) {
    const isVersionGT = Number(appVersion.split('.').join('')) >= 5240
    if (isVersionGT) {
      window.location.href = 'klook://app/load_success'
    }
  }
}

export function getActivityItem(ticket: any) {
  const formatTicket: Chat.Ticket = {
    activityName: ticket.activity_name || '',
    activityImgUrl: ticket.activity_img_url || '',
    startTime: ticket.start_time,
    ticketType: ''
  }
  if (isTicketType('hotel', ticket)) {
    if (ticket.other_fields.hotel_api) {
      formatTicket.activityName = ticket.other_fields.hotel_api.order_info.hotel_name
      formatTicket.activityImgUrl = ticket.other_fields.hotel_api.order_info.hotel_img
      formatTicket.ticketType = 'hotel'
    }
  }
  if (isTicketType('transfer', ticket) && ticket.other_fields && ticket.other_fields.transfer_airport) {
    formatTicket.activityImgUrl = ticket.other_fields.transfer_airport.override_info.activity_img_url
    formatTicket.activityName = ticket.other_fields.transfer_airport.override_info.activity_name
    formatTicket.ticketType = 'transfer'
    if (ticket.other_fields.transfer_airport.override_info.start_time) {
      ticket.start_time = ticket.other_fields.transfer_airport.override_info.start_time
    }
  }

  if (isTicketType('car_rental', ticket) && ticket.other_fields && ticket.other_fields.car_rental) {
    formatTicket.ticketType = 'car_rental'
    formatTicket.activityImgUrl = ticket.other_fields.car_rental.override_info.activity_img_url
    formatTicket.activityName = ticket.other_fields.car_rental.override_info.activity_name
    if (ticket.other_fields.car_rental.override_info.start_time) {
      ticket.start_time = ticket.other_fields.car_rental.override_info.start_time
    }
  }

  return formatTicket
}
