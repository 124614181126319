import Vue from 'vue'
import SvgIcon from '~/components/common/svg-icon.vue'
import Message from '~/components/common/message'
import MobileWarnTip from '~/components/common/mobile-warn-tip'

declare module 'vue/types/vue' {
  interface Vue {
    $message: any
    validate: Function
    $mobileWarnTip: any
  }
}

export default () => {
  Vue.component('SvgIcon', SvgIcon)
  Vue.use(Message)
  Vue.use(MobileWarnTip)
}
