/*
增加 vue 自定义指令，解决 mobile web 浮层滚动穿透问题。
 */

function fix() {
  if (document.body.style.position !== 'fixed') {
    // window.pageYOffset（只读） 兼容性最佳，其他比如 window.scrollY document.body.scrollTop 读取会有兼容性问题，详情见 MDN docs
    this.fixScrollY = window.pageYOffset
    // position fixed 防止滚动穿透
    document.body.style.position = 'fixed'
    // body fixed 后保持 body 滚动位置
    document.body.style.top = -this.fixScrollY + 'px'
  }
}

function noFix() {
  // 恢复 body position
  document.body.style.position = 'static'
  // 改变 position 重新恢复 scrollTop
  document.body.scrollTop = this.fixScrollY // For Safari
  document.documentElement.scrollTop = this.fixScrollY // For Chrome, Firefox, IE and Opera
}

export default {
  bind(_el, { value }, vnode) {
    if (vnode.context.releaseBody !== false && value) {
      fix.apply(vnode.context)
    }
  },
  update(_el, { value, oldValue }, vnode) {
    if (vnode.context.releaseBody !== false && value !== oldValue) {
      if (value) {
        fix.apply(vnode.context)
      } else {
        noFix.apply(vnode.context)
      }
    }
  },
  unbind(_el, _p, vnode) { // .multi
    // 在spa 模式下 切换路由造成实例销毁  body.style.position 还是fixed 无法滚动
    // 如果是多层弹窗 请往使用该组件 加属性 releaseBody: false 同klook-new-web 没有值 或者 值为true 忽略
    // @Prop({ type: Boolean, default: true }) releaseBody!: boolean // 单例弹窗
    if (vnode.context.releaseBody !== false) {
      noFix.apply(vnode.context)
    }
  }
}
