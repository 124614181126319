import Vue from 'vue'

import {
  getMatchedComponentsInstances,
  promisify,
  globalHandleError
} from './utils'

import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../node_modules/@klook/klook-ui/lib/styles/index.scss'

import '../assets/styles/index.scss'

import '../node_modules/swiper/dist/css/swiper.css'

import '../node_modules/@klook/klook-icons/styles/index.css'

const _6092ef3c = () => import('../layouts/activity-booking-options.vue'  /* webpackChunkName: "layouts/activity-booking-options" */).then(m => m.default || m)
const _77180f1e = () => import('../layouts/blank.vue'  /* webpackChunkName: "layouts/blank" */).then(m => m.default || m)
const _59765274 = () => import('../layouts/booking-options.vue'  /* webpackChunkName: "layouts/booking-options" */).then(m => m.default || m)
const _996cf988 = () => import('../layouts/checkout.vue'  /* webpackChunkName: "layouts/checkout" */).then(m => m.default || m)
const _6f6c098b = () => import('../layouts/default.vue'  /* webpackChunkName: "layouts/default" */).then(m => m.default || m)
const _7164aae1 = () => import('../layouts/errors.vue'  /* webpackChunkName: "layouts/errors" */).then(m => m.default || m)
const _77dd5794 = () => import('../layouts/plain.vue'  /* webpackChunkName: "layouts/plain" */).then(m => m.default || m)
const _02175a9a = () => import('../layouts/white-label.vue'  /* webpackChunkName: "layouts/white-label" */).then(m => m.default || m)

const layouts = { "_activity-booking-options": _6092ef3c,"_blank": _77180f1e,"_booking-options": _59765274,"_checkout": _996cf988,"_default": _6f6c098b,"_errors": _7164aae1,"_plain": _77dd5794,"_white-label": _02175a9a }

let resolvedLayouts = {}

export default {
  head: {"title":"Klook Travel","meta":[{"charset":"utf-8"},{"hid":"keywords","name":"keywords","content":"klook, travel"},{"hid":"description","name":"description","content":"Klook Travel"}],"link":[],"style":[],"script":[{"type":"text\u002Fjavascript","src":"https:\u002F\u002Fcdn.klook.com\u002Fs\u002Fdist_web\u002Fcommon\u002F1.12.18\u002Fklk_common.js"},{"pbody":true,"type":"text\u002Fjavascript","innerHTML":"(typeof klk_common !== 'undefined')&&klk_common.detectSpm&&klk_common.detectSpm()"},{"body":true,"type":"text\u002Fjavascript","innerHTML":"(typeof klk_common !== 'undefined')&&klk_common.detectSpm&&klk_common.detectSpm();"}],"noscript":[]},

  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    if (this.nuxt.err && NuxtError.layout) {
      this.setLayout(
        typeof NuxtError.layout === 'function'
          ? NuxtError.layout(this.context)
          : NuxtError.layout
      )
    }

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$klook.$nextTick(() => {
            window.$klook.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__klook'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: ''
  }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    Vue.prototype.$klook = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$klook = window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  mounted () {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    }
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map((page) => {
        const p = []

        if (page.$options.fetch) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail()
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },

    errorChanged () {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) {
          this.$loading.fail()
        }
        if (this.$loading.finish) {
          this.$loading.finish()
        }
      }
    },

    setLayout (layout) {
      if (!layout || !resolvedLayouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      let _layout = '_' + layout
      this.layout = resolvedLayouts[_layout]
      return this.layout
    },
    loadLayout (layout) {
      const undef = !layout
      const nonexistent = !(layouts['_' + layout] || resolvedLayouts['_' + layout])
      let _layout = '_' + ((undef || nonexistent) ? 'default' : layout)
      if (resolvedLayouts[_layout]) {
        return Promise.resolve(resolvedLayouts[_layout])
      }
      return layouts[_layout]()
        .then((Component) => {
          resolvedLayouts[_layout] = Component
          delete layouts[_layout]
          return resolvedLayouts[_layout]
        })
        .catch((e) => {
          if (this.$klook) {
            return this.$klook.error({ statusCode: 500, message: e.message })
          }
        })
    }
  },

  components: {
    NuxtLoading
  }
}
