// businessName，楼层的业务名称，也是楼层的id(name)
export const handler = 'Experience'

export const businessNames = {
  ExperienceHeader: 'Header',
  subVerticalMenu: 'SubVerticalMenu',
  subVerticalBanner: 'SubVerticalBanner',
  ExperienceHotCountry: 'HotCountry_ActivityLIST',
  ExperienceDestinationSection: 'TopDestination',
  ActivityHighlight: 'ThemeActivity_LIST',
  NearbyActivities: 'NearbyActivity_LIST',
  NearbyCities: 'NearbyDestination',
  CountrySeoLink: 'CountrySeoLink',
  CitySeoLink: 'CitySeoLink',
  ExperienceBlogList: 'BlogLIST',
  ReviewList: 'UserReview_UserReviewCardLIST',
  SeoLink: 'SeoLink',
  ExperiencePromoSystem: 'SubVerticalPromo'
}

export const bannerUrl = 'https://res.klook.com/image/upload/v1656398779/web3.0/experiences/category_Things_to_do.jpg'
