import { Plugin } from '@nuxt/types'

import BrowserTraffic from '@klook/traffic'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $traffic: BrowserTraffic
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $traffic: BrowserTraffic
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $traffic: BrowserTraffic
  }
}

const plugin: Plugin = async function (ctx, inject) {
  const traffic = new BrowserTraffic({
    // ctx: ctx,

    cookies: ctx.app.$cookies
  })

  await traffic.ready()

  inject('traffic', traffic)
}

export default plugin
