<template>
  <div class="page-error">
    <Klook-error-panel
      :status="statusCode"
      :title="$t('47988')"
      :content="$t('74485')"
      :btn-refresh-fn="btnRefreshFn"
    />
  </div>
</template>

<script>
import KlookErrorPanel from '@klook/klook-error-panel'

export default {
  name: 'Error',
  layout: 'errors',
  components: {
    KlookErrorPanel
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    }
  },
  methods: {
    btnRefreshFn(times) {
      if (!times) {
        window.location.href = this.$href('/')
      }
      return this.$t('74486', { number: times })
    }
  }
}
</script>

<style lang='scss' scoped>
.page-error {
  padding: 200px 0;
}

@media screen and (max-width: 765px) {
  .page-error {
    padding: 40px 0;
  }
}
</style>
