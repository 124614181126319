import Vue from 'vue'
import VueI18n from '@klook/vue-i18n'
import { Messages } from '@klook/i18n/src/types'
import { Plugin } from '@nuxt/types'
import { assignLocale as assignHeaderLocale } from '@klook/klk-traveller-header'
import { assignLocale as assignFooterLocale } from '@klook/klk-traveller-footer'
import localesConf from '~/locales.config'
import { RootState } from '~/store'

Vue.use(VueI18n)

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $i18n: VueI18n
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $i18n: VueI18n
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $i18n: VueI18n;
    $t: typeof VueI18n.prototype.t
    $tc: typeof VueI18n.prototype.t
  }
}

const map = new Map<string, any>()

/**
 * Load language by asynchronous
 */
async function loadLanguageAsync(locale: Data.Language) {
  const filename = `locale-${locale}.json`
  let messages = null

  try {
    const result = await import(`~/locales/${filename}` /* webpackChunkName: "lang-[request]" */)
    messages = result.default || result
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Load locale error:', error)
    return messages
  }

  return messages
}

const plugin: Plugin = async (ctx, inject) => {
  const { app, store, route } = ctx
  const defaultLocale = localesConf.fallbackLocale as Data.Language
  let locale: Data.Language = defaultLocale
  const testMessages: Messages = localesConf.testMessages as Messages
  const isDebugMode = route.fullPath?.includes('cmstextid=1') || false

  if (process.server) {
    locale = ctx.req.language || defaultLocale
  }

  if (process.client) {
    locale = (store.state as RootState).klook.language || defaultLocale
  }

  if (!map.get(locale)) {
    const messages = await loadLanguageAsync(locale)
    map.set(locale, new VueI18n({
      isDebugMode,
      locale,
      messages: messages ? { [locale]: messages } : testMessages,
      interpolation: {
        prefix: '{',
        postfix: '}',
        linkKeyWord: 'MULTILANG_URL_PLACEHOLDER'
      }
    }))

    const i18nInstance = map.get(locale)
    await Promise.all([
      assignHeaderLocale(i18nInstance),
      assignFooterLocale(i18nInstance)
    ])
  }

  // @ts-ignore
  app.i18n = map.get(locale)

  // update isDebugMode
  app.i18n.options.isDebugMode = isDebugMode

  inject('i18n', app.i18n)
}

export default plugin
