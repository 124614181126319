export type ConsoleMethods = 'trace' | 'debug' | 'info' | 'warn' | 'error'
export type Levels = ConsoleMethods | 'fatal'
export type LoggerMethods = Levels

export interface LogObj {
  level: ConsoleMethods
  args: any[]
}

export type Layer = (...args: any[]) => void

export interface Logger {
  trace: Layer
  debug: Layer
  info: Layer
  warn: Layer
  error: Layer
  fatal: Layer
}

/**
 * Normalize print
 */
function log(logObj: LogObj) {
  const date = new Date().toISOString()
  const level = logObj.level.toUpperCase()

  // eslint-disable-next-line no-console
  console[logObj.level](`[${date}] ${level}`, ...logObj.args)
}

/**
 * A layer for log()
 */
function layer(level: ConsoleMethods): Layer {
  return (...args) => log({ level, args })
}

const logger: Logger = {
  trace: layer('trace'),
  debug: layer('debug'),
  info: layer('info'),
  warn: layer('warn'),
  error: layer('error'),
  fatal: layer('error')
}

export default logger
