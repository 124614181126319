import { localStorageSupport } from '~/assets/scripts/localstorage.js'

export function getSessionData(key) {
  if (localStorageSupport()) {
    return sessionStorage.getItem(key)
  }
}

export function getSessionDataParsed(key) {
  const data = getSessionData(key)

  try {
    return JSON.parse(data)
  } catch (e) {
    return data
  }
}

export function deleteSessionData(key) {
  if (localStorageSupport()) {
    sessionStorage.removeItem(key)
  }
}

export function setSessionData(key, value) {
  if (localStorageSupport()) {
    if (Object.prototype.toString.call(value) === '[object Object]') {
      sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      sessionStorage.setItem(key, value)
    }
  }
}
