export const SET_PKGS_ADDON_SKU = 'SET_PKGS_ADDON_SKU'
export const SET_PKGS_ADDON_SCHEDULES_AND_UNITS = 'SET_PKGS_ADDON_SCHEDULES_AND_UNITS'
export const SET_PKGS_BASIC_INFO = 'SET_PKGS_BASIC_INFO'
export const SET_COMMON_LOADING_STATE = 'SET_COMMON_LOADING_STATE'
export const SET_TAG_FILTER_LIST = 'SET_TAG_FILTER_LIST'
export const SET_TAG_FILTER_VALUE = 'SET_TAG_FILTER_VALUE'
export const SET_PRE_SETTLEMENT_API_DATA = 'SET_PRE_SETTLEMENT_API_DATA'
export const GET_ACTIVITY_DETAIL = 'GET_ACTIVITY_DETAIL'
export const GET_REVIEW_IMAGES_INFO = 'GET_REVIEW_IMAGES_INFO'
export const GET_PACKAGE_SCHEDULE = 'GET_PACKAGE_SCHEDULE'
export const SET_PACKAGE_SELECTED_DATE = 'SET_PACKAGE_SELECTED_DATE'
export const SET_PACKAGE_ID = 'SET_PACKAGE_ID'
export const SET_CACHED_PACKAGE_ID = 'SET_CACHED_PACKAGE_ID'
export const RESET_CACHED_PACKAGE_ID = 'RESET_CACHED_PACKAGE_ID'
export const RESET_CACHED_PACKAGE = 'RESET_CACHED_PACKAGE'
export const SET_CACHED_SHOULD_RESET_DATE = 'SET_CACHED_SHOULD_RESET_DATE'
export const GET_PACKAGE_DETAIL = 'GET_PACKAGE_DETAIL'
export const CLEAR_PACKAGE = 'CLEAR_PACKAGE'
export const CLEAR_PACKAGE_OPTIONS = 'CLEAR_PACKAGE_OPTIONS'
export const SET_PACKAGE_UNITS = 'SET_PACKAGE_UNITS'
export const SET_PACKAGE_TIME = 'SET_PACKAGE_TIME'
export const CHANGE_UNIT_COUNT = 'CHANGE_UNIT_COUNT'
export const SET_PACKAGE_SCHEDULE = 'SET_PACKAGE_SCHEDULE'
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SET_PACKAGE_UNITMAP = 'SET_PACKAGE_UNITMAP'
export const RESET_BOOKING_OPTIONS = 'RESET_BOOKING_OPTIONS'
export const SET_PRESALE_PACKAGES = 'SET_PRESALE_PACKAGES'
export const SET_ACTIVITY_INSTANT = 'SET_ACTIVITY_INSTANT'
export const SET_ACTIVITY_REVIEWS = 'SET_ACTIVITY_REVIEWS'
export const SET_REVIEWS_LIKED = 'SET_REVIEWS_LIKED'
export const CLEAR_REVIEW_LIST = 'CLEAR_REVIEW_LIST'
export const TRIGGER_REVIEW_LIST_VISIBLE = 'TRIGGER_REVIEW_LIST_VISIBLE'
export const GET_ACTIVITY_FAQ = 'GET_ACTIVITY_FAQ'
export const ACTIVITY_FAQ_VISIBLE = 'ACTIVITY_FAQ_VISIBLE'
export const SET_REOPEN_MODAL_VISIBLE = 'SET_REOPEN_MODAL_VISIBLE'
export const SET_REOPEN_REMIND = 'SET_REOPEN_REMIND'
export const SET_REOPEN_REMIND_SUCCESSS = 'SET_REOPEN_REMIND_SUCCESSS'
export const GET_PRICE_PACKAGE_SCHEDULE = 'GET_PRICE_PACKAGE_SCHEDULE'
export const SET_PRICE_PACKAGE_SCHEDULE = 'SET_PRICE_PACKAGE_SCHEDULE'
export const SHOW_DATE_PICKER = 'SHOW_DATE_PICKER'
export const KEEP_SOURCE_ACTIVITY_DETAIL = 'KEEP_SOURCE_ACTIVITY_DETAIL'
export const KEEP_SOURCE_PACKAGE_DETAIL = 'KEEP_SOURCE_PACKAGE_DETAIL'
export const SAVE_ACTIVITY_TRANSLATE = 'SAVE_ACTIVITY_TRANSLATE'
export const SAVE_PACKAGE_TRANSLATE = 'SAVE_PACKAGE_TRANSLATE'
export const CHANGE_TRANSLATE_STATUS = 'CHANGE_TRANSLATE_STATUS'
export const CMS_AND_PREVIEW = 'CMS_AND_PREVIEW'
export const CLEAR_ALL_CLICK = 'CLEAR_ALL_CLICK'
export const SET_MODIFICATION_SHOPPING_CART = 'SET_MODIFICATION_SHOPPING_CART'
export const SET_ROUTE_NAME = 'SET_ROUTE_NAME'
export const CHANGE_SCHEDULE_MODE = 'CHANGE_SCHEDULE_MODE'
export const GET_PROMOTION_DETAIL = 'GET_PROMOTION_DETAIL'
export const CLEAR_PROMOTION_DETAIL = 'CLEAR_PROMOTION_DETAIL'
export const BATCH_SET_UNITS_COUNT = 'BATCH_SET_UNITS_COUNT'
export const SET_UNITS_COUNT_TO_ONE = 'SET_UNITS_COUNT_TO_ONE'
export const SET_PRICE_PACKAGE_SCHEDULE_STATE = 'SET_PRICE_PACKAGE_SCHEDULE_STATE'
export const SET_EXPERIENCES_ABTEST = 'SET_EXPERIENCES_ABTEST'
export const SET_ACTIVITY_INFO = 'SET_ACTIVITY_INFO'
export const GET_ACTIVITY_CARDS = 'GET_ACTIVITY_CARDS'
export const SET_ACTIVITY_PREINFO = 'SET_ACTIVITY_PREINFO'
export const SET_ACTIVITY_DETAIL = 'SET_ACTIVITY_DETAIL'
export const SET_USER_AGENT = 'SET_USER_AGENT'
export const SET_PREVIEW_EXPIRED = 'SET_PREVIEW_EXPIRED'
export const SET_CURR_PACKAGE_OPTION_TAB = 'SET_CURR_PACKAGE_OPTION_TAB'
